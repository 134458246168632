import React, { useState, useEffect } from 'react';
import './NewPlan.scss';
import Header from './Header';
import SideBar from './SideBar';
import { Button, Card } from 'react-bootstrap';
import { serverUrl } from '../../serverUrl';
import jwt_decode from "jwt-decode";
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";

function NewPlan() {
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    const navigate = useNavigate()
    const months = Array.from({ length: 12 }, (_, i) => {
        const month = new Date(new Date().getFullYear(), i, 1);
        return month.toLocaleString("default", { month: "long" });
    });
    const currentMonthIndex = new Date().getMonth();
    const currentMonth = months[currentMonthIndex];
    const currentYear = new Date().getFullYear();

    const { id } = useParams();
    const accessToken = localStorage.getItem("authTokens");
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const [subscriptionPlan, setSubscriptionPlan] = useState({});

    useEffect(() => {
        serverUrl.get(`/landlord/subscription-amount-units/?apartment_id=${id}`, config)
            .then((res) => {
                setSubscriptionPlan(res.data.results[0]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [userId]);

    const handleAddSubscription = (plan, value) => {


        // get the subscription wallet
        serverUrl
            .get(`landlord/wallet/?property=${id}`, config)
            .then((res) => {
                const wallet = res.data.results.filter(
                    (wallet) => wallet.account_type === "subscription"
                );

                const data = {
                    property: id,
                    status: "inactive",
                    amount: parseInt(value),
                    duration: plan,
                    wallet: wallet[0].id,
                };
                // post the subscription data
                serverUrl
                    .post(`/landlord/subscriptions/`, data, config)
                    .then((res) => {
                        const invoiceData = {
                            apartment: id,
                            wallet: wallet[0].id,
                            subscription: res.data.id,
                            amount: parseInt(value),
                            month: currentMonth.toLocaleLowerCase(),
                            year: currentYear,
                            status: "unpaid",
                            user: userId,
                        };
                        serverUrl
                            .post(`/landlord/subscriptions-invoice/`, invoiceData, config)
                            .then((res) => {
                                toast.success("Succesfully created a subscription plan");
                                navigate(`/wallet-data/${wallet[0].id}`)
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='new-plan'>
                <h2>Plans and Pricing</h2>
                <p>Choose the right plan that suits you </p>
                <div className='d-flex justify-content-between displayCards'>
                    {subscriptionPlan && Object.entries(subscriptionPlan).map(([key, value]) => (
                        <Card key={key}>
                            <Card.Body>
                                <div className='border-bottom topBorderDisplay'>
                                    <div className='d-flex justify-content-between '>
                                        <h3 className='fw-bold' style={{ color: key === 'monthly' ? "#1F8CF8" : key === 'quartely' ? "#57F81F" : key === 'semi_annually' ? "#8e44ad" : "#34495e", fontSize: key === 'semi_annually' ? '23px' : '' }}>
                                            {key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ")}
                                        </h3>
                                        {key === 'quartely' && <span className='spanQuartely'>10% off</span>}
                                        {key === 'semi_annually' && <span className='spansemi_annually'>20% off</span>}

                                        {key === 'annually' && <span className='spanannually'>30% off</span>}
                                    </div>

                                    <p className='displayParagraph'>
                                        {key === 'monthly' && "Unleash seamless property management with our monthly subscription - your key to effortless control!"}
                                        {key === 'quartely' && "Elevate your property management game with our quarterly subscription - enjoy convenience with every quarter!"}
                                        {key === 'semi_annually' && "Dive into superior property management with our semi annual subscription - embrace long-lasting efficiency and savings!"}
                                        {key === 'annually' && "Experience unmatched property management excellence with our annual subscription - secure your success for years to come!"}
                                    </p>


                                </div>
                                <p className='mt-2'>
                                    <b className='fs-1'>
                                        Ksh {value ? value.toLocaleString() : ''}
                                    </b>/ per {key === 'monthly' ? 'month' : key === 'quartely' ? '3 months' : key === 'semi_annually' ? '6 months' : 'year'}
                                </p>
                                <Button onClick={() => handleAddSubscription(key, value)} className={`btn btn-warning text-white ${key === 'monthly' ? 'monthlyButton' : key === 'quartely' ? 'quartelyButton' : key === 'semi_annually' ? 'semi_annuallyButton' : 'annuallyButton'}`}>
                                    Subscribe
                                </Button>
                                <div className='mt-4'>
                                    <p style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="checkbox" checked={true} readOnly className="custom-checkbox" />
                                        <span>{key === 'monthly' ? 'Effortlessly manage your tenants with our monthly subscription!' : key === 'quartely' ? 'Stay on top of rent payments with detailed insights in our quarterly plan!' : key === 'semi_annually' ? 'Add new properties seamlessly with our semi annual subscription!' : 'Empower your tenants with personalized app access in our annual plan!'}</span>
                                    </p>
                                    <p style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="checkbox" checked={true} readOnly className="custom-checkbox" />
                                        <span>{key === 'monthly' ? 'Track rent payment statuses with ease!' : key === 'quartely' ? 'Effortlessly manage your properties in one account!' : key === 'semi_annually' ? 'Enjoy enhanced control and efficiency!' : 'Provide your tenants with convenient access to their accounts!'}</span>
                                    </p>
                                    <p style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="checkbox" checked={true} readOnly className="custom-checkbox" />
                                        <span>{key === 'monthly' ? 'Add new properties effortlessly!' : key === 'quartely' ? 'Empower your tenants with personalized access!' : key === 'semi_annually' ? 'Stay organized and efficient!' : 'Stay organized and efficient!'}</span>
                                    </p>
                                    <p style={{ display: 'flex', alignItems: 'center' }}>
                                        <input type="checkbox" checked={true} readOnly className="custom-checkbox" />
                                        <span>{key === 'monthly' ? 'Provide tenants with convenient access!' : key === 'quartely' ? 'Simplify your property management tasks!' : key === 'semi_annually' ? 'Effortlessly handle your property account!' : 'Effortlessly handle your property account!'}</span>
                                    </p>
                                </div>

                            </Card.Body>
                        </Card>
                    ))}
                </div>
            </div>
        </>
    );
}

export default NewPlan;
