import React, { useState, useEffect } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './Property.scss'
import './Blog.scss'
import { Button, Card, Container, Form, FormControl, Image, Modal } from 'react-bootstrap';
import Apartment9 from '../../assets/img/apartment9.jpg'

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import loaderImage from '../../assets/img/logo.png'


function Blogs() {
    const [showSidebar, setShowSidebar] = useState(true);

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);

    // related to apartment
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

    // counted house
    const [houseCount, setHouseCount] = useState({});

    // apartments of the landlord
    const [landlordApartment, setLandlordApartment] = useState([])

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const apartmentImage = {};

    const [apartmentAll, setApartmentAll] = useState([])

    // from the localstorage get the logged in user
    useEffect(() => {
        setIsLoading(true)

        // get the categories needed
        serverUrl.get(`/landlord/categories`, config)
            .then((res) => {
                setApartmentAll(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })

        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                // get the apartments all
                serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordIds}`, config)
                    .then((res) => {
                        // Filter the results where is_featured is true
                        const filteredApartments = res.data.results.filter(apartment => apartment.is_featured === false);

                        // Set the filtered results in state
                        // setApartmentAll(filteredApartments);
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                // using the landlord id get the apartments linked to the landlord
                const landlordApartmentPromises = landlordIds.map((landlordId) => {
                    return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => res.data.results)
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });
                });
                serverUrl.get(`/landlord/blog/`, config)
                    .then((res) => {
                        const apartmentImage = {}; // Create an object to store the images

                        // Create an array of promises for fetching blog images
                        const blogImagePromises = res.data.results.map((blog) => {
                            return serverUrl.get(`/landlord/blog-image/?blog_id=${blog.id}`, config)
                                .then((res) => {
                                    // Filter the images where is_main is true
                                    const mainImages = res.data.results.filter((image) => image.is_main === true);

                                    if (mainImages.length > 0) {
                                        apartmentImage[blog.id] = mainImages[0].image; // Store the first main image
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });

                        // Wait for all promises to resolve
                        Promise.all(blogImagePromises)
                            .then(() => {
                                setLandlordApartment(res.data.results);
                                setSelectedApartmentImage(apartmentImage);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)

            });

    }, []);



    // pasing the apartment Details to the modal
    const handleOpenModal = apartmentDetail => {
        setSelectedApartment(apartmentDetail);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    // delete apartment
    const handleDeleteApartment = () => {
        // the passed id from the modal
        const id = selectedApartment.id;
        serverUrl.delete(`/landlord/blog/${id}/`, config)
            .then((res) => {
                toast.success('Succesfully deleted the Blog!')
                setShowModal(false);
                // Filter the LandlordApartment state to remove the item with the updated ID
                const updatedLandlordApartments = landlordApartment.filter(apartment => apartment.id !== id);
                setLandlordApartment(updatedLandlordApartments);


            })
            .catch((error) => {
                console.log(error)
            })

    };


    // filter based on the property location
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);

    // Create a Set to store unique location status
    const uniquePropertyLocation = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueLocation = landlordApartment.filter((blog) => {
        if (!uniquePropertyLocation.has(blog.category)) {
            uniquePropertyLocation.add(blog.category);
            return true;
        }
        return false;
    });

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const filteredData = landlordApartment.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.category !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [landlordApartment]);

    // add the featured properties
    const [modalFeatured, setShowModalFeatured] = useState(false)

    const handleOpenFeatured = () => {
        setShowModalFeatured(true);
    };

    const handleCloseFeatured = () => {
        setShowModalFeatured(false);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    // function to add the property to featured
    const [selectedApartmentId, setSelectedApartmentId] = useState('')

    function handleApartmentSelect(selectedApartmentId) {
        setSelectedApartmentId(selectedApartmentId)
    }

    const [formDataBlog, setFormDataBlog] = useState({
        blog: "",
        description: "",
        long_description: "",
    });


    const handleChangeBlog = (event) => {
        const { name, value } = event.target;
        setFormDataBlog((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }


    function AddFeaturedProperty(e) {
        e.preventDefault();
        var isPublic = false

        if (document.querySelector('input[type="checkbox"]').checked) {
            var isPublic = true
        }

        // update the status is featured to true
        const data = {
            name: formDataBlog.blog,
            user: userId,
            description: formDataBlog.description,
            category: selectedApartmentId,
            long_description: formDataBlog.long_description,
            is_public: isPublic,
        }

        serverUrl.post(`/landlord/blog/`, data, config)
            .then((res) => {
                toast.success('Successfully added the Blog!');
                setShowModalFeatured(false);

                // Add the newly blog to the state
                setLandlordApartment((prevProperties) => [...prevProperties, res.data]);
                setFormDataBlog({
                    blog: "",
                    description: "",
                    long_description: "",
                })


            })
            .catch((error) => {
                console.log(error);
            });
    }

    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }

    return (

        <div>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='property'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>OUR BLOGS</p>

                </div>


                <Card className='cardProperty'>

                    <Form className="searchProperty">
                        <FormControl type="text" placeholder="Search Blog" className="mr-sm-2 rounded-4" onChange={handleSearch} />
                    </Form>

                    {/* <Form.Select size='sm' className='formProperty' value={selectedPropertyLocation} onChange={(e) => setPropertyLocation((e.target.value))} style={{ marginRight: "34rem", width: "40%" }}>
                        <option disabled selected>By Category</option>
                        <option value="">All Blogs</option>
                        {uniqueLocation.map((apartment) => (
                            <option value={apartment.category}>{apartment.category}</option>
                        ))}

                    </Form.Select> */}


                </Card>

                <div>
                    <Button className='propertyButton btn-sm'>
                        <Link to='/add-blog' className='text-white text-decoration-none'>
                            Add New Blog
                        </Link>
                    </Button>
                </div>
                {/* modal for featured property */}
                <Modal show={modalFeatured} onHide={handleCloseFeatured}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Blog</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={AddFeaturedProperty}>
                            <Form.Select
                                size='sm'
                                className='formProperty newInputCategory'
                                onChange={(e) => handleApartmentSelect(e.target.value)}
                            >
                                <option value="">Select Category</option>
                                {apartmentAll.map((blog) => (
                                    <option key={blog.id} value={blog.id}>
                                        {blog.name}
                                    </option>
                                ))}
                            </Form.Select>

                            <div className="input-icons">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    name="blog"
                                    value={formDataBlog.blog}
                                    onChange={handleChangeBlog}
                                    placeholder={"Blog Title"}
                                    required
                                />
                            </div>



                            <div className="input-icons">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    name="description"
                                    value={formDataBlog.description}
                                    onChange={handleChangeBlog}
                                    placeholder={"Short Description"}
                                    required
                                />
                            </div>
                            <div className="input-icons">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    name="long_description"
                                    value={formDataBlog.long_description}
                                    onChange={handleChangeBlog}
                                    placeholder={"Long Description"}
                                    required
                                />
                            </div>

                            <div className="">
                                <input type="checkbox" className='ms-4' /><span className='ms-2'>Make blog public</span>
                            </div>

                            <Button className='loginBtn mt-3 ms-4' type='submit'>Add</Button>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseFeatured}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* the cards that have the houses and their properties */}
                <div className='housesCards'>
                    {!displayedItems || landlordApartment.length === 0 ?
                        <p className='noHouse'>You do not have any blog at the moment kindly add one</p>
                        :
                        displayedItems.map((apartmentDetail, index) =>

                            <Card key={index.id} className='house'>
                                <Card.Body>
                                    <Link key={apartmentDetail.id} to={`/selected-blog/${apartmentDetail.id}`} className='text-danger text-decoration-none'>
                                        <div className="image-container">
                                            <Image src={selectedApartmentImage[apartmentDetail.id]} className="property-grid"></Image>

                                            <p className='text-muted float-left'>{apartmentDetail.location}</p>
                                            <br></br>
                                        </div>
                                        <p className='houseName' style={{ clear: "left" }}>{apartmentDetail.name}</p>
                                        <p></p>

                                    </Link>
                                    <div className='buttonsProperties'>
                                        <Link key={apartmentDetail.id} to={`/selected-blog/${apartmentDetail.id}`} className='text-danger text-decoration-none'>
                                            View Blog <i className='bi bi-arrow-right'></i>
                                        </Link>
                                        <button className="icon-button btn btn-danger bg-danger text-white" onClick={() => handleOpenModal(apartmentDetail)}>
                                            <i className='bi bi-trash'></i>
                                        </button>
                                        <Modal show={showModal} onHide={handleCloseModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Remove Blog</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Do you want to delete Blog?</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="success" onClick={handleCloseModal}>
                                                    No
                                                </Button>
                                                <Button apartmentId={apartmentDetail.id} variant="danger" onClick={handleDeleteApartment}>
                                                    Yes
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>


                                </Card.Body>
                            </Card>
                        )
                    }




                </div>
                {landlordApartment.length > 8 ?
                    <div className="pagination" style={{ marginLeft: "14rem" }}>
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                    :
                    <>
                    </>}


            </div>

        </div>
    )
}

export default Blogs




