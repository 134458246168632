import React, { useState, useEffect } from 'react'
import './WalletProperty.scss'
import { serverUrl } from "../../serverUrl";
import jwt_decode from "jwt-decode";
import { useParams, Link } from "react-router-dom";
import Header from './Header'
import SideBar from './SideBar'
import Wallet2 from '../../assets/img/wallet_2.png'
import { Table, Form, Image, Modal, Button, Row, Col, FormSelect, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';


function WalletProperty() {
    const { id } = useParams();
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const [wallets, setWallets] = useState([])

    const apartmentImage = {};
    const wallet_exists = {}

    const [apartment, setApartment] = useState([])

    const [walletTransactions, setWalletTransactions] = useState([]);

    useEffect(() => {
        // Fetch wallet data
        serverUrl
            .get(`/landlord/wallet/?property=${id}`, config)
            .then((res) => {
                setWallets(res.data.results);

                // Map through the results and fetch wallet transactions for each wallet
                res.data.results.forEach((wallet) => {
                    fetchWalletTransactions(wallet.id);
                });
            })
            .catch((error) => {
                console.log(error);
            });

        // get the apartment details
        serverUrl.get(`/landlord/apartment?id=${id}`, config)
            .then((res) => {
                setApartment(res.data.results[0])
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const fetchWalletTransactions = async (walletId) => {
        try {
            const response = await serverUrl.get(`/landlord/wallet-transcations/?wallet=${walletId}`, config);
            setWalletTransactions((prevTransactions) => [...prevTransactions, ...response.data.results]);
        } catch (error) {
            console.error(`Error fetching wallet transactions for wallet ${walletId}:`, error);
        }
    };

    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const totalPages = Math.ceil(wallets.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? wallets.length : startIndex + itemsPerPage;
    const displayedItems = wallets.slice(startIndex, endIndex);

    // const displayedItems = filteredData

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const [walletCreate, setWalletCreate] = useState(false)
    const [walletApartment, setWalletApartment] = useState('')
    const [apartmentUtilities, setApartmentUtilities] = useState([])


    const handleWalletCreate = () => {
        setWalletCreate(true)

        serverUrl.get(`/landlord/expense/?apartment_id=${id}`, config)
            .then((res) => {
                setApartmentUtilities(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const handleWalletClose = () => {
        setWalletCreate(false)
    }

    const [formData, setFormData] = useState({
        name: "",
        minimum_authorzers: "",
        account_type: "",
        collection_type: "",
        collection_type_id: "",
    });

    const [accountType, setAccountType] = useState('')
    const [collectionType, setCollectionType] = useState('')

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "account_type") {
            setAccountType(value);
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
        else if (name === "collection_type") {
            setCollectionType(value);
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
        else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }


    };
    const [landlordCount, setLandlordCount] = useState('')
    const [landlordId, setLandlordId] = useState('')

    useEffect(() => {
        // get the landlordId
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                setLandlordId(landlordIds)
            })
            .catch((error) => {
                console.log(error)
            })

        // get the landlords count in that apartment
        serverUrl.get(`/landlord/landlord-apartment/?apartment_id=${id}`, config)
            .then((res) => {
                setLandlordCount(res.data.count)
            })
            .catch((error) => {
                console.log(error)
            })

    }, [])


    function handleAddAccount(e) {
        e.preventDefault();
        // create the new wallet account
        let count_authorizer
        if (landlordCount <= 1) {
            count_authorizer = 1
            // create the authorizer and make them a maker
            const data = {
                property: id,
                authorizer_type: "maker",
                landlord: landlordId[0]
            }
            serverUrl.post('/landlord/wallet-authorizers/', data, config)
                .then((res) => {
                })
                .catch((error) => {
                    console.log(error)
                })

        }
        else {
            count_authorizer = formData.minimum_authorzers
        }

        const data = {
            name: formData.name,
            property: id,
            minimum_authorzers: count_authorizer,
            account_type: accountType,
            collection_type_id: formData.collection_type_id,
            collection_type: collectionType,
        }
        serverUrl.post('/landlord/wallet/', data, config)
            .then((res) => {
                setFormData({
                    name: "",
                    property: "",
                    minimum_authorzers: "",
                    account_type: "",
                    collection_type_id: "",
                    collection_type: "",
                })
                setWalletCreate(false)
                serverUrl
                    .get(`/landlord/wallet/?property=${id}`, config)
                    .then((res) => {
                        setWallets(res.data.results);

                    })
                    .catch((error) => {
                        console.log(error);
                    });
                // setApartmentWallte(wallet_exists)
                toast.success('Succesfully created a wallet account')

            })
    }

    const isButtonActive = (walletId) => {
        // Find the latest transaction for the given wallet
        const latestTransaction = walletTransactions
            .filter((transaction) => transaction.wallet === walletId)
            .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))[0];

        if (!latestTransaction) {
            // If there are no transactions, consider the button inactive
            return false;
        }

        const updatedDate = new Date(latestTransaction.updated_at);
        const currentDate = new Date();

        // Calculate the difference in months
        const monthDiff = (currentDate.getFullYear() - updatedDate.getFullYear()) * 12 +
            currentDate.getMonth() - updatedDate.getMonth();

        return monthDiff < 6;
    };

    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            <div className='reports_dashboard'>
                <div className='topSelectedProperty' style={{ backgroundImage: `url(${Wallet2})` }}>

                    <p className='properties text-uppercase'>{apartment.name} WALLET</p>

                </div>


                <div className='tasks' >
                    <div className='d-flex justify-content-between'>

                        <h5 className='float-start'>Wallet(s) in {apartment.name}</h5>
                        <Button className='btn btn-primary btn-sm text-white me-3 p-2' style={{ height: "fit-content" }} onClick={handleWalletCreate}> <i class="bi bi-plus fw-bold"></i> Create Wallet</Button>

                    </div>

                    <div className='d-flex justify-content-between d-none'>

                        <div>
                            <Button className='btn btn-success text-white me-3'> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
                        </div>
                        <Form className="searchBar">
                            <FormControl type="text" placeholder="Search" />
                        </Form>
                    </div>

                    <Table className='table'>
                        <thead className='underline'>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Account Type</th>
                            <th>Account Status</th>
                            <th>Used For Collection</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {displayedItems.map((wallet) =>
                                <tr>
                                    <td>{wallet.name}</td>
                                    <td>{wallet.account_number}</td>
                                    <td className='text-capitalize'>{wallet.account_type}</td>
                                    <td>
                                        <button
                                            className={`btn btn-sm ${isButtonActive(wallet.id)
                                                ? 'btn-success opacity-75'
                                                : 'btn-danger'
                                                }`}
                                            disabled={!isButtonActive(wallet.id)}
                                        >
                                            {isButtonActive(wallet.id) ? 'Active' : 'Inactive'}
                                        </button>
                                    </td>

                                    {wallet.account_type === "collection" ?
                                        <td>Yes</td>

                                        :
                                        <td>No</td>
                                    }
                                    <td><Button className='btn btn-sm btn-info text-white' href={`/#/property-wallet/${wallet.id}`}><i className='bi bi-eye'></i> View Account</Button></td>


                                </tr>
                            )}


                        </tbody>
                    </Table>

                    <Modal show={walletCreate} onHide={handleWalletClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add a wallet account</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleAddAccount}>
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        placeholder="Wallet Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                {landlordCount <= 1 ?

                                    <></>
                                    :
                                    <div className="input-icons">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="number"
                                            placeholder="Minimum Authorizers"
                                            name="minimum_authorzers"
                                            value={formData.minimum_authorzers}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                }


                                <div className="input-icons">
                                    <FormSelect
                                        className="formProperty"
                                        name='account_type'
                                        value={formData.account_type}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option disabled value=''>
                                            Select Account Type
                                        </option>
                                        {/* <option value='subscription'>
                                            Subscription
                                        </option> */}
                                        <option value='collection'>
                                            Collection
                                        </option>
                                    </FormSelect>
                                </div>


                                {accountType === "collection" ?
                                    <div className="input-icons">
                                        <FormSelect
                                            className="formProperty"
                                            name='collection_type'
                                            value={formData.collection_type}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option disabled value="">
                                                Select Collection Category
                                            </option>
                                            <option value='rent' selected="">
                                                Rent
                                            </option>

                                            <option value='utility' selected="">
                                                Utility
                                            </option>

                                        </FormSelect>

                                    </div>

                                    :
                                    <>
                                    </>
                                }

                                {collectionType === "utility" ?
                                    <div className="input-icons">
                                        <FormSelect
                                            className="formProperty"
                                            name='collection_type_id'
                                            value={formData.collection_type_id}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option disabled value="">
                                                Select Utility
                                            </option>
                                            {apartmentUtilities.length === 0 ?

                                                <option disabled selected >No utility add one kindly</option>
                                                :
                                                apartmentUtilities.map((utility) => (
                                                    <option value={utility.id}>{utility.title}</option>
                                                ))
                                            }

                                        </FormSelect>

                                    </div>

                                    :
                                    <>
                                    </>
                                }

                                <Modal.Footer>
                                    <Button variant="primary" type='submit'>
                                        Save
                                    </Button>
                                    <Button onClick={handleWalletClose} variant='secondary'>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal.Body>

                    </Modal>

                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${wallets.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>
                    {/* the conversion of the pdf  */}



                </div>
            </div>
        </>
    )
}

export default WalletProperty