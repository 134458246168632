import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from "react-bootstrap";

// styles
import './WalletLogin.scss';

// importing components
import HomeCarousel from '../../components/HomeCarousel';
import HomeFooter from '../../components/HomeFooter';
import { serverUrl } from '../../serverUrl';
import jwt_decode from "jwt-decode"
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify';


function WalletOtp() {
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 13) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 13) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/LandingPage/${randomNumber}.jpg`;
            setBackgroundImageApartment(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
        };

        getRandomImage();
    }, []);

    const images = [backgroundImageApartment];

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const property_id_wallet = localStorage.getItem("wallet_property")
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const [landlordId, setLandlordId] = useState('')

    useEffect(() => {
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                setLandlordId(res.data.results[0].id)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])
    const [formData, setFormData] = useState({
        otp: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const storedDataString = localStorage.getItem('wallet_new_details');
    const storedData = JSON.parse(storedDataString);

    function handleOtp(e) {
        e.preventDefault();

        const data = {
            landlord: landlordId,
            apartment: parseInt(property_id_wallet),
            otp: formData.otp
        }
        // post the data and set the active walet is the one clicked on
        serverUrl.get(`/landlord/landlord-wallet-otp/?otp=${formData.otp}`, config)
            .then((res) => {

                if (storedData) {
                    serverUrl.post('/landlord/wallet/', storedData, config)
                        .then((res) => {

                            if (res.data.minimum_authorzers === 1) {
                                const data = {
                                    landlord: landlordId,
                                    property: parseInt(property_id_wallet),
                                    authorizer_type: 'maker'
                                }
                                serverUrl.post(`/landlord/wallet-authorizers/`, data, config)
                                    .then((res) => {
                                        toast.success('Succesfully created a wallet account')
                                        navigate(`/wallet-property/${parseInt(storedData.property)}`)
                                        localStorage.removeItem('wallet_new_details')
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })

                            }
                            else {
                                toast.success('Succesfully created a wallet account')
                                navigate(`/wallet-property/${parseInt(storedData.property)}`)
                                localStorage.removeItem('wallet_new_details')
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
                else {
                    navigate(`/wallet-property/${parseInt(property_id_wallet)}`)
                }


            })
            .catch((error) => {
                console.log(error)
            })


    }

    return (
        <div className='homeCard'>
            <div className="image-container">
                <HomeCarousel images={images} className="imageHome" />
                <div className="wave"></div>
            </div>
            <div className='main-content '>
                <h2 className='text-capitalize fs-5 fw-bold mt-3'>Kindly enter your otp</h2>

                <Form onSubmit={handleOtp}>
                    <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <input
                            className="input-field"
                            type="number"
                            placeholder="Enter Otp"
                            name="otp"
                            value={formData.otp}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <Button variant="primary" type="submit" className="loginBtn">
                        Confirm Otp

                    </Button>
                </Form>
            </div>

        </div>
    );
}

export default WalletOtp;
