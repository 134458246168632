import React, { useState, useEffect, useRef } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import { Table, Button, Form, FormControl, Card, Modal, Image, Row, Col, Tab, FormSelect, InputGroup, ModalHeader } from 'react-bootstrap';
import './Wallet.scss'
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import WithdrawR from '../../assets/img/money-withdrawal.png'
import Withdraw from '../../assets/img/purse.png'
import Deposit from '../../assets/img/deposit.png'
import Cost from '../../assets/img/withdraw.png'
import Wallet1 from '../../assets/img/wallet_1.png'
import WalletCheckers from './WalletCheckers';
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from 'react-toastify';


function Wallet() {
    // sidebar and header functions
    // getting the id and finding the apparment details
    const { id } = useParams();
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // selected Apartment
    const [apartmentSelected, setApartmentSelected] = useState([])
    // get the user who is logged in and the date related to them
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const [selectedWallet, setSelectedWallet] = useState({})
    const [walletBalance, setWalletBalance] = useState('')
    const [transactionRecords, setTransactionRecords] = useState([])
    const [incompleteTransactions, setIncompleteTransactions] = useState([])
    const [landlordId, setLandlordId] = useState('')
    const [apartmentWallet, setApartmentWallet] = useState([])
    const [pesaWiseBank, setPesaWiseBank] = useState([])
    const [walletAuthorizers, setWalletAuthorizers] = useState([])
    const [walletAuthorizersCount, setWalletAuthorizersCount] = useState('')

    useEffect(() => {
        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                setLandlordId(res.data.results[0].id)
                // using the landlord id get the apartments linked to the landlord
                landlordIds.map((landlordId) => {
                    return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => {
                            const selectedApartmentObj = res.data.results.find(utilitiesApartment => utilitiesApartment.id === parseInt(id));
                            setApartmentSelected(selectedApartmentObj)
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                });
            })
            .catch((error) => {
                console.log(error);
            });



        //   the wallet selected
        serverUrl.get(`/landlord/wallet/${id}/`, config)
            .then((res) => {
                setSelectedWallet(res.data)
                localStorage.setItem('active_wallet_property', res.data.property)
            })
            .catch((error) => {
                console.log(error)
            })

        // get the wallet balannce
        serverUrl.get(`/landlord/wallet-account-balance?wallet=${id}`, config)
            .then((res) => {
                setWalletBalance(res.data.results[0])
            })
            .catch((error) => {
                console.log(error)
            })

        // get the wallet transactions records
        serverUrl.get(`/landlord/wallet-transcations/?wallet=${id}`, config)
            .then((res) => {
                const successfulTransactions = res.data.results.filter((transaction) => transaction.status === 'success')
                setTransactionRecords(successfulTransactions)

                const unSuccessfulTransactions = res.data.results.filter((transaction) => transaction.status !== 'success');
                setIncompleteTransactions(unSuccessfulTransactions)

            })
            .catch((error) => {
                console.log(error)
            })

        // get the wallets that are within that apartment
        serverUrl.get(`/landlord/wallet/?property=${activeProperty}`, config)
            .then((res) => {
                setApartmentWallet(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })


        // wallet authorizers
        serverUrl.get(`/landlord/wallet-authorizer-widthraw-details/?property_id=${activeProperty}`, config)
            .then((res) => {
                setWalletAuthorizers(res.data.results)
                setWalletAuthorizersCount(res.data.count)
                console.log(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
        serverUrl.get(`/landlord/get-bank-details/`, config)
            .then((res) => {
                setPesaWiseBank(res.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])
    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');

    useEffect(() => {

        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 7) + 1;
            const invalidNumbers = [0, -1];
            while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 7) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/reports/reports${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
        };

        getRandomImage();
    }, []);

    const [sortType, setSortType] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };
    const sortedData = transactionRecords.sort((a, b) => {
        const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === 'asc') {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredData = sortedData.filter((item) => {
        const searchableFields = Object.values(item).join(' ').toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    const exportCSVIncomplete = () => {
        const csvData = [
            ['Date', 'To/From', 'Amount', 'Status', 'Balance', 'Transaction Type', 'Transaction Cost'],
            ...incompleteTransactions.map((item) => [new Date(item.updated_at).toLocaleDateString(), item.recepient_name, item.amount, item.status, item.current_balance, item.transcation_type, item.transaction_cost]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'incomplete_transaction_records.csv');
    };

    const [sortTypeIncomplete, setSortTypeIncomplete] = useState('name');
    const [sortOrderIncomplete, setSortOrderIncomplete] = useState('asc');
    const [searchQueryIncomplete, setSearchQueryIncomplete] = useState('');
    const [selectedRowIncomplete, setSelectedRowIncomplete] = useState(null);

    const handleSearchIncomplete = (event) => {
        setSearchQueryIncomplete(event.target.value);
        setSelectedRowIncomplete(null); // Reset selected row when user starts a new search
    };
    const sortedDataIncomplete = incompleteTransactions.sort((a, b) => {
        const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === 'asc') {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredDataIncomplete = sortedDataIncomplete.filter((item) => {
        const searchableFields = Object.values(item).join(' ').toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    const [currentPageIncomplete, setCurrentPageIncomplete] = useState(0);
    const itemsPerPageIncomplete = 5;

    const totalPagesIncomplete = Math.ceil(filteredDataIncomplete.length / itemsPerPageIncomplete);
    const startIndexIncomplete = currentPageIncomplete * itemsPerPageIncomplete;
    const endIndexIncomplete = currentPageIncomplete === totalPagesIncomplete - 1 ? filteredDataIncomplete.length : startIndexIncomplete + itemsPerPageIncomplete;
    const displayedItemsIncomplete = filteredDataIncomplete.slice(startIndexIncomplete, endIndexIncomplete);

    // modal for the receipt view
    const [receiptModal, setShowReceiptModal] = useState(false)
    const [transactionId, setTransactionId] = useState('')
    const handleOpenReceipt = (id) => {
        setShowReceiptModal(true)
        setTransactionId(id)
    }
    const handleCloseReceipt = () => {
        setShowReceiptModal(false)
    }
    const printAreaRef = useRef();

    const handlePrintReceipt = () => {
        const printableContent = generatePrintableContent();

        const printWindow = window.open('', '_blank');
        printWindow.document.write(printableContent);
        printWindow.document.close();
        printWindow.print();
    };

    const generatePrintableContent = () => {
        // Generate the content you want to print
        const content = transactionRecords.map((record) => (
            record.id === transactionId ? (
                `<div key=${record.id}>
              <p class='fw-bold'>${record.recepient_name}</p>
              <p class='fw-bold'>${record.recepient_phone_number}</p>
              <p><b>Date:</b><span>${new Date(record.updated_at).toLocaleString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: true,
                })}</span></p>
              <p><b>Amount:</b>KES ${record.amount}</p>
              <p><b>Transaction Cost:</b> KES ${record.transaction_cost}.00</p>
              <p><b class='fw-bold'>Payment Reason:</b>  <span>${record.reason}</span></p>
              <p class='text-center mt-2'>Generated On ${formattedDate}</p>
            </div>`
            ) : ''
        ));

        return `<div>${content.join('')}</div>`;
    };


    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    // show balance
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    //   show account
    const [showBalance, setShowBalance] = useState(false);

    const handleToggleBalanace = () => {
        setShowBalance((prevShowBalance) => !prevShowBalance);
    };

    // the list of the widthraws
    const [showWithdrawal, setShowWithdrawal] = useState(false)

    const handleOpenWithdraw = () => {
        setShowWithdrawal(true)
    }
    const handleCloseWithdraw = () => {
        setShowWithdrawal(false)
        window.location.reload()
    }
    // the view withdrawal request
    const [showWithdrawalModal, setShowWithdrawalModal] = useState(false)
    const [pendingTransaction, setPendingTransaction] = useState({})

    const handleOpenRequest = (incompleteTransactionId) => {
        setShowWithdrawalModal(true)
        setShowWithdrawal(false)
        serverUrl.get(`/landlord/wallet-transcations/${incompleteTransactionId}/`, config)
            .then((res) => {
                console.log(res.data)
                setPendingTransaction(res.data)
            })
            .catch((error) => {
                console.log(error)
            })

    }
    const handleCloseRequest = () => {
        setShowWithdrawalModal(false)
    }

    // the calculator modal
    const [showCalculator, setShowCalculator] = useState(false)

    const handleOpenCalculator = () => {
        setShowCalculator(true)

    }
    const handleCloseCalculator = () => {
        setShowCalculator(false)
        window.location.reload()

    }

    // the deposit calculator
    const [showDeposit, setShowDeposit] = useState(false)
    const handleOpenDeposit = () => {
        setShowDeposit(true)

    }

    const handleCloseDeposit = () => {
        setShowDeposit(false)
        window.location.reload()
    }

    // modal for the withdraw view
    const [makeWithdraw, setShowMakeWithdraw] = useState(false)

    const handleOpenMakeWithdraw = () => {
        setShowMakeWithdraw(true)
    }
    const handleCloseMakeWithdraw = () => {
        setShowMakeWithdraw(false)
    }
    // if the bank or mpesa is selected
    const [selectedChannel, setSelectedChannel] = useState('');


    const handleChannelChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedChannel(selectedValue);

    };

    // if the transaction or incomplete transactions
    const [selectedTable, setSelectedTable] = useState('transaction');

    const handleTableChange = (selectedValue) => {
        if (selectedValue === 'transaction') {
            setSelectedTable('transaction');
        } else if (selectedValue === 'incomplete') {
            setSelectedTable('incomplete');
        }
    };

    // modal for the add bank account name view
    const [addAccount, setShowAddAccount] = useState(false)
    const handleOpenAddAccount = () => {
        setShowAddAccount(true)
        handleCloseMakeWithdraw()
    }
    const handleCloseAddAccount = () => {
        setShowAddAccount(false)
    }




    const [formWithdraw, setFormWithdraw] = useState({
        amount: "",
        reference: "",
        recepient_name: "",
        recepient_phone_number: "",
        reason: "",
        bank: "",
        selectedLandlord: '',


    })
    const handleChangeWithdraw = (event) => {
        const { name, value } = event.target;
        let errorMessage = "";
        if (name === "recepient_phone_number") {
            // Check if the entered number starts with '+254' and has at most 12 digits
            if (!value.startsWith("254") || value.length > 13 || isNaN(value.slice(4))) {
                errorMessage = "Invalid phone number format. Please enter a valid number starting with '254' and followed by up to 9 digits.";
            }
        }

        setFormWithdraw((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            errorMessage: errorMessage

        }));
    };


    const handleLandlordSelection = (event) => {
        const selectedLandlordInfo = event.target.value.split('|');
        const selectedLandlordName = selectedLandlordInfo[0];
        const selectedLandlordPhone = selectedLandlordInfo[1];
        const selectedLandlordPhoneNumber = selectedLandlordPhone.replace(/^0/, '254');

        setFormWithdraw((prevFormWithdraw) => ({
            ...prevFormWithdraw,
            selectedLandlord: selectedLandlordName,
            recepient_name: selectedLandlordName,
            recepient_phone_number: selectedLandlordPhoneNumber,
        }));
    };

    // withdraw confirmation
    const [widthrawConfirm, setWithdrawConfirm] = useState(false)

    const handleOpenConfirmWithdraw = () => {
        setWithdrawConfirm(true)
        handleCloseMakeWithdraw()
    }
    const handleCloseConfirmWithdraw = () => {
        setWithdrawConfirm(false)
    }

    // withdraw otp transaction confirmation
    const [widthrawConfirmOtp, setWithdrawConfirmOtp] = useState(false)

    const handleMakeWithdraw = () => {
        // update the data later on
        const data = {
            amount: formWithdraw.amount,
            recepient_phone_number: formWithdraw.recepient_phone_number,
            recepient_name: formWithdraw.recepient_name,
            reason: formWithdraw.reason,
            transcation_type: 'outbound',
            bank: formWithdraw.bank,
            landlord: landlordId,
            payment_type: selectedChannel,

        }
    }

    const [formBank, setFormBank] = useState({
        bank: "",
        account_number: "",

    })
    const handleChangeBank = (event) => {
        const { name, value } = event.target;

        setFormBank((prevFormData) => ({
            ...prevFormData,
            [name]: value,

        }));
    };

    const [formWalletID, setFormWalletID] = useState({
        wallet: "",

    })
    const handleChangeWallet = (event) => {
        const { name, value } = event.target;

        setFormWalletID((prevFormData) => ({
            ...prevFormData,
            [name]: value,

        }));
    };



    const [landlordCount, setLandlordCount] = useState('')

    const [authorizerLandlord, setAuthorizerLandlord] = useState('')
    const [bankAccount, setBankAccount] = useState([])
    const [currentLandlord, setCurrentLandlord] = useState([])


    useEffect(() => {
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {

                serverUrl.get(`/landlord/get-bank-authorizer/?apartment_id=${activeProperty}&landlord_id=${res.data.results[0].id}`, config)
                    .then((res) => {
                        setBankAccount(res.data.results)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error);
            });


        serverUrl.get(`/landlord/property_landlord_details?property_id=${activeProperty}`, config)
            .then((res) => {
            })
            .catch((error) => {
                console.log(error)
            })

        serverUrl.get(`/landlord/all_apartement_owners/?apartment_id=${activeProperty}`, config)
            .then((res) => {
                setCurrentLandlord(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })

        serverUrl.get(`/landlord/landlord-apartment/?apartment_id=${activeProperty}`, config)
            .then((res) => {
                setLandlordCount(res.data.count)
            })
            .catch((error) => {
                console.log(error)
            })
        // get the authorizers for that apartment
        serverUrl.get(`/landlord/wallet-authorizers/?property=${activeProperty}`, config)
            .then((res) => {
                setAuthorizerLandlord(res.data.results[0])
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])


    const handleBankAdd = () => {

        const data = {
            bank: formBank.bank,
            account_number: formBank.account_number,
            created_by: landlordId,
            modified_by: landlordId,
            wallet_authorizer: authorizerLandlord.id
        }
        serverUrl.post(`/landlord/bank-account/`, data, config)
            .then((res) => {
                setShowAddAccount(false)
                toast.success('Succesfully added the bank account')
            })

            .catch((error) => {
                console.log(error)
            })
    }

    const [walletTransactionSet, setWalletTransactionset] = useState({})

    const handleOpenConfirmWithdrawOtp = () => {
        setWithdrawConfirmOtp(true)
        setWithdrawConfirm(false)

        if (selectedChannel === "Wallet") {
            // post the transaction for the wallet
            // get the details of the selected wallet
            serverUrl.get(`/landlord/wallet/${formWalletID.wallet}/`, config)
                .then((res) => {
                    const walletDetails = res.data
                    serverUrl.get(`/landlord/wallet-account-balance?wallet=${res.data.id}`, config)
                        .then((res) => {
                            const data = {
                                reference: selectedChannel,
                                recepient_phone_number: walletDetails.account_number,
                                recepient_name: walletDetails.name,
                                reason: formWithdraw.reason,
                                wallet: formWalletID.wallet,
                                apartment: activeProperty,
                                amount: formWithdraw.amount,
                                current_balance: parseInt(res.data.results[0].wallet_balance) + parseInt(formWithdraw.amount),
                                status: 'success',
                                transcation_type: "inbound",
                                landlord: landlordId,
                            }

                            // post the results now
                            serverUrl.post('/landlord/wallet-transcations/', data, config)
                                .then((res) => {
                                    const otpdata = {
                                        apartment: activeProperty,
                                        landlord: landlordId,
                                        wallet_transcations: res.data.id
                                    }
                                    setWalletTransactionset(res.data)
                                    console.log(res.data)
                                    // serverUrl.post('/landlord/otp-transaction-authorizer/', otpdata, config)
                                    //     .then((res) => {
                                    //         toast.success('Succesfully sent an otp to your phone')
                                    //     })
                                    //     .catch((error) => {
                                    //         console.log(error)
                                    //     })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch((error) => {

                        })


                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else if (selectedChannel === "Bank") {
            // post the data and add a place for the bank details
            const data = {
                reference: selectedChannel,
                reason: formWithdraw.reason,
                wallet: id,
                apartment: activeProperty,
                amount: formWithdraw.amount,
                current_balance: (walletBalance.wallet_balance - formWithdraw.amount),
                status: 'pending',
                transcation_type: "outbound",
                bank: formWithdraw.bank,
                payment_type: "bank"
            }

            // post the results now
            serverUrl.post('/landlord/wallet-transcations/', data, config)
                .then((res) => {
                    const otpdata = {
                        apartment: activeProperty,
                        landlord: landlordId,
                        wallet_transcations: res.data.id
                    }
                    setWalletTransactionset(res.data)

                    serverUrl.post('/landlord/otp-transaction-authorizer/', otpdata, config)
                        .then((res) => {
                            toast.success('Succesfully sent an otp to your phone')

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        else if (selectedChannel === "MPESA") {
            const data = {
                reference: selectedChannel,
                reason: formWithdraw.reason,
                wallet: id,
                apartment: activeProperty,
                amount: formWithdraw.amount,
                current_balance: (walletBalance.wallet_balance - formWithdraw.amount),
                status: 'pending',
                transcation_type: "outbound",
                recepient_phone_number: formWithdraw.recepient_phone_number,
                recepient_name: formWithdraw.recepient_name,
                transaction_cost: 0,
                payment_type: "mpesa",
                landlord: landlordId
            }
            // post the results now
            serverUrl.post('/landlord/wallet-transcations/', data, config)
                .then((res) => {
                    const otpdata = {
                        apartment: activeProperty,
                        landlord: landlordId,
                        wallet_transcations: res.data.id
                    }
                    setWalletTransactionset(res.data)
                    serverUrl.post('/landlord/otp-transaction-authorizer/', otpdata, config)
                        .then((res) => {
                            toast.success('Succesfully sent an otp to your phone')
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })


        }

    }
    const handleCloseConfirmWithdrawOtp = () => {
        setWithdrawConfirmOtp(false)
    }

    const [formWithdrawOtp, setFormWithdrawOtp] = useState({
        otp: "",

    })
    const handleChangeWithdrawOtp = (event) => {
        const { name, value } = event.target;

        setFormWithdrawOtp((prevFormData) => ({
            ...prevFormData,
            [name]: value,

        }));
    };


    function handleWithdraw(e) {
        e.preventDefault();
        // confirm if the otp is correct
        const confirmData = {
            transcation_authorization_status: 'success',
            otp_status: 'approved'
        }
        if (selectedChannel === 'Wallet') {
            const data = {
                amount: walletTransactionSet.amount,
                apartment: activeProperty,
                current_balance: parseInt(walletBalance.wallet_balance) - parseInt(walletTransactionSet.amount),
                landlord: landlordId,
                reason: walletTransactionSet.reason,
                reference: 'Wallet',
                wallet: id,
                transcation_type: "outbound",
                status: 'success',
                recepient_name: walletTransactionSet.recepient_name,
                recepient_phone_number: walletTransactionSet.recepient_phone_number
            }

            // get to transfer the money to the other account 
            serverUrl.get(`/landlord/otp-transaction-authorizer/?otp=${formWithdrawOtp.otp}`, config)
                .then((res) => {
                    serverUrl.patch(`/landlord/otp-transaction-authorizer/${res.data.results[0].id}/`, confirmData, config)
                        .then((res) => {
                            setWithdrawConfirmOtp(false)
                            serverUrl.post('/landlord/wallet-transcations/', data, config)
                                .then((res) => {
                                    toast.success('Transfer of funds was succesful')
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        else if (selectedChannel === 'MPESA') {
            const data = {
                otp: formWithdrawOtp.otp,
                wallet_id: parseInt(id),
                wallet_trans_id: walletTransactionSet.id,
                landlord_id: landlordId,
                property_id: parseInt(activeProperty)
            }
            // get to transfer the money to the other account 
            serverUrl.get(`/landlord/otp-transaction-authorizer/?otp=${formWithdrawOtp.otp}`, config)
                .then((res) => {
                    serverUrl.patch(`/landlord/otp-transaction-authorizer/${res.data.results[0].id}/`, confirmData, config)
                        .then((res) => {
                            setWithdrawConfirmOtp(false)
                            serverUrl.get(`/landlord/verify-otp-authorizer?property_id=${parseInt(activeProperty)}&landlord_id=${landlordId}&otp=${formWithdrawOtp.otp}&wallet_id=${id}&wallet_trans_id=${walletTransactionSet.id}`, config)
                                .then((res) => {
                                    // console.log(res)
                                    toast.success('Transfer of funds was succesful')
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }


        else if (selectedChannel === 'Bank') {
            const data = {
                otp: formWithdrawOtp.otp,
                wallet_id: parseInt(id),
                wallet_trans_id: walletTransactionSet.id,
                landlord_id: landlordId,
                property_id: parseInt(activeProperty)

            }

            // get to transfer the money to the other account 

            serverUrl.get(`/landlord/otp-transaction-authorizer/?otp=${formWithdrawOtp.otp}`, config)
                .then((res) => {
                    serverUrl.patch(`/landlord/otp-transaction-authorizer/${res.data.results[0].id}/`, confirmData, config)
                        .then((res) => {
                            setWithdrawConfirmOtp(false)
                            serverUrl.get(`/landlord/verify-otp-authorizer?property_id=${parseInt(activeProperty)}&landlord_id=${landlordId}&otp=${formWithdrawOtp.otp}&wallet_id=${id}&wallet_trans_id=${walletTransactionSet.id}`, config)
                                .then((res) => {
                                    console.log(res)
                                    toast.success('Transfer of funds was succesful')
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }

        // serverUrl.get(`/landlord/otp-transaction-authorizer/?otp=${formWithdrawOtp.otp}`, config)
        //     .then((res) => {
        //         serverUrl.patch(`/landlord/otp-transaction-authorizer/${res.data.results[0].id}/`, confirmData, config)
        //             .then((res) => {
        //                 setWithdrawConfirmOtp(false)
        //                 serverUrl.post('/landlord/wallet-transcations/', data, config)
        //                 .then((res) => {
        //                     toast.success('Transfer of funds was succesful')
        //                 })
        //                 .catch((error)=>{
        //                     console.log(error)
        //                 })
        //             })
        //             .catch((error) => {
        //                 console.log(error)
        //             })
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //     })

    }

    // modal for downloading the report
    const [downloadReport, setShowDownloadReport] = useState(false)
    const handleOpenDownloadReport = () => {
        setShowDownloadReport(true)
    }
    const handleCloseDownloadReport = () => {
        setShowDownloadReport(false)
    }

    // filtter date
    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };
    const currentDate = new Date();
    const options = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
    };
    const activeProperty = localStorage.getItem('active_wallet_property')

    const formattedDate = currentDate.toLocaleString('en-US', options);

    // pass the deposit
    const [formData, setFormData] = useState({
        phone_number: "",
        amount: ""
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        let errorMessage = "";
        if (name === "phone_number") {
            // Check if the entered number starts with '+254' and has at most 12 digits
            if (!value.startsWith("254") || value.length > 13 || isNaN(value.slice(4))) {
                errorMessage = "Invalid phone number format. Please enter a valid number starting with '254' and followed by up to 9 digits.";
            }
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
            errorMessage: errorMessage

        }));
    };

    function handleSubmitDeposit(e) {
        e.preventDefault();

        const current_balance = parseFloat(walletBalance.wallet_balance);
        const formDataDeposit = new FormData();
        formDataDeposit.append('property_id', activeProperty);
        formDataDeposit.append('landlord_id', landlordId);
        formDataDeposit.append('wallet_id', id);
        formDataDeposit.append('phone_number', formData.phone_number);
        formDataDeposit.append('current_balance', current_balance);
        formDataDeposit.append('amount', formData.amount);

        const config = {
            'Content-Type': 'application/json',
            headers: { Authorization: `Bearer ${access}` }
        }

        serverUrl.post(`/landlord/deposit-wallet-transaction`, formDataDeposit, config)
            .then((res) => {
                toast.success('Wait for you to input your pin')
                setShowDeposit(false)
                window.location.reload()
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const [amount, setAmount] = useState('');
    const [withdrawTo, setWithdrawTo] = useState('');
    const [processingFee, setProcessingFee] = useState(0);


    const handleAmountChange = (e) => {
        const enteredAmount = e.target.value;
        setAmount(enteredAmount);

    }
    useEffect(() => {
        if (amount > 1 || formWithdraw.amount < 1 || formWithdraw.amount === null) {
            serverUrl.get(`/landlord/get-processing-fee/?payment_type=${withdrawTo}&amount=${amount}`, config)
                .then((res) => {
                    setProcessingFee(res.data.fee)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else if (formWithdraw.amount > 1 || amount < 1 || amount === null) {
            serverUrl.get(`/landlord/get-processing-fee/?payment_type=${withdrawTo}&amount=${amount}`, config)
                .then((res) => {
                    setProcessingFee(res.data.fee)
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }, [withdrawTo, amount])

    const [balanceError, setBalanceError] = useState('');

    useEffect(() => {
        const withdrawalAmount = parseFloat(formWithdraw.amount);
        const currentBalance = parseFloat(walletBalance.wallet_balance);

        if (isNaN(withdrawalAmount) || withdrawalAmount <= 0) {
            setBalanceError('');
            return;
        }

        if (currentBalance < withdrawalAmount) {
            setBalanceError('Insufficient balance for withdrawal.');
        } else {
            setBalanceError('');
        }
    }, [formWithdraw.amount, walletBalance.wallet_balance]);

    const renderWalletName = () => {
        const walletName = selectedWallet?.name || '';

        if (walletName.toLowerCase().includes('wallet')) {
            const trimmedName = walletName.replace(/wallet/gi, '');
            return <p className='properties'>{trimmedName} WALLET</p>;
        } else {
            return <p className='properties'>{walletName} WALLET</p>;
        }
    };

    //   get the calculation of the transaction cost of the amount to be withdrawn
    const [transactionCostMpesa, setTransactionCostMpesa] = useState(0);
    const [transactionCostBank, setTransactionCostBank] = useState(0);
    const [balance, setBalance] = useState(0);
    useEffect(() => {
        const fetchTransactionCost = async () => {
            try {
                const responseMpesa = await serverUrl.get(`/landlord/get-processing-fee/?payment_type=MPESA&amount=${formWithdraw.amount}`, config);
                setTransactionCostMpesa(responseMpesa.data.fee);

                const responseBank = await serverUrl.get(`/landlord/get-processing-fee/?payment_type=PESALINK&amount=${formWithdraw.amount}`, config);
                setTransactionCostBank(responseBank.data.fee);

            } catch (error) {
                // Handle errors if needed
                console.error('Error fetching transaction costs:', error);
            }
        };

        fetchTransactionCost();
    }, [formWithdraw.amount]);

    const totalAmountMpesa = parseInt(formWithdraw.amount) + parseInt(transactionCostMpesa);
    const totalAmountBank = parseInt(formWithdraw.amount) + parseInt(transactionCostBank);

    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            <div className='wallet_dashboard'>

                {/* top display */}
                <div className='topSelectedProperty' style={{ backgroundImage: `url(${Wallet1})` }}>
                    <div></div>
                    <Button className='btn btn-warning text-white d-flex ms-3 mt-3 d-none'>Pay Subscription</Button>

                    <p className='properties'>{renderWalletName()}</p>
                </div>

                {/* top cards */}
                <div className='staticCards'>


                    <Card className='rentDue' onClick={handleOpenWithdraw}>
                        <Card.Body className='d-flex'>

                            <Image src={WithdrawR} alt='water'></Image>

                            <div className='float-left'>
                                <span>Withdraw request</span>
                                <div className='d-flex mt-2'>
                                    <Button className='btn btn-sm' onClick={handleOpenWithdraw}>View Request</Button>
                                    <span className='notificationSpan'>{incompleteTransactions.length}</span>
                                </div>

                            </div>
                        </Card.Body>

                        {/* the list of the transactions */}
                        <Modal show={showWithdrawal} onHide={handleCloseWithdraw} className='modal-xl modal-dialog-centered modal-dialog-scrollable'>
                            <Modal.Header closeButton>
                                <Modal.Title>Outbound Transactions</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table>
                                    <thead className="underline">
                                        <tr>
                                            <th >
                                                To<i className="bi bi-chevron-expand"></i>
                                            </th>

                                            <th>
                                                Amount<i className="bi bi-chevron-expand"></i>
                                            </th>
                                            <th >
                                                Date<i className="bi bi-chevron-expand"></i>
                                            </th>
                                            <th className='w-25' >
                                                Status<i className="bi bi-chevron-expand"></i>
                                            </th>
                                            {landlordCount > 1 ?
                                                <th >
                                                    Action<i className="bi bi-chevron-expand"></i>
                                                </th>
                                                :
                                                <></>

                                            }

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {incompleteTransactions.map((landlord) => (
                                            landlord.status === "pending" ?
                                                <tr>
                                                    <td>{landlord.recepient_name}</td>
                                                    <td>{landlord.amount?.toLocaleString()}</td>
                                                    <td>{new Date(landlord.updated_at).toLocaleString('en-US', {
                                                        month: 'short',
                                                        day: 'numeric',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric',
                                                        hour12: true,
                                                    })}
                                                    </td>
                                                    <td><Button className='btn btn-sm btn-warning text-white'>{landlord.status}</Button></td>
                                                    {landlordCount > 1 ?
                                                        <td><Button className='btn btn-sm' onClick={() => handleOpenRequest(landlord.id)}>View</Button></td>

                                                        :
                                                        <></>

                                                    }
                                                </tr>
                                                :
                                                <></>

                                        ))}

                                    </tbody>
                                </Table>

                            </Modal.Body>

                        </Modal>

                        {/* the withdrawal request modal */}
                        <Modal xl show={showWithdrawalModal} onHide={handleCloseRequest} className='modal-xl modal-dialog-centered modal-dialog-scrollable'>
                            <Modal.Header closeButton>
                                <Modal.Title>Transaction Request Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    {landlordCount > 1 ?
                                        <Row>
                                            <Col md={5}>
                                                <div>
                                                    <label className='withLabel w-100'>Request Date:</label>
                                                    <input type='text' className='inputWithdraw' value={new Date(pendingTransaction.updated_at).toLocaleString('en-US', {
                                                        month: 'short',
                                                        day: 'numeric',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric',
                                                        hour12: true,
                                                    })} />
                                                </div>

                                                <div>
                                                    <label className='withLabel'>To:</label>
                                                    <input type='text' className='inputWithdraw' value={`${pendingTransaction.recepient_name}  ${pendingTransaction.recepient_phone_number}`} />
                                                </div>

                                                <div>
                                                    <label className='withLabel'>Amount:</label>
                                                    <input type='text' className='inputWithdraw' value={`KES ${pendingTransaction.amount}.00 `} />
                                                </div>

                                                <div>
                                                    <label className='withLabel'>Cost:</label>
                                                    <input type='text' className='inputWithdraw' value={`KES ${pendingTransaction.transaction_cost}.00 `} />
                                                </div>


                                                <div>
                                                    <label className='withLabel'>Reason:</label>
                                                    <input type='text' className='inputWithdraw' value={pendingTransaction.reason} />
                                                </div>

                                                <div>
                                                    <label className='withLabel'>Status:</label>
                                                    <Button className='btn btn-sm btn-warning text-white'>{pendingTransaction.status}</Button>
                                                </div>

                                            </Col>

                                            <Col md={7}>
                                                {walletAuthorizers.map((authorizer) => (
                                                    authorizer.authorizer_type === 'maker' ?
                                                        <>
                                                            <h4>{authorizer.authorizer_type}</h4>
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>NAME</th>
                                                                        <th>CONFIRMATION STATUS</th>
                                                                        <th>TIME</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    <tr>

                                                                        <td>{authorizer.landlord_name}</td>
                                                                        {authorizer.transaction_details.response === null ?
                                                                            <td><Button className='btn btn-sm btn-warning text-white'>Pending</Button></td>

                                                                            :
                                                                            <td><Button className='btn btn-sm btn-success'>{authorizer.transaction_details.response}</Button></td>

                                                                        }
                                                                        {!authorizer.transaction_details.updated_at ?
                                                                            <></> :
                                                                            <td>
                                                                                {new Date(authorizer.transaction_details.updated_at).toLocaleString('en-US', {
                                                                                    month: 'short',
                                                                                    day: 'numeric',
                                                                                    year: 'numeric',
                                                                                    hour: 'numeric',
                                                                                    minute: 'numeric',
                                                                                    second: 'numeric',
                                                                                    hour12: true,
                                                                                })}
                                                                            </td>
                                                                        }

                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </>
                                                        :
                                                        <>
                                                            <h4>{authorizer.authorizer_type}</h4>
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>NAME</th>
                                                                        <th>CONFIRMATION STATUS</th>
                                                                        <th>TIME</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    <tr>

                                                                        <td>{authorizer.landlord_name}</td>
                                                                        {authorizer.transaction_details.response === null ?
                                                                            <td><Button className='btn btn-sm btn-warning text-white'>Pending</Button></td>

                                                                            :
                                                                            <td><Button className='btn btn-sm btn-success'>{authorizer.transaction_details.response}</Button></td>

                                                                        }
                                                                        {/* Aug 18,2023, 12:38:28 PM */}
                                                                        {!authorizer.transaction_details.updated_at ?
                                                                            <></> :
                                                                            <td>
                                                                                {new Date(authorizer.transaction_details.updated_at).toLocaleString('en-US', {
                                                                                    month: 'short',
                                                                                    day: 'numeric',
                                                                                    year: 'numeric',
                                                                                    hour: 'numeric',
                                                                                    minute: 'numeric',
                                                                                    second: 'numeric',
                                                                                    hour12: true,
                                                                                })}
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </>
                                                ))}

                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col md={12}>
                                                <div>
                                                    <label className='withLabel w-100'>Request Date:</label>
                                                    <input type='text' className='inputWithdraw' value={new Date(pendingTransaction.updated_at).toLocaleString('en-US', {
                                                        month: 'short',
                                                        day: 'numeric',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric',
                                                        hour12: true,
                                                    })} />
                                                </div>

                                                <div>
                                                    <label className='withLabel'>To:</label>
                                                    <input type='text' className='inputWithdraw' value={`${pendingTransaction.recepient_name}  ${pendingTransaction.recepient_phone_number}`} />
                                                </div>

                                                <div>
                                                    <label className='withLabel'>Amount:</label>
                                                    <input type='text' className='inputWithdraw' value={`KES ${pendingTransaction.amount}.00 `} />
                                                </div>

                                                <div>
                                                    <label className='withLabel'>Cost:</label>
                                                    <input type='text' className='inputWithdraw' value={`KES ${pendingTransaction.transaction_cost}.00 `} />
                                                </div>


                                                <div>
                                                    <label className='withLabel'>Reason:</label>
                                                    <input type='text' className='inputWithdraw' value={pendingTransaction.reason} />
                                                </div>

                                                <div>
                                                    <label className='withLabel'>Status:</label>
                                                    <Button className='btn btn-sm btn-warning text-white'>{pendingTransaction.status}</Button>
                                                </div>

                                            </Col>

                                        </Row>}
                                    {walletAuthorizers.map((authorizer) => (
                                        parseInt(authorizer.landlord_id) === landlordId ?
                                            <>

                                            </>
                                            :
                                            <>
                                                <Button className='btn btn-sm btn-success text-white'>APPROVE</Button>
                                                <Button className='btn btn-sm btn-danger ms-5 text-white'>REJECT</Button>

                                            </>

                                    ))}

                                </Form>


                            </Modal.Body>
                        </Modal>

                    </Card>

                    <Card className='defaulters' onClick={handleOpenCalculator}>
                        <Card.Body className='d-flex'>

                            <Image src={Cost} alt='security'></Image>

                            <div className='float-left'>
                                <span className="">Cost Calculator</span>
                                <div className='d-flex mt-2'>
                                    <Button className='btn btn-sm btn-warning text-white' onClick={handleOpenCalculator}>Calculate</Button>
                                </div>
                            </div>
                        </Card.Body>

                        {/* modal for calculator */}
                        <Modal show={showCalculator} onHide={handleCloseCalculator} className='modal-lg modal-dialog-centered modal-dialog-scrollable'>
                            <Modal.Header closeButton>
                                <Modal.Title>Cost Calculator</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div className='d-flex mb-3'>
                                    <label className='withLabel'>Withdraw To</label>
                                    <select value={withdrawTo} onChange={(e) => setWithdrawTo(e.target.value)}>
                                        <option value='' disabled>Select a channel</option>
                                        <option value='PESALINK'>Bank</option>
                                        <option value='MPESA'>MPESA</option>
                                    </select>
                                </div>

                                <div className='d-flex mb-3'>
                                    <label className='withLabel'>Amount</label>
                                    <input
                                        type='number'
                                        placeholder='Enter Amount To Withdraw'
                                        value={amount}
                                        onChange={handleAmountChange}
                                    />
                                </div>

                                <input
                                    type='text'
                                    className='calculatorInput'
                                    placeholder={`Processing Fee: KES ${processingFee}`}
                                    readOnly
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <Button className='btn btn-sm' >Calculate</Button> */}
                                <Button className='btn btn-sm' variant='secondary' onClick={handleCloseCalculator}>Close</Button>

                            </Modal.Footer>
                        </Modal>

                    </Card>

                    <Card className='occupacy' onClick={handleOpenDeposit}>
                        <Card.Body className='d-flex'>

                            <Image src={Deposit} alt='internet'></Image>

                            <div className='float-left'>
                                <span>Deposit</span>
                                <div className='d-flex mt-2'>
                                    <Button className='btn btn-sm btn-success text-white' onClick={handleOpenDeposit}>Make Deposit</Button>
                                </div>

                            </div>
                        </Card.Body>

                        <Modal show={showDeposit} onHide={handleCloseDeposit}>
                            <Modal.Header className='' closeButton>
                                <Modal.Title>Deposit to wallet</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <Form onSubmit={handleSubmitDeposit}>
                                    <div className='d-flex mb-3'>
                                        <label className='withLabel'>Amount</label>
                                        <FormControl placeholder='Enter amount to deposut'
                                            type='text'
                                            value={formData.amount}
                                            name='amount'
                                            onChange={handleChange}
                                        />

                                    </div>

                                    <div className='d-flex mb-3'>
                                        <label className='withLabel'>Phone Number</label>
                                        <FormControl placeholder='Enter Safaricom Phone Number'
                                            value={formData.phone_number}
                                            name='phone_number'
                                            type='text'
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {formData.errorMessage && (
                                        <p style={{ color: 'red' }}>{formData.errorMessage}</p>
                                    )}
                                    <Button variant='primary' type='submit'>
                                        Send
                                    </Button>
                                </Form>
                            </Modal.Body>

                            <Modal.Footer>

                                <Button variant='secondary' onClick={handleCloseDeposit}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </Card>

                    <Card className='income'>
                        <Card.Body className='d-flex'>

                            <Image src={Withdraw} alt='garbage'></Image>

                            <div className='float-left'>
                                <span className=''>Withdraw</span>
                                <div className='d-flex mt-2'>
                                    <Button className='btn btn-sm btn-info text-white' onClick={handleOpenMakeWithdraw} >Withdraw</Button>

                                </div>

                            </div>
                        </Card.Body>
                        {/* modal for the withdra */}
                        <Modal show={makeWithdraw} onHide={handleCloseMakeWithdraw}>
                            <Modal.Header closeButton>
                                <Modal.Title>Make Withdraw</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <Form onSubmit={handleWithdraw}>
                                    <div className='mb-3'>
                                        <label className='withLabel w-100'>Amount (Minimal withdraw to bank or Mpesa is 100)</label>
                                        <FormControl placeholder='Enter Amount To Withdraw'
                                            name="amount" onChange={handleChangeWithdraw} value={formWithdraw.amount}
                                        />

                                    </div>
                                    {balanceError && <div className="text-danger">{balanceError}</div>}


                                    <div className={`mb-3 ${balanceError ? 'd-none' : ''}`}>
                                        <label className='withLabel w-100'>Withdraw To</label>

                                        <FormSelect onChange={handleChannelChange}>
                                            <option value='' selected disabled>Select a channel</option>
                                            {formWithdraw.amount < 99 ?
                                                <option value='Wallet'>To Wallet</option>
                                                :
                                                <>
                                                    {walletBalance.wallet_balance >= totalAmountMpesa && (
                                                        <option value='MPESA'>MPESA</option>
                                                    )}
                                                    {walletBalance.wallet_balance >= totalAmountBank && (
                                                        <option value='Bank'>Bank</option>
                                                    )}
                                                    <option value='Wallet'>To Wallet</option>
                                                </>
                                            }
                                        </FormSelect>
                                        {formWithdraw.amount > 99 && selectedChannel === 'Bank' && (
                                            <div className='mt-3 d-flex justify-content-between'>
                                                <FormSelect className='w-50' name="bank" onChange={handleChangeWithdraw} value={formWithdraw.bank}>
                                                    <option value='' selected disabled>Select Bank</option>
                                                    {bankAccount.map((bank) => (
                                                        <option key={bank.bank} value={bank.bank}>
                                                            {bank.bank.split('-')[1]}
                                                        </option>
                                                    ))}
                                                </FormSelect>
                                                <Button className='btn btn-sm' onClick={handleOpenAddAccount}>Add Bank Account</Button>
                                            </div>
                                        )}
                                        {selectedChannel === '' && (
                                            <></>
                                        )}
                                        {selectedChannel === 'Wallet' && (
                                            <div className='mb-3'>
                                                <label className='withLabel w-100'>Wallet To Withdraw To</label>

                                                <FormSelect onChange={handleChangeWallet} value={formWalletID.wallet} name='wallet'>
                                                    <option selected disabled>Select wallet</option>
                                                    {apartmentWallet.map((wallet) => (
                                                        <option value={wallet.id}>{wallet.name}</option>
                                                    ))}
                                                </FormSelect>
                                            </div>)}

                                        {formWithdraw.amount > 99 && selectedChannel === 'MPESA' && (
                                            <>
                                                {landlordCount > 1 ? (
                                                    <div className='mb-3'>
                                                        <label className='withLabel w-100'>Select a landlord</label>
                                                        {/* <select
                                                            name="selectedLandlord"
                                                            onChange={handleLandlordSelection}
                                                            value={formWithdraw.selectedLandlord || ''} 
                                                            className="form-control"
                                                        >
                                                            <option value="" disabled>Select User</option>
                                                            {currentLandlord.map((landlord) => (
                                                                <option
                                                                    key={landlord.id}
                                                                    value={`${landlord.full_name}|${landlord.phone_number}`}
                                                                >
                                                                    {landlord.full_name}
                                                                </option>
                                                            ))}
                                                        </select> */}

                                                        <select
                                                            name="selectedLandlord"
                                                            onChange={handleLandlordSelection}
                                                            value={formWithdraw.selectedLandlord}
                                                            className="form-control"
                                                        >
                                                            <option value="" disabled hidden>Select User</option>
                                                            {currentLandlord.map((landlord) => (
                                                                <option
                                                                    key={landlord.id}
                                                                    value={`${landlord.full_name}|${landlord.phone_number}`}
                                                                >
                                                                    {landlord.full_name}
                                                                </option>
                                                            ))}
                                                        </select>

                                                    </div>
                                                ) : null}

                                                <div className='mb-3'>
                                                    <label className='withLabel w-100'>Phone Number</label>
                                                    <FormControl placeholder='Enter Phone Number'
                                                        name="recepient_phone_number" onChange={handleChangeWithdraw} value={formWithdraw.recepient_phone_number}

                                                    />
                                                    {formWithdraw.errorMessage && (
                                                        <p style={{ color: 'red' }}>{formWithdraw.errorMessage}</p>
                                                    )}
                                                </div>

                                                <div className='mb-3'>
                                                    <label className='withLabel w-100'>Receipient Name</label>
                                                    <FormControl placeholder='Enter Receipient Name'
                                                        name="recepient_name" onChange={handleChangeWithdraw} value={formWithdraw.recepient_name}

                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className={`mb-3 ${balanceError ? 'd-none' : ''}`}>
                                        <label className='withLabel w-100'>Reason for withdrawal</label>
                                        <FormControl placeholder='Enter Reason for withdrawal'
                                            name="reason" onChange={handleChangeWithdraw} value={formWithdraw.reason}

                                        />
                                    </div>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-between">
                                <Button variant='secondary' onClick={handleOpenConfirmWithdraw}>
                                    {/* <i className='bi bi-printer'></i> Print */}
                                    Withdraw
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </Card>
                    {/* confirmation of the withdraw */}
                    <Modal show={widthrawConfirm} onHide={handleCloseConfirmWithdraw}>
                        <Modal.Header closeButton>
                            Withdraw
                        </Modal.Header>
                        <Modal.Body>
                            <p>To withdraw KES.{formWithdraw.amount} from your wallet account you will be charged a processing fee of KES.{processingFee}
                            </p>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <Button variant='primary' onClick={handleOpenConfirmWithdrawOtp}>
                                Confirm
                            </Button>
                            <Button variant='secondary' onClick={handleCloseAddAccount}>
                                Close
                            </Button>

                        </Modal.Footer>
                    </Modal>

                    {/* confirmation of the wallet transaction otp once the user has accepted the withdraw */}

                    <Modal show={widthrawConfirmOtp} onHide={handleCloseConfirmWithdrawOtp}>
                        <Modal.Header closeButton>
                            Wallet Otp Confirmation
                        </Modal.Header>
                        <Modal.Body>
                            <div className='mb-3'>
                                <label className='withLabel w-100'>Enter Otp</label>
                                <FormControl placeholder='Enter The Otp sent to your phone number'
                                    onChange={handleChangeWithdrawOtp} value={formWithdrawOtp.otp} name='otp' />

                            </div>
                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                            <Button variant='primary' onClick={handleWithdraw}>
                                Confirm Otp
                            </Button>
                            <Button variant='secondary' onClick={handleCloseAddAccount}>
                                Close
                            </Button>

                        </Modal.Footer>
                    </Modal>
                </div>

                {/* modal for adding account */}
                <Modal show={addAccount} onHide={handleCloseAddAccount} className='modal-dialog-scrollable'>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Bank Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='mb-3'>
                            <label className='withLabel w-100'>Bank Name</label>

                            <FormSelect onChange={handleChangeBank} value={formBank.bank} name='bank'>
                                <option selected disabled>Select a channel</option>
                                {pesaWiseBank.map((bank) => (
                                    <option value={`${bank.id}-${bank.bankName}`}>{bank.bankName}</option>
                                ))}

                            </FormSelect>
                        </div>
                        <div className='mb-3'>
                            <label className='withLabel w-100'>Account Number</label>
                            <FormControl placeholder='Enter Account Number' onChange={handleChangeBank} value={formBank.account_number} name='account_number' />

                        </div>

                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-between">
                        <Button variant='primary' onClick={handleBankAdd}>
                            Add
                        </Button>
                        <Button variant='secondary' onClick={handleCloseAddAccount}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* accout details */}

                <div className='account_details'>
                    <div className='d-flex'>
                        <Card>
                            <Card.Body>
                                <p className='Balance text-uppercase fs-3 fw-bold'>Account Balance</p>
                                <div className="input-icons">
                                    <i style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                                        {showPassword ? <i className="fa fa-eye icon"></i>
                                            : <i className="fa fa-eye-slash icon"></i>

                                        }
                                    </i>
                                    <input
                                        className="input-field"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Password"
                                        name="password"
                                        value={`Ksh. ${walletBalance.wallet_balance?.toLocaleString()}`}
                                    />

                                </div>

                            </Card.Body>
                        </Card>

                    </div>
                    <div className='d-flex'>
                        <Card>
                            <Card.Body>
                                <p className='text-warning text-uppercase fs-3 fw-bold'>Account Number: </p>
                                <div className="input-icons">
                                    <i style={{ cursor: "pointer" }} onClick={handleToggleBalanace}>
                                        {showBalance ? <i className="fa fa-eye icon"></i>
                                            : <i className="fa fa-eye-slash icon"></i>

                                        }
                                    </i>
                                    <input
                                        className="input-field"
                                        type={showBalance ? "text" : "password"}
                                        placeholder="Password"
                                        name="password"
                                        value={selectedWallet.account_number}
                                    />

                                </div>


                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className='d-flex mt-4' style={{ justifyContent: "right" }}>
                    <Button onClick={handleOpenDownloadReport}>Download Report <i class="bi bi-file-pdf"></i></Button>
                </div>

                {/* modal for downloading the report  */}
                <Modal show={downloadReport} onHide={handleCloseDownloadReport} className='modal-dialog-scrollable'>
                    <Modal.Header closeButton>
                        <Modal.Title>Filter by Date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex w-100 justify-content-center' >

                            <InputGroup className='justify-content-center'>
                                <Button style={{ width: "25%" }}
                                    className={`btn btn-outline-primary ${activeButton === 'Rent' ? 'typeActive text-white' : 'text-dark bg-transparent'} p-3`}
                                    onClick={() => handleButtonClick('Today')}
                                >
                                    Today
                                </Button>
                                <Button style={{ width: "25%" }}
                                    className={`btn btn-outline-primary ${activeButton === 'RentToOwn' ? 'typeActive text-white' : 'text-dark bg-transparent'}`}
                                    onClick={() => handleButtonClick('7D')}
                                >
                                    7D
                                </Button>
                                <Button style={{ width: "25%" }}
                                    className={`btn btn-outline-primary ${activeButton === 'Purchase' ? 'typeActive text-white' : 'text-dark bg-transparent'}`}
                                    onClick={() => handleButtonClick('30D')}
                                >
                                    30D
                                </Button>
                                <Button style={{ width: "25%" }}
                                    className={`btn btn-outline-primary ${activeButton === 'Purchase' ? 'typeActive text-white' : 'text-dark bg-transparent'}`}
                                    onClick={() => handleButtonClick('1Y')}
                                >
                                    1Y
                                </Button>
                            </InputGroup>
                        </div>

                        <div className='d-flex w-100 mt-2 justify-content-center' >


                        </div>

                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-between">
                        <Button variant='secondary' onClick={handleCloseDownloadReport}>
                            Download CSV <i className="bi bi-file-earmark-excel "></i>
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* transactions table */}
                <div className="tasks mt-4">

                    <div className='d-flex'>
                        <h2 onClick={() => handleTableChange('transaction')} className={`text-secondary text-capitialize  ${selectedTable === 'transaction' ? 'text-decoration-underline text-info' : ''}`}>Completed Records</h2>

                        <h2 onClick={() => handleTableChange('incomplete')} className={`text-secondary text-capitialize mx-3 ${selectedTable === 'incomplete' ? 'text-decoration-underline text-info' : ''}`}>Incomplete/Pending Transactions</h2>
                    </div>

                    {selectedTable === 'transaction' ?
                        <>
                            <div className="d-flex justify-content-between">
                                <div>
                                    {/* <Button className="btn btn-success text-white me-3" onClick={exportCSV}>
                                        {' '}
                                        EXCEL <i className="bi bi-file-earmark-excel "></i>
                                    </Button> */}

                                </div>
                                <Form className="searchBar">
                                    <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                                </Form>
                            </div>

                            <Table className="table mt-3">
                                <thead className="underline">
                                    <tr>
                                        <th >
                                            Date<i className="bi bi-chevron-expand"></i>
                                        </th>

                                        <th>
                                            To/From<i className="bi bi-chevron-expand"></i>
                                        </th>
                                        <th >
                                            Amount<i className="bi bi-chevron-expand"></i>
                                        </th>
                                        <th >
                                            Transaction Cost<i className="bi bi-chevron-expand"></i>
                                        </th>
                                        <th >
                                            Balance<i className="bi bi-chevron-expand"></i>
                                        </th>
                                        <th >
                                            Status<i className="bi bi-chevron-expand"></i>
                                        </th>
                                        <th >
                                            Receipt<i className="bi bi-chevron-expand"></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayedItems.map((landlord) => (

                                        <tr>
                                            <td>{new Date(landlord.updated_at).toLocaleDateString()}</td>
                                            <td>{landlord.recepient_name}</td>
                                            {landlord.transcation_type === "inbound" ?
                                                <td className='text-success'>+Ksh {parseFloat(landlord.amount)?.toLocaleString()}.00</td>
                                                :
                                                <td className='text-danger'>-Ksh {parseFloat(landlord.amount)?.toLocaleString()}.00</td>
                                            }
                                            <td>{parseFloat(landlord.transaction_cost)?.toLocaleString()}</td>
                                            <td>Ksh. {parseFloat(landlord.current_balance)?.toLocaleString()}</td>
                                            {landlord.status === "success" ?
                                                <td className='text-success'>Complete</td>
                                                :
                                                <td className='text-danger'>Incomplete</td>
                                            }
                                            <td><Button className='btn btn-sm btn-warning text-white' onClick={() => handleOpenReceipt(landlord.id)}>Print Receipt</Button></td>
                                        </tr>

                                    ))}

                                </tbody>
                                {/* the modal for the receipt */}
                                <Modal show={receiptModal} onHide={handleCloseReceipt} className='modal-dialog-scrollable'>
                                    <Modal.Header closeButton className='bg-info text-white'>
                                        <Modal.Title >Payment Receipt</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body ref={printAreaRef}>
                                        {transactionRecords.map((record) => (
                                            record.id === transactionId ? (
                                                <div key={record.id}>
                                                    <p className='fw-bold'>{record.recepient_name}</p>
                                                    <p className='fw-bold'>{record.recepient_phone_number}</p>
                                                    <p><b>Date:</b><span>{new Date(record.updated_at).toLocaleString('en-US', {
                                                        month: 'short',
                                                        day: 'numeric',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric',
                                                        hour12: true,
                                                    })}</span></p>
                                                    <p><b>Amount:</b>KES {record.amount?.toLocaleString()}</p>
                                                    <p><b>Transaction Cost:</b> KES {record.transaction_cost?.toLocaleString()}.00</p>
                                                    <p><b className='fw-bold'>Payment Reason:</b>  <span>{record.reason}</span></p>
                                                    <p className='text-center mt-2'>Generated On {formattedDate}</p>
                                                </div>
                                            ) : null
                                        ))
                                        }
                                    </Modal.Body>

                                    <Modal.Footer className="d-flex justify-content-between">
                                        <Button variant='secondary' onClick={handlePrintReceipt}>
                                            <i className='bi bi-printer'></i> Print
                                        </Button>

                                    </Modal.Footer>
                                </Modal>
                            </Table>

                            <div className="pagination">
                                <button onClick={handlePrevPage} disabled={currentPage === 0}>
                                    &lt;
                                </button>
                                <span>{`${startIndex + 1}-${endIndex} of ${transactionRecords.length}`}</span>
                                <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                                    &gt;
                                </button>
                            </div>
                        </>
                        :
                        <>
                            <div className="d-flex justify-content-between">
                                <div>
                                    {/* <Button className="btn btn-success text-white me-3" onClick={exportCSVIncomplete}>
                                        {' '}
                                        EXCEL <i className="bi bi-file-earmark-excel "></i>
                                    </Button> */}

                                </div>
                                <Form className="searchBar">
                                    <FormControl type="text" placeholder="Search" onChange={handleSearchIncomplete} />
                                </Form>
                            </div>

                            <Table className="table mt-3">

                                <thead className="underline">
                                    <tr>
                                        <th >
                                            Date<i className="bi bi-chevron-expand"></i>
                                        </th>

                                        <th>
                                            To/From<i className="bi bi-chevron-expand"></i>
                                        </th>
                                        <th >
                                            Amount<i className="bi bi-chevron-expand"></i>
                                        </th>
                                        <th >
                                            Transaction Cost<i className="bi bi-chevron-expand"></i>
                                        </th>
                                        <th >
                                            Balance<i className="bi bi-chevron-expand"></i>
                                        </th>
                                        <th >
                                            Status<i className="bi bi-chevron-expand"></i>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayedItemsIncomplete.map((landlord) => (
                                        <tr>
                                            <td>{new Date(landlord.updated_at).toLocaleDateString()}</td>
                                            <td>{landlord.recepient_name}</td>
                                            {landlord.transcation_type === "inbound" ?
                                                <td className='text-success'>+Ksh {landlord.amount?.toLocaleString()}.00</td>
                                                :
                                                <td className='text-danger'>-Ksh {landlord.amount?.toLocaleString()}.00</td>

                                            }
                                            <td>{landlord.transaction_cost?.toLocaleString()}</td>
                                            <td>Ksh. {landlord.current_balance?.toLocaleString()}</td>
                                            {landlord.status === "pending" ?
                                                <td className='text-warning'>{landlord.status}</td>
                                                :
                                                <td className='text-danger'>{landlord.status}</td>
                                            }



                                        </tr>

                                    ))}

                                </tbody>

                            </Table>

                            <div className="pagination">
                                <button onClick={handlePrevPage} disabled={currentPage === 0}>
                                    &lt;
                                </button>
                                <span>{`${startIndexIncomplete + 1}-${endIndexIncomplete} of ${incompleteTransactions.length}`}</span>
                                <button onClick={handleNextPage} disabled={currentPageIncomplete === totalPagesIncomplete - 1}>
                                    &gt;
                                </button>
                            </div>
                        </>

                    }


                </div>


                {landlordCount > 1 ?
                    <WalletCheckers />

                    :
                    <></>}

            </div >
        </>
    )
}

export default Wallet