import React, { useEffect, useState } from 'react';
import Header from './Header';
import SideBar from './SideBar';
import { useParams } from 'react-router-dom';
import { Modal, Button, FormSelect, Form, Card } from 'react-bootstrap';
import { serverUrl } from '../../serverUrl';
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

import './MakeSubscriptionPayment.scss'
function MakeSubscriptionPayment() {
    const [showSidebar, setShowSidebar] = useState(true);
    const { id } = useParams();

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    const [userLogedIn, setUserLogedIn] = useState([]);


    const [showModal, setShowModal] = useState(true);
    // Check if the user is logged in 
    const accessToken = localStorage.getItem("authTokens");
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const [apartmentWallets, setApartmentWallets] = useState([]);

    const [walletSummary, setWalletSummary] = useState({});
    const [wallet, setWallet] = useState({})
    const [invoiceData, setInvoiceData] = useState({});
    const [amountPhone, setAmountPhone] = useState("");

    useEffect(() => {

        serverUrl.get(`/landlord/selected-wallet-summary/?wallet_id=${id}`, config)
            .then((res) => {
                setWalletSummary(res.data.results[0])
            })
            .catch((error) => {
                console.log(error)
            })
        // get the landlord logged
        serverUrl
            .get(`/users/users-details/?id=${userId}`, config)
            .then((res) => {
                setUserLogedIn(res.data.results[0]);
            })
            .catch((error) => {
                console.log(error);
            });
        // get the wallet data
        serverUrl
            .get(`/landlord/wallet/${id}/`, config)
            .then((res) => {
                setWallet(res.data);
                // get the wallets that the apartment has
                serverUrl
                    .get(`/landlord/wallet/?property=${res.data.property}`, config)
                    .then((res) => {
                        setApartmentWallets(
                            res.data.results.filter(
                                (wallet) => wallet.account_type !== "subscription"
                            )
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            })
            .catch((error) => {
                console.log(error);
            });

        // get the invoices
        serverUrl.get(`/landlord/subscriptions-invoice/?wallet=${id}`, config)
            .then((res) => {
                setInvoiceData(res.data.results[0])
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])
    const [enteredAmount, setEnteredAmount] = useState("");

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedWalletName, setSelectedWalletName] = useState([]);
    const [selectedMpesaOption, setSelectedMpesaOption] = useState(null);
    const [phoneNumberPayment, setPhoneNumberPayment] = useState("");

    const handlePaymentMethod = (method) => {
        setSelectedPaymentMethod(method);
        setSelectedWalletName([]);
        handleMpesaOption(null);
    };

    const handleAmountPayment = (e) => {
        setAmountPhone(e.target.value);
    };

    const handleAmountChange = (amount) => {
        setEnteredAmount(amount);
    };

    const handleMpesaOption = (method) => {
        setSelectedMpesaOption(method);
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumberPayment(e.target.value);
    };

    const [selectedWallet, setSelectedWallet] = useState(null);
    const [selectedWalletBalance, setSelectedWalletBalance] = useState(null);
    const [selectedCurrentWalletBalance, setSelectedCurrentWalletBalance] = useState(null);
    const mpesSubscriptionBalance = parseInt(walletSummary.total_unpaid) - parseInt(amountPhone)


    function handleWalletSelect(selectedWalletId) {
        setSelectedWallet(selectedWalletId);
        serverUrl
            .get(
                `/landlord/wallet-account-balance?wallet=${selectedWalletId}`,
                config
            )
            .then((res) => {
                setSelectedWalletBalance(res.data.results[0].wallet_balance);
                const balancePayment =
                    res.data.results[0].wallet_balance - parseInt(invoiceData.amount);
                setSelectedCurrentWalletBalance(balancePayment);

                const balance = parseInt(invoiceData.amount) - balancePayment;
            })
            .catch((error) => {
                console.log(error);
            });

        // get the details of the selected wallet
        serverUrl
            .get(`/landlord/wallet/${selectedWalletId}/`, config)
            .then((res) => {
                setSelectedWalletName(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const handlePaySubscription = () => {
        if (selectedPaymentMethod === "wallet") {
            const data = {
                subscription_wallet: parseInt(selectedWallet),
                wallet: invoiceData.wallet,
                property: wallet.property,
                wallet_balance: parseInt(selectedWalletBalance) - parseInt(enteredAmount),
                wallet_name: selectedWalletName.name,
                invoice: parseInt(invoiceData.id),
                amount_paid: parseInt(enteredAmount),
            };
            const formData = new FormData();

            // Append each key-value pair to the FormData object
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, String(data[key]));
                }
            }
            // post the data for the payment
            serverUrl
                .post(`/landlord/pay-subscription-from-wallet/`, formData, config)
                .then((res) => {
                    // show paid and close the modal
                    toast.success("Succesfuly made payment for the subscription");
                    setEnteredAmount(null);
                    setSelectedWallet(null);
                    setSelectedWalletName(null);
                    setSelectedPaymentMethod(null);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        else {
            let modifiedPhoneNumber = "";

            if (selectedMpesaOption === "my_number") {
                modifiedPhoneNumber = userLogedIn.phone_number;

                if (userLogedIn.phone_number.length === 10 && userLogedIn.phone_number.startsWith("0")) {
                    modifiedPhoneNumber = "254" + userLogedIn.phone_number.slice(1);
                }
            } else if (selectedMpesaOption === "other_number") {
                modifiedPhoneNumber = phoneNumberPayment;

                if (phoneNumberPayment.length === 10 && phoneNumberPayment.startsWith("0")) {
                    modifiedPhoneNumber = "254" + phoneNumberPayment.slice(1);
                }
            }

            const data = {
                amount: parseInt(amountPhone),
                phone_number: modifiedPhoneNumber,
                current_balance: parseInt(invoiceData.amount) - amountPhone,
                wallet: invoiceData.wallet,
                subscription_plan_id: invoiceData.subscription,
                invoice_id: parseInt(invoiceData.id),
                property_id: invoiceData.apartment

            };
            console.log(data)

        }
    };
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            {/* Modal to display the payment process */}
           
            <Modal show={showModal} size='xl'>
                <Modal.Header>
                    <Modal.Title className='fw-bold text-uppercase' style={{ margin: "0 auto", color: accessToken ? "#007bff" : "black" }}>
                        {accessToken ? 'Choose Payment Method' : 'Enter Your Details'}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Your subscription balance is <b>Ksh. {walletSummary.total_unpaid?.toLocaleString()}</b></p>

                    {apartmentWallets.length !== 0 ? (
                        <div className="d-flex justify-content-between mb-3">
                            <Button
                                className="border-0"
                                style={{
                                    width: "20vw",
                                    background: selectedPaymentMethod === "wallet" ? "rgb(255 215 26)" : "lightgrey",
                                    color: selectedPaymentMethod === "wallet" ? "white" : "white",
                                }}
                                onClick={() => handlePaymentMethod("wallet")}
                            >
                                Wallet
                            </Button>
                            <Button
                                className="border-0"
                                style={{
                                    width: "20vw",
                                    background: selectedPaymentMethod === "mpesa" ? "rgb(255 215 26)" : "lightgrey",
                                    color: selectedPaymentMethod === "mpesa" ? "white" : "white",
                                }}
                                onClick={() => handlePaymentMethod("mpesa")}
                            >
                                Mpesa
                            </Button>
                        </div>
                    ) : (
                        <div className="d-flex justify-content-between">
                            <Button
                                className="btn btn-sm text-white border-0"
                                style={{ background: "#9b59b6" }}
                                onClick={() => handleMpesaOption("my_number")}
                            >
                                Pay with my number
                            </Button>
                            <Button
                                className="btn btn-sm text-white border-0"
                                style={{ background: "#34495e" }}
                                onClick={() => handleMpesaOption("other_number")}
                            >
                                Pay with another number
                            </Button>
                        </div>
                    )}

                    {selectedPaymentMethod !== null ? (
                        selectedPaymentMethod === "wallet" ? (
                            <>
                                <FormSelect
                                    className="mt-2"
                                    value={selectedWallet}
                                    onChange={(e) => handleWalletSelect(e.target.value)}
                                >
                                    <option selected disabled value="">
                                        Select wallet to pay from
                                    </option>
                                    {apartmentWallets.map((wallet) => (
                                        <option key={wallet.id} value={wallet.id}>
                                            {wallet.name}
                                        </option>
                                    ))}
                                </FormSelect>
                                {selectedWalletName.length === 0 ? (
                                    <></>
                                ) : (
                                    <>
                                        {selectedWalletBalance === 0 ? (
                                            <p className="mt-3 fw-bold" style={{ fontSize: "0.9rem", color: "purple" }}>
                                                Sorry, {selectedWalletName.name} does not have
                                                any money in it to make payment
                                            </p>
                                        ) : (
                                            <p className="mt-3 fw-bold" style={{ fontSize: "0.9rem", color: "purple" }}>
                                                {selectedWalletName.name} has a balance of
                                                Ksh. {selectedWalletBalance?.toLocaleString()}
                                            </p>
                                        )}
                                    </>
                                )}
                                {selectedWalletBalance === 0 ? (
                                    <></>
                                ) : (
                                    <>
                                        <input
                                            type="number"
                                            style={{ borderRadius: "5px" }}
                                            className="p-2 w-100 mt-2"
                                            placeholder="Enter amount to pay"
                                            value={enteredAmount}
                                            onChange={(e) =>
                                                handleAmountChange(e.target.value)
                                            }
                                        />

                                        {enteredAmount ? (
                                            parseInt(enteredAmount) <= 0 ?
                                                <p className="mt-2 text-danger fw-bold">
                                                    You cannot make a payment of Ksh. 0.
                                                </p>
                                                :
                                                <>
                                                    {selectedWalletBalance < enteredAmount ? (
                                                        <p className="mt-2 text-danger fw-bold">
                                                            Sorry, you have insufficient funds in your account.
                                                        </p>
                                                    ) : (
                                                        <p className="mt-3 fw-bold" style={{ fontSize: "0.9rem", color: "goldenrod" }}>
                                                            After this transaction, your balance in {selectedWalletName.name} will be Ksh. {(selectedWalletBalance - enteredAmount)?.toLocaleString()}.
                                                        </p>
                                                    )}
                                                </>
                                        ) : (
                                            <></> // No message displayed when amount is not entered
                                        )}



                                    </>
                                )}
                            </>
                        ) : (
                            <div className="d-flex justify-content-between">
                                <Button
                                    className="btn btn-sm text-white border-0"
                                    style={{ background: "#9b59b6" }}
                                    onClick={() => handleMpesaOption("my_number")}
                                >
                                    Pay with my number
                                </Button>
                                <Button
                                    className="btn btn-sm  text-white border-0"
                                    style={{ background: "#34495e" }}
                                    onClick={() => handleMpesaOption("other_number")}
                                >
                                    Pay with another number
                                </Button>
                            </div>
                        )
                    ) : (
                        <></>
                    )}

                    <Form>
                        {selectedMpesaOption !== null ? (
                            <>
                                {selectedMpesaOption === "my_number" ? (
                                    <div className="d-block">
                                        <input
                                            value={userLogedIn.phone_number}
                                            type="number"
                                            style={{ borderRadius: "5px" }}
                                            className="p-2 w-100 mt-2"
                                            placeholder="Enter amount to pay"
                                        />
                                        <input
                                            type="number"
                                            value={amountPhone}
                                            name="amount_phone"
                                            onChange={handleAmountPayment}
                                            style={{ borderRadius: "5px" }}
                                            className="p-2 w-100 mt-2"
                                            placeholder="Enter amount to pay"
                                        />
                                    </div>
                                ) : (
                                    <div className="d-block">
                                        <input
                                            type="number"
                                            value={phoneNumberPayment}
                                            name="phone_number"
                                            onChange={handlePhoneNumberChange}
                                            style={{ borderRadius: "5px" }}
                                            className="p-2 w-100 mt-2"
                                            placeholder="Enter phone number"
                                        />
                                        <input
                                            type="number"
                                            value={amountPhone}
                                            name="amount_phone"
                                            onChange={handleAmountPayment}
                                            style={{ borderRadius: "5px" }}
                                            className="p-2 w-100 mt-2"
                                            placeholder="Enter amount to pay"
                                        />
                                    </div>
                                )}
                                {amountPhone && (
                                    <p className="mt-2 text-warning">The subscription balance will be {mpesSubscriptionBalance}</p>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </Form>


                </Modal.Body>

                <Modal.Footer>
                    <Button
                        className="btn btn-success text-white fw-bold opacity-75"
                        onClick={handlePaySubscription}
                    >
                        Pay
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MakeSubscriptionPayment;
