import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import SideBar from '../SideBar';
import './HistoryReport.scss';
import { useParams } from 'react-router-dom';
import { Button, Table, Form, FormControl } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { CSVLink } from 'react-csv';
// import { PDFExport } from '@progress/kendo-react-pdf';

// auth imports
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../../serverUrl';
import { toast } from 'react-toastify';

function ReceiptReport() {
    // sidebar and header functions
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }

    // getting the id and finding the apparment details
    const { id } = useParams();
    // house selected
    const [houseSelected, setHouseSelected] = useState([]);
    const [houseSelectedReport, setHouseSelectedReport] = useState([]);
    const [houseSelectedReportPaid, setHouseSelectedReportPaid] = useState([]);
    // loading status
    const [isLoading, setIsLoading] = useState(false);
    const [occupancySet, setOccupancy] = useState({})

    // get the list of the houses

    const accessToken = localStorage.getItem('authTokens');
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);

    // get the house selected by user
    useEffect(() => {
        setIsLoading(true);
        serverUrl
            .get(`/landlord/house/${id}/`, config)
            .then((res) => {
                setHouseSelected(res.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
        // get the tenant of the house
        serverUrl
            .get(`/tenant/occupancy/?house_id=${id}`, config)
            .then((res) => {
                const tenantOccupancy = {};
                res.data.results.forEach((occupancy) => {
                    if (occupancy.status === "active") {
                        tenantOccupancy[id] = occupancy;
                        setOccupancy(occupancy);
                    }
                });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
        // get the house report of the selected house 

    }, [id]);

    useEffect(() => {
        // first check if the location is tenant report or house selected
        const pathname = window.location.pathname;
        // const currentUrl = window.location.pathname;
        const hash = window.location.hash;
        const currentUrl = hash.substring(1);
        // Split the URL by '/'
        const urlParts = currentUrl.split('/');
        // Get the last part of the URL
        const lastPart = urlParts[urlParts.length - 2];

        serverUrl
            .get(`/tenant/tenant/${occupancySet.tenant}`, config)
            .then((res) => {
                // Check if the last part is "tenant-report"
                if (lastPart === 'tenant-report') {
                    serverUrl.get(`/tenant/house-tenant-receipts/?house_id=${id}`, config)
                        .then((res) => {
                            setHouseSelectedReport(res.data.results);
                            // setHouseSelectedReportPaid(res.data.total_paid);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    serverUrl.get(`/tenant/house-tenant-receipts/?house_id=${id}&user_id=${res.data.user}`, config)
                        .then((res) => {
                            setHouseSelectedReport(res.data.results);
                            // setHouseSelectedReportPaid(res.data.total_paid);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                serverUrl
                    .get(`/users/users-details/${res.data.user}/`, config)
                    .then((res) => {
                        setIsLoading(false);

                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [occupancySet.tenant])

    // declare the selected house
    const selectedUnit = houseSelected;
    const idHouse = parseInt(id);

    function handleDownload(invoiceId) {
        try {
            setIsLoading(true);
            serverUrl
                .get(`/tenant/generate_pdf_receipt?invoice_id=${invoiceId}&download=true`, config)
                .then((res) => {
                    // create a download url for the pdf and then automatically download the pdf
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', 'receipt_report.pdf');
                    document.body.appendChild(link);
                    link.click();
                    toast.success('Successfully Downloaded Your Receipt Report');
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) { }
    }

    function handleDownloadAll() {
        try {
            setIsLoading(true);
            serverUrl
                .get(`/tenant/generate_pdf_receipt?house_id=${id}&download=true`, config)
                .then((res) => {
                    // create a download url for the pdf and then automatically download the pdf
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', 'house_receipt_report.pdf');
                    document.body.appendChild(link);
                    link.click();
                    toast.success('Successfully Downloaded Your House Receipt Report');
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (error) { }
    }

    const handleViewPDF = (invoiceId) => {
        setIsLoading(true);

        serverUrl
            .get(`/tenant/generate_pdf_receipt?invoice_id=${invoiceId}&download=true`, config)
            .then((res) => {
                const pdfData = res.data; // Assuming the PDF data is returned in the response
                const pdfUrl = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
                window.open(pdfUrl, '_blank');
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    function getMonthNumber(month) {
        const months = {
            january: 1,
            february: 2,
            march: 3,
            april: 4,
            may: 5,
            june: 6,
            july: 7,
            august: 8,
            september: 9,
            october: 10,
            november: 11,
            december: 12
        };

        return months[month];
    }
    // searching function
    const [sortType, setSortType] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSort = (sortField) => {
        setSortType(sortField);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const sortedData = houseSelectedReport.sort((a, b) => {
        const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === 'asc') {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredData = sortedData.filter((item) => {
        const searchableFields = Object.values(item).join(' ').toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });

    const handleRowClick = (item) => {
        setSelectedRow(item);
    };

    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);


    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const exportCSV = () => {
        const csvData = [
            ['Amount', 'Month', 'Year', 'Date Paid', 'Item'],
            ...houseSelectedReport.map((item) => [item.total, item.month, item.year, item.date_paid, item.description]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'data.csv');
    };

    const pdfExportRef = useRef();

    const exportPDF = () => {
        pdfExportRef.current.save();
    };

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }
    return (
        <>
            {selectedUnit.id === idHouse ? (
                <div className="HistoryReport" atyle={{ width: "70vw" }}>
                    {/* list of the tenants of the apartment and payment details */}
                    <div className="tasks ">
                        <h2>Receipt report - {selectedUnit.house_number} </h2>
                        {/* <p>
            Current Status :
            {selectedUnit.occupancy_status === 'occupied' ? (
              <b className="text-success text-uppercase"> {selectedUnit.occupancy_status}</b>
            ) : (
              <b className="text-danger text-uppercase">{selectedUnit.occupancy_status}</b>
            )}
          </p> */}
                        <div className="d-flex justify-content-between">
                            <div>
                                <Button className="btn btn-success text-white me-3" onClick={exportCSV}>
                                    {' '}
                                    EXCEL <i className="bi bi-file-earmark-excel "></i>
                                </Button>
                                <Button className="btn btn-danger text-white" onClick={handleDownloadAll}>
                                    PDF <i className="bi bi-file-pdf-fill"></i>
                                </Button>
                            </div>
                            <Form className="searchBar">
                                <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                            </Form>
                        </div>
                        <Table className="table mt-3">
                            <thead className="underline">
                                <tr>
                                    <th onClick={() => handleSort('tenant_name')}>
                                        Amount<i className="bi bi-chevron-expand"></i>
                                    </th>
                                    <th onClick={() => handleSort('status')}>
                                        Month<i className="bi bi-chevron-expand"></i>
                                    </th>
                                    <th onClick={() => handleSort('amount')}>
                                        Year<i className="bi bi-chevron-expand"></i>
                                    </th>
                                    <th onClick={() => handleSort('amount')}>
                                        Date Paid<i className="bi bi-chevron-expand"></i>
                                    </th>
                                    <th onClick={() => handleSort('days')}>
                                        Item<i className="bi bi-chevron-expand"></i>
                                    </th>
                                    <th onClick={() => handleSort('date')}>
                                        Receipt
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedItems.map((houseHistory) => (
                                    <tr key={houseHistory.id}>

                                        <td>{houseHistory.total}</td>
                                        <td>{houseHistory.month}</td>
                                        <td>{houseHistory.year}</td>
                                        <td>{houseHistory.date_paid}</td>
                                        <td>{houseHistory.description}</td>
                                        <td> <Button className="btn btn-primary text-white btn-sm" onClick={() => handleViewPDF(houseHistory.invoice_id)}>
                                            View <i className="bi bi-eye-fill"></i>
                                        </Button></td>

                                        <td>
                                            <Button className="btn btn-danger text-white btn-sm" onClick={() => handleDownload(houseHistory.invoice_id)}>
                                                PDF <i className="bi bi-file-pdf-fill"></i>
                                            </Button></td>
                                    </tr>
                                ))}
                            </tbody>
                            <div className="bottomDataHouseReport">
                                <p className="text-center mt-2"> total {houseSelectedReportPaid}</p>
                            </div>
                        </Table>
                        <div className="pagination">
                            <button onClick={handlePrevPage} disabled={currentPage === 0}>
                                &lt;
                            </button>
                            <span>{`${startIndex + 1}-${endIndex} of ${houseSelectedReport.length}`}</span>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                                &gt;
                            </button>
                        </div>
                    </div>

                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default ReceiptReport