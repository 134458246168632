import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import './Table.scss';
import { Button } from 'react-bootstrap';
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';

function Table() {
  const [data, setData] = useState({ rent: [], expenses: [] });
  const months = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(new Date().getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });
  const currentYear = new Date().getFullYear();
  const [yearFilterActive, setYearFilterActive] = useState('');

  const accessToken = localStorage.getItem('authTokens');
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;

  useEffect(() => {
    try {
      const setYearFilter = localStorage.getItem('yearRentIncomeDashboard');
      setYearFilterActive(setYearFilter);

      let yearSelect = setYearFilter ? setYearFilter : currentYear;

      serverUrl
        .get(`/landlord/landlord/?user_id=${userId}`, config)
        .then((res) => {
          const landlordIds = res.data.results.map((landlord) => landlord.id);

          const fetchDataPromises = months.map((month) =>
            serverUrl.get(`/landlord/react-rent-expeses-landlord/?landlord_id=${landlordIds}&month=${month}&year=${yearSelect}`, config)
          );

          Promise.all(fetchDataPromises)
            .then((responses) => {
              const rentData = responses.map((response) => response.data.total_rent_income);
              const expenseData = responses.map((response) => response.data.total_expense_maintenance_income);
              setData({ rent: rentData, expenses: expenseData });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            console.log(error.response.data.data.error);
          } else {
            console.log(error);
            console.log('Unexpected error: ' + error.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const dataChart = months.map((month, index) => ({
    month,
    Rent: data.rent[index],
    Expenses: data.expenses[index],
  }));

  const yAxisLabelStyles = {
    fontSize: 14,
    marginBottom: '1rem',
    fontWeight: 'bold',
    fill: 'black',
  };

  const options = {
    chart: {
      background: 'white',
    },
    xaxis: {
      categories: months,
    },
    yaxis: {
      title: {
        text: 'Amount (Ksh)',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fill: 'black',
        },
      },
    },
    tooltip: {
      style: {
        background: 'rgba(255, 255, 255, 0.9)',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        transform: 'translate(0px, 0px)',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    plotOptions: {
      bar: {
        borderRadius:5,
        // borderRadius: {
        //   topLeft: 10,
        //   topRight: 10,
        //   bottomLeft: 0,
        //   bottomRight: 0,
        // },
        dataLabels: {
          position: 'top',
          color:['transparent']
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  const series = [
    {
      name: 'Income',
      data: data.rent,
      color: '#000000',
    },
    {
      name: 'Expenses',
      data: data.expenses,
      color: '#ffd700',
    },
  ];

  const handleReset = () => {
    localStorage.removeItem('yearRentIncomeDashboard');
    toast.success('Successfully Reset The Graph Income vs Expenses!');
    window.location.reload();
  };

  return (
    <>
      <div className='rentexpenses'>
        <div className='d-flex justify-content-around mb-3'>
          {yearFilterActive ? (
            <p className='filterText mt-2'>Income vs Expenses For Year {yearFilterActive}</p>
          ) : (
            <h2 className=''>Income vs Expenses</h2>
          )}
          {yearFilterActive ? (
            <Button className='text-white filterButton' onClick={handleReset}>
              Reset Filter
            </Button>
          ) : (
            <></>
          )}
        </div>
        <ReactApexChart options={options} series={series} type='bar' width={1300} height={600} />
      </div>
    </>
  );
}

export default Table;
