import React, { useState, useReducer, useEffect } from 'react';
import { Card, Form, FloatingLabel, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import QuillEditor from "./QuillEditor";
import './BlogAdd.scss'
import Header from './Header';
import SideBar from './SideBar';
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import makeAnimated from "react-select/animated";

// stepper
const animatedComponents = makeAnimated();

const steps = [
    "Blog Details",
    "Blog Content",
    "Blog Preview",
    "Save Blog",
];
const initialState = {
    step: 0,
    form1: {
        name: "",
        location: "",
        county: "",
        latitude: "",
        longitude: "",
    },
    form2: {
        selectedOptions: [],
        apartmentId: "",
        apartmentName: "",
    },
};

const reducer = (state, action) => {
    switch (action.type) {
        case "next":
            return { ...state, step: state.step + 1 };
        case "back":
            return { ...state, step: state.step - 1 };
        case "reset":
            return { ...state, step: 0 };
        default:
            return state;
    }
};

function BlogsAdd({ title }) {
    const [showSidebar, setShowSidebar] = useState(true);
    const navigate = useNavigate();

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }

    // get the user details, including the username
    const [fullName, setFullName] = useState('');
    const [image, setImage] = useState('');


    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;


    // from the local storage, get the logged-in user
    useEffect(() => {
        // hitting the endpoint to get the user's details
        serverUrl
            .get(`/users/users-details/${userId}/`, config)
            .then((res) => {
                // get the full name of the user
                setFullName(res.data.full_name);
                setImage(res.data.image);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // stepper data 
    const [state, dispatch] = useReducer(reducer, initialState);
    const { step } = state;

    const [skipped, setSkipped] = useState(new Set());

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const [formDataBlog, setFormDataBlog] = useState({
        blog: '',
        description: '',
        long_description: '',
        category: '',
    });

    const handleChangeBlog = (event) => {
        const { name, value } = event.target;
        setFormDataBlog((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    const [blogId, setBlogId] = useState('')


    const [quillValue, setQuillValue] = useState("");
    const [quillFileValue, setQuillFileValue] = useState("");
    const onEditorChange = (value) => {
        setQuillValue(value);
    };

    const onFileChange = (file) => {
        setQuillFileValue(file);
    };

    const handleButtonClick = () => {
        const fileInput = document.getElementById('imageUpload');
        fileInput.click();
    };

    const [images, setImages] = useState([]);

    const handleChange = (e) => {
        const file = e.target.files[0];
        setImages(file);
    };


    function handleBlog(e) {
        e.preventDefault();
        var isPublic = false

        document.addEventListener("DOMContentLoaded", function() {
            if (document.getElementById("public").checked) {
                var isPublic = true;
            }
        });
        
        const blogData = {
            name: formDataBlog.blog,
            user: userId,
            category: formDataBlog.category,
            description: formDataBlog.description,
            long_description: quillValue,
            is_public: isPublic
        }



        // update the blog data details
        serverUrl.post('/landlord/blog/', blogData, config)
            .then((res => {
                if (images) {
                    const formData = new FormData();
                    formData.append('image', images);
                    formData.append('blog', res.data.id);
                    formData.append('is_main', true);

                    serverUrl.post(`/landlord/blog-image/`, formData, config)
                        .then((res) => {
                            navigate('/blog')
                            toast.success('Blog Details is uploaded succesfully')
                            dispatch({ type: "reset" });


                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
                else {
                    navigate('/blog')
                    toast.success('Blog Details is uploaded succesfully')

                }
                setBlogId(res.data.id)

            }))
            .catch((error) => {
                console.log(error)
            })
    }

    const handleBack = () => {
        dispatch({ type: "back" });
    };
    const handleNext = () => {
        dispatch({ type: "next" });
    }

    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && <SideBar />}
            <div className="blogAdd" >
            <Box sx={{ width: "100%" }}>
                <h2 className='text-uppercase mt-1 text-primary fw-bold'>Create a blog</h2>

                <Typography variant="h5" sx={{ mb: 3 }}>
                    {title}
                </Typography>
                <Stepper activeStep={step}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {/* entering the blog details */}
                {step === 0 && (
                    <>
                        <p className='text-uppercase text-muted fw-bold mt-3 mb-3'>Enter the details of the blog</p>
                        <div className='d-flex justify-content-between'>
                            <div className="input-icons">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    placeholder="Title"
                                    name="blog"
                                    value={formDataBlog.blog}
                                    onChange={handleChangeBlog}
                                    required
                                />
                            </div>
                            <div className="input-icons">
                                <i className="fa fa-hashtag icon"></i>
                                <input
                                    className="input-field"
                                    type="text"
                                    placeholder="Category"
                                    name="category"
                                    value={formDataBlog.category}
                                    onChange={handleChangeBlog}
                                    required
                                />
                            </div>

                           
                        </div>

                        <textarea
                                rows={5}
                                className='w-100 p-2'
                                placeholder="Short Description"
                                value={formDataBlog.description}
                                onChange={handleChangeBlog}
                                required
                                name="description"
                                style={{
                                    borderRadius:"5px",
                                    border:"1px solid grey"
                                }}
                            >

                            </textarea><br></br>

                        <Button className='btn btn-sm mb-2' onClick={handleButtonClick}>
                            Select Blog cover photo
                        </Button>

                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleChange}
                        />

                        <div className="col-md-12">
                            <img src={images && images instanceof File ? URL.createObjectURL(images) : ''} alt="" className=' ' style={{ width: "50vw", height: "40vh", objectFit: "cover" }} />
                        </div>

                        <div style={{ marginLeft: "2rem", textAlignLast: "left", marginBottom: "1rem" }}>
                            <input type='checkbox' id='public' /><span className='ms-2'>Make the blog public?</span>
                        </div>

                        <Button
                            type="submit"
                            sx={{ mt: 2 }}
                            variant="contained"
                            color="primary"
                            className='float-end'
                            onClick={handleNext}
                        >
                            Next
                        </Button>



                    </>
                )}

                {/* enter the content of the blog */}
                {step === 1 && (
                    <>
                        <p className='text-uppercase text-muted fw-bold mt-3 mb-3'>Enter the blog content</p>
                        <QuillEditor
                            placeholder={"Start Typing Something"}
                            onEditorChange={onEditorChange}
                            onFilesChange={onFileChange}
                            value={formDataBlog.long_description}
                        />

                        <div className='float-end'>
                            <Button
                                type="submit"
                                sx={{ mt: 2 }}
                                variant="contained"
                                color="secondary"
                                className='opacity-75 me-3'
                                onClick={handleBack}

                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                sx={{ mt: 2 }}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}

                            >
                                Next
                            </Button>
                        </div>

                    </>
                )}

                {/* preview the blog displayed according to the website preview */}
                {step === 2 && (
                    <>
                        <p className='text-uppercase text-muted fw-bold mt-3 mb-3'>Preview of the how the blog will look like</p>
                        <div className='singleBlog'>


                            <Card>
                                <Button className='btn btn-sm btn-warning text-white'>{formDataBlog.category}</Button>
                                <h1 className='mt-2 text-dark'>{formDataBlog.blog}</h1>

                                <div className='dateUserBlog mb-3 mt-2'>
                                    <div>
                                        <Image className='me-2' src={image} style={{ width: "5%" }} />
                                        <span >{fullName}</span>
                                    </div>

                                    <div>
                                        <i className="bi bi-calendar2-check-fill me-2 text-warning"></i>
                                        <span>
                                            {new Date().toLocaleDateString('en-US', {
                                                month: 'long',
                                                day: 'numeric',
                                                year: 'numeric'
                                            })}
                                        </span>
                                    </div>

                                </div>

                                <Image src={images && images instanceof File ? URL.createObjectURL(images) : ''} className='coverImage' />
                                <p>{formDataBlog.description}</p>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: quillValue,
                                    }}
                                ></p>
                            </Card>
                        </div>
                        <div className='float-end mb-3'>
                            <Button
                                type="submit"
                                sx={{ mt: 2 }}
                                variant="contained"
                                color="secondary"
                                className='opacity-75 me-3'
                                onClick={handleBack}

                            >
                                Back
                            </Button>
                            <Button
                                type="submit"
                                onClick={handleNext}
                                sx={{ mt: 2 }}
                                variant="contained"
                                color="primary"
                            >
                                Next
                            </Button>
                        </div>

                    </>
                )}

                {/* save the blog after being satisfied */}
                {step === 3 && (
                    <>
                        <p className='text-muted fw-bold mt-3 mb-3'>Save the blog if you are satisfied with it else go back and make the changes</p>
                        <Form onSubmit={handleBlog}>

                            <div className='float-end mb-3'>

                                <Button
                                    type="submit"
                                    sx={{ mt: 2 }}
                                    variant="contained"
                                    color="secondary"
                                    className='opacity-75 me-3'
                                    onClick={handleBack}

                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    sx={{ mt: 2 }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Finish
                                </Button>
                            </div>
                        </Form>
                    </>
                )}

            </Box>
            </div>
         

        </>
    );
}

export default BlogsAdd;
