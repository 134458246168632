import React, { useState, useEffect,useMemo } from 'react'
// import './HouseImage.scss'
import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import {  useParams } from "react-router-dom";
import { serverUrl } from "../../serverUrl";
import { useNavigate } from 'react-router'

function AddRoomImage() {
    const navigate = useNavigate()
    const { id } = useParams();

    // image
    const [image, setImage] = useState(null);

    // button that does the uploading to while the input is hidden
    const handleButtonClick = () => {
        document.getElementById('imageUpload').click();
    };

    // the data to be sent in the backend
    const [formData, setFormData] = useState({
        Houseimg: null
    });

    // get the list of the houses

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = useMemo(() => {
        return {
            headers: { Authorization: `Bearer ${access}` }
        };
    }, [access]);
    // onchange functionality
    const handleChange = (event) => {
        const selectedImage = event.target.files[0];
        if (selectedImage && selectedImage.type.includes('image')) {
            setImage(selectedImage);
            setFormData((prevFormData) => ({
                ...prevFormData,
                Houseimg: selectedImage,
            }));
        } else {
            setImage(null);
            setFormData((prevFormData) => ({
                ...prevFormData,
                Houseimg: null,
            }));
            toast.error('Kindly select an image file!');
        }
    }

    const [setUnitRooms] = useState([])
    useEffect(() => {
        serverUrl.get(`/landlord/alliance-unit-rooms?house_id=${id}`, config)
            .then((res) => {
                setUnitRooms(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [id,config,setUnitRooms])


    // handling the form and how the data will be sent to the backend
    function handleSubmit(e) {

        // preventing from redirecting to other pages
        e.preventDefault();

        // get user details so that you can link with the Apartment from the access tokens
        const accessToken = localStorage.getItem("authTokens");
        // get the Apartment id to be used
        const houseId = localStorage.getItem('house')

        // the data that will be posted to the api is as follows
        const data = new FormData();
        data.append('house', houseId);
        data.append('image', formData.Houseimg);

        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;

        // header's access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }

        //  now post the data
        try {
            serverUrl

                //send the Apartment image using the following endpoint
                .post("/landlord/house-images/", data, config)
                .then((res) => {
                    console.log(res)
                    toast.success('Successfully Added house Image!');
                    navigate('/home')

                })

        } catch (error) {
            toast.error(error);
        }

    }


    return (
        <div className="imageProperty">
            <h2>Unit Room Image(s)</h2>

            <Form onSubmit={handleSubmit} encType="multipart/form-data">

                {/* <FormSelect
                    className="formProperty"
                    value={formDataRoomImage.amenity || ''}
                    onChange={handleChangeRoomImag('amenity')}
                >
                    <option disabled value="">
                        Select room to add image
                    </option>
                    {unitRooms.length > 0 ? (
                        unitRooms.map((amenity) => (
                            <option
                                key={amenity.id}
                                value={amenity.id}
                            >
                                {amenity.room}
                            </option>
                        ))
                    ) : (
                        <p>No Amenities Found For This Apartment. Kindly Add One.</p>
                    )}
                </FormSelect> */}

                <div className="input-icons">
                    <Button className='imageButton' onClick={handleButtonClick}>
                        Select Image For Unit Room
                    </Button>

                    <input
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleChange}
                    />
                    {image && (
                        <div>
                            <img src={URL.createObjectURL(image)} alt="preview" className='imageUploaded' />
                        </div>
                    )}
                    <div className="bottomButtons">
                        <Button type="button" className="skipButton">
                            Skip
                        </Button>

                        <Button type="submit" className="nextButton">
                            Next
                        </Button>
                    </div>
                </div>


            </Form>

        </div>
    )
}

export default AddRoomImage