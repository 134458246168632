import React, { useEffect, useState } from 'react';
import { Form, Button, Modal, Image, Col, Carousel, Nav, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { useNavigate } from 'react-router';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SelectedPropertyImage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const [formData, setFormData] = useState({
        Apartmentimg: [],
    });

    const handleButtonClick = () => {
        document.getElementById('imageUpload').click();
    };

    const handleChange = (event) => {
        const selectedImages = Array.from(event.target.files);

        if (selectedImages.length > 0) {
            setImages(selectedImages);
            setFormData((prevFormData) => ({
                ...prevFormData,
                Apartmentimg: selectedImages,
            }));
        } else {
            setImages([]);
            setFormData((prevFormData) => ({
                ...prevFormData,
                Apartmentimg: [],
            }));
            toast.error('Kindly select image(s)!');
        }
    };

    const accessToken = localStorage.getItem('authTokens');
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };

    function handleSubmit(e) {
        e.preventDefault();
        const accessToken = localStorage.getItem('authTokens');
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        const uploadPromises = formData.Apartmentimg.map((image, index) => {
            const data = new FormData();
            data.append('apartment', id);
            data.append(`image`, image);

            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;

            const config = {
                headers: { Authorization: `Bearer ${access}` },
            };

            return serverUrl.post('/landlord/apartment-images/', data, config);
        });

        Promise.all(uploadPromises)
            .then((results) => {
                console.log(results);
                toast.success('Successfully Added Property Images!');
                setImages([]);
                serverUrl.get(`/landlord/apartment-images/?apartment_id=${id}`, config)
                    .then((res) => {
                        setApartmentImages(res.data.results);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                // navigate(`/selected-property/${id}`);
            })
            .catch((error) => {
                toast.error(error.message || 'Error adding property images');
            });
    }


    const [showModalHouse, setShowModalHouse] = useState(false);

    const handleOpenModalHouse = () => {
        setShowModalHouse(true);
    };

    const handleCloseModalHouse = () => {
        setShowModalHouse(false);
    };

    const [apartmentImages, setApartmentImages] = useState([]);

    useEffect(() => {
        serverUrl.get(`/landlord/apartment-images/?apartment_id=${id}`, config)
            .then((res) => {
                setApartmentImages(res.data.results);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [id]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedApartmentId, setSelectedApartmentId] = useState(null);

    const openModal = (apartmentId) => {
        setSelectedApartmentId(apartmentId);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedApartmentId(null);
    };
    const handleDeleteApartment = () => {
        if (selectedApartmentId) {
            serverUrl
                .delete(`/landlord/apartment-image/${selectedApartmentId}`, config)
                .then((res) => {
                    serverUrl
                        .get(`/landlord/apartment-images/?apartment_id=${id}`, config)
                        .then((res) => {
                            setApartmentImages(res.data.results);
                            closeModal();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    toast.success('Successfully deleted the image');
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const slickSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: Math.max(1, Math.min(3, apartmentImages.length)),
        slidesToScroll: 1,
    }

   

    const [activeTab, setActiveTab] = useState('viewImages');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            <div className='d-flex justify-content-'>
                <Link to={`/selected-property/${id}`} className='addHouse'>
                    <i
                        className="bi bi-arrow-left-circle-fill"
                        style={{ fontSize: '2.5rem', color: '#1F57F8', padding: '1rem' }}
                    ></i>
                </Link>
                <h2></h2>
            </div>
            <div className="imageProperty">
                <h2>Property Image(s)</h2>

                <div className=' w-100'>
                    <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
                        <Nav variant='tabs' className='d-flex'>
                            <Nav.Item style={{ background: 'transparent'}}>
                                <Nav.Link
                                    className='btn btn-sm btn-warning text-white'
                                    style={{ backgroundColor: 'orange', width: 'fit-content' }}
                                    eventKey='viewImages'
                                >
                                    View Property's Images
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{ background: 'transparent'}}>
                                <Nav.Link
                                    eventKey='selectImages'
                                    className='btn btn-sm btn-warning text-white'
                                    style={{ backgroundColor: 'blue', width: 'fit-content' }}
                                >
                                    Select Image For Property
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content>
                        <Tab.Pane eventKey='viewImages'>
                                {apartmentImages && apartmentImages.length > 0 ? (
                                    <div>

                                        <Slider {...slickSettings} style={{ marginLeft: '0rem' }}>
                                            {apartmentImages.map((apartment) => (
                                                <div key={apartment.id}>
                                                    <Col md={6} sm={6} style={{ marginTop: '1rem' }}>
                                                        <span
                                                            className='mt-4'
                                                            onClick={() => openModal(apartment.id)}
                                                            style={{
                                                                position: 'relative',
                                                                padding: '0.8rem',
                                                                backgroundColor: 'red',
                                                                borderRadius: '50%',
                                                                marginLeft: '-10rem',
                                                            }}
                                                        >
                                                            <i className='bi bi-trash-fill text-white' style={{ position: 'relative' }}></i>
                                                        </span>
                                                        <Image className='propertiesViewImage' src={apartment.image} />
                                                    </Col>
                                                </div>
                                            ))}
                                        </Slider>

                                        <Modal show={isModalOpen} onHide={closeModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Delete Image</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <p>Do you want to delete this image?</p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button className='btn btn-danger' onClick={handleDeleteApartment}>
                                                    Delete
                                                </button>
                                                <button className='btn btn-secondary' onClick={closeModal}>
                                                    Close
                                                </button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                ) : (
                                    <p style={{fontSize:"2rem"}}>No Images found kindly add one </p>
                                )}

                            </Tab.Pane>

                            <Tab.Pane eventKey='selectImages'>
                                <Form onSubmit={handleSubmit} encType='multipart/form-data'>
                                    <div className='input-icons'>
                                        <Button className='imageButton btn btn-sm mt-3' onClick={handleButtonClick}>
                                            Choose images to upload
                                        </Button>
                                        <input type='file' id='imageUpload' accept='image/*' multiple style={{ display: 'none' }} onChange={handleChange} />

                                        {images.length > 0 && (
                                            <>
                                                <div className='d-flex' style={{ width: "100%", overflowX: "scroll" }}>
                                                    {images.map((image, index) => (
                                                        <>
                                                            {/* <p className="imageSize">{(image.size / 1024).toFixed(2)} KB</p> */}
                                                            <br></br>

                                                            <div key={index} className="imageContainer d-flex">
                                                                <span
                                                                    className='mt-1'
                                                                    onClick={() => setImages(images.filter((_, i) => i !== index))}

                                                                    style={{
                                                                        position: 'relative',
                                                                        padding: '0.8rem',
                                                                        backgroundColor: 'red',
                                                                        borderRadius: '50%',
                                                                        marginRight: '-4rem',
                                                                        height: "fit-content",
                                                                    }}
                                                                >
                                                                    <i className='bi bi-trash-fill text-white' style={{ position: 'relative' }}></i>
                                                                </span>

                                                                <img
                                                                    src={URL.createObjectURL(image)}
                                                                    alt={`preview_${index}`}
                                                                    className='imageUploaded'
                                                                    style={{ marginInline: "1.2rem", width: "20vw", objectFit: "cover" }}
                                                                />
                                                                <br></br>
                                                            </div>

                                                            <br></br>

                                                        </>

                                                    ))}

                                                </div>
                                                <div className='bottomButtons w-100 mt-4'>
                                                    <Button type='submit' className='nextButton  btn btn-sm'>
                                                        Save Images
                                                    </Button>
                                                </div>
                                            </>

                                        )}

                                    </div>
                                </Form>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </>
    );
}

export default SelectedPropertyImage;
