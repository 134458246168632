import React from 'react'
import BottomNavbar from '../../components/BottomNavbar'
import HomeHeader from '../../components/headers/HomeHeader'

function Landing() {
  return (
    <div>
     
        <HomeHeader/>
         <BottomNavbar/>
    </div>
  )
}

export default Landing