import React, { useEffect, useRef,useState } from "react";
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router";
import loaderImage from '../../assets/img/logo.png'
import {Button} from 'react-bootstrap'


function FileInput({ onImageSelected }) {
    const inputRef = useRef();

    const handleOnChange = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = function (e) {
                onImageSelected(reader.result);
            };
        }
    };

    const onChooseImg = () => {
        inputRef.current.click();
    };

    // get the user details the username
    const [fullName, setFullName] = useState("")

    // get the access token and see who is logged in
    const accessToken = localStorage.getItem("authTokens");

    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // get the details of the user
    useEffect(() => {
        serverUrl.get(`/users/users-details/${userId}/`, config)
            .then((res) => {
                // get the fullname of the user

                setFullName(res.data.full_name);

            })
            .catch((error) => {
                console.log(error);
            });
    })
    return (
        <div>
            <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={handleOnChange}
                style={{ display: "none" }}
            />
            <p>Choose an image so that your profile picture can be updated</p>

            <Button className="btn" onClick={onChooseImg}>
                Pick Image
            </Button>
        </div>
    );
}

export default FileInput;
