import React, { useState, useEffect } from 'react'
import { Button, Table, Form, FormControl, Modal, FormSelect } from 'react-bootstrap';
import { saveAs } from 'file-saver';

// auth imports
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import { useMessages } from './MessageContext';


function MessagesOutbox() {

  const [addTemplate, setAddTemplate] = useState(false);

  const handleReminderModalOpen = () => {
    setAddTemplate(true);
  };

  const handleReminderModalClose = () => {
    setAddTemplate(false);
  };


  const [isLoading, setIsLoading] = useState(true)

  const accessToken = localStorage.getItem('authTokens');
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };
  // decoding the token so that i can get the user id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;

  const [messageOutbox, setMessageOutbox] = useState([])

  // Create a function to fetch the data and update the stat

  // // get the house selected by user
  useEffect(() => {
    setIsLoading(true);
    // get the landlord details that are linked to the user
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);
        //  get the outbox messages for the landlord
        serverUrl.get(`/communication/get-landlord-messages/?landlord_id=${landlordIds.toString()}`, config)
          .then((res) => {
            setMessageOutbox(res.data.results)
          })
          .catch((error) => {
            console.log(error)
          })
      })
      .catch((error) => {
        console.log(error);
      });


  }, [userId, config]);
  // filter the displayed data based on the apartment selected
  const [selectedApartment, setSelectedApartment] = useState(null);

  // get the unique apartrments from the communication
  const uniqueApartment = new Set();

  // Filter houses array to get unique apartment names
  const uniqueApartmentLandlord = messageOutbox.filter((apartmentMessage) => {
    if (!uniqueApartment.has(apartmentMessage.apartment_name)) {
      uniqueApartment.add(apartmentMessage.apartment_name);
      return true;
    }
    return false;
  })
  const exportCSV = () => {
    const csvData = [
      ['Recipient', 'Message', 'Date Time', 'Sender', 'House Number', 'Apartment'],
      ...messageOutbox.map((item) => [item.recipient, item.content, item.created_at, item.sender, item.house_number, item.apartment_name]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'outbox_mesaage.csv');
  };

  const [sortType, setSortType] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSort = (sortField) => {
    setSortType(sortField);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };

  const sortedData = messageOutbox.sort((a, b) => {
    const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === 'asc') {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    // filter by the apartment selected
    if (selectedApartment && item.apartment_name !== selectedApartment) {
      return false;
    }
    const searchableFields = Object.values(item).join(' ').toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  const handleRowClick = (item) => {
    setSelectedRow(item);
  };

  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);


  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString();

    return formattedDate;
  }

  return (
    <>
      {/* list of the tenants of the apartment and payment details */}
      <div className='tasks mt-5' style={{ width: "98%" }}>
        <div className='d-flex justify-content-between'>
          <h3 className='fw-bold' style={{ color: "#8e44ad" }}>Outbox Messages </h3>
        </div>

        <div className='d-flex justify-content-between mt-2'>

          <div>
            <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
            {/* <Button className='btn btn-danger text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
          </div>

          <div className='d-flex w-50 justify-content-end'>
            <FormSelect size='sm' className='formProperty w-25' value={selectedApartment} onChange={(e) => setSelectedApartment(e.target.value)}>
              <option disabled selected>Apartment</option>
              <option value="">All Apartments</option>
              {uniqueApartmentLandlord.map((apartmentMessage) => (
                <option key={apartmentMessage.apartment_name} value={apartmentMessage.apartment_name} > {apartmentMessage.apartment_name}</option>


              ))}
            </FormSelect>
            <Form className="searchBar mt-3 ms-2">
              <FormControl type="text" placeholder="Search" onChange={handleSearch} />
            </Form>
          </div>

        </div>
        <Table className='table mt-2'>
          <thead className='underline'>
            <th>Receipt</th>
            <th className='w-25'>Message</th>
            <th>Date Sent</th>
            <th>Sender</th>
            <th>House Number</th>

            <th>Apartment</th>


          </thead>
          <tbody>

            {displayedItems.map((template) => (
              <tr >
                <td>{template.recipient}</td>
                <td className='text-start'>{template.content}</td>
                <td>{formatDate(template.created_at)}</td>
                <td>{template.sender}</td>
                <td>{template.house_number}</td>
                <td>{template.apartment_name}</td>

                {/* <td>
                                    <Button className='btn btn-sm'><i className='bi bi-eye-fill'></i>View</Button>

                                </td> */}
              </tr>
            ))}
          </tbody>

          {/* modal for adding template */}
          <Modal show={addTemplate} onHide={handleReminderModalClose}>
            <Modal.Header className='bg-primary text-white' closeButton>

              <Modal.Title>Add Message Templates</Modal.Title>

            </Modal.Header>
            <Modal.Body>

              <>
                <div className="input-icons  mt-2 mb-2">
                  <i class="bi bi-chat-left-dots icon"></i>
                  <textarea className="input-field" rows={1}>Enter Title</textarea>
                </div>

                <div className="input-icons">
                  <i class="bi bi-send-plus icon"></i>
                  <textarea className="input-field">Enter Message</textarea>
                </div>
              </>


            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button variant='danger' onClick={handleReminderModalClose}>
                Close
              </Button>
              <Button variant='success' onClick={handleReminderModalClose}>
                Send
              </Button>
            </Modal.Footer>
          </Modal>

        </Table>

        <div className="pagination">
          <button onClick={handlePrevPage} disabled={currentPage === 0}>
            &lt;
          </button>
          <span>{`${startIndex + 1}-${endIndex} of ${messageOutbox.length}`}</span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
            &gt;
          </button>
        </div>

      </div>
    </>
  )
}

export default MessagesOutbox