import { useState } from "react";
import "./Register.css";
import { useNavigate} from 'react-router'
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import {serverUrl } from "../../serverUrl";
import jwt_decode from  "jwt-decode"
import { toast } from "react-toastify";

function Register() {
  const [formData, setFormData] = useState({
    fullname: "",
    fullnameError: "",
    email: "",
    phoneNumber: "",
    gender: "",
    password: "",
    passwordError: "",
    confirmPassword: "",
    confirmPasswordError: "",
  }); 

  const digitCount = formData.phoneNumber.replace(/[^0-9]/g, "").length; // Count only digits
  const navigate = useNavigate();
    // user details needed so that they can be automatically logged in 
    let [authTokens, setAuthTokens] = useState( ()=>localStorage .getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null  )
    let [user, setUser] = useState(()=>localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)
  
  const handleChange = (event) => {
    const { name, value } = event.target;
  
    // limit phone number input to 10 digits
    if (name === "phoneNumber") {
      const digitOnly = value.replace(/[^0-9]/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: digitOnly.substring(0, 10),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
  


  //submit user details fuction
  function handleSubmit(e) {
    // prevent form from redirecting to another page
    e.preventDefault();

    //check if password and confirm password match
    if (formData.password !== formData.confirmPassword)
    return setFormData((prevFormData) => ({
      ...prevFormData,
      confirmPasswordError: "Passwords do not match",
    }));
  else
    setFormData((prevFormData) => ({
      ...prevFormData,
      confirmPasswordError: "",
    }));
    // end of check if password and confirm password match
    
    // Password characters to be 8 
    if (formData.password.length < 8)
    return setFormData((prevFormData) => ({
      ...prevFormData,
      passwordError: "Password should have 8 or more characters",
    }));
  else
    setFormData((prevFormData) => ({
      ...prevFormData,
      passwordError: "",
    }));

    // end of checking characters length

     // check if the user has entered the 5 characters
     if (formData.fullname.length < 5)
     return setFormData((prevFormData) => ({
       ...prevFormData,
       fullnameError: "Full Name must be at least 5 characters",
     }));
   else
     setFormData((prevFormData) => ({
       ...prevFormData,
       fullnameError: "",
     }));
    //  end of username checking

    try {
      serverUrl

      //registering the user by hitting the endpoints
        .post("/users/register/", {
          email: formData.email,
          full_name: formData.fullname,
          phone_number: formData.phoneNumber,
          password: formData.password,
          gender: formData.gender,
        })
        .then((res) => {
          toast.success(`Welcome, You are successfully registered.`)
          // login in the user after they have successfully registered
          serverUrl
          .post("/users/login/", {
            phone_number: formData.phoneNumber,
            password: formData.password,
          })
          .then((res) => {
            // get the user acces tokens and store them
            const data = res.data.data
              setAuthTokens(data.tokens)
              setUser(jwt_decode(data.tokens.access))
              localStorage.setItem('authTokens',JSON.stringify(data.tokens))
            // Redirect to the page where they can add the landlord details
            navigate('/statutory')
          })
          .catch((error) => {
            console.log("Login failed: " + error.response.data);
          });
        })

        // displaying the messages of why the error occoyred
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            toast.error('Registration failed user with similar credentials is already registered')
          
          } else {
            console.log('Unexpected error: ' + error.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
    

  }
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="registerForm">
      <h2>Create an account with Kodi</h2>
      <p className="text-muted">Best tool to manage your property</p>
      <Form onSubmit={handleSubmit}>
        <div className="input-icons">
          <i className="fa fa-user icon"></i>
          <input
            className="input-field"
            type="text"
            placeholder="Full Name"
            name="fullname"
            value={formData.fullname}
            onChange={handleChange}
            required
          />
        </div>
        {formData.fullnameError && (
          <div className="fullnameerror">
            {formData.fullnameError}
          </div>
        )}
        <div className="input-icons">
          <i className="fa fa-envelope icon"></i>
          <input
            className="input-field"
            type="email"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-icons">
          <i className="fa fa-phone icon"></i>
          <input
            className="input-field"
            type="number"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            maxLength={10} // set maximum input length to 10 digits
            required
          />
        </div>
        <p
          style={{
            fontSize: "12px",
            paddingRight: "20px",
            float: "right",
          }}
        >
          {digitCount}/10
        </p>
        <div className="genderRadio">
          <div className="radioProperty">
            <input
              type="radio"
              name="gender"
              id="male"
              value="male"
              onChange={handleChange}
            />
            <label htmlFor="male">Male</label>
          </div>
          <div className="radioProperty">
            <input
              type="radio"
              name="gender"
              id="female"
              value="female"
              onChange={handleChange}
            />
            <label htmlFor="female">Female</label>
          </div>
        </div>
        <div className="input-icons">
          <i className="fa fa-lock icon"></i>
          <input
            className="input-field"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
            {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "10px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "10px" }} />}
          </span>

        </div>
     
        {formData.passwordError && (
          <div className="fullnameerror">
            {formData.passwordError}
          </div>
        )}
          <div className="input-icons">
          <i className="fa fa-lock icon"></i>
          <input
            className="input-field"
            type={showPassword ? "text" : "password"}
            placeholder="Confirm Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
          <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
            {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "10px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "10px" }} />}
          </span>

        </div>

        {/* <div className="input-icons">
          <i className="fa fa-lock icon"></i>
          <input
            className="input-field"
            type="password"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div> */}
        {formData.confirmPasswordError && (
          <div className="fullnameerror">
            {formData.confirmPasswordError}
          </div>
        )}
        <div className="termsAndConditions">
          <h6>By creating an account, you are agreeing to our</h6>

          <h6>
            <Link to="/" className="terms">
              Terms & Conditions
            </Link>{" "}
            and
            <Link to="/" className="privatePolicy">
              {" "}
              Privacy Policy
            </Link>
          </h6>
        </div>
        <Button variant="primary" type="submit" className="nextBtn">
          Next
        </Button>
      </Form>
    </div>
  );
}

export default Register;
