import React, { useState, useEffect } from 'react'
import { Image, Card, FormSelect, Button, Row, Col, Form, FormControl, Modal, Dropdown } from 'react-bootstrap';
import Header from './Header'
import './SelectedProperty.scss'
import './SelectedBlog.scss'

import SideBar from './SideBar'
import Apartment from '../../assets/img/apartment6.jpg'
import House5 from '../../assets/img/house5.jpg'
import { useParams, Link } from "react-router-dom";
import Book from '../../assets/img/book.png'
import Due from '../../assets/img/due-date.png'
import Percentage from '../../assets/img/discount.png'
import Income from '../../assets/img/salary.png'
import loaderImage from '../../assets/img/logo.png'
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";


function SelectedBlog() {
    const [showSidebar, setShowSidebar] = useState(true);
    const { id } = useParams();

    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [selectedBlog, setSelectedBlog] = useState([])

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // get the months and years filtered out
    const [monthFilterActive, setMonthFilterActive] = useState("")

    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }

    const [apartmentAll, setApartmentAll] = useState([])
    const [blogImages, setBlogImages] = useState([])
    const [blogImagesCover, setBlogImagesCover] = useState([])
    const [categoryName, setCategoryName] = useState('')

    // get the selected blog
    useEffect(() => {
        serverUrl.get(`/landlord/blog/${id}`, config)
            .then((res) => {
                setSelectedBlog(res.data)
            })
            .catch((error) => {
                console.log(error)
            })

        // get the categories needed
        serverUrl.get(`/landlord/categories`, config)
            .then((res) => {
                setApartmentAll(res.data.results);
                const selectedBlogCategory = res.data.results.find(category => category.id === selectedBlog.category)
                setCategoryName(selectedBlogCategory.name)
            })
            .catch((error) => {
                console.log(error)
            })



        // get the images of the blog
        serverUrl.get(`/landlord/blog-image/?blog_id=${id}`, config)
            .then((res) => {
                setBlogImages(res.data.results.filter((image) => image.is_main === false));
                const foundBlog = res.data.results.find(blog => blog.is_main === true);
                setBlogImagesCover(foundBlog)
            })
            .catch((error) => {
                console.log(error)
            })

    }, [])

    // function to add the property to featured
    const [selectedApartmentId, setSelectedApartmentId] = useState('')

    function handleApartmentSelect(selectedApartmentId) {
        setSelectedApartmentId(selectedApartmentId)
    }

    // / modal for editing blog
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        long_description: '',
        category: '',
    });

    useEffect(() => {
        if (selectedBlog) {
            setFormData({
                name: selectedBlog.name || '',
                description: selectedBlog.description || '',
                long_description: selectedBlog.long_description || '',
                category: selectedBlog.category || '',

            });
        }
    }, [selectedBlog]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };



    //  function to edit the blog
    function handleSubmit(e) {

        // preventing from redirecting to other pages
        e.preventDefault();

        // get user details so that you can use it from the access tokens
        const accessToken = localStorage.getItem("authTokens");

        // decode the tokens so that you can get the user_id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;

        // header's access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }

        // the data that will be posted to the api is as follows
        const data = {
            name: formData.name,
            description: formData.description,
            long_description: formData.long_description,
            category: selectedApartmentId,
            user: userId
        };

        //  now post the data
        try {
            serverUrl

                //send the Apartment details using the following endpoint
                .patch(`/landlord/blog/${id}/`, data, config)
                .then((res) => {
                    window.location.reload()
                    handleCloseModal()
                    toast.success('Successfully Changed the details of the blog!');
                })

                // displaying the messages of why the error 
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        alert('Error while changing the details of the blog' + error.message);
                    } else {
                        toast.error('Unexpected error')
                        console.log('Unexpected error: ' + error.message);
                    }
                });

        } catch (error) {
            console.log(error);
        }

    }

    const [showModalDeleteBlog, setShowModalDeleteBlog] = useState(false);
    const [deleteBlog, setDeleteBlog] = useState(null);

    // function to delete an image
    const handleOpenModalDeleteBlogImage = unitDetail => {
        setDeleteBlog(unitDetail);
        setShowModalDeleteBlog(true);
    };
    const handleCloseModalDeleteBlog = () => {
        setShowModalDeleteBlog(false);
    };

    // delete unit/house
    const handleDeleteBlog = () => {

        const accessToken = localStorage.getItem("authTokens");
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;


        // headers access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        // decoding the token so that i can get the user id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // the passed if from the modal
        const id = deleteBlog;
        // get the landlord details of the user
        serverUrl.delete(`/landlord/blog-image/${id}/`, config)
            .then((res) => {

                setDeleteBlog(null);
                toast.success("Blog Image Deleted!")
                handleCloseModalDeleteBlog()
                // Update the state to remove the deleted template
                setBlogImages((prevBlogImages) =>
                    prevBlogImages.filter((image) => image.id !== deleteBlog)
                );

            }, [])
            .catch((error) => {
                console.log(error);
            });
    }


    // randomise the images for the blog
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');

    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            const invalidNumbers = [0, -1];
            while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImageApartment(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);
    const backgroundImage = blogImagesCover ? blogImagesCover.image : backgroundImageApartment;

    return (
        <>

            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            <div className='selected-property'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>

                    <div className='ImageHouseName'>
                        <Button className='btn btn-sm btn-success'>
                            <Link to={`/edit-blog/${id}`} className='text-decoration-none text-white'>
                                Edit<i className='bi bi-pen ms-2'></i>

                            </Link>
                        </Button>
                        {/* modal to edit the apartment details */}
                        <Modal show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Blog Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <>
                                    <Link to={`/selected-blog-image/${id}`} className='addHouse btn btn-info text-white' style={{ margin: "0 auto", display: "flex", justifyContent: "center" }}>Add Blog Image</Link>
                                    <Form onSubmit={handleSubmit} className='mt-3'>
                                        <p className='lableName'>Blog Name</p>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="text"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                placeholder="Blog Name"
                                                required
                                            />
                                        </div>
                                        <p className='lableName'>Blog Short Description</p>

                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="text"
                                                name="description"
                                                value={formData.description}
                                                onChange={handleChange}
                                                placeholder="Description"
                                                required
                                            />
                                        </div>
                                        <p className='lableName'>Blog Long Description</p>

                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="text"
                                                name="long_description"
                                                value={formData.long_description}
                                                onChange={handleChange}
                                                placeholder="Long Description"
                                                required
                                            />
                                        </div>
                                        <p className='lableName'>Blog Category</p>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="text"
                                                name="category"
                                                value={formData.category}
                                                onChange={handleChange}
                                                placeholder="Category"
                                                required
                                            />
                                        </div>
                                        <Button variant="primary" type="submit" className="nextBtn mt-3" >
                                            Save
                                        </Button>
                                    </Form>
                                </>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" onClick={handleCloseModal} >
                                    Done
                                </Button>
                            </Modal.Footer>
                        </Modal>


                        {selectedBlog && (<>
                            <span className='properties'>{selectedBlog.category}</span>
                            <h2>{selectedBlog.name}</h2>
                        </>)}


                    </div>
                </div>

                <div className='blogDetails mt-3'>
                    <Card>
                        <Card.Body>
                            <h2>{selectedBlog.description}</h2>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: selectedBlog.long_description,
                                }}
                            ></p>


                            <Row className='blogImages'>
                                {blogImages.map((images) => (
                                    <Col md={6} sm={12} xm={12}>
                                        <i className='bi bi-trash-fill text-danger' onClick={() => handleOpenModalDeleteBlogImage(images.id)} ></i>
                                        <Image src={images.image} />
                                    </Col>

                                ))}

                            </Row>
                        </Card.Body>
                    </Card>


                    <Modal show={showModalDeleteBlog} onHide={handleCloseModalDeleteBlog}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Image</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Do you want to delete the Image?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={handleCloseModalDeleteBlog}>
                                No
                            </Button>
                            <Button houseDeleteId={deleteBlog} variant="danger" onClick={handleDeleteBlog}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

            </div>
        </>
    )
}

export default SelectedBlog