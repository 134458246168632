import React, { useState, useEffect } from 'react'
import './SelectedHouse.scss'
import { Link, useParams } from "react-router-dom";
import Header from './Header';
import SideBar from './SideBar';

import loaderImage from '../../assets/img/logo.png'
import SelectedHouseCarousel from './SelectedHouseCarousel.js';
import { Button, Card, Col, Row, Image, Modal, Form, Dropdown, FormSelect, FormControl, Table } from 'react-bootstrap';
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import IssueReport from './reports/IssueReport';
import HistoryReport from './reports/HistoryReport';
import InvoiceReport from './reports/InvoiceReport';
import ReceiptReport from './reports/ReceiptReport';
import PaymentImage from './reports/PaymentImage';
import { saveAs } from 'file-saver';

function SelectedHouse() {

    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [showModalHouse, setShowModalHouse] = useState(false)
    const [showModalRate, setShowModalRate] = useState(false);

    // modal for editing the details of the tenant
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [houseTenant, setHouseTenant] = useState([])
    const [joined, setJoined] = useState("")
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());
    // the current date format in yyyy-mm-dd
    let formatDate = new Date().toISOString().split("T")[0]

    // remove tenant
    const [showModalDeleteTenant, setShowModalDeleteTenant] = useState(false);
    const [tenantRemove, setTenantRemove] = useState(null);
    const [tenantIdOccupancy, setTenantIdOccupancy] = useState(null);
    const [houseId, setHouseId] = useState(null);

    const handleOpenDeleteTenant = () => {
        setShowModalDeleteTenant(true);
    };
    const handleCloseModalDeleteTenant = () => {
        setShowModalDeleteTenant(false);
    };
    const handleCloseModalReloadTenantDelete = () => {
        setShowModalDeleteTenant(false);
        window.location.reload()
    };
    // loading status
    const [isLoading, setIsLoading] = useState(true)


    // form data for tenant details
    const [formDataTenant, setFormDataTenant] = useState({
        full_name: "",
        phone_number: ""
    });
    const [landlord, setLandlord] = useState("")

    // house selected
    const [houseSelected, setHouseSelected] = useState([])
    const [houseSelectedApartment, setHouseSelectedApartment] = useState("")
    const [houseApartment, setHouseApartment] = useState("")
    const [unitAmenities, setUnitAmenities] = useState([])
    const [unitRooms, setUnitRooms] = useState([])

    const [unitAmenitiesIcons, setUnitAmenitiesIcons] = useState([])

    // for kodi score purpose
    const [tenantScore, setTenantScore] = useState("")
    const [tenantId, setTenantId] = useState("")
    const [occupancySet, setOccupancy] = useState({})
    const [rentStatus, setRentStatus] = useState({})
    // get the list of the houses

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;


    // get the house selected by user
    // get the house selected by user
    useEffect(() => {
        setIsLoading(true);
        // get the amenities of that unit 
        serverUrl.get(`/landlord/unit-icons/`, config)
            .then((res) => {
                setUnitAmenitiesIcons(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })



        // get the landlord of that house
        serverUrl
            .get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                setLandlord(res.data.results[0].id);
            })
            .catch((error) => {
                console.log(error);
            });

        serverUrl
            .get(`/landlord/house/${id}/`, config)
            .then((res) => {
                setHouseSelected(res.data);
                setHouseSelectedApartment(res.data.apartment);
                setIsLoading(false);
                // get the apartment name of the house
                serverUrl.get(`/landlord/apartment/?id=${res.data.apartment}`, config)
                    .then((res) => {
                        setHouseApartment(res.data.results[0].name)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });

        // get the tenant of the house
        serverUrl
            .get(`/tenant/occupancy/?house_id=${id}`, config)
            .then((res) => {
                // this the occupancy id obtained
                setTenantRemove(res.data.results[0].id);
                // the house id obtained
                setHouseId(res.data.results[0].house);
                // the tenant id obtained
                setTenantIdOccupancy(res.data.results[0].tenant);
                setJoined(res.data.results[0].date_in);

                const tenantOccupancy = {};
                res.data.results.forEach((occupancy) => {
                    if (occupancy.status === "active") {
                        tenantOccupancy[id] = occupancy;
                        setOccupancy(occupancy);
                    }
                });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });



        // Assuming unitRooms is an array of objects with room_id property
        serverUrl.get(`/landlord/alliance-unit-rooms?house_id=${id}`, config)
            .then((res) => {
                const unitRooms = res.data.results;
                const groupedRooms = unitRooms.reduce((acc, amenity) => {
                    const { room_id, room, room_image, room_image_id } = amenity;

                    if (acc[room_id]) {
                        acc[room_id].push({ room, room_image, room_id, room_image_id });
                    } else {
                        acc[room_id] = [{ room, room_image, room_id, room_image_id }];
                    }

                    return acc;
                }, {});
                // Transform the groupedRooms object into an array of objects
                const groupedRoomsArray = Object.keys(groupedRooms).map((roomId) => ({
                    room: groupedRooms[roomId][0],
                    data: groupedRooms[roomId],

                }));
                // Now, you can set the state with the grouped data
                setUnitRooms(groupedRoomsArray);

            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        serverUrl
            .get(`/tenant/tenant/${occupancySet.tenant}`, config)
            .then((res) => {
                setTenantId(res.data.id);
                // get the kodi score of the tenant
                serverUrl
                    .get(`/tenant/tenancy-kodi-score/?tenant_id=${res.data.id}`, config)
                    .then((res) => {
                        setTenantScore(res.data.kodi_score);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                serverUrl
                    .get(`/users/users-details/${res.data.user}/`, config)
                    .then((res) => {
                        setHouseTenant(res.data);
                        // get the rent status payment
                        serverUrl
                            .get(`/landlord/get-rent-status/?user_id=${res.data.id}&house_id=${id}`, config)
                            .then((res) => {
                                setRentStatus(res.data);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }, [occupancySet.tenant]);

    // delete amenity variables
    const [formDataAmenityDelete, setFormDataAmenityDelete] = useState({
        amenity: "",

    });
    // onchange functionality on delete new amenity
    const handleChangeAmenityDelete = (name) => (event) => {
        const { value } = event.target;
        setFormDataAmenityDelete((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    // add amenities variables 
    const [formDataAmenity, setFormDataAmenity] = useState({
        icon: ""
    });

    const handleChangeAmenity = (e) => {
        const selectedFacilityIconId = e.target.value;
        setFormDataAmenity({ ...formDataAmenity, icon: selectedFacilityIconId });
    };

    // add nearby facilities
    function handleAmenities(e) {
        // preventing from redirecting to other pages
        e.preventDefault();

        // the data that will be posted to the api is as follows
        const data = {
            house: parseInt(id),
            icon: parseInt(formDataAmenity.icon),
        };

        //  now post the data 
        try {
            serverUrl

                //send the amenities of the apartment to the backend
                .post(`/landlord/unit-amenities/`, data, config)
                .then((res) => {
                    toast.success('Successfully added the amenities for this unit!');
                    setFormDataAmenity({
                        icon: "",
                    })
                    // get the amenities
                    serverUrl.get(`/landlord/alliance-unit-amenities?house_id=${id}`, config)
                        .then((res) => {
                            setUnitAmenities(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })

                // displaying the messages of why the error 
                .catch((error) => {
                    toast.error('Unexpected error')
                    console.log('Unexpected error: ' + error.message);

                });

        } catch (error) {
            console.log(error);
        }

    }

    const handleDeleteTenanat = () => {

        const accessToken = localStorage.getItem("authTokens");
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;


        // headers access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        // decoding the token so that i can get the user id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // on remove of the tenant from house the update the occupancy status 
        try {
            // the data for the occupancy status to be updated 
            const occupancyUpdate = {
                date_out: formatDate,
                status: "expired",
                house: houseId,
                tenant: tenantIdOccupancy
            }
            serverUrl
                //send the tenant details using the following endpoint
                .patch(`/tenant/occupancy/${tenantRemove}/`, occupancyUpdate, config)
                .then((res) => {
                    toast.success("Tenant Sucessfully Removed")
                    handleCloseModalReloadTenantDelete()
                })
                // displaying the messages of why the error 
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        toast.error(error.response.data.data.error);
                    } else {
                        console.log(error)
                        toast.error('Unexpected error')
                        console.log('Unexpected error: ' + error.message);
                    }
                });

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }

    };

    const selectedUnit = houseSelected
    const idHouse = parseInt(id)

    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // modal for adding tenant

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    // done button reloading the page
    const handleCloseModalReload = () => {
        setShowModal(false);
        window.location.reload()
    };

    // modal for editing the details of the tenant

    const handleOpenModalEdit = () => {
        setShowModalEdit(true);
    };

    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    };
    const handleCloseModalEditReload = () => {
        setShowModal(false);
        window.location.reload()
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedApartmentId, setSelectedApartmentId] = useState(null);

    const openModal = (apartmentId) => {
        setSelectedApartmentId(apartmentId);
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedApartmentId(null);
    };


    const handleDeleteApartment = () => {
        if (selectedApartmentId) {
            serverUrl.delete(`/landlord/house-room-image/${selectedApartmentId}`, config)
                .then((res) => {
                    serverUrl.get(`/landlord/alliance-unit-rooms?house_id=${id}`, config)
                        .then((res) => {
                            const unitRooms = res.data.results;
                            const groupedRooms = unitRooms.reduce((acc, amenity) => {
                                const { room_id, room, room_image, room_image_id } = amenity;

                                if (acc[room_id]) {
                                    acc[room_id].push({ room, room_image, room_id, room_image_id });
                                } else {
                                    acc[room_id] = [{ room, room_image, room_id, room_image_id }];
                                }

                                return acc;
                            }, {});

                            // Transform the groupedRooms object into an array of objects
                            const groupedRoomsArray = Object.keys(groupedRooms).map((roomId) => ({
                                room: groupedRooms[roomId][0],
                                data: groupedRooms[roomId],

                            }));
                            // Now, you can set the state with the grouped data
                            setUnitRooms(groupedRoomsArray);
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                    setIsModalOpen(false);

                    toast.success('Succesfully deleted the image')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }


    const [showModalRoomsDelete, setShowModalRoomsDelete] = useState(false);
    const handleOpenModalRoomDelete = () => {
        setShowModalRoomsDelete(true);
    };

    const handleCloseModalRoomDelete = () => {
        setShowModalRoomsDelete(false);
    };

    // delete room 
    const [formDataRoomDelete, setFormDataRoomDelete] = useState({
        room: "",

    });

    // onchange functionality on delete new room
    const handleChangeRoomDelete = (name) => (event) => {
        const { value } = event.target;
        setFormDataRoomDelete((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };


    // delete the room selected
    function handleDeleteRoom(e) {
        e.preventDefault();

        // Get the room ID and then delete it
        serverUrl.get(`/landlord/house-room/?room=${formDataRoomDelete.room}&house=${id}`)
            .then((res) => {
                const deletePromises = res.data.results.map((result) => {
                    return serverUrl.delete(`/landlord/house-room/${result.id}/`, config);
                });

                Promise.all(deletePromises)
                    .then((deleteResults) => {
                        toast.success('Successfully Deleted The Room(s) Of The Unit!');
                        setFormDataRoomDelete({
                            room: "",
                        });

                        // Assuming unitRooms is an array of objects with room_id property
                        serverUrl.get(`/landlord/alliance-unit-rooms?house_id=${id}`, config)
                            .then((res) => {
                                const unitRooms = res.data.results;
                                const groupedRooms = unitRooms.reduce((acc, amenity) => {
                                    const { room_id, room, room_image, room_image_id } = amenity;

                                    if (acc[room_id]) {
                                        acc[room_id].push({ room, room_image, room_id, room_image_id });
                                    } else {
                                        acc[room_id] = [{ room, room_image, room_id, room_image_id }];
                                    }

                                    return acc;
                                }, {});

                                // Transform the groupedRooms object into an array of objects
                                const groupedRoomsArray = Object.keys(groupedRooms).map((roomId) => ({
                                    room: groupedRooms[roomId][0],
                                    data: groupedRooms[roomId],

                                }));
                                // Now, you can set the state with the grouped data
                                setUnitRooms(groupedRoomsArray);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        toast.error('Error deleting room(s)');
                        console.log('Error deleting room(s): ' + error.message);
                    });
            })
            .catch((error) => {
                toast.error('Error fetching room(s) for deletion');
                console.log('Error fetching room(s) for deletion: ' + error.message);
            });
    }
    // counting the number of digits inserted on the phone number
    const digitCount = formDataTenant.phone_number.replace(/[^0-9]/g, "").length;
    // the data that will be posted to the api is as follows



    const handleOpenModalRate = () => {
        setShowModalRate(true);
    };

    const handleCloseModalRate = () => {
        setShowModalRate(false);
    };
    // done button reloading the page
    const handleCloseModalReloadRate = () => {
        setShowModalRate(false);
        window.location.reload()
    };
    // form data for tenant details ratings
    const [formDataTenantRate, setFormDataTenantRate] = useState({
        comment: "",
        rating: ""
    });

    // onchange functionality on rating tenant
    const handleChangeTenantRate = (event) => {
        const { name, value } = event.target;

        setFormDataTenantRate((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

    }
    // the stars value
    const [starsClicked, setStarsClicked] = useState(0);

    useEffect(() => {
        // console.log("Value of stars:", starsClicked === 0 ? 0 : starsClicked);
    }, [starsClicked]);

    // add the special amenities
    const [showModalAmenity, setShowModalAmenity] = useState(false);
    const handleOpenModalAmenityAdd = () => {
        setShowModalAmenity(true);
    };

    const handleCloseModalAmenityAdd = () => {
        setShowModalAmenity(false);
    };
    // add the special amenities
    const [showModalAmenityDelete, setShowModalAmenityDelete] = useState(false);
    const handleOpenModalAmenityDelete = () => {
        setShowModalAmenityDelete(true);
    };

    const handleCloseModalAmenityDelete = () => {
        setShowModalAmenityDelete(false);
    };

    const addedFacilityIds = unitAmenities.map((facility) => facility.icon_id);

    const availableAmenities = unitAmenitiesIcons.filter((facility) => !addedFacilityIds.includes(facility.id));


    // submit to delete the amenity
    function handleDeleteAmenity(e) {
        // preventing from redirecting to other pages
        e.preventDefault();

        //  now post the data
        try {
            serverUrl

                //send the amenities of the apartment to the backend
                .delete(`/landlord/unit-amenities/${formDataAmenityDelete.amenity}/`, config)
                .then((res) => {
                    toast.success('Successfully Deleted The Amenity Of The Unit!');
                    setFormDataAmenityDelete({
                        amenity: ""
                    })
                    // get the amenities
                    serverUrl.get(`/landlord/alliance-unit-amenities?house_id=${id}`, config)
                        .then((res) => {
                            setUnitAmenities(res.data.results)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })

                // displaying the messages of why the error 
                .catch((error) => {
                    toast.error('Unexpected error')
                    console.log('Unexpected error: ' + error.message);

                });

        } catch (error) {
            console.log(error);
        }

    }
    const handleStarClick = (starIndex) => {
        if (starIndex === 0) {
            setStarsClicked(0);
        } else if (starsClicked === starIndex) {
            setStarsClicked(starIndex - 1);
        } else {
            setStarsClicked(starIndex);
        }
    };
    // rate the tenant
    function handleSubmitTenanatRate(e) {
        e.preventDefault();
        // get user details so that you can use it from the access tokens
        const accessToken = localStorage.getItem("authTokens");

        // decode the tokens so that you can get the user_id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;

        // header's access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }

        // the data that will be posted to the api is as follows
        const dataTenantRate = {
            comment: `${formDataTenantRate.comment}`,
            rating: starsClicked,
            tenant: tenantIdOccupancy,
            landlord: landlord,

        };
        try {
            serverUrl

                //send the tenant details using the following endpoint
                .post(`/tenant/landlord-rating/`, dataTenantRate, config)
                .then((res) => {
                    toast.success('Tenant Successfully Rated!')
                    window.location.reload()
                })
                // displaying the messages of why the error 
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        toast.error(error.response.data.data.error);
                    } else {
                        toast.error('Unexpected error')
                        console.log('Unexpected error: ' + error.message);
                    }
                });

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }

    }
    // onchange functionality on adding tenant
    const handleChangeTenant = (event) => {
        const { name, value } = event.target;
        // limit phone number input to 10 digits
        if (name === "phone_number") {
            const digitOnly = value.replace(/[^0-9]/g, "");
            setFormDataTenant((prevFormData) => ({
                ...prevFormData,
                [name]: digitOnly.substring(0, 10),
            }));
        } else {
            setFormDataTenant((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    }


    // add the tenant to database
    function handleSubmitTenanat(e) {
        e.preventDefault();
        // get user details so that you can use it from the access tokens
        const accessToken = localStorage.getItem("authTokens");

        // decode the tokens so that you can get the user_id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;

        // header's access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }

        // the data that will be posted to the api is as follows
        const dataTenant = {
            email: `${formDataTenant.phone_number}@mail.com`,
            full_name: formDataTenant.full_name,
            phone_number: formDataTenant.phone_number,
            password: formDataTenant.phone_number,

        };
        try {
            serverUrl

                //send the tenant details using the following endpoint
                .post(`/users/register/`, dataTenant)
                .then((res) => {
                    const pendingData = {
                        user: res.data.data.id,
                        apartment: id
                    }
                    serverUrl.post(`/tenant/pending-tenant/`, pendingData, config)
                        .then((res) => {
                            toast.success("Tenant Successfully added!");

                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        });
                })
                // displaying the messages of why the error 
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        toast.error(error.response.data.data.error);
                    } else {
                        toast.error('Unexpected error')
                        console.log('Unexpected error: ' + error.message);
                    }
                });

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }

    }
    // form data to be sent to backend on eadding the House
    const [formDataHouse, setFormDataHouse] = useState({
        house_number: "",
        total_rooms: "",
        due_date: "",
        bedroom: "",
        deposit: "",
        bathroom: "",
        rent: "",
        type_of_house: "",
    });

    // Use useEffect to update formData when selectedApartment changes
    useEffect(() => {
        if (selectedUnit) {
            setFormDataHouse({
                house_number: selectedUnit.house_number || '',
                total_rooms: selectedUnit.total_rooms || '',
                due_date: selectedUnit.due_date || '',
                bedroom: selectedUnit.bedroom || '',
                deposit: selectedUnit.deposit || '',
                bathroom: selectedUnit.bathroom || '',
                rent: selectedUnit.rent || '',
                type_of_house: selectedUnit.type_of_house || '',
            });
        }
    }, [selectedUnit]);

    // onchange functionality on editing a  house
    const handleChangeHouse = (event) => {
        const { name, value } = event.target;
        setFormDataHouse((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    // editing the selected house
    function handleSubmitHouse(e) {

        // preventing from redirecting to other pages
        e.preventDefault();

        // get user details so that you can use it from the access tokens
        const accessToken = localStorage.getItem("authTokens");

        // decode the tokens so that you can get the user_id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;

        // header's access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }

        // the data that will be posted to the api is as follows
        const dataHouse = {
            house_number: formDataHouse.house_number,
            total_rooms: formDataHouse.total_rooms,
            bedroom: formDataHouse.bedroom,
            bathroom: formDataHouse.bathroom,
            due_date: formDataHouse.due_date,
            deposit: formDataHouse.deposit,
            rent: formDataHouse.rent,
            apartment: houseSelectedApartment
        };
        //  now post the data
        try {
            serverUrl

                //send the House details using the following endpoint
                .patch(`/landlord/house/${id}/`, dataHouse, config)
                .then((res) => {
                    setFormDataHouse({
                        house_number: "",
                        total_rooms: "",
                        due_date: "",
                        bedroom: "",
                        deposit: "",
                        bathroom: "",
                        rent: "",
                        type_of_house: "",
                    });
                    toast.success('Successfully Updated The House!');
                    window.location.reload()
                })

                // displaying the messages of why the error 
                .catch((error) => {
                    if (error.response && error.response.status === 400) {
                        alert('Error while adding a new house' + error.message);
                    } else {
                        toast.error('Unexpected error')
                        console.log('Unexpected error: ' + error.message);
                    }
                });

        } catch (error) {
            console.log(error);
        }

    }

    // now display the stars based on the score they have obtained

    const scoreParts = tenantScore.split("/");
    const scoreKodi = scoreParts[0];
    const dividedScore = parseFloat(scoreParts[0]) / 2;

    const [sharedUnit, setSharedUnits] = useState('')
    const [likedUnits, setLikedUnit] = useState('')
    const [userShareCounts, setUserShareCounts] = useState([])
    const [userLikedUnit, setUserLikedUnit] = useState([])

    const starCount = Math.floor(dividedScore);
    // give them default half stars if for their score
    const hasHalfStar = dividedScore < 1;

    const stars = [];
    for (let i = 0; i < 5; i++) {
        if (i < starCount) {
            stars.push(<i className='bi bi-star-fill'></i>);
        } else if (i === starCount && hasHalfStar) {
            stars.push(<i className='bi bi-star-half'></i>);
        } else {
            stars.push(<i className='bi bi-star'></i>);
        }
    }

    // when user clicks call then open the call
    const handleClickPhone = () => {
        window.location.href = `tel:${houseTenant.phone_number}`;
    };

    // send reminder for the landlord
    const [remindersModal, setRemindersModal] = useState(false)

    // modal for filtering on the month
    const handleReminderModalOpen = () => {
        setRemindersModal(true);
    };

    const handleReminderModalClose = () => {
        setRemindersModal(false);
    };
    const handleReminderModalReload = () => {
        setRemindersModal(false);
        window.location.reload()
    };

    const [selectedOptionReminder, setSelectedOptionReminder] = useState(null);
    const [addTemplate, setAddTemplate] = useState(false);

    const handleOptionSelect = (option) => {
        setSelectedOptionReminder(option);
        setAddTemplate(false)
    };

    const handleAddTemplate = () => {
        setSelectedOptionReminder('')
        setAddTemplate(true)
    }

    const [publicModal, setPublicModal] = useState(false)


    const handleOpenPublicModal = () => {
        setPublicModal(true);
    };

    const handleClosePublicModal = () => {
        setPublicModal(false);
    };

    // download the tenants history automatically
    const handleDownload = () => {
        try {
            toast.primary("Downloading PDF. Please be patient...");

            serverUrl.get(`tenant/tenancy-history/?tenant_id=${tenantId}&download=true`, config)
                .then((res) => {
                    // create a download url for the pdf and then automatically download the pdf
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', 'report.pdf');
                    document.body.appendChild(link);
                    link.click();
                    toast.success("Successfully Downloaded Your Tenant's Report");
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast.error("Error occurred while downloading the PDF. Please try again later.");
                });
        } catch (error) {
            console.log(error);
        }
    };

    // change the unit status
    const occupancyStatus = () => {
        // get to see the status of the house first
        serverUrl.get(`/landlord/house/${id}/`, config)
            .then((res) => {

                if (res.data.occupancy_status === "vacant") {
                    const data = {
                        occupancy_status: "occupied"
                    }

                    serverUrl.patch(`/landlord/house/${id}/`, data, config)
                        .then((res) => {
                            toast.success('Succesfully changed the status the house is now occupied')
                            setShowModal(false);
                            window.location.reload()

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
                else {
                    const data = {
                        occupancy_status: "vacant"
                    }


                    serverUrl.patch(`/landlord/house/${id}/`, data, config)
                        .then((res) => {
                            toast.success('Succesfully changed the status the house is now vacant')
                            setShowModal(false);
                            window.location.reload()

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
            .catch((error) => {
                console.log(error)
            })


    }

    const publicStatus = () => {
        // get to see the status of the house first
        serverUrl.get(`/landlord/house/${id}/`, config)
            .then((res) => {
                const PublicUnit = res.data.is_public
                // check if the house has images
                serverUrl.get(`/landlord/house-images?house_id=${res.data.id}`, config)
                    .then((res) => {
                        if (res.data.count > 4) {
                            if (PublicUnit === false) {
                                const data = {
                                    is_public: true
                                }

                                serverUrl.patch(`/landlord/house/${id}/`, data, config)
                                    .then((res) => {
                                        toast.success('Succesfully changed the status the house is publicly listed')
                                        setPublicModal(false);
                                        window.location.reload()

                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }
                            else {
                                const data = {
                                    is_public: false
                                }


                                serverUrl.patch(`/landlord/house/${id}/`, data, config)
                                    .then((res) => {
                                        toast.success('Succesfully changed the status the house is not publicly listed')
                                        setPublicModal(false);
                                        window.location.reload()

                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            }
                        }
                        else {
                            toast.error('Kindly add more than 5 images for this unit to allow it to be made public')
                            setPublicModal(false);
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })


            })
            .catch((error) => {
                console.log(error)
            })


    }

    const [sharesUnitModal, setSharesUnitModal] = useState(false)


    const handleOpenShareUnitsModal = () => {
        setSharesUnitModal(true);
    };

    const handleCloseShareUnitsModal = () => {
        setSharesUnitModal(false);
    };


    const [reviewsModal, setreviewsModal] = useState(false)


    const handleOpenReviewModal = () => {
        setreviewsModal(true);
    };

    const handleCloseReviewModal = () => {
        setreviewsModal(false);
    };


    const [likedUnitModal, setLikedUnitModal] = useState(false)


    const handleOpenLikedUnitsModal = () => {
        setLikedUnitModal(true);
    };

    const handleCloseLikedUnitsModal = () => {
        setLikedUnitModal(false);
    };
    // on search of the property liked
    const [searchQueryProperty, setSearchQueryProperty] = useState("");
    const [selectedRowProperty, setSelectedRowProperty] = useState(null);

    const handleSearchProperty = (event) => {
        setSearchQueryProperty(event.target.value);
        setSelectedRowProperty(null); // Reset selected row when user starts a new search
    };

    const filteredDataProperty = userShareCounts.filter((item) => {

        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQueryProperty.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPageProperty, setCurrentPageProperty] = useState(0);
    const itemsPerPageProperty = 10;

    const totalPagesProperty = Math.ceil(filteredDataProperty.length / itemsPerPageProperty);
    const startIndexProperty = currentPageProperty * itemsPerPageProperty;
    const endIndexProperty = currentPageProperty === totalPagesProperty - 1 ? filteredDataProperty.length : startIndexProperty + itemsPerPageProperty;
    const displayedItemsProperty = filteredDataProperty.slice(startIndexProperty, endIndexProperty);

    const exportCSVProperty = () => {
        const csvData = [
            ['Unit', 'Shares', 'Time Shared'],
            ...userShareCounts.map((item) => [item.name, item.count, new Date(item.latestShareTime).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }),]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Unit Shares.csv');
    };
    const handlePrevPageProperty = () => {
        setCurrentPageProperty(currentPageProperty - 1);
    };

    const handleNextPageProperty = () => {
        setCurrentPageProperty(currentPageProperty + 1);
    };


    // on search of the property liked
    const [searchQueryLikes, setSearchQueryLikes] = useState("");
    const [selectedRowLikes, setSelectedRowLikes] = useState(null);

    const handleSearchLikes = (event) => {
        setSearchQueryLikes(event.target.value);
        setSelectedRowLikes(null); // Reset selected row when user starts a new search
    };

    const filteredDataLikes = userLikedUnit.filter((item) => {

        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQueryLikes.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPageLikes, setCurrentPageLikes] = useState(0);
    const itemsPerPageLikes = 10;

    const totalPagesLikes = Math.ceil(filteredDataLikes.length / itemsPerPageLikes);
    const startIndexLikes = currentPageLikes * itemsPerPageLikes;
    const endIndexLikes = currentPageLikes === totalPagesLikes - 1 ? filteredDataLikes.length : startIndexLikes + itemsPerPageLikes;
    const displayedItemsLikes = filteredDataLikes.slice(startIndexLikes, endIndexLikes);

    const exportCSVLikes = () => {
        const csvData = [
            ['Apartment', 'Likes', 'Liked Date'],
            ...userLikedUnit.map((item) => [item.apartmentName, item.likeCount, new Date(item.latestLikedTime).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
            }),]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Unit Likes.csv');
    };

    const handlePrevPageLikes = () => {
        setCurrentPageLikes(currentPageLikes - 1);
    };

    const handleNextPageLikes = () => {
        setCurrentPageLikes(currentPageLikes + 1);
    };



    function toCamelCase(str) {
        return str.replace(/_/g, " ");
    }

    // const reviews = [
    //     { id: 1, fullName: 'John Doe', review: 'Great experience with the property.', isPublic: true, isPrivate: false },
    //     { id: 2, fullName: 'Jane Smith', review: 'Nice place, good amenities.', isPublic: false, isPrivate: true },
    //     { id: 3, fullName: 'Alice Johnson', review: 'Had some issues but overall satisfied.', isPublic: true, isPrivate: false },
    //     { id: 4, fullName: 'Bob Brown', review: 'Not happy with the cleanliness.', isPublic: true, isPrivate: false },
    //     { id: 5, fullName: 'Emma Wilson', review: 'Fantastic location and great value.', isPublic: true, isPrivate: false },
    //     { id: 6, fullName: 'Michael Davis', review: 'Average experience, could be better.', isPublic: false, isPrivate: true },
    //     { id: 7, fullName: 'Sophia Martinez', review: 'Lovely neighborhood, quiet and peaceful.', isPublic: true, isPrivate: false },
    //     { id: 8, fullName: 'William Taylor', review: 'Needs improvement in maintenance.', isPublic: true, isPrivate: false },
    //     { id: 9, fullName: 'Olivia Anderson', review: 'Excellent service from the landlord.', isPublic: false, isPrivate: false },
    //     { id: 10, fullName: 'James Rodriguez', review: 'Overall satisfied with the property.', isPublic: true, isPrivate: false },
    // ];

    const [reviews, setReviews] = useState([])
    const [unAttendedReviews, setUnAttended] = useState([])
    const [reviewCount, setReviewCount] = useState('')

    useEffect(() => {
        // Get the reviews of that unit
        serverUrl.get(`/tenant/tenant-reviews/?house=${id}&ordering=-created_at`)
            .then((res) => {
                const reviews = res.data.results;

                // Filter reviews with same created_at and updated_at dates (ignoring seconds)
                const sameTimeReviews = reviews.filter(review => {
                    // Get the date without seconds
                    const createdDate = new Date(review.created_at);
                    createdDate.setSeconds(0);

                    const updatedDate = new Date(review.updated_at);
                    updatedDate.setSeconds(0);

                    // Compare the dates
                    return createdDate.getTime() === updatedDate.getTime();
                });

                // Count the number of reviews with same dates
                setUnAttended(sameTimeReviews)
                setReviewCount(sameTimeReviews.length)
                setReviews(reviews);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [id]);



    // the modal for updating the review
    const [selectedReview, setSelectedReview] = useState(null);
    const [showReviewModal, setShowReviewModal] = useState(false);

    const handleButtonClick = (review) => {
        setSelectedReview(review);
        setShowReviewModal(true);
    };

    const handleReviewCloseModal = () => {
        setShowReviewModal(false);
    };


    const handleConfirm = async () => {
        if (selectedReview) {
            try {

                const updatedReview = {
                    ...selectedReview,
                    is_public: !selectedReview.is_public
                };

                await serverUrl.patch(`/tenant/tenant-reviews/${selectedReview.id}/`, updatedReview, config);

                // Update the reviews array
                // Filter reviews with same created_at and updated_at dates (ignoring seconds)
                const sameTimeReviews = reviews.filter(review => {
                    // Get the date without seconds
                    const createdDate = new Date(review.created_at);
                    createdDate.setSeconds(0);

                    const updatedDate = new Date(review.updated_at);
                    updatedDate.setSeconds(0);

                    // Compare the dates
                    return createdDate.getTime() === updatedDate.getTime();
                });
                setUnAttended(sameTimeReviews)

                setReviews(reviews.map(r => r.id === selectedReview.id ? updatedReview : r));
                toast.success('Succesfully updated the review status')
                setShowReviewModal(false);
            } catch (error) {
                console.error('Error updating review status:', error);
                setShowReviewModal(false);
            }
        }
    };



    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            {
                selectedUnit.id === idHouse ?
                    (<div className='selectedHouse'>

                        <SelectedHouseCarousel houseId={selectedUnit.id} />
                        <div className='selectedHouseImageContent'>
                            <h2>{selectedUnit.house_number}</h2>
                            <span >{houseApartment}</span>
                            <div className='buttonsSelectedHouse'>
                                <Button className='btn btn-primary border-0' onClick={handleOpenModalEdit}>Edit</Button>
                                <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit Your Unit Details</Modal.Title>
                                    </Modal.Header>
                                    <>
                                        <Link to={`/selected-house-image/${idHouse}`} className='addHouse btn btn-info text-white '>Add Unit Image</Link>
                                        <Form onSubmit={handleSubmitHouse}>
                                            <p className='lableName'>Unit Name</p>

                                            <div className="input-icons mt-2">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="text"
                                                    value={formDataHouse.house_number}
                                                    onChange={handleChangeHouse}
                                                    placeholder={selectedUnit.house_number}
                                                    name="house_number"
                                                    required
                                                />
                                            </div>
                                            <p className='lableName'>Rent Amount</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="number"
                                                    name="rent"
                                                    value={formDataHouse.rent}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"Rent Amount"}
                                                    required
                                                />
                                            </div>
                                            <p className='lableName'>Unit Due Date</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="date"
                                                    name="due_date"
                                                    value={formDataHouse.due_date}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"Unit Due Date"}
                                                    required
                                                />
                                            </div>
                                            <p className='lableName'>No of Bedrooms</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="number"
                                                    name="bedroom"
                                                    value={formDataHouse.bedroom}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"No of Bedrooms"}
                                                    required
                                                />
                                            </div>
                                            <p className='lableName'>No of Bathrooms</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="number"
                                                    name="bathroom"
                                                    value={formDataHouse.bathroom}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"No of Bathrooms"}
                                                    required
                                                />
                                            </div>
                                            <p className='lableName'>Total Rooms</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="number"
                                                    name="total_rooms"
                                                    value={formDataHouse.total_rooms}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"Total Rooms"}
                                                    required
                                                />
                                            </div>
                                            <p className='lableName'>Deposit Amount</p>

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="number"
                                                    name="deposit"
                                                    value={formDataHouse.deposit}
                                                    onChange={handleChangeHouse}
                                                    placeholder={"Deposit Amount"}
                                                    required
                                                />
                                            </div>
                                            <p className='lableName'>Unit Type</p>
                                            {/* <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <Form.Select
                                                    size='sm'
                                                    className='formProperty'
                                                    value={formDataHouse.type_of_house}
                                                    onChange={handleChangeHouse}
                                                >
                                                    <option value={selectedUnit.type_of_house} selected>{selectedUnit.type_of_house}</option>
                                                    <option value="cash">Cash</option>
                                                    <option value="direct-to-phone">Direct To Phone</option>
                                                </Form.Select>
                                            </div> */}

                                            <div className="input-icons">
                                                <i className="fa fa-hashtag icon"></i>
                                                <input
                                                    className="input-field"
                                                    type="text"
                                                    name="type_of_house"
                                                    value={formDataHouse.type_of_house}
                                                    placeholder={"Unit Type"}
                                                    onChange={handleChangeHouse}

                                                />
                                            </div>
                                            <Button variant="primary" type="submit" className="nextBtn mt-3">
                                                Save
                                            </Button>
                                        </Form>

                                    </>

                                </Modal>

                                {/* check if the status of the house is vacant or if it is empty */}
                                {selectedUnit.occupancy_status === 'vacant' ?
                                    <>

                                        <Button className='btn btn-warning border-0 text-white' >
                                            <Link to={`/add-tenant/${selectedUnit.apartment}/${selectedUnit.id}`} className='text-white text-decoration-none'>Add Tenant</Link>
                                        </Button>

                                        <Button className='btn btn-warning border-0 text-white d-none' onClick={handleOpenModal}>Add Tenant</Button>
                                        {/* modal for adding a new tenant */}
                                        <Modal show={showModal} onHide={handleCloseModal}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Add Tenant</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <>
                                                    <Form onSubmit={handleSubmitTenanat}>
                                                        <div className="input-icons">
                                                            <i className="fa fa-hashtag icon"></i>
                                                            <input
                                                                className="input-field"
                                                                type="text"
                                                                name="full_name"
                                                                value={formDataTenant.full_name}
                                                                onChange={handleChangeTenant}
                                                                placeholder={"Tenant Name"}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="input-icons">
                                                            <i className="fa fa-hashtag icon"></i>
                                                            <input
                                                                className="input-field"
                                                                type="number"
                                                                name="phone_number"
                                                                value={formDataTenant.phone_number}
                                                                onChange={handleChangeTenant}
                                                                placeholder={"Tenant Phone Number"}
                                                                required
                                                            />
                                                        </div>
                                                        <p
                                                            style={{
                                                                fontSize: "12px",
                                                                paddingRight: "20px",
                                                                float: "right",
                                                            }}
                                                        >
                                                            {digitCount}/10
                                                        </p>
                                                        <Button variant="primary" type="submit" className="nextBtn mt-3" >
                                                            Save
                                                        </Button>
                                                    </Form> </>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="success" onClick={handleCloseModalReload} >
                                                    DONE
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>

                                    </>

                                    :
                                    <>
                                        <Button className='btn btn-warning border-0 text-white' onClick={handleOpenDeleteTenant}>Remove Tenant</Button>
                                        {/* remove tenant*/}
                                        <Modal show={showModalDeleteTenant} onHide={handleCloseModalDeleteTenant}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Remove Tenant.</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Do you want to remove this?</Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="success" onClick={handleCloseModalDeleteTenant}>
                                                    No
                                                </Button>
                                                <Button variant="danger" onClick={handleDeleteTenanat}>
                                                    Yes
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </>

                                }
                                <Button className='btn btn-danger border-0'>
                                    <Link to={`/tenant-report/${selectedUnit.id}`} className='text-white text-decoration-none'>Reports</Link>
                                </Button>


                                <Link to={`/selected-house-image/${idHouse}`}>
                                    <Button className='btn btn-info btn-sm text-white text-capitialize'>
                                        Gallery

                                    </Button>
                                </Link>
                                {/* {selectedUnit.is_public ?
                                    <Button onClick={handleOpenPublicModal} className='btn ms-3 btn-sm text-white border-0 text-capitialize' style={{ backgroundColor: '#e67e22' }}>Remove From Publicly Listed </Button>

                                    :
                                    <Button onClick={handleOpenPublicModal} className='btn ms-3 btn-sm text-white border-0 text-capitialize' style={{ backgroundColor: '#8e44ad' }}>Make Publicly Listed </Button>

                                } */}


                                <Modal show={publicModal} onHide={handleClosePublicModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Unit publicly listed </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {selectedUnit.is_public ?
                                            <p>Do you want to remove the unit from publicly listed?</p>
                                            :
                                            <p>Do you want to add the unit to publicly listed ?</p>

                                        }
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="success" onClick={publicStatus} >
                                            Yes
                                        </Button>
                                        <Button variant="danger" onClick={handleClosePublicModal} >
                                            No
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>

                        <div className='selectedHouseStatus'>
                            {selectedUnit.occupancy_status === 'vacant' ?
                                <>
                                    <Button className='btn btn-success text-capitalize'>{selectedUnit.occupancy_status}</Button>
                                    <Button className='btn bg-transparent btn-dark rounded-2' style={{ marginLeft: "1rem", color: "#2ecc71", fontWeight: "bold" }}>
                                    No Rent Due
                                    </Button>
                                </>
                                :
                                <>
                                    <Button className='btn btn-primary text-capitalize' >{selectedUnit.occupancy_status}</Button>
                                    {selectedUnit.rent === 0 ? (

                                        new Date(rentStatus.next_date) <= new Date(currentDate) ? (
                                            <Button className='btn bg-transparent btn-dark rounded-2' style={{ marginLeft: "1rem", color: "#e74c3c", fontWeight: "bold" }}>
                                                Service Charge Arrears Since: {new Date(rentStatus.next_date).toLocaleDateString()}
                                            </Button>
                                        ) : (
                                            <Button className='btn bg-transparent btn-dark rounded-2' style={{ marginLeft: "1rem", color: "#2ecc71", fontWeight: "bold" }}>
                                                Rent Due On: {new Date(rentStatus.next_date).toLocaleDateString()}
                                            </Button>
                                        )

                                    ) : (

                                        new Date(rentStatus.next_date) <= new Date(currentDate) ? (
                                            <Button className='btn bg-transparent btn-dark rounded-2' style={{ marginLeft: "1rem", color: "#e74c3c", fontWeight: "bold" }}>
                                                Rent Arrears Since: {new Date(rentStatus.next_date).toLocaleDateString()}
                                            </Button>
                                        ) : (
                                            <Button className='btn bg-transparent btn-dark rounded-2' style={{ marginLeft: "1rem", color: "#2ecc71", fontWeight: "bold" }}>
                                                Rent Due On: {new Date(rentStatus.next_date).toLocaleDateString()}
                                            </Button>
                                        )
                                    )}

                                    {/* rent_status */}
                                    {rentStatus.rent_status === "paid" ?
                                        <Button className='border-0 text-white ms-2' style={{ backgroundColor: "#2ecc71" }}>{rentStatus.rent_status}</Button>

                                        : rentStatus.rent_status === "partial" ?
                                            <Button className='border-0 text-white ms-2' style={{ backgroundColor: "#bdc3c7" }} >{rentStatus.rent_status}</Button>
                                            : rentStatus.rent_status === "None" ?
                                                <></>
                                                :
                                                <Button className='border-0 text-white ms-2' style={{ backgroundColor: "#e74c3c" }} >{rentStatus.rent_status}</Button>

                                    }
                                </>



                            }

                            {selectedUnit.type_rent_status === 'rent' ?
                                <Button className='btn btn-warning ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#8e44ad" }}>{toCamelCase(selectedUnit.type_rent_status)}</Button>
                                : selectedUnit.type_rent_status === 'rent_to_own' ?
                                    <Button className='btn btn-info ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#e67e22" }}>{toCamelCase(selectedUnit.type_rent_status)}</Button>
                                    :
                                    <Button className='btn btn-secondary border-0 ms-3 text-white text-capitalize' style={{ backgroundColor: "#9b59b6" }} >{toCamelCase(selectedUnit.type_rent_status)}</Button>
                            }

                            {selectedUnit.rent_type === 'residential' ?
                                <Button className='btn btn-success ms-3 border-0 text-capitalize' style={{ backgroundColor: "#34495e" }}>{selectedUnit.rent_type}</Button>
                                :
                                <>
                                    <Button className='btn btn-warning ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#2ecc71" }} >{selectedUnit.rent_type}</Button>

                                </>

                            }

                            {selectedUnit.is_public ?
                                <Button className='btn btn-success ms-3 border-0 text-capitalize' style={{ backgroundColor: "#bdc3c7" }}>Publicly Listed</Button>
                                :
                                <>

                                </>

                            }

                        </div>

                        <div className='selectedHouseStatusRight d-none'>
                            <Button className='btn btn-warning ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#a29bfe" }} onClick={handleOpenReviewModal}><i class="bi bi-chat"></i> Reviews
                                <span>{reviewCount}</span>
                            </Button>
                            <Button className='btn btn-warning ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#16a085" }} onClick={handleOpenShareUnitsModal}><i class="bi bi-share"></i> Shared {sharedUnit}</Button>
                            <Button className='btn btn-warning ms-3 border-0 text-white text-capitalize' style={{ backgroundColor: "#3498db" }} onClick={handleOpenLikedUnitsModal} ><i class="bi bi-hand-thumbs-up"></i> Liked {likedUnits}</Button>


                        </div>

                        {/* <h3 className='selectedHouseTenant'>{houseTenant.full_name?.toLowerCase()}</h3> */}
                        <h3 className="selectedHouseTenant">
                            {houseTenant.full_name
                                ? houseTenant.full_name.toLowerCase()
                                : "No tenant"}
                        </h3>

                        <Row className='selectedHouseDetails'>
                            <Col md={8} sm={12}>
                                {selectedUnit.rent === 0 ?
                                    (
                                        <p className='Rent float-left' style={{ fontSize: "1.1rem" }}>Ksh. {selectedUnit.service_charge?.toLocaleString()} per Month</p>

                                    ) : (

                                        <p className='Rent float-left' style={{ fontSize: "1.1rem" }}>Ksh. {selectedUnit.rent?.toLocaleString()} per Month</p>

                                    )}

                                <h5 className='float-left'>Description</h5>
                                {selectedUnit.about === null ?
                                    <p className='descriptionHouseSelected'>About status is empty</p>
                                    :
                                    <p className='descriptionHouseSelected'>{selectedUnit.about}</p>
                                }
                                {/* units attributes */}

                                <Card className='selectedHouseMetrics'>
                                    <Card.Header className='text-start' style={{ color: "#8e44ad" }}>
                                        WHAT THIS UNIT OFFERS
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            {unitAmenities.length > 0 ?
                                                unitAmenities.map((amenity) => (
                                                    <Col md={12} className="amenties text-start d-flex">
                                                        <Image src={amenity.icon_image} style={{ width: "5vw", height: "7vh", marginBottom: "1rem" }} />
                                                        <div className='mb-3'>
                                                            <p className='ms-3'> {amenity.icon}</p>
                                                            <span className='text-muted ms-3 d-flex '>{amenity.icon_description}</span>
                                                        </div>
                                                    </Col >
                                                ))
                                                :
                                                <p style={{ color: "#95a5a6" }}>No special amenity added for this house kindly add one</p>
                                            }
                                        </Row>
                                        <Row>
                                            <div className='amenitiesDiv'>

                                                {unitAmenities.length > 2 ? (
                                                    <>
                                                    </>
                                                )
                                                    :
                                                    (<>
                                                        <span><i className='bi bi-plus bg-primary text-white p-2' onClick={handleOpenModalAmenityAdd}></i></span>

                                                    </>)}



                                                {unitAmenities.length > 0 ? (
                                                    <>
                                                        <span><i className='bi bi-trash bg-danger text-white p-2' onClick={handleOpenModalAmenityDelete}></i></span>
                                                    </>
                                                )
                                                    :
                                                    (<></>)}
                                            </div>
                                        </Row>

                                        {/* add the amenity to the apartment */}
                                        <Modal show={showModalAmenity} onHide={handleCloseModalAmenityAdd}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Add special amenities for this unit</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form onSubmit={handleAmenities}>
                                                    <div className="input-icons">

                                                        <FormSelect className="formProperty" value={formDataAmenity.icon} onChange={handleChangeAmenity}>
                                                            <option value="">Select amenity To Add</option>
                                                            {availableAmenities.map((amenity) => (
                                                                <option key={amenity.id} value={amenity.id}>
                                                                    {amenity.name}
                                                                </option>
                                                            ))}
                                                        </FormSelect>


                                                    </div>


                                                    <Button className='nextBtn mt-3' type='submit'>Save</Button>
                                                </Form>
                                            </Modal.Body>

                                        </Modal>


                                        {/* delete the selected amenity */}

                                        <Modal show={showModalAmenityDelete} onHide={handleCloseModalAmenityDelete}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Delete Amenities For This Unit</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>


                                                <Form onSubmit={handleDeleteAmenity}>
                                                    <div className="input-icons">
                                                        <FormSelect
                                                            className="formProperty"
                                                            value={formDataAmenityDelete.amenity || ''} // Update the value property
                                                            onChange={handleChangeAmenityDelete('amenity')} // Pass the name as an argument
                                                        >
                                                            <option disabled value="">
                                                                Select Amenity To Delete
                                                            </option>
                                                            {unitAmenities.length > 0 ? (
                                                                unitAmenities.map((amenity) => (
                                                                    <option
                                                                        key={amenity.id}
                                                                        value={amenity.id}
                                                                    >
                                                                        {amenity.icon}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <p>No Amenities Found For This Apartment. Kindly Add One.</p>
                                                            )}
                                                        </FormSelect>

                                                    </div>

                                                    <Button className="nextBtn btn-danger border-0 mt-3" type="submit">
                                                        Delete Amenity
                                                    </Button>
                                                </Form>


                                            </Modal.Body>

                                        </Modal>

                                    </Card.Body>
                                </Card>
                                <InvoiceReport style={{ marginLeft: "3rem", width: "90%" }} />
                                <ReceiptReport style={{ marginLeft: "3rem", width: "90%" }} />
                                <HistoryReport style={{ marginLeft: "3rem", width: "90%" }} />
                                <IssueReport style={{ marginLeft: "3rem", width: "90%" }} />
                                <PaymentImage style={{ marginLeft: "3rem", width: "90%" }} />


                            </Col>
                            <Col md={4} sm={12}>
                                <Card className='selectedHouseTenantDetails'>
                                    {selectedUnit.occupancy_status === 'vacant' ?
                                        <Card.Body className='noTenantCard'>
                                            <p>No Tenant</p>
                                        </Card.Body>
                                        :
                                        <Card.Body>
                                            <Image style={{ width: "25%" }} src={houseTenant.image}></Image>
                                            <div className='badgeRating'>
                                                {scoreKodi}
                                            </div>
                                            <p className='mt-2' style={{ fontSize: "1.5rem", textTransform: "capitalize", marginBottom: "0", color: "#4cd137", fontWeight: "bold" }}>{houseTenant.full_name?.toLowerCase()}</p>
                                            <div className='d-flex tenantRatings' style={{ fontSize: "1.3rem", marginTop: "0" }}>
                                                {stars}

                                            </div>
                                            <span>{houseTenant.phone_number} | {houseTenant.email}</span>
                                            <p className='text-muted'>Joined : {joined}</p>
                                            {rentStatus.rent_status === 'unpaid' || rentStatus.rent_status === 'partial' ?
                                                <Button className='btn btn-danger' onClick={handleReminderModalOpen}>Send Reminder</Button>
                                                : <></>
                                            }

                                            <div className='tenantCardButtons mb-3 mt-2'>
                                                <Button onClick={handleClickPhone}>Call</Button>
                                                <Button className='btn btn-warning text-white' onClick={handleOpenModalRate}>Rate</Button>
                                                <Button style={{ background: "#34495e", border: "0" }} onClick={handleDownload}>History</Button>
                                            </div>

                                            {/* modal for send reminders */}
                                            <Modal show={remindersModal} onHide={handleReminderModalClose}>
                                                <Modal.Header className='bg-primary text-white' closeButton>
                                                    {addTemplate ?
                                                        <Modal.Title>Add Message Templates</Modal.Title>
                                                        :
                                                        <Modal.Title>Send Reminder Message</Modal.Title>

                                                    }
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className='d-flex justify-content-between'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant={addTemplate ? 'secondary' : 'primary'} size='sm'>

                                                                {selectedOptionReminder ? (
                                                                    selectedOptionReminder === 'utility' ?
                                                                        <>Utility</>
                                                                        :
                                                                        selectedOptionReminder === 'rent' ?
                                                                            <>Rent</>
                                                                            :
                                                                            <>Choose</>

                                                                )
                                                                    :
                                                                    <>Choose Template</>
                                                                }
                                                            </Dropdown.Toggle>


                                                            <Dropdown.Menu>
                                                                <Dropdown.Item onClick={() => handleOptionSelect('utility')}>Utility</Dropdown.Item>
                                                                <Dropdown.Item onClick={() => handleOptionSelect('rent')}>Rent</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>

                                                        <Button className={`btn btn-sm ${addTemplate ? 'btn-primary' : 'btn-secondary'}`} onClick={handleAddTemplate}>Add Template</Button>
                                                    </div>

                                                    {addTemplate ? (
                                                        <>
                                                            <div className="input-icons mt-2">
                                                                <i class="bi bi-send-plus icon"></i>
                                                                <textarea className="input-field" rows={4} placeholder='Enter Message'></textarea>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        selectedOptionReminder ? (
                                                            <div className='mt-2'>
                                                                {selectedOptionReminder === 'utility' && (
                                                                    <>
                                                                        <div className="input-icons  mb-2">
                                                                            <i class="bi bi-chat-left-dots icon"></i>
                                                                            <textarea className="input-field" rows={1}>Utility Reminder</textarea>
                                                                        </div>

                                                                        <div className="input-icons">
                                                                            <i class="bi bi-send-plus icon"></i>
                                                                            <textarea className="input-field">Utility bill payment reminder</textarea>
                                                                        </div>

                                                                    </>
                                                                )}

                                                                {selectedOptionReminder === 'rent' && (
                                                                    <>
                                                                        <div className="input-icons  mb-2">
                                                                            <i class="bi bi-chat-left-dots icon"></i>
                                                                            <textarea className="input-field" rows={1}>Rent Reminder</textarea>
                                                                        </div>

                                                                        <div className="input-icons">
                                                                            <i class="bi bi-send-plus icon"></i>
                                                                            <textarea className="input-field">Please pay the rent</textarea>
                                                                        </div>

                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="input-icons  mt-2 mb-2">
                                                                    <i class="bi bi-chat-left-dots icon"></i>
                                                                    <textarea className="input-field" rows={1} placeholder='Custom Message'></textarea>
                                                                </div>

                                                                <div className="input-icons">
                                                                    <i class="bi bi-send-plus icon"></i>
                                                                    <textarea className="input-field" rows={8} placeholder='Enter Message'></textarea>
                                                                </div>
                                                            </>
                                                        )
                                                    )}

                                                </Modal.Body>
                                                <Modal.Footer className="d-flex justify-content-between">
                                                    <Button variant='danger' onClick={handleReminderModalClose}>
                                                        Close
                                                    </Button>
                                                    <Button variant='success' onClick={handleReminderModalReload}>
                                                        Send
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                            {/* modal for rating tenant */}
                                            <Modal show={showModalRate} onHide={handleCloseModalRate}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title style={{ fontSize: '1.5rem', color: '#1F57F8' }}>Rate Tenant :{houseTenant.full_name}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <>
                                                        <Form onSubmit={handleSubmitTenanatRate}>
                                                            <div style={{ fontSize: "2rem", textAlign: "center", color: "lightgray" }}>
                                                                <i
                                                                    className={`bi bi-star-fill ${starsClicked >= 1 ? 'text-warning' : ''}`}
                                                                    onClick={() => handleStarClick(1)}
                                                                ></i>
                                                                <i
                                                                    className={`bi bi-star-fill ${starsClicked >= 2 ? 'text-warning' : ''}`}
                                                                    onClick={() => handleStarClick(2)}
                                                                ></i>
                                                                <i
                                                                    className={`bi bi-star-fill ${starsClicked >= 3 ? 'text-warning' : ''}`}
                                                                    onClick={() => handleStarClick(3)}
                                                                ></i>
                                                                <i
                                                                    className={`bi bi-star-fill ${starsClicked >= 4 ? 'text-warning' : ''}`}
                                                                    onClick={() => handleStarClick(4)}
                                                                ></i>
                                                                <i
                                                                    className={`bi bi-star-fill ${starsClicked >= 5 ? 'text-warning' : ''}`}
                                                                    onClick={() => handleStarClick(5)}
                                                                ></i>
                                                            </div>
                                                            {/* <div className="input-icons">
                                                                <i className="fa fa-hashtag icon"></i>
                                                                <input
                                                                    className="input-field"
                                                                    type="number"
                                                                    name="0rating"
                                                                    value={formDataTenantRate.rating}
                                                                    onChange={handleChangeTenantRate}
                                                                    placeholder={"Tenant Ratings"}
                                                                    required
                                                                />
                                                            </div> */}
                                                            <div className="input-icons">
                                                                <i className="fa fa-comment icon"></i>
                                                                <textarea
                                                                    className="input-field"
                                                                    type="text"
                                                                    name="comment"
                                                                    value={formDataTenantRate.comment}
                                                                    onChange={handleChangeTenantRate}
                                                                    placeholder={"Provide a comment for the rating (Your comment will remain private)"}
                                                                    required
                                                                />
                                                            </div>

                                                            <Button variant="primary" type="submit" className="nextBtn mt-3" >
                                                                Rate {houseTenant.full_name}
                                                            </Button>
                                                        </Form> </>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    {/* <Button variant="success" onClick={handleCloseModalReload} >
                                                        DONE
                                                    </Button> */}
                                                </Modal.Footer>
                                            </Modal>
                                        </Card.Body>
                                    }

                                </Card>

                                <Card className='selectedHouseMetrics' style={{ marginLeft: "0rem", width: "100%" }}>
                                    <Card.Header className='text-start' style={{ color: "#f39c12" }}>
                                        ATTRIBUTES
                                    </Card.Header>
                                    <Card.Body>
                                        <Row >
                                            <Col md={6}>
                                                <span><Image src='https://kodinyumba.app/media/house_metric_icons/bedroom.png' style={{ width: "15%" }} /> Bedrooms
                                                    {selectedUnit.bedroom === null ?
                                                        <span style={{ color: "#f39c12", fontWeight: "bold" }}> [0]</span>
                                                        :
                                                        <span style={{ color: "#f39c12", fontWeight: "bold" }}> [{selectedUnit.bedroom}]</span>
                                                    }

                                                </span>
                                            </Col>
                                            <Col md={6}>
                                                <span><Image src='https://kodinyumba.app/media/house_metric_icons/bathroom.png' style={{ width: "15%" }} /> Bathrooms
                                                    {selectedUnit.bathroom === null ?
                                                        <span style={{ color: "#f39c12", fontWeight: "bold" }}> [0]</span>
                                                        :
                                                        <span style={{ color: "#f39c12", fontWeight: "bold" }}> [{selectedUnit.bathroom}]</span>
                                                    }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} style={{ marginTop: "1rem" }}>
                                                {selectedUnit.occupancy_status === "vacant" ?
                                                    <span><Image src='https://kodinyumba.app/media/house_metric_icons/vacant.png' style={{ width: "15%", color: "#f39c12", fontWeight: "bold" }} /> Vacant House</span>
                                                    :
                                                    <span><Image src='https://kodinyumba.app/media/house_metric_icons/due-date.png' style={{ width: "15%" }} /> Due Date <span style={{ color: "#f39c12", fontWeight: "bold" }}>[{new Date(rentStatus.next_date).getDate()}]</span></span>
                                                }

                                            </Col>
                                            <Col md={6} style={{ marginTop: "1rem" }}>
                                                {selectedUnit.type_of_house.toLowerCase() === "apartment_units" ?
                                                    <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/apartment.png' style={{ width: "15%" }} /> {selectedUnit.type_of_house}</span>

                                                    : selectedUnit.type_of_house.toLowerCase() === "studio" ?
                                                        <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/studio.png' style={{ width: "15%" }} /> {selectedUnit.type_of_house}</span>

                                                        : selectedUnit.type_of_house.toLowerCase() === "bungalow" ?
                                                            <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/bungalow.png' style={{ width: "15%" }} /> {selectedUnit.type_of_house}</span>

                                                            : selectedUnit.type_of_house.toLowerCase() === "bedsitter" ?
                                                                <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/bedsitter.png' style={{ width: "15%" }} /> {selectedUnit.type_of_house}</span>

                                                                : selectedUnit.type_of_house.toLowerCase() === "servant_quarters" ?
                                                                    <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/servant_quarter.png' style={{ width: "15%" }} /> {selectedUnit.type_of_house}</span>

                                                                    : selectedUnit.type_of_house.toLowerCase() === "mansionette" ?
                                                                        <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/mansionette.png' style={{ width: "15%" }} /> {selectedUnit.type_of_house}</span>

                                                                        : selectedUnit.type_of_house.toLowerCase() === "office" ?
                                                                            <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/office.png' style={{ width: "15%" }} /> {selectedUnit.type_of_house}</span>

                                                                            : selectedUnit.type_of_house.toLowerCase() === "stall" ?
                                                                                <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/stall.png' style={{ width: "15%" }} /> {selectedUnit.type_of_house}</span>

                                                                                :
                                                                                <span className='text-capitalize'><Image src='https://kodinyumba.app/media/house_metric_icons/stall.png' style={{ width: "15%" }} /> {selectedUnit.type_of_house}</span>

                                                }

                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* reviews for this unit */}
                                <Card className='selectedHouseMetrics d-none' style={{ marginLeft: "0rem", width: "100%" }}>
                                    <Card.Header className='text-start' style={{ color: "#00b894" }}>
                                        UNIT REVIEWS
                                    </Card.Header>
                                    <Card.Body style={{ height: "40vh", overflowY: "scroll" }}>
                                        {reviews.map(review => (
                                            <div key={review.id} className='d-flex justify-content-between mb-2'>
                                                <div className='d-flex'>
                                                    <div style={{ marginRight: "5px" }}>
                                                        <img
                                                            // src={`https://randomuser.me/api/portraits/men/${review.tenant.image}.jpg`}
                                                            src={review.user.image}
                                                            alt={`${review.user.full_name}`}
                                                            style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius: "50%" }}
                                                        />
                                                    </div>
                                                    <div style={{ textAlign: "left" }}>
                                                        <h5 className='fw-bold' style={{ textTransform: "capitalize", fontSize: "16px", lineHeight: '0.5' }}>{review.user.full_name}</h5>
                                                        <p style={{ fontSize: "13px" }}>{review.review}</p>
                                                    </div>
                                                </div>


                                                <div className='d-flex'>
                                                    <Button
                                                        className='btn btn-sm border-0 text-white'
                                                        style={{ height: "25px", backgroundColor: review.is_public ? "#55efc4" : "#ced4da" }}
                                                        onClick={() => handleButtonClick(review)}
                                                    >
                                                        Public
                                                    </Button>
                                                    <Button
                                                        className='btn btn-sm border-0 text-white ms-2'
                                                        style={{ height: "25px", backgroundColor: review.is_public ? "#ced4da" : "#6c5ce7" }}
                                                        onClick={() => handleButtonClick(review)}
                                                    >
                                                        Private
                                                    </Button>
                                                </div>

                                            </div>
                                        ))}

                                    </Card.Body>
                                </Card>

                                {/* modal for updating the review */}
                                <Modal show={showReviewModal} onHide={handleReviewCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirm Review Status Change</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        By toggling the status, you are making the review <b className='text-info'>{selectedReview?.is_public ? 'private' : 'public'}</b>.
                                        This means the review will be <b className='text-warning'>{selectedReview?.is_public ? 'visible only to you' : 'visible to the public'}</b>.
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleReviewCloseModal}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={handleConfirm}>
                                            Confirm
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                {/* unit rooms */}
                                <Card className='selectedHouseTenantDetails mt-4'>
                                    <Card.Body className='noTenantCard' style={{ height: 'auto' }}>
                                        <h2>The rooms within this unit</h2>
                                        <Row>
                                            {unitRooms.length !== 0 ? (
                                                unitRooms.map((roomData) => (
                                                    <div className="amenties text-start" key={roomData.room.room_id}>
                                                        <span className='' style={{ fontSize: "1.3rem" }}>{roomData.room.room}</span>

                                                        {/* If there are more unit rooms in the group, display them */}
                                                        {roomData.data.length > 0 ? (
                                                            <div className='d-flex w-100 mt-4 mb-4' style={{ overflow: 'auto' }}>
                                                                {roomData.data.map((unitRoom, index) => (
                                                                    <div key={index}>
                                                                        {/* Display additional unit rooms in the group */}
                                                                        <Image src={unitRoom.room_image} className='unitRoomImage' />
                                                                        <span className='unitRoomDelete'
                                                                            onClick={() => openModal(unitRoom.room_image_id)}
                                                                            style={{
                                                                                position: 'relative',
                                                                                padding: '0.8rem',
                                                                                backgroundColor: 'red',
                                                                                borderRadius: '50%',
                                                                                marginRight: '-4rem',
                                                                                height: "fit-content",
                                                                            }}
                                                                        ><i className='bi bi-trash'></i></span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )
                                                            :
                                                            (
                                                                <>
                                                                    <br></br>
                                                                    <br></br>

                                                                    <span>Sorry no image for this room kindly add one</span>
                                                                    <br></br>
                                                                    <br></br>
                                                                </>
                                                            )}
                                                    </div>
                                                ))
                                            ) : (
                                                <span style={{ color: "#95a5a6" }}>This unit has no added rooms. Kindly add one.</span>
                                            )}

                                            {/* modal to delete image */}
                                            <Modal show={isModalOpen} onHide={closeModal}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Delete Image</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <p>Do you want to delete this image?</p>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button className='btn btn-danger' onClick={handleDeleteApartment}>
                                                        Delete
                                                    </button>
                                                    <button className='btn btn-secondary' onClick={closeModal}>
                                                        Close
                                                    </button>
                                                </Modal.Footer>
                                            </Modal>
                                        </Row>

                                        <Row>
                                            <div className='amenitiesDiv'>
                                                <Link to={`/add-room/${id}`}>
                                                    <span><i className='bi bi-plus bg-primary text-white p-2'></i></span>

                                                </Link>

                                                {unitRooms.length !== 0 ? (
                                                    <>
                                                        <span><i className='bi bi-trash bg-danger text-white p-2' onClick={handleOpenModalRoomDelete}></i></span>
                                                    </>
                                                )
                                                    :
                                                    (<></>)}
                                            </div>
                                        </Row>

                                        {/* delete the selected room */}

                                        <Modal show={showModalRoomsDelete} onHide={handleCloseModalRoomDelete}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Delete Rooms For This Unit</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>


                                                <Form onSubmit={handleDeleteRoom}>
                                                    <div className="input-icons">
                                                        <FormSelect
                                                            className="formProperty"
                                                            value={formDataRoomDelete.room || ''}
                                                            onChange={handleChangeRoomDelete('room')}
                                                        >
                                                            <option disabled value="">
                                                                Select Room To Delete
                                                            </option>
                                                            {unitRooms.length !== 0 ? (
                                                                unitRooms.map((room) => (
                                                                    <option
                                                                        key={room.room.room_id}
                                                                        value={room.room.room_id}
                                                                    >
                                                                        {room.room.room}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <p>No Rooms Found For This Unit. Kindly Add One.</p>
                                                            )}
                                                        </FormSelect>

                                                    </div>

                                                    <Button className="nextBtn btn-danger border-0 mt-3" type="submit">
                                                        Delete Room
                                                    </Button>
                                                </Form>


                                            </Modal.Body>

                                        </Modal>

                                    </Card.Body>

                                </Card>


                            </Col>
                        </Row>
                    </div >)
                    :
                    (<>
                        <div className='mt-5 pt-5'>
                            <p className='mt-5 text-capitialize fw-bold fs-1' style={{ color: "#95a5a6" }}>House Not Found</p>
                        </div>
                    </>)

            }

            {/* the reviews not worked on */}
            <Modal show={reviewsModal} onHide={handleCloseReviewModal} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Reviews not attended to</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tasks ' >
                        <div className='d-flex justify-content-between'>

                            <div>
                                {/* <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSVProperty}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button> */}
                                {/* <Button className='btn btn-danger btn-sm text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
                            </div>
                            <Form className="searchBar">
                                <FormControl type="text" placeholder="Search" onChange={handleSearchProperty} />
                            </Form>
                        </div>
                        <Table className='table mt-2'>
                            <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                <th>Tenant</th>
                                <th>Review</th>
                                <th>Time Posted</th>
                                {/* <th>Action</th> */}
                            </thead>
                            <tbody>
                                {unAttendedReviews.length === 0 ?
                                    <p>No new review </p>
                                    :
                                    unAttendedReviews.map((tenant) => (
                                        <tr>

                                            <td>{tenant.user.full_name}</td>
                                            <td>{tenant.review}</td>
                                            <td>{new Date(tenant.created_at).toLocaleDateString('en-US', {
                                                month: 'long',
                                                day: 'numeric',
                                                year: '2-digit'
                                            })}</td>
                                            {/* <td>
                                                <div className='d-flex'>
                                                    <Button
                                                        className='btn btn-sm border-0 text-white'
                                                        style={{ height: "25px", backgroundColor: tenant.is_public ? "#55efc4" : "#ced4da" }}
                                                        onClick={() => handleButtonClick(tenant)}
                                                    >
                                                        Public
                                                    </Button>
                                                    <Button
                                                        className='btn btn-sm border-0 text-white ms-2'
                                                        style={{ height: "25px", backgroundColor: tenant.is_public ? "#ced4da" : "#6c5ce7" }}
                                                        onClick={() => handleButtonClick(tenant)}
                                                    >
                                                        Private
                                                    </Button>
                                                </div>
                                            </td> */}

                                        </tr>

                                    ))
                                }

                            </tbody>
                        </Table>
                        <div className="pagination d-none">
                            <button onClick={handlePrevPageProperty} disabled={currentPageProperty === 0}>
                                &lt;
                            </button>
                            <span>{`${startIndexProperty + 1}-${endIndexProperty} of ${userShareCounts.length}`}</span>
                            <button onClick={handleNextPageProperty} disabled={currentPageProperty === totalPagesProperty - 1}>
                                &gt;
                            </button>
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseReviewModal} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* the shared list */}
            <Modal show={sharesUnitModal} onHide={handleCloseShareUnitsModal} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Users who shared</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tasks ' >
                        <div className='d-flex justify-content-between'>

                            <div>
                                <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSVProperty}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                                {/* <Button className='btn btn-danger btn-sm text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
                            </div>
                            <Form className="searchBar">
                                <FormControl type="text" placeholder="Search" onChange={handleSearchProperty} />
                            </Form>
                        </div>
                        <Table className='table mt-2'>
                            <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                <th>Unit</th>
                                <th>Shares</th>
                                <th>Time Shared</th>
                            </thead>
                            <tbody>
                                {displayedItemsProperty.length === 0 ?
                                    <p>No user found </p>
                                    :
                                    displayedItemsProperty.map((user) => (
                                        <tr>

                                            <td>{user.name}</td>
                                            <td>{user.count}</td>
                                            <td>{new Date(user.latestShareTime).toLocaleDateString('en-US', {
                                                month: 'long',
                                                day: 'numeric',
                                                year: '2-digit'
                                            })}</td>

                                        </tr>

                                    ))
                                }

                            </tbody>
                        </Table>
                        <div className="pagination">
                            <button onClick={handlePrevPageProperty} disabled={currentPageProperty === 0}>
                                &lt;
                            </button>
                            <span>{`${startIndexProperty + 1}-${endIndexProperty} of ${userShareCounts.length}`}</span>
                            <button onClick={handleNextPageProperty} disabled={currentPageProperty === totalPagesProperty - 1}>
                                &gt;
                            </button>
                        </div>
                    </div>



                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseShareUnitsModal} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* the liked list */}
            <Modal show={likedUnitModal} onHide={handleCloseLikedUnitsModal} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Users who liked this unit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tasks ' >
                        <div className='d-flex justify-content-between'>

                            <div>
                                <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSVLikes}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                            </div>
                            <Form className="searchBar">
                                <FormControl type="text" placeholder="Search" onChange={handleSearchLikes} />
                            </Form>
                        </div>
                        <Table className='table mt-2'>
                            <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                                <th>User</th>
                                <th>User Phone Number</th>
                                <th>Liked Time</th>
                            </thead>
                            <tbody>
                                {displayedItemsLikes.map((user) => (
                                    <tr>

                                        <td>{user.name}</td>
                                        <td>{user.phone}</td>
                                        <td>{new Date(user.latestLikedDate).toLocaleDateString('en-US', {
                                            month: 'long',
                                            day: 'numeric',
                                            year: '2-digit'
                                        })}</td>

                                    </tr>

                                ))
                                }

                            </tbody>
                        </Table>
                        <div className="pagination">
                            <button onClick={handlePrevPageLikes} disabled={currentPageLikes === 0}>
                                &lt;
                            </button>
                            <span>{`${startIndexLikes + 1}-${endIndexLikes} of ${userLikedUnit.length}`}</span>
                            <button onClick={handleNextPageLikes} disabled={currentPageLikes === totalPagesLikes - 1}>
                                &gt;
                            </button>
                        </div>
                    </div>

                    {/* <Table className='table table-bordered'>
                                        <thead>
                                            <th>User</th>
                                            <th>User Phone Number</th>
                                        </thead>
                                        {
                                            userLikedUnit.map((user) => (
                                                <tbody>
                                                    <td>
                                                        {user.name}
                                                    </td>
                                                    <td>{user.phone}

                                                    </td>
                                                    <td>{new Date(user.latestLikedDate).toLocaleDateString('en-US', {
                                                                month: 'long',
                                                                day: 'numeric',
                                                                year: '2-digit'
                                                            })}

                                                    </td>

                                                </tbody>
                                            ))
                                        }

                                    </Table> */}


                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleCloseLikedUnitsModal} >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default SelectedHouse