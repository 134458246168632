import React, { useState, useEffect } from 'react'
import { Table, Form, Image, Modal, Button, Row, Col, FormSelect } from 'react-bootstrap';
import Header from './Header'
import SideBar from './SideBar'
// import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, pdf } from "@react-pdf/renderer";

// auth imports
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from "react-router";

import { serverUrl } from "../../serverUrl";
import jwt_decode from "jwt-decode";
import loaderImage from '../../assets/img/logo.png'
import { saveAs } from 'file-saver';
import Wallet2 from '../../assets/img/wallet_2.png'
import { toast } from 'react-toastify';

function MainWallet() {
    // sidebar and header functions
    const navigate = useNavigate();

    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // loading status
    const [isLoading, setIsLoading] = useState(false)

    // apartments of the landlord
    const [landlordApartment, setLandlordApartment] = useState([])
    // counted house
    const [houseCount, setHouseCount] = useState({});
    const [occupiedCount, setOccupiedCount] = useState({});
    const [incomeCount, setIncomeCount] = useState({});
    const [curentIncomeCount, setCurentIncomeCount] = useState({});
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});
    const [apartmentWallet, setApartmentWallte] = useState('');

    const apartmentImage = {};
    const wallet_exists = {}

    // get the current month so that i can filter out the total income
    const months = Array.from({ length: 12 }, (_, i) => {
        const month = new Date(new Date().getFullYear(), i, 1);
        return month.toLocaleString('default', { month: 'long' });
    });

    // Get the current month automatically
    const currentMonthIndex = new Date().getMonth();
    const currentMonth = months[currentMonthIndex];

    const [showModal, setShowModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const [filterMonth, setFilterMonth] = useState("")
    const [getMonthSelect, setGetMonth] = useState("")
    const [monthFilterActive, setMonthFilterActive] = useState("")
    const [selectedMonth, setSelectedMonth] = useState("");

    const handleMonthButtonClick = (month) => {
        setMonthFilterActive(month);
        localStorage.setItem("monthOccupancyReport", month);
        setShowModal(false);
        window.location.reload();

    };
    const currentYear = new Date().getFullYear();



    // get the months and years filtered out
    const [yearFilterActive, setYearFilterActive] = useState("")

    const [rentPaidApartment, setRentPaidApartment] = useState('')
    const [rentPercentageApartment, setRentPercentageApartment] = useState('')

    // set to get the month filtered if not then display the current month
    useEffect(() => {
        const selectedMonthModal = localStorage.getItem("monthOccupancyReport");
        if (filterMonth) {
            setMonthFilterActive(filterMonth);
        } else if (selectedMonthModal) {
            setMonthFilterActive(selectedMonthModal);
        } else {
            setMonthFilterActive(currentMonth);
        }
    }, [filterMonth]);
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }

    // get the data to be displayed on the tables
    useEffect(() => {

        // decoding the token so that i can get the user id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;
        setIsLoading(true);

        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                // using the landlord id get the apartments linked to the landlord
                const landlordApartmentPromises = landlordIds.map((landlordId) => {
                    return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => res.data.results)
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });
                });

                Promise.all(landlordApartmentPromises)
                    .then((results) => {
                        // i am merging the sub-arrays into a new array
                        const apartments = results.flat();
                        setLandlordApartment(apartments);
                        setIsLoading(false);

                        // get the occupancy percentage

                        const apartmentPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/get_rent_summary/?apartment_id=${apartment.id}&month=${currentMonth}&year=${currentYear}`, config)
                                .then((res) => ({ apartmentId: apartment.id, dataSummary: res.data }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, dataSummary: res.data };
                                });

                        })

                        // set count of the number of houses that are in that apartment
                        Promise.all(apartmentPromises)
                            .then((results) => {

                                // object that gets the number of apartments
                                const apartmentRentPaid = {};
                                const apartmentOccupancyPercentage = {};
                                const apartmentRentPaidExpected = {};

                                results.forEach(({ apartmentId, dataSummary }) => {
                                    apartmentRentPaid[apartmentId] = dataSummary.total_income;
                                    apartmentOccupancyPercentage[apartmentId] = dataSummary.occupancy_percentage;
                                    apartmentRentPaidExpected[apartmentId] = dataSummary.total_rent;


                                });
                                setRentPaidApartment(apartmentRentPaid);
                                setRentPercentageApartment(apartmentOccupancyPercentage)
                                setIncomeCount(apartmentRentPaidExpected)
                                setIsLoading(false)

                            })
                            .catch((error) => {
                                console.log(error);
                                setIsLoading(false)

                            });

                        // getting the houses in the apartment
                        const houseCountPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                                .then((res) => ({ apartmentId: apartment.id, count: res.data.count }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, count: 0 };
                                });

                        });
                        // get the images of the apartments based on the apartment id 
                        apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/apartment-images/?apartment_id=${apartment.id}`, config)
                                .then((res) => {
                                    res.data.results.forEach((res) => {
                                        apartmentImage[res.apartment] = res.image;
                                    })

                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });

                        // get the property if its in the wallet
                        apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/wallet/?property=${apartment.id}`, config)
                                .then((res) => {
                                    wallet_exists[apartment.id] = res.data.count;
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });

                        setSelectedApartmentImage(apartmentImage)
                        setApartmentWallte(wallet_exists)
                        setIsLoading(false);

                        // set count of the number of houses that are in that apartment
                        Promise.all(houseCountPromises)
                            .then((results) => {

                                // object that gets the number of apartments
                                const houseCountObj = {};
                                results.forEach(({ apartmentId, count }) => {
                                    houseCountObj[apartmentId] = count;
                                });
                                setHouseCount(houseCountObj);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        const houseOccupancyPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/display-occupied-houses-in-expenses/?apartment_id=${apartment.id}`, config)
                                .then((res) => ({ apartmentId: apartment.id, count: res.data.count }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, count: 0 };
                                });
                        });
                        // set count of the number of houses that are occupied in that apartment
                        Promise.all(houseOccupancyPromises)
                            .then((results) => {

                                // object that gets the number of apartments
                                const houseOccupiedObj = {};
                                results.forEach(({ apartmentId, count }) => {
                                    houseOccupiedObj[apartmentId] = count;
                                });
                                setOccupiedCount(houseOccupiedObj);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        // get the income monthly
                        const houseIncomePromises = apartments.map((apartment) => {
                            return serverUrl.get(`/tenant/expected-rental-income-by-month/?apartment_id=${apartment.id}&month=${getMonthSelect}`, config)
                                .then((res) => ({ apartmentId: apartment.id, count: res.data.total_rental_income }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, count: 0 };
                                });
                        });
                        // set count of the number of houses that are occupied in that apartment
                        Promise.all(houseIncomePromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const houseIncomeObj = {};
                                results.forEach(({ apartmentId, count }) => {
                                    houseIncomeObj[apartmentId] = count;
                                });
                                // setIncomeCount(houseIncomeObj);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        const houseCurrentIncomePromises = apartments.map((apartment) => {
                            return serverUrl.get(`/tenant/generate_month_income_report/?apartment_id=${apartment.id}&month=${getMonthSelect}`, config)
                                .then((res) => ({ apartmentId: apartment.id, currentIncome: res.data.total }))
                                .catch((error) => {
                                    console.log(error);
                                    return []; // Return an empty array as a fallback
                                });
                        });
                        const curentIncomeObj = {};

                        // set count of the number of houses that have defaulters in that apartment
                        Promise.all(houseCurrentIncomePromises)
                            .then((results) => {
                                results.forEach(({ apartmentId, currentIncome }) => {
                                    curentIncomeObj[apartmentId] = currentIncome;
                                });

                                setCurentIncomeCount(curentIncomeObj);
                            })
                            .catch((error) => {
                                console.log(error);
                            });


                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    // getting the id and finding the apparment details
    const { id } = useParams();


    //pdf stylings 
    // const { url } = pdf;

    // const styles = StyleSheet.create({
    //     page: {
    //         flexDirection: "column",
    //         backgroundColor: "#E4E4E4",
    //         padding: 10,
    //     },
    //     section: {
    //         flexDirection: "row",
    //         alignItems: "center",
    //         margin: 10,
    //         padding: 10,
    //         borderBottom: "1 solid #000",
    //     },
    //     header: {
    //         backgroundColor: "#BFBFBF",
    //     },
    //     cell: {
    //         flexGrow: 1,
    //         textAlign: "center",
    //     },
    // });


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            setIsLoading(true);

            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [landlordApartment]);

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        setIsLoading(true);

        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 7) + 1;
            const invalidNumbers = [0, -1];
            while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 7) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/reports/reports${randomNumber}.jpg`;

            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const totalPages = Math.ceil(landlordApartment.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? landlordApartment.length : startIndex + itemsPerPage;
    const displayedItems = landlordApartment.slice(startIndex, endIndex);

    // const displayedItems = filteredData

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    // when user clicks on the property get the property id and check if it has already been logged in 


    const handleWalletOtp = (propertyId) => {
        localStorage.removeItem("authTokens");
        localStorage.setItem("wallet_property", propertyId)
        navigate("/wallet-login");

        // window.href(`/property-wallet/${propertyId}`)
    }

    const [walletCreate, setWalletCreate] = useState(false)
    const [walletApartment, setWalletApartment] = useState('')
    const [apartmentUtilities, setApartmentUtilities] = useState([])

    const handleWalletCreate = (propertyId) => {

        
        setWalletCreate(true)
        setWalletApartment(propertyId)
        serverUrl.get(`/landlord/expense/?apartment_id=${propertyId}`, config)
            .then((res) => {
                setApartmentUtilities(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const handleWalletClose = () => {
        setWalletCreate(false)

    }
    const [formData, setFormData] = useState({
        name: "",
        minimum_authorzers: "",
        account_type: "",
        collection_type: "",
        collection_type_id: "",
    });

    const [accountType, setAccountType] = useState('')
    const [collectionType, setCollectionType] = useState('')

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "account_type") {
            setAccountType(value);
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
        else if (name === "collection_type") {
            setCollectionType(value);
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
        else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }


    };
    const [landlordCount, setLandlordCount] = useState('')
    useEffect(() => {
        serverUrl.get(`/landlord/landlord-apartment/?apartment_id=${walletApartment}`, config)
            .then((res) => {
                setLandlordCount(res.data.count)
            })
            .catch((error) => {
                console.log(error)
            })

    }, [])

    function handleAddAccount(e) {
        e.preventDefault();
        // create the new wallet account
        let count_authorizer
        if (landlordCount > 1) {
            count_authorizer = 1
        }
        else{
            count_authorizer=formData.minimum_authorzers
        }
        const data = {
            name: formData.name,
            property: walletApartment,
            minimum_authorzers: count_authorizer,
            account_type: accountType,
            collection_type_id: formData.collection_type_id,
            collection_type: collectionType,
        }
        localStorage.setItem('wallet_new_details', JSON.stringify(data));

        localStorage.removeItem("authTokens");
        navigate("/wallet-login");
       
    }

    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='reports_dashboard'>
                <div className='topSelectedProperty' style={{ backgroundImage: `url(${Wallet2})` }}>

                    <p className='properties'>MY WALLET</p>

                </div>


                {/* table display the reports */}
                <div className='tasks' >
                    <h5 className='float-start'>Properties with wallets</h5>
                    <Table className='table'>
                        <thead className='underline'>
                            <th>Image</th>
                            <th>Property</th>
                            <th>Units</th>
                            <th>Occupancy</th>
                            <th>Expected Income</th>
                            <th>Current Income</th>
                            <th>Wallet</th>
                        </thead>
                        <tbody>
                            {displayedItems.map((listApartment, index) =>
                                <tr>
                                    <td style={{ width: "15%" }}>

                                        {selectedApartmentImage[listApartment.id] && selectedApartmentImage[listApartment.id] ?
                                            <Image src={selectedApartmentImage[listApartment.id]} className="imageTableReports" alt={index} style={{ width: "70px", height: "47px" }}></Image> :
                                            <Image src={backgroundImageApartment[listApartment.id]} className="imageTableReports" style={{ width: "70px", height: "47px" }}></Image>
                                        }

                                    </td>
                                    <td className='pt-4'>

                                        {listApartment.name}

                                    </td>
                                    <td className='pt-4'>{houseCount[listApartment.id]}</td>

                                    {rentPercentageApartment[listApartment.id] > 0 ?
                                        <>
                                            {rentPercentageApartment[listApartment.id] < 33 ? (
                                                <td className='pt-4'><span className={`badge bg-danger p-2`}>{rentPercentageApartment[listApartment.id]}%</span></td>

                                            ) : rentPercentageApartment[listApartment.id] < 67 ? (

                                                <td className='pt-4'><span className={`badge bg-warning p-2`}>{rentPercentageApartment[listApartment.id]}%</span></td>
                                            ) : (
                                                <td className='pt-4'><span className={`badge bg-success p-2`}>{rentPercentageApartment[listApartment.id]}%</span></td>
                                            )}
                                        </>
                                        :
                                        <td className='pt-4'><span className={`badge bg-danger p-2`}>0%</span></td>
                                    }

                                    <td className='pt-4'>Ksh. {incomeCount[listApartment.id]?.toLocaleString()}</td>
                                    <td className='pt-4'>Ksh. {rentPaidApartment[listApartment.id]?.toLocaleString()}</td>

                                    <td className='pt-4'>
                                        {apartmentWallet[listApartment.id] === 0 ?
                                            <p className='text-decoration-underline text-success fw-bold' style={{ cursor: "pointer" }} onClick={() => handleWalletCreate(listApartment.id)}>
                                                Add Wallet
                                            </p>
                                            :
                                            <p className='text-decoration-underline text-primary fw-bold' style={{ cursor: "pointer" }} onClick={() => handleWalletOtp(listApartment.id)}>
                                                View Wallet
                                            </p>
                                        }

                                    </td>

                                </tr>
                            )}


                        </tbody>
                    </Table>

                    <Modal show={walletCreate} onHide={handleWalletClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add a wallet account</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleAddAccount}>
                                <div className="input-icons">
                                    <i className="fa fa-hashtag icon"></i>
                                    <input
                                        className="input-field"
                                        type="text"
                                        placeholder="Wallet Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                {landlordCount > 1 ?

                                    <></>
                                    :
                                    <div className="input-icons">
                                        <i className="fa fa-hashtag icon"></i>
                                        <input
                                            className="input-field"
                                            type="number"
                                            placeholder="Minimum Authorizers"
                                            name="minimum_authorzers"
                                            value={formData.minimum_authorzers}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                }

                                <div className="input-icons">
                                    <FormSelect
                                        className="formProperty"
                                        name='account_type'
                                        value={formData.account_type}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option disabled value=''>
                                            Select Account Type
                                        </option>
                                        <option value='subscription'>
                                            Subscription
                                        </option>
                                        <option value='collection'>
                                            Collection
                                        </option>
                                    </FormSelect>
                                </div>


                                {accountType === "collection" ?
                                    <div className="input-icons">
                                        <FormSelect
                                            className="formProperty"
                                            name='collection_type'
                                            value={formData.collection_type}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option disabled value="">
                                                Select Collection Category
                                            </option>
                                            <option value='rent' selected="">
                                                Rent
                                            </option>

                                            <option value='utility' selected="">
                                                Utility
                                            </option>

                                        </FormSelect>

                                    </div>

                                    :
                                    <>
                                    </>
                                }

                                {collectionType === "utility" ?
                                    <div className="input-icons">
                                        <FormSelect
                                            className="formProperty"
                                            name='collection_type_id'
                                            value={formData.collection_type_id}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option disabled value="">
                                                Select Utility
                                            </option>
                                            {apartmentUtilities.length === 0 ?

                                                <option disabled selected >No utility add one kindly</option>
                                                :
                                                apartmentUtilities.map((utility) => (
                                                    <option value={utility.id}>{utility.title}</option>
                                                ))
                                            }

                                        </FormSelect>

                                    </div>

                                    :
                                    <>
                                    </>
                                }

                                <Modal.Footer>
                                    <Button variant="primary" type='submit'>
                                        Save
                                    </Button>
                                    <Button onClick={handleWalletClose} variant='secondary'>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal.Body>

                    </Modal>

                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>
                    {/* the conversion of the pdf  */}
                    {/* <PDFDownloadLink
                        document={
                            <Document>
                                <Page style={styles.page}>
                                    <View style={styles.section}>
                                        <Text>MY PROPERTIES REPORT</Text>

                                    </View>
                                    <View style={styles.section}>

                                        <Text style={[styles.cell, styles.header]}>Property</Text>
                                        <Text style={[styles.cell, styles.header]}>Units</Text>
                                        <Text style={[styles.cell, styles.header]}>Occupancy</Text>
                                        <Text style={[styles.cell, styles.header]}>Expected Income</Text>
                                        <Text style={[styles.cell, styles.header]}>Current Income</Text>

                                    </View>
                                    {landlordApartment.map((row, index) => (
                                        <View style={styles.section} key={index}>
                                            <Text style={styles.cell}>{row.name}</Text>
                                            <Text style={styles.cell}>{row.units}</Text>
                                            <Text style={styles.cell}>{row.occupancy}</Text>
                                            <Text style={styles.cell}>{row.income}</Text>
                                            <Text style={styles.cell}>{row.current_income}</Text>

                                        </View>
                                    ))}
                                </Page>
                            </Document>
                        }
                        fileName="table.pdf"
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : (
                                <div>
                                    <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>

                                    <button className='btn btn-danger text-white' onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = URL.createObjectURL(blob);
                                        link.download = 'table.pdf';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }}>
                                        PDF <i className='bi bi-file-pdf-fill'></i>
                                    </button>
                                </div>
                            )
                        }
                    </PDFDownloadLink> */}


                </div>
            </div>

        </>
    )
}

export default MainWallet