import React, { useEffect, useState } from 'react'
import './AddHouse.css'
import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';


// auth imports
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { useNavigate } from 'react-router'

function AddHouse() {

  const navigate = useNavigate()
  // set the apartment name
  const [apartment, setApartment] = useState("");
  // get the details of the house apartment 
  useEffect(() => {
    const accessToken = localStorage.getItem("authTokens");

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // headers access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    // get landlord id 
    const apartment = localStorage.getItem("Apartment");
    
    // hitting the endpoint of getting the apartment details
    serverUrl.get(`/landlord/apartment/?id=${apartment}`, config)
      .then((res) => {
        res.data.results.map((apartmentName,index) => 
        setApartment(apartmentName.name)
        )

      },[])

      .catch((error) => {
        console.log(error);
      });
  }, []);

  // the data to be sent in the backend
  const [formData, setFormData] = useState({
    apartment: "",
    house_number: "",
    bedroom: "",
    bathroom: "",
    token_prepaid_account_number: "",
    due_date: "",
    rent: "",
    deposit: ""
  });

  // onchange functionality
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }
  // when submitting the form it's functionallities
  function handleSubmit(e) {

    // preventing from redirecting to other pages
    e.preventDefault();

    // get user details so that you can use it from the access tokens
    const accessToken = localStorage.getItem("authTokens");

    // decode the tokens so that you can get the user_id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // header's access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }
    // get the appartment id from the local storage 
    const appartmentId = localStorage.getItem("Apartment");

    // the data that will be posted to the api is as follows
    const data = {
      apartment: appartmentId,
      house_number: formData.house_number,
      bedroom: formData.bedroom,
      bathroom: formData.bathroom,
      token_prepaid_account_number: formData.token_prepaid_account_number,
      due_date: formData.due_date,
      rent: formData.rent,
      deposit: formData.deposit
    };



    //  now post the data
    try {
      serverUrl

        //send the Apartment details using the following endpoint
        .post("/landlord/house/", data, config)
        .then((res) => {
          localStorage.setItem('house',JSON.stringify(res.data.id))
          toast.success('Successfully Added House!');
          navigate('/house-image')

        })

        // displaying the messages of why the error 
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            alert('Error while adding property' + error.message);
          } else {
            toast.error('Unexpected error')
            console.log('Unexpected error: ' + error.message);
          }
        });

    } catch (error) {
      console.log(error);
    }

  }
  return (
    <div className="registerForm">

      <h2 className="">Create Your Houses</h2>

      <Form onSubmit={handleSubmit}>
        <div className="input-icons">
          
          <i className="fa fa-hashtag icon"></i>
          <input
            className="input-field"
            type="text"
            placeholder="Property Name"
            name="apartment"
            value={apartment}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-icons">
          <i className="fa fa-hashtag icon"></i>
          <input
            className="input-field"
            type="text"
            placeholder="House Number"
            name="house_number"
            value={formData.house_number}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-icons">
          <i className="fa fa-hashtag icon"></i>
          <input
            className="input-field"
            type="number"
            placeholder="Number of Bedrooms"
            name="bedroom"
            value={formData.bedroom}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-icons">
          <i className="fa fa-hashtag icon"></i>
          <input
            className="input-field"
            type="number"
            placeholder="Number of Bathrooms"
            name="bathroom"
            value={formData.bathroom}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-icons">
          <i className="fa fa-hashtag icon"></i>
          <input
            className="input-field"
            type="number"
            placeholder="Token Meter Number"
            name="token_prepaid_account_number"
            value={formData.token_prepaid_account_number}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-icons">
          <i className="fa fa-hashtag icon"></i>
          <input
            className="input-field"
            type="date"
            placeholder="Due Date"
            name="due_date"
            value={formData.due_date}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-icons">
          <i className="fa fa-hashtag icon"></i>
          <input
            className="input-field"
            type="number"
            placeholder="Rent(Amount in Ksh)"
            name="rent"
            value={formData.rent}
            onChange={handleChange}
            required
          />
        </div>

        <div className="input-icons">
          <i className="fa fa-hashtag icon"></i>
          <input
            className="input-field"
            type="number"
            placeholder="Deposit(Amount in Ksh)"
            name="deposit"
            value={formData.deposit}
            onChange={handleChange}
            required
          />
        </div>

        <Button variant="primary" type="submit" className="nextBtn">
          Save
        </Button>
      </Form>

    </div>
  )
}

export default AddHouse