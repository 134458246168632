import React, { useState } from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './BottomConfigure.css';

function BottomConfigure() {
  const [showButtons, setShowButtons] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(false);

  const handleButtonClick = () => {
    setShowButtons(true);
    setShowCloseIcon(true);
  }

  const handleCloseButtons = () => {
    setShowButtons(false);
    setShowCloseIcon(false);
  }

  return (
    <div className='addButton'>
      {showCloseIcon ?
        <Button className='btn btn-sm  btn-primary buttonMain' onClick={handleCloseButtons}>
          <i className='bi bi-x'></i>
        </Button> :
        <Button className='btn btn-primary buttonMain' onClick={handleButtonClick}>
          <i className='bi bi-plus'></i>
        </Button>
      }
      <Modal show={showButtons} onHide={handleCloseButtons} centered>
        <Modal.Body className='modalBody'>
        <ButtonGroup className='buttonsGroup' style={{display: showButtons ? 'block' : 'none'}}>
        <div className='buttonIcons'>
          
          <Button className='buttonsAdd'>
            <Link to='/add-house' className="text-white text-decoration-none">
            Add House
            </Link>;
          </Button>
          <Link to='/add-house' className="text-decoration-none">
          <i className='bi bi-house-fill'></i>
            </Link>
         
        </div>
        <div className='buttonIcons'>
          <Button className='buttonsAdd'>
          <Link to='/add-property' className="text-white text-decoration-none">
            Add Property
          </Link>
          </Button>
          <Link to='/add-property' className="text-white text-decoration-none">
          <i className='bi bi-bank2'></i> 
          </Link>
          
        </div>  
           
      </ButtonGroup>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default BottomConfigure;
