import React, { useEffect, useState } from 'react';

// styles
import './Home.scss';

// importing components
import HomeCarousel from '../../components/HomeCarousel';
import HomeFooter from '../../components/HomeFooter';

function Home() {
  const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
  useEffect(() => {
    const getRandomImage = () => {
      let storedImage = localStorage.getItem('backgroundImage');
      if (!storedImage) {
        storedImage = '';
      }

      let randomNumber = Math.floor(Math.random() * 13) + 1;
      while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
        randomNumber = Math.floor(Math.random() * 13) + 1;
      }

      const imageUrl = `https://kodinyumba.app/media/Stock/hd/LandingPage/${randomNumber}.jpg`;
      setBackgroundImageApartment(imageUrl);
      localStorage.setItem('backgroundImage', imageUrl);
    };

    getRandomImage();
  }, []);

  const images = [backgroundImageApartment];

  return (
    <div className='homeCard'>
      <div className="image-container">
        <HomeCarousel images={images} className="imageHome" />
        <div className="wave"></div>
      </div>
      <div className='main-content p-5'>
        <h2 className='text-capitalize fw-bold'>real estate management made easy</h2>
        <p>Kodi is a smart, all-in-one tool built to help you manage your real estate properties wherever you are.</p>
      </div>
      <HomeFooter />
    </div>
  );
}

export default Home;
