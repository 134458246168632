
import React, { useState } from "react";
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";
import SideBar from './SideBar'
import Header from './Header'
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";


function ImageResize() {
    // sidebar and header functions
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    const navigate = useNavigate();

    const [image, setImage] = useState("");
    const [currentPage, setCurrentPage] = useState("choose-img");
    const [imgAfterCrop, setImgAfterCrop] = useState("");

    // Invoked when new image file is selected
    const onImageSelected = (selectedImg) => {
        setImage(selectedImg);
        setCurrentPage("crop-img");
    };

    // Generating Cropped Image When Done Button Clicked
    const onCropDone = (imgCroppedArea) => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = imgCroppedArea.width;
        canvasEle.height = imgCroppedArea.height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();
        imageObj1.src = image;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            );

            const dataURL = canvasEle.toDataURL("image/jpeg");

            setImgAfterCrop(dataURL);
            setCurrentPage("img-cropped");
        };
    };

    // Handle Cancel Button Click
    const onCropCancel = () => {
        setCurrentPage("choose-img");
        setImage("");
    };

    // onchange functionality and submit automatically when user finishes selecting the image
    const handleChangeImage = (event) => {
        // Set the preview of the uploaded image

        // Convert base64 string to Blob
        const blob = dataURLToBlob(imgAfterCrop);

        // Create a File object from the Blob
        const imageFile = new File([blob], "profile_image.jpeg", { type: "image/jpeg" });

        // Create FormData and append the File object
        const imageData = new FormData();
        imageData.append("image", imageFile);


        // get user details to use as header
        const accessToken = localStorage.getItem("authTokens");

        // decode the tokens so that you can get the user_id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;

        // header's access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        // now send the image to the backend
        try {
            serverUrl

                //send the  image using the following endpoint
                .patch(`/users/users-details/${userId}/`, imageData, config)
                .then((res) => {
                    console.log(res)
                    toast.success('Successfully Updated User Image!')
                    navigate("/profile");
                })
                .catch((error) => {
                    console.log(error);
                    toast.error('Image not updated!')
                });

        } catch (error) {
            toast.error(error);
        }


    }

      // Helper function to convert dataURL to Blob
  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            <div className="container-crop">
                {currentPage === "choose-img" ? (
                    <FileInput setImage={setImage} onImageSelected={onImageSelected} />
                ) : currentPage === "crop-img" ? (
                    <ImageCropper
                        image={image}
                        onCropDone={onCropDone}
                        onCropCancel={onCropCancel}
                    />
                ) : (
                    <div style={{ position: "relative" }}>
                        <p>This will be your profile picture</p>
                        <div>
                            <img src={imgAfterCrop} className="cropped-img" />
                        </div>

                        <button
                            onClick={() => {
                                // setCurrentPage("crop-img");
                                handleChangeImage();

                            }}

                            className="btn btn-success text-white"
                        >
                            Upload
                        </button>

                        <button
                            onClick={() => {
                                setCurrentPage("choose-img");
                                setImage("");
                            }}
                            className="btn btn-warning text-white ms-5"
                        >
                            Change Image
                        </button>
                    </div>
                )}
            </div>
        </>

    );
}

export default ImageResize;
