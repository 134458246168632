import React, { useState, useEffect } from 'react'
import './OwnersReport.scss'
import { useParams } from 'react-router-dom';
import { Button, Table, Form, FormControl,Image } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import Header from '../Header';
import SideBar from '../SideBar';
// auth imports
import jwt_decode from "jwt-decode";
import { toast } from 'react-toastify';
import { serverUrl } from "../../../serverUrl";

function OwnersReport(Apartmentid) {
  // sidebar and header functions
  const [showSidebar, setShowSidebar] = useState(true);
  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }
  // set the const for the owners
  const [apartmentOwners, setApartmentOwners] = useState([])
  // getting the id and finding the apparment details
  const { id } = useParams();


  // get the houses and the tenants that are within that house
  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;
  const [isLoading, setIsLoading] = useState(false);

  //get the endpoint that has the owners in the apartments 
  useEffect(() => {
    serverUrl.get(`/landlord/all_apartement_owners/?apartment_id=${id}`, config)
      .then((res) => {
        setApartmentOwners(res.data.results)
      })
      .catch((error) => {
        console.log(error);

      })

  }, []);

  function handleDownload() {
    setIsLoading(true);

    serverUrl.get(`/landlord/react-generate-owners-report-pdf/?apartment_id=${id}&download=true`, config)
      .then((res) => {
        // create a download url for the pdf and then automatically download the pdf
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'owners_report.pdf');
        document.body.appendChild(link);
        link.click();
        toast.success('Successfully Downloaded Your Owners Report');
        setIsLoading(false);

      })
      .catch((error) => {
        console.log(error);
      })
  };
  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }




  // searching function and sorting functionalities
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSort = (sortField) => {
    setSortType(sortField);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };

  const sortedData = apartmentOwners.sort((a, b) => {
    const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === "asc") {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });
  // exporting data in excel and in pdf
  const exportCSV = () => {
    const csvData = [
      ['Name', 'Gender', 'Phone Number', 'Landlord Category','Landlord Type'],
      ...apartmentOwners.map(item => [item.full_name, item.gender, item.phone_number, item.landlord_category, item.landlord_type])
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'owners_report.csv');
  };
  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

     // if the data is loading show a message
     if (isLoading) {
      return (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      );
    }
  return (
    <>
        <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
    {showSidebar && (
      <SideBar />
    )}

      <div className='OwnersReport'>


        {/* list of the tenants of the apartment and payment details */}
        <div className='tasks ' >
          <h2 style={{ textTransform: "uppercase", color:"#8e44ad" }} >owner's report</h2>

          <div className='d-flex justify-content-between'>

            <div>
              <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
              <Button className='btn btn-danger text-white' onClick={handleDownload}>PDF <i className='bi bi-file-pdf-fill'></i></Button>
            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" onChange={handleSearch} />
            </Form>
          </div>
          <Table className='table'>
            <thead className='underline'>
              <th>User Image</th>
              <th onClick={() => handleSort("full_name")}>Name<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("gender")}>Gender<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("phone_number")}>Phone Number<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("landlord_category")}>Landlord Category<i className='bi bi-chevron-expand'></i></th>
              <th onClick={() => handleSort("landlord_type")}>Landlord Type<i className='bi bi-chevron-expand'></i></th>


            </thead>
            <tbody>
              {displayedItems.map((Defaulter, index) =>
                <tr>
                  <td style={{ width: "15%" }}>
                    <Image src={Defaulter.image} style={{ width: "20%", borderRadius: "50%" }}/>
                    </td>
                  <td>{Defaulter.full_name}</td>
                  <td>{Defaulter.gender}</td>
                  <td>{Defaulter.phone_number}</td>
                  <td>{Defaulter.landlord_category}</td>
                  {Defaulter.landlord_type === 'landlord' ?
                    (<td className='landlord'>{Defaulter.landlord_type}</td>)
                    : Defaulter.account === 'agent' ?
                      (<td className='agent'>{Defaulter.landlord_type}</td>)
                      :
                      (<td className='caretaker'>{Defaulter.landlord_type}</td>)
                  }

                </tr>

              )}

            </tbody>
          </Table>
          {/* get the display of the items 5 per page */}
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              &lt;
            </button>
            <span>{`${startIndex + 1}-${endIndex} of ${apartmentOwners.length}`}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
              &gt;
            </button>
          </div>


        </div>


      </div>
    </>
  )
}

export default OwnersReport