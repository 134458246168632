import React from 'react'
import BottomConfigure from '../../components/bottoms/BottomConfigure';
import BottomNavbar from './../../components/BottomNavbar';
import HeaderConfigure from './../../components/headers/HeaderConfigure';


function Configure() {
  return (
    <div>
          <HeaderConfigure/>
          <BottomConfigure/>
         <BottomNavbar  />
    </div>
  )
}

export default Configure