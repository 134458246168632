import React, { useState, useEffect } from 'react'
import './Utilties.scss'
import Header from './Header'
import SideBar from './SideBar'
import { Button, Table, Card, Form, FormControl, Image, Modal, Col, Row, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Internet from '../../assets/img/internet.png'
import Water from '../../assets/img/water.png'
import Electricity from '../../assets/img/electricity.jpeg'
import Security from '../../assets/img/security.png'
import Garbage from '../../assets/img/garbage.png'
import Lottie from 'react-lottie';
import SadEmoji from '../../assets/lottie/sad_emoji.json'
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import loaderImage from '../../assets/img/logo.png'


function Utilities() {
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }

    // get the apartements of the landlord
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    // apartments of the landlord
    const [landlordApartment, setLandlordApartment] = useState([])
    const [defaultersApartment, setDefaultersApartment] = useState({});

    // counted house
    const [houseCount, setHouseCount] = useState({});
    const [occupiedCount, setOccupiedCount] = useState({});
    const [incomeCount, setIncomeCount] = useState({});
    const [expensesCount, setExpensesCount] = useState({});
    const [defaultersCount, setDefaultersCount] = useState({});

    // the total utilities income
    const [waterIncome, setWaterIncome] = useState("");
    const [securityIncome, setSecurityIncome] = useState("");
    const [internetIncome, setInternetIncome] = useState("");
    const [electricityIncome, setElecttricityIncome] = useState("");
    const [garbageIncome, setGarbageIncome] = useState("");

    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

    // get the current month so that i can filter out the total income
    const months = Array.from({ length: 12 }, (_, i) => {
        const month = new Date(new Date().getFullYear(), i, 1);
        return month.toLocaleString('default', { month: 'long' });
    });

    const currentDate = new Date();
    const currentMonthIndex = new Date().getMonth();
    const currentMonth = months[currentMonthIndex];
    // get the years
    const currentYear = new Date().getFullYear();
    const startYear = currentYear;


    // get the months till today
    const monthsTillToday = Array.from({ length: currentMonthIndex + 1 }, (_, i) => {
        const month = new Date(currentDate.getFullYear(), i, 1);
        return month.toLocaleString('default', { month: 'long' });
    });

    const [showModal, setShowModal] = useState(false)
    const [monthFilterActive, setMonthFilterActive] = useState("")
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedMonth, setSelectedMonth] = useState("");
    const [landlordCurrentOccupancy, setLandlordCurrentOccupancy] = useState(0);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        setShowModal(true);
    };

    const handleCloseModal = () => {

        setShowModal(false);
    };
    const handleMonthButtonClick = (month) => {
        setSelectedMonth(month);
        localStorage.setItem("monthUtilities", month);
        setShowModal(false);
        window.location.reload();

    };
    const [subscriptionStatus, setSubscriptionStatus] = useState([]);
    const [showModalInactive, setShowModalInactive] = useState(false);
    const [subscriptionAmount, setSubscriptionAmount] = useState("");
    const [subscription, setSubscription] = useState([]);


    const handleCloseModalInactive = () => {

        setShowModalInactive(false);
    }
    // from the localstorage get the logged in user
    useEffect(() => {
        const accessToken = localStorage.getItem("authTokens");
        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;
        // get if the filter is set
        const setMonthFilter = localStorage.getItem("monthUtilities")
        setMonthFilterActive(setMonthFilter)

        // headers access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }
        // decoding the token so that i can get the user id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // check the filtered data
        let monthSelect = "";

        if (setMonthFilter) {
            monthSelect = setMonthFilter
        }
        else {
            monthSelect = currentMonth
        }
        // get the landlord details that are linked to the user
        setIsLoading(true)
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                // get the rent paid and occupancy amount per apartment


                // get the total income per each utility of the landlord
                serverUrl.get(`/landlord/react-utilities-income-landlord/?landlord_id=${landlordIds}&month=${monthSelect.toLowerCase()}&year=${currentYear}`, config)
                    .then((res) => {
                        setWaterIncome(res.data.water_income)
                        setSecurityIncome(res.data.security_income)
                        setInternetIncome(res.data.internet_income)
                        setElecttricityIncome(res.data.electricity_income)
                        setGarbageIncome(res.data.garbage_income)
                    })
                    .catch((error) => {
                        console.log(error)
                    })

                // using the landlord id get the apartments linked to the landlord
                const landlordApartmentPromises = landlordIds.map((landlordId) => {
                    return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => res.data.results)
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });
                });

                Promise.all(landlordApartmentPromises)
                    .then((results) => {
                        // i am merging the sub-arrays into a new array
                        const apartments = results.flat();

                        const apartmentPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/get_rent_summary/?apartment_id=${apartment.id}&month=${currentMonth}&year=${currentYear}`, config)
                                .then((res) => ({ apartmentId: apartment.id, dataSummary: res.data }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, dataSummary: res.data };
                                });

                        })




                        // set count of the number of houses that are in that apartment
                        Promise.all(apartmentPromises)
                            .then((results) => {

                                // object that gets the number of apartments
                                const apartmentRentPaid = {};
                                const apartmentOccupancyPercentage = {};
                                const apartmentRentPaidExpected = {};
                                const apartmentIncome = {};


                                results.forEach(({ apartmentId, dataSummary }) => {
                                    apartmentRentPaid[apartmentId] = dataSummary.rent_paid;
                                    apartmentOccupancyPercentage[apartmentId] = dataSummary.occupancy_percentage;
                                    apartmentRentPaidExpected[apartmentId] = dataSummary.total_rent;

                                });
                                setLandlordCurrentOccupancy(apartmentOccupancyPercentage)
                                setIsLoading(false)

                            })
                            .catch((error) => {
                                console.log(error);
                                setIsLoading(false)

                            });

                        //get the details based on the wallet data
                        const walletPromises = apartments.map((apartment) => {
                            return serverUrl
                                .get(`/landlord/subscriptions-invoice/?apartment=${apartment.id}`, config)
                                .then((res) => {
                                    console.log(res.data.results)
                                    const unpaidInvoices = res.data.results.filter((invoice) => invoice.status !== 'paid');
                                    // if (res.data.results.length > 0) {
                                    //     setShowModalInactive(true)

                                    // }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });


                        // the subscription amount
                        const unitAmountSubscriptionPromises = apartments.map(
                            (apartment) => {
                                return serverUrl
                                    .get(
                                        `/landlord/subscription-amount-units/?apartment_id=${apartment.id}`,
                                        config
                                    )
                                    .then((res) => ({
                                        apartmentId: apartment.id,
                                        data: res.data.results[0],
                                        units: res.data.units,
                                    }))
                                    .catch((error) => {
                                        console.log(error);
                                        return { apartmentId: apartment.id };
                                        setIsLoading(false);
                                    });
                            }
                        );
                        // set count of the number of houses that are occupied in that apartment
                        Promise.all(unitAmountSubscriptionPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const houseOccupiedObj = {};
                                results.forEach(({ apartmentId, data }) => {
                                    houseOccupiedObj[apartmentId] = data;
                                });

                                setSubscriptionAmount(houseOccupiedObj);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        // get the subscription PLAN
                        const subscriptionPromises = apartments.map((apartment) => {
                            return serverUrl
                                .get(
                                    `/landlord/subscription-defaulters/?apartment_id=${apartment.id}`,
                                    config
                                )
                                .then((res) => ({
                                    apartmentId: apartment.id,
                                    subscription: res.data.subscription,
                                }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id };
                                });
                        });

                        // get the subscription status
                        const subscriptionStatusPromises = apartments.map((apartment) => {
                            return serverUrl
                                .get(
                                    `/landlord/subscription-defaulters/?apartment_id=${apartment.id}`,
                                    config
                                )
                                .then((res) => ({
                                    apartmentId: apartment.id,
                                    subscription: res.data.results,
                                }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id };
                                });
                        });

                        // set the response of the subscriptions
                        Promise.all(subscriptionPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const subscriptionObj = {};
                                results.forEach(({ apartmentId, subscription }) => {
                                    subscriptionObj[apartmentId] = subscription;
                                });
                                setSubscriptionStatus(subscriptionObj);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        // set the response of the subscriptions plan
                        Promise.all(subscriptionStatusPromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const subscriptionObj = {};
                                results.forEach(({ apartmentId, subscription }) => {
                                    subscriptionObj[apartmentId] = subscription;
                                });
                                setSubscription(subscriptionObj);
                                setIsLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        setLandlordApartment(apartments);
                        setIsLoading(false)

                        // get the defaulters in the each apartment
                        const defaulterApartment = {}
                        apartments.map((defaultersApartment) => {
                            serverUrl.get(`/landlord/get_defaulters_list/?apartment_id=${defaultersApartment.id}`)
                                .then((res) => {
                                    defaulterApartment[defaultersApartment.id] = res.data.defaulters
                                    setDefaultersApartment(defaulterApartment)
                                })
                                .catch((error) => {
                                    console.log(error)
                                })

                        })


                        // getting the houses in the apartment
                        const houseCountPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                                .then((res) => ({ apartmentId: apartment.id, count: res.data.count }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, count: 0 };
                                });
                        });
                        // set count of the number of houses that are in that apartment
                        Promise.all(houseCountPromises)
                            .then((results) => {

                                // object that gets the number of apartments
                                const houseCountObj = {};
                                results.forEach(({ apartmentId, count }) => {
                                    houseCountObj[apartmentId] = count;
                                });
                                setHouseCount(houseCountObj);
                                setIsLoading(false)

                            })
                            .catch((error) => {
                                console.log(error);
                            });



                        const houseOccupancyPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/display-occupied-houses-in-expenses/?apartment_id=${apartment.id}`, config)
                                .then((res) => ({ apartmentId: apartment.id, count: res.data.count }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, count: 0 };
                                });
                        });
                        // set count of the number of houses that are occupied in that apartment
                        Promise.all(houseOccupancyPromises)
                            .then((results) => {

                                // object that gets the number of apartments
                                const houseOccupiedObj = {};
                                results.forEach(({ apartmentId, count }) => {
                                    houseOccupiedObj[apartmentId] = count;
                                });
                                setOccupiedCount(houseOccupiedObj);
                                setIsLoading(false)

                            })
                            .catch((error) => {
                                console.log(error);
                            });


                        // get the expenses of the houses in the apartments 
                        const houseExpensesPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/expense/?apartment_id=${apartment.id}`, config)
                                .then((res) => ({ apartmentId: apartment.id, count: res.data.count }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, count: 0 };
                                });
                        });
                        // set count of the number of houses that are occupied in that apartment
                        Promise.all(houseExpensesPromises)
                            .then((results) => {

                                // object that gets the number of apartments
                                const houseExpenseObj = {};
                                results.forEach(({ apartmentId, count }) => {
                                    houseExpenseObj[apartmentId] = count;
                                });
                                setExpensesCount(houseExpenseObj);
                                setIsLoading(false)

                            })
                            .catch((error) => {
                                console.log(error);
                            });


                        // get the expenses income monthly
                        const houseIncomePromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/react-utilities-income-landlord/?month=${monthSelect.toLowerCase()}&apartment_id=${apartment.id}&year=${currentYear}`, config)
                                .then((res) => ({ apartmentId: apartment.id, count: res.data.total_income }))

                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, count: res.data.total_income };
                                });
                        });
                        // set count of the number of houses that are occupied in that apartment
                        Promise.all(houseIncomePromises)
                            .then((results) => {
                                // object that gets the number of apartments
                                const houseIncomeObj = {};
                                results.forEach(({ apartmentId, count }) => {
                                    houseIncomeObj[apartmentId] = count;
                                });
                                setIncomeCount(houseIncomeObj);
                                setIsLoading(false)

                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        const houseDefaultersPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                                .then((res) => {
                                    const defaultersPromise = res.data.results.map((defaulter) => {
                                        const apartmentId = defaulter.apartment;
                                        const due = defaulter.due_date;
                                        return { apartmentId, due };
                                    });
                                    return defaultersPromise; // Return the value of defaultersPromise
                                })
                                .catch((error) => {
                                    console.log(error);
                                    return []; // Return an empty array as a fallback
                                });
                        });
                        const defaultersCount = {};

                        // set count of the number of houses that have defaulters in that apartment
                        Promise.all(houseDefaultersPromises)
                            .then((results) => {
                                results.forEach((apartmentDefaulters) => {
                                    apartmentDefaulters.forEach((defaulter) => { // Loop through each defaulter in the apartmentDefaulters array
                                        const apartmentId = defaulter.apartmentId;
                                        const today = new Date();
                                        const dueDate = new Date(defaulter.due);
                                        const defaulterCount = dueDate < today ? 1 : 0; // Check if the due date is less than today
                                        if (defaultersCount.hasOwnProperty(apartmentId)) {
                                            defaultersCount[apartmentId] += defaulterCount; // Add the count to the existing count for the apartment
                                        } else {
                                            defaultersCount[apartmentId] = defaulterCount; // Initialize the count for the apartment
                                        }
                                    });
                                });

                                setDefaultersCount(defaultersCount);
                                setIsLoading(false)

                            })
                            .catch((error) => {
                                console.log(error);
                            });

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    // send reminder for the landlord
    const [remindersModal, setRemindersModal] = useState(false)

    // modal for filtering on the month
    const handleReminderModalOpen = () => {
        setRemindersModal(true);
    };

    const handleReminderModalClose = () => {
        setRemindersModal(false);
    };
    const handleReminderModalReload = () => {
        setRemindersModal(false);
        window.location.reload()
    };

    const [selectedOptionReminder, setSelectedOptionReminder] = useState(null);
    const [addTemplate, setAddTemplate] = useState(false);

    const handleOptionSelect = (option) => {
        setSelectedOptionReminder(option);
        setAddTemplate(false)
    };

    const handleAddTemplate = () => {
        setSelectedOptionReminder('')
        setAddTemplate(true)
    }


    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const totalPages = Math.ceil(landlordApartment.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? landlordApartment.length : startIndex + itemsPerPage;
    const displayedItems = landlordApartment.slice(startIndex, endIndex);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            setIsLoading(true);

            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [landlordApartment]);

    // send the reminders from the table that is for every apartment
    function handleSendReminder(apartmentId) {

        try {
            serverUrl.get(`/landlord/send_payment_reminder/?apartment_id=${apartmentId}`)
                .then((res) => {
                    toast.success("Reminder Sent Successfully!")
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {

        }
    }

    const handleReset = () => {
        // Clear the tokens from local storage and redirect to the login page
        localStorage.removeItem('monthUtilities');
        toast.success('Successfully reset the filter!');
        window.location.reload()
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: SadEmoji,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='utilities_houses'>
                <div className='topSelectedProperty' style={{ backgroundImage: `url("https://kodinyumba.app/media/utility-dashboard.jpg")` }}>
                    <p className='properties text-uppercase'>Utilities For Your Properties</p>

                </div>

                {/* inactive subscription modal */}
                <Modal className='d-none' show={showModalInactive} onHide={handleCloseModalInactive} style={{ marginTop: "7.5rem" }} size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>{ }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Card className="text-center border-0 position-relative">
                            <div className="card-background"></div>
                            <div className='card-body-content'>
                                <h3 className='text-uppercase'>Your plan is not activated</h3>
                                <p className='w-75 text-center mx-auto'>It seems you have uncleared invoices. In order to continue enjoying our services, please take a moment to settle them.</p>
                                <div className="d-flex justify-content-center">
                                    <Lottie
                                        options={defaultOptions}
                                        height={200}
                                        width={400}
                                    />
                                </div>
                                <p>We value your continued presence and support. We kindly ask you to activate your plan by settling the outstanding invoices.</p>
                                <Button className='w-auto d-flex text-center mb-4 ' style={{ margin: "0 auto" }}>
                                    <Link className='text-white text-decoration-none' to='/subscription'>
                                        ACTIVATE NOW
                                    </Link>
                                </Button>
                            </div>
                        </Card>


                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModalInactive}>Close</Button>
                    </Modal.Footer> */}
                </Modal>



                <div className='staticCards'>
                    <Card className='rentDueElectricity'>
                        <Card.Body className='d-flex'>

                            <Image src={Electricity} alt='water'></Image>

                            <div className='float-left'>
                                <span>Electricity</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost'>Ksh. {electricityIncome}</p>
                                </div>

                            </div>
                        </Card.Body>

                    </Card>

                    <Card className='rentDue'>
                        <Card.Body className='d-flex'>

                            <Image src={Water} alt='water'></Image>

                            <div className='float-left'>
                                <span>Water</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost'>Ksh. {waterIncome}</p>
                                </div>

                            </div>
                        </Card.Body>

                    </Card>

                    <Card className='defaulters'>
                        <Card.Body className='d-flex'>

                            <Image src={Security} alt='security'></Image>

                            <div className='float-left'>
                                <span className="">Security</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost'>Ksh. {securityIncome}</p>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>



                    <Card className='occupacy'>
                        <Card.Body className='d-flex'>

                            <Image src={Internet} alt='internet'></Image>

                            <div className='float-left'>
                                <span>Internet</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost' >Ksh. {internetIncome}</p>
                                </div>

                            </div>
                        </Card.Body>

                    </Card>

                    <Card className='income'>
                        <Card.Body className='d-flex'>

                            <Image src={Garbage} alt='garbage'></Image>

                            <div className='float-left'>
                                <span className=''>Garbage</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost'>Ksh. {garbageIncome}</p>

                                </div>

                            </div>
                        </Card.Body>

                    </Card>
                </div>
                <div className='tasks' >
                    <h5 className='float-start'>My List of Properties</h5>
                    <div className='d-flex justify-content-between d-none'>
                        <div className='d-flex' style={{ placeContent: "center", marginLeft: "5rem" }}>
                            {monthFilterActive ?
                                <p className='filterText mt-3'>Utilities For {monthFilterActive} </p>
                                :
                                <></>
                            }

                            {monthFilterActive ?
                                <Button className='text-white filterButton' onClick={handleReset}>Reset Filter</Button>

                                :
                                <></>}
                        </div>

                        <Form.Select size='sm' className='formCal d-none' onChange={handleSelectChange} style={{ width: "20%", float: "right" }}>
                            <option selected disabled>Filter By:</option>
                            <option>Monthly</option>
                            <div className='d-flex justify-content-between'>
                                <Form.Select size='sm' className='formCal' onChange={handleSelectChange} style={{ width: "20%" }}>
                                    <option selected disabled>Filter By:</option>
                                    <option>Monthly</option>
                                </Form.Select>

                                {monthFilterActive ?
                                    <p className='filterText'>Utilities For {monthFilterActive} </p>
                                    :
                                    <></>
                                }

                                {monthFilterActive ?
                                    <Button className='text-white filterButton' onClick={handleReset}>Reset Filter</Button>

                                    :
                                    <></>}
                            </div>

                            {/* modal to be displayed */}
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header className='bg-primary text-white' closeButton>
                                    <Modal.Title>Filter By {selectedOption}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedOption === "Monthly" ?
                                        (
                                            <>
                                                <Form>
                                                    <Row className="flex-wrap justify-content-between">
                                                        {monthsTillToday.map((month) => (
                                                            <Col key={month} xs={6} sm={4} md={4} className="mt-3">
                                                                <Button className='btn btn-primary text-white w-75' style={{ marginInline: '0.5rem' }} onClick={() => handleMonthButtonClick(month)}>
                                                                    {month}
                                                                </Button>
                                                            </Col>
                                                        ))}
                                                    </Row>

                                                </Form>
                                            </>

                                        )
                                        :
                                        (
                                            <>
                                                <p>Date</p>
                                            </>
                                        )
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant='secondary' onClick={handleCloseModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </Form.Select>


                    </div>


                    <Table className='table'>
                        <thead className='underline'>
                            <th>Image</th>
                            <th>Property</th>
                            <th>Units</th>
                            <th>Occupancy</th>
                            <th>Utility Income</th>
                            <th>Defaulters</th>
                            <th>Reminders</th>
                        </thead>
                        <tbody>
                            {displayedItems.map((listApartment, index) =>
                                <tr>
                                    <td>
                                        {selectedApartmentImage[listApartment.id] && selectedApartmentImage[listApartment.id] ?
                                            <Image src={selectedApartmentImage[listApartment.id]} className="imageTableDashboard" alt={index} style={{ width: "70px", height: "47px" }}></Image> :
                                            <Image src={backgroundImageApartment[listApartment.id]} className="imageTableDashboard" style={{ width: "70px", height: "47px" }}></Image>
                                        }

                                    </td>
                                    <td>
                                        {/* <Link to={`/selected-property-utility/${listApartment.id}`}>
                                            {listApartment.name}
                                        </Link> */}
                                        <td>
                                            {/* Render the Link component with the determined path */}
                                            <Link
                                                className="btn bg-transparent text-primary text-decoration-underline border-0"
                                                to={
                                                    listApartment.num_houses !== 0
                                                        ? subscriptionStatus[listApartment.id] &&
                                                            subscriptionStatus[listApartment.id] === "No subscription found"
                                                            ? `/new-plan/${listApartment.id}`
                                                            : subscription[listApartment.id] &&
                                                                subscription[listApartment.id].length !== 0 &&
                                                                subscription[listApartment.id][0].status !== "paid"
                                                                ? `/wallet-data/${listApartment.id}`
                                                                : `/selected-property-utility/${listApartment.id}`
                                                        : `/selected-property-utility/${listApartment.id}`
                                                }
                                            >
                                                {listApartment.name}
                                            </Link>
                                        </td>

                                    </td>
                                    <td>{houseCount[listApartment.id]}</td>

                                    {occupiedCount[listApartment.id] > 0 ?
                                        <>
                                            {landlordCurrentOccupancy[listApartment.id] < 33 ? (
                                                <td><span className={`badge bg-danger p-2`}>{landlordCurrentOccupancy[listApartment.id]} %</span></td>

                                            ) : landlordCurrentOccupancy[listApartment.id] < 67 ? (

                                                <td><span className={`badge bg-warning p-2`}>{landlordCurrentOccupancy[listApartment.id]} %</span></td>
                                            ) : (
                                                <td><span className={`badge bg-success p-2`}>{landlordCurrentOccupancy[listApartment.id]} %</span></td>
                                            )}
                                        </>
                                        :
                                        <td><span className={`badge bg-danger p-2`}>0%</span></td>
                                    }
                                    {incomeCount[listApartment.id] !== undefined ?
                                        <td>Ksh. {incomeCount[listApartment.id]}</td> :
                                        <td>Ksh. 0</td>
                                    }

                                    {defaultersApartment[listApartment.id] > 0 ?
                                        <td>
                                            {defaultersApartment[listApartment.id]}

                                        </td>
                                        :
                                        <td>0</td>
                                    }

                                    {defaultersApartment[listApartment.id] > 0 ? (
                                        <td><Button className="sendReminders btn-sm" onClick={handleReminderModalOpen}>Send Reminders</Button></td>
                                    )
                                        : <td></td>
                                    }

                                </tr>
                            )}

                            {/* modal for send reminders */}
                            <Modal show={remindersModal} onHide={handleReminderModalClose}>
                                <Modal.Header className='bg-primary text-white' closeButton>
                                    {addTemplate ?
                                        <Modal.Title>Add Message Templates</Modal.Title>
                                        :
                                        <Modal.Title>Send Reminder Message</Modal.Title>

                                    }
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='d-flex justify-content-between'>
                                        <Dropdown>
                                            <Dropdown.Toggle variant={addTemplate ? 'secondary' : 'primary'} size='sm'>

                                                {selectedOptionReminder ? (
                                                    selectedOptionReminder === 'utility' ?
                                                        <>Utility</>
                                                        :
                                                        selectedOptionReminder === 'rent' ?
                                                            <>Rent</>
                                                            :
                                                            <>Choose</>

                                                )
                                                    :
                                                    <>Choose Template</>
                                                }
                                            </Dropdown.Toggle>


                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleOptionSelect('utility')}>Utility</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleOptionSelect('rent')}>Rent</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Button className={`btn btn-sm ${addTemplate ? 'btn-primary' : 'btn-secondary'}`} onClick={handleAddTemplate}>Add Template</Button>
                                    </div>

                                    {addTemplate ? (
                                        <>
                                            <div className="input-icons mt-2">
                                                <i class="bi bi-send-plus icon"></i>
                                                <textarea className="input-field" rows={4} placeholder='Enter Message'></textarea>
                                            </div>
                                        </>
                                    ) : (
                                        selectedOptionReminder ? (
                                            <div className='mt-2'>
                                                {selectedOptionReminder === 'utility' && (
                                                    <>
                                                        <div className="input-icons  mb-2">
                                                            <i class="bi bi-chat-left-dots icon"></i>
                                                            <textarea className="input-field" rows={1}>Utility Reminder</textarea>
                                                        </div>

                                                        <div className="input-icons">
                                                            <i class="bi bi-send-plus icon"></i>
                                                            <textarea className="input-field">Utility bill payment reminder</textarea>
                                                        </div>

                                                    </>
                                                )}

                                                {selectedOptionReminder === 'rent' && (
                                                    <>
                                                        <div className="input-icons  mb-2">
                                                            <i class="bi bi-chat-left-dots icon"></i>
                                                            <textarea className="input-field" rows={1}>Rent Reminder</textarea>
                                                        </div>

                                                        <div className="input-icons">
                                                            <i class="bi bi-send-plus icon"></i>
                                                            <textarea className="input-field">Please pay the rent</textarea>
                                                        </div>

                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <>

                                                <div className="input-icons  mt-2 mb-2">
                                                    <i class="bi bi-chat-left-dots icon"></i>
                                                    <textarea className="input-field" rows={1} placeholder='Custom Message'></textarea>
                                                </div>

                                                <div className="input-icons">
                                                    <i class="bi bi-send-plus icon"></i>
                                                    <textarea className="input-field" rows={8} placeholder='Enter Message'></textarea>
                                                </div>
                                            </>
                                        )
                                    )}

                                </Modal.Body>
                                <Modal.Footer className="d-flex justify-content-between">
                                    <Button variant='danger' onClick={handleReminderModalClose}>
                                        Close
                                    </Button>
                                    <Button variant='success' onClick={handleReminderModalReload}>
                                        Confirm
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </tbody>
                    </Table>

                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Utilities