import React, { useState, useEffect } from 'react'
import './FailedReconciliation.scss'
import { useParams } from 'react-router-dom';
import { Card, Table, Modal, Button, Form, FormControl, FormSelect } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import Header from '../Header';
import SideBar from '../SideBar';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../../serverUrl";
import { toast } from "react-toastify";


function FailedReconciliation() {
    // getting the id and finding the apparment details
    const { id } = useParams();
    // sidebar and header functions
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    const monthNames = [
        "january", "february", "march", "april", "may", "june",
        "july", "august", "september", "october", "november", "december"
    ];
    // houses within the apartment
    const [ctobTransactions, setCtobTransactions] = useState([])

    // get the houses and the tenants that are within that house
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const [landlordApartment, setLandlordApartment] = useState([])

    const [shortCodeApartment, setShortCode] = useState('')
    // from the localstorage get the logged in user and get the details of the selected apartment with the houses in the apartments and the tenants 
    useEffect(() => {


        // get the landlord details that are linked to the user
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);

                // get the failed transactions
                const fetchFailedTransactions = serverUrl.get(`/landlord/get-failed-reconciliation/?landlord_id=${landlordIds}`, config);
                const fetchMpesaCredentials = serverUrl.get(`/landlord/apartment-mpesa-credentials/?apartment=${id}`, config);

                Promise.all([fetchFailedTransactions, fetchMpesaCredentials])
                    .then(([failedTransactionsRes, mpesaCredentialsRes]) => {
                        const dataCtobFailed = failedTransactionsRes.data.results;
                        const shortCodeApartment = mpesaCredentialsRes.data.results[0].short_code;

                        const filteredTransactions = dataCtobFailed.filter(transaction => {
                            return transaction.business_shortcode === shortCodeApartment.toString();
                        });

                        setCtobTransactions(filteredTransactions);
                        setShortCode(shortCodeApartment.toString());
                    })
                    .catch(error => {
                        console.log(error);
                    });

                // using the landlord id get the apartments linked to the landlord
                const landlordApartmentPromises = landlordIds.map((landlordId) => {
                    return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                        .then((res) => res.data.results)
                        .catch((error) => {
                            console.log(error);
                            return [];
                        });
                });

                Promise.all(landlordApartmentPromises)
                    .then((results) => {
                        // i am merging the sub-arrays into a new array
                        const apartments = results.flat();
                        setLandlordApartment(apartments);
                        // getting the houses in the apartment
                        const houseCountPromises = apartments.map((apartment) => {
                            return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                                .then((res) => ({ apartmentId: apartment.id, houses: res.data.results }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartmentId: apartment.id, houses: [] };
                                });
                        });

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    // searching function and sorting functionalities
    const [sortType, setSortType] = useState("name");
    const [sortOrder, setSortOrder] = useState("asc");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSort = (sortField) => {
        setSortType(sortField);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const sortedData = ctobTransactions.sort((a, b) => {
        const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === "asc") {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredData = sortedData.filter((item) => {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });

    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    // modal for adding new transaction
    const [showModal, setShowModal] = useState(false)

    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    // done button reloading the page
    const handleCloseModalReload = () => {
        setShowModal(false);
        window.location.reload()
    };

    // form data to be sent to backend on adding the transaction
    const [formData, setFormData] = useState({
        transcation_type: "",
        bill_ref_number: "",
        overflow_amount: "",
        apartment: id,
        first_name: "",
        surname: "",
        transcation_amount: "",
        reconciled: true,
        transcation_time: "",
        phone_number: "",


    });
    // onchange functionality on adding the transaction
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChangeDate = (event) => {
        const { name, value } = event.target;

        // Check if the entered date is in the future
        const currentDate = new Date();
        const selectedDate = new Date(value);
        if (selectedDate > currentDate) {
            // Display an error message or handle the error as per your requirements
            toast.error('Error: Cannot select a future date');
            return;
        }

        // Check if the entered date is more than 6 months ago
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        if (selectedDate < sixMonthsAgo) {
            // Display an error message or handle the error as per your requirements
            toast.error('Error: Cannot select a date older than 6 months');
            return;
        }

        // Update the form data if the entered date is valid
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // submit of adding a new transaction
    function handleSubmit(e) {

        // preventing from redirecting to other pages
        e.preventDefault();

        // get user details so that you can use it from the access tokens
        const accessToken = localStorage.getItem("authTokens");

        // decode the tokens so that you can get the user_id
        const decodedToken = jwt_decode(accessToken);
        const userId = decodedToken.user_id;

        // get the access token
        const parsedTokens = JSON.parse(accessToken);
        const access = parsedTokens.access;

        // header's access token
        const config = {
            headers: { Authorization: `Bearer ${access}` }
        }


        const data = {
            transcation_type: formData.transcation_type,
            bill_ref_number: formData.bill_ref_number,
            overflow_amount: formData.overflow_amount,
            transcation_amount: formData.transcation_amount,
            apartment: id,
            first_name: formData.first_name,
            surname: formData.surname,
            reconciled: true,
            transcation_time: formData.transcation_time,
            phone_number: formData.phone_number,

        };

        // serverUrl.post(`/landlord/c2btranscation/`, data, config)
        //     .then((res) => {
        //         toast.success('Transaction Succesfully!')
        //     })
        //     .catch((error) => {
        //         toast.success('Error in Adding IUJTransaction!')

        //         console.log(error)
        //     })

    }

    // reconciliation
    const [showModalReconcile, setShowModalReconcile] = useState(false)
    const [selectedDefaulterId, setSelectedDefaulterId] = useState(null);

    const handleOpenModalReconcile = (defaulterId) => {
        setSelectedDefaulterId(defaulterId);
        setShowModalReconcile(true);
    };
    const handleCloseModalReconcile = () => {
        setShowModalReconcile(false)
    }

    const [selectedApartmentHouse, setSelectedApartmentHouse] = useState(null);
    const [selectedApartmentId, setSelectedApartmentId] = useState("");
    const [selectedUtilities, setSelectedUtilities] = useState([])
    const [apartmentHouse, setApartmentHouse] = useState([]);

    // get the list of the apartments and there houses
    function handleApartmentSelect(selectedApartmentId) {
        setSelectedApartmentId(selectedApartmentId)
        // get the houses to be listed when apartment is selected
        serverUrl
            .get(`/landlord/react-house-apartment-tenant/?apartment_id=${selectedApartmentId}`, config)
            .then((res) => {
                // Filter houses with occupancy status "vacant"
                const vacantHouses = res.data.results.filter((house) => house.status === 'occupied');

                // Set the filtered vacant houses in the state
                setApartmentHouse(vacantHouses);
            })
            .catch((error) => {
                // Handle the error, e.g., display an error message to the user
                console.log("Error fetching apartment data:", error);
                // You can also update state to indicate the error, if needed
            });

        // get the utilities to be displayed for that apartment
        serverUrl.get(`/landlord/expense/?apartment_id=${selectedApartmentId}`, config)
            .then((res) => {
                setSelectedUtilities(res.data.results)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // handle when the house is selected
    const [houseSelectedId, setHouseSelectedId] = useState("");
    const [selectedHouseId, setSelectedHouseId] = useState(null);
    const [houseSelectedName, setHouseSelectedName] = useState("")
    const [invoices, setInvoices] = useState([])


    function handleHouseSelect(selectedHouseid) {
        setHouseSelectedId(selectedHouseid)

        // Find the selected house object based on its ID
        const selectedHouse = apartmentHouse.find((house) => house.house_id === parseInt(selectedHouseid));

        // Log the ID and house_number
        if (selectedHouse) {
            setHouseSelectedName(selectedHouse.house)
        }


        serverUrl.get(`/landlord/tenant-invoice/?house_id=${selectedHouseid}&reference=${selectedCategory}`, config)
            .then((res) => {
                // Filter invoices with status not equal to "paid"
                const unpaidInvoices = res.data.results.filter(invoice => invoice.status !== "paid");
                setInvoices(unpaidInvoices);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    // handle when the type of payment is selected
    const [selectedMethod, setSelectedMethod] = useState("");
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

    const handlePaymentSelect = (e) => {
        setSelectedMethod(e.target.value);
        setSelectedPaymentMethod("");
    };

    const [invoiceSelectedId, setInvoiceSelectedId] = useState("");

    // the invoice selected
    function handleInvoiceSelect(selectedInvoiceid) {
        setInvoiceSelectedId(selectedInvoiceid)

    }
    // handle when the utility is selected
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedUtility, setSelectedUtility] = useState("");

    const handleCategorySelect = (e) => {
        setSelectedCategory(e.target.value);
        setSelectedUtility("");
    };
    // form data for tenant details ratings
    const [formDataPayment, setFormDataPayment] = useState({
        apartment: "",
        house_number: "",
        category: '',
        utility_type: '',
        source: '',
        amount: '',
        paid_date: ''
    });
    // onchange functionality on rating tenant
    const handleChangePayment = (event) => {
        const { name, value } = event.target;

        setFormDataPayment((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

    }
    const exportCSV = () => {
        const csvData = [
            ['Paid Number', 'Paid By', 'Amount', 'Transaction Id', 'Date Paid', 'Failed Reason'],
            ...ctobTransactions.map((item) => [item.phone_number, item.first_name, item.transcation_amount, item.transcation_id, item.transcation_time, item.reason_for_failed_reconciliation]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'failed_reconciliation.csv');
    };
    // save the data to the backend on the payment
    function handleSaveReconcile(e) {
        // prevent form from redirecting to another page
        e.preventDefault();
        // get the reconciliation
        serverUrl.get(`/landlord/reconcile_transactions/?c2btransaction_id=${selectedDefaulterId}&house_id=${houseSelectedId}&payment_category=${selectedCategory}&expense_id=${selectedUtility}&invoice_month=${invoiceSelectedId}`, config)
            .then((res) => {
                toast.success('Succesfully Reconciled Transaction!')
                window.location.reload()
            })
            .catch((error) => {
                console.log(error)
            })
    }
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='reconciliationReport'>
                <div className='tasks ' >
                    <h2 style={{ color: "#00cec9" }}>Failed Reconciliation</h2>

                    <div className='d-flex justify-content-between'>

                        <div>
                            <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
                            <Button className='btn btn-sm text-white me-3 d-none ' onClick={handleOpenModal}>Add Transaction</Button>
                            {/* modal used in adding the transaction */}
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add A New Transaction</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form onSubmit={handleSubmit}>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="text"
                                                name="bill_ref_number"
                                                value={formData.bill_ref_number}
                                                onChange={handleChange}
                                                placeholder={"House Number"}
                                                required
                                            />
                                        </div>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="number"
                                                name="phone_number"
                                                value={formData.phone_number}
                                                onChange={handleChange}
                                                placeholder={"Phone Number"}
                                                required
                                            />
                                        </div>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="number"
                                                name="overflow_amount"
                                                value={formData.overflow_amount}
                                                onChange={handleChange}
                                                placeholder={"Overflow Amount"}
                                                required
                                            />
                                        </div>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="number"
                                                name="transcation_amount"
                                                value={formData.transcation_amount}
                                                onChange={handleChange}
                                                placeholder={"Paid Amount"}
                                                required
                                            />
                                        </div>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="date"
                                                name="transcation_time"
                                                value={formData.transcation_time}
                                                onChange={handleChangeDate}
                                                placeholder={"Date Paid"}
                                                required
                                            />
                                        </div>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="text"
                                                name="transcation_type"
                                                value={formData.transcation_type}
                                                onChange={handleChange}
                                                placeholder={"Transaction Type"}
                                                required
                                            />

                                        </div>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="text"
                                                name="first_name"
                                                value={formData.first_name}
                                                onChange={handleChange}
                                                placeholder={"First Name"}
                                                required
                                            />

                                        </div>
                                        <div className="input-icons">
                                            <i className="fa fa-hashtag icon"></i>
                                            <input
                                                className="input-field"
                                                type="text"
                                                name="surname"
                                                value={formData.surname}
                                                onChange={handleChange}
                                                placeholder={"Surname"}
                                                required
                                            />

                                        </div>


                                        <Button variant="primary" type="submit" className="nextBtn mt-3" >
                                            Save
                                        </Button>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>

                                    <Button variant="success" onClick={handleCloseModalReload} >
                                        DONE
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Button className='btn btn-danger text-white d-none' >PDF <i className='bi bi-file-pdf-fill'></i></Button>
                        </div>
                        <Form className="searchBar">
                            <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                        </Form>
                    </div>

                    <Table className='table'>
                        <thead className='underline'>
                            <th onClick={() => handleSort("phone_number")}>Paid Number<i className='bi bi-chevron-expand'></i></th>
                            <th onClick={() => handleSort("first_name")}>Paid By<i className='bi bi-chevron-expand'></i></th>
                            <th onClick={() => handleSort("bill_ref_number")}>Unit<i className='bi bi-chevron-expand'></i></th>
                            <th onClick={() => handleSort("transcation_amount")}>Amount<i className='bi bi-chevron-expand'></i></th>
                            <th onClick={() => handleSort("transcation_id")}>Transaction Id<i className='bi bi-chevron-expand'></i></th>
                            <th onClick={() => handleSort("transcation_time")}>Date Paid<i className='bi bi-chevron-expand'></i></th>
                            <th style={{ width: "30%" }} onClick={() => handleSort("reason_for_failed_reconciliation")}>Failed Reason<i className='bi bi-chevron-expand'></i></th>
                            <th>Reconcile Transaction</th>
                        </thead>
                        <tbody>
                            {displayedItems.map((Defaulter, index) => (
                                <tr key={index}>
                                    <td>{Defaulter.phone_number}</td>
                                    <td>{Defaulter.first_name}</td>
                                    <td>{Defaulter.bill_ref_number}</td>
                                    <td>{Defaulter.transcation_amount}</td>
                                    <td>{Defaulter.transcation_id}</td>
                                    <td>{Defaulter.transcation_time.substr(6, 2)}-{Defaulter.transcation_time.substr(4, 2)}-{Defaulter.transcation_time.substr(0, 4)}</td>
                                    <td>{Defaulter.reason_for_failed_reconciliation}</td>
                                    <td onClick={() => handleOpenModalReconcile(Defaulter.id)} className='btn btn-success bg-success btn-sm text-white mt-3'>Reconcile</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {/* modal for reconciliation */}
                    <Modal show={showModalReconcile} onHide={handleCloseModalReconcile}>
                        <Modal.Header className='bg-primary text-white' closeButton>
                            <Modal.Title>Reconcile</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={handleSaveReconcile}>
                                <Form.Select
                                    size='sm'
                                    className='formProperty'
                                    value={selectedApartmentHouse}
                                    onChange={(e) => handleApartmentSelect(e.target.value)}

                                >
                                    <option value="">Select Apartment</option>
                                    {landlordApartment.map((landlord) => (
                                        <option key={landlord.id} value={landlord.id}>
                                            {landlord.name}
                                        </option>
                                    ))}
                                </Form.Select>

                                <Form.Select
                                    size='sm'
                                    className='formProperty'
                                    value={selectedHouseId} // Update this to the selected house ID
                                    onChange={(e) => handleHouseSelect(e.target.value)} // Update the selected house ID

                                >
                                    <option value="">Select House</option>
                                    {apartmentHouse.map((landlord) => (
                                        <option key={landlord.house_id} value={landlord.house_id}>
                                            {landlord.house} - {landlord.tenant}
                                        </option>
                                    ))}
                                </Form.Select>

                                {/* <Form.Select
                                size='sm'
                                className='formProperty'
                                value={selectedMethod}
                                onChange={handlePaymentSelect}
                               
                            >
                                <option value="" disabled>Select Payment Method</option>
                                <option value="cash">Cash</option>
                                <option value="direct-to-phone">Direct To Phone</option>
                            </Form.Select> */}


                                <Form.Select
                                    size='sm'
                                    className='formProperty'
                                    value={selectedCategory}
                                    onChange={handleCategorySelect}

                                >
                                    <option value="" disabled>Payment Category</option>
                                    <option value="expense">Utility</option>
                                    <option value="rent">Rent</option>
                                </Form.Select>

                                {selectedCategory === "expense" && (
                                    <Form.Select
                                        size='sm'
                                        className='formProperty'
                                        value={selectedUtility}
                                        onChange={(e) => setSelectedUtility(e.target.value)}
                                        style={{ width: "35vw", marginTop: "10px" }}
                                    >
                                        <option value="" disabled selected>Select Utility</option>
                                        {selectedUtilities.map((utility) => (
                                            <option key={utility.id} value={utility.id}>
                                                {utility.title}
                                            </option>
                                        ))}

                                    </Form.Select>

                                )}

                                <Form.Select
                                    size='sm'
                                    className='formProperty'
                                    value={invoiceSelectedId}
                                    onChange={(e) => handleInvoiceSelect(e.target.value)}

                                >
                                    <option value="">Month Invoice Payment</option>
                                    {monthNames.map((month, index) => {
                                        const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);
                                        const hasInvoiceForMonth = invoices.some(invoice => invoice.month.toLowerCase() === month);

                                        return (
                                            < >
                                                {hasInvoiceForMonth ? (
                                                    invoices.map((invoice) => (
                                                        invoice.month.toLowerCase() === month ? (
                                                            <option
                                                                key={invoice.id}
                                                                value={invoice.month}
                                                                style={{ color: invoice.status === "unpaid" ? 'red' : 'orange' }}
                                                            >
                                                                {invoice.month} - {invoice.status} - {invoice.amount}
                                                            </option>
                                                        ) : null
                                                    ))
                                                ) : (
                                                    <option key={month} value={month}>
                                                        {formattedMonth}
                                                    </option>
                                                )}
                                            </>
                                        );
                                    })}
                                </Form.Select>

                                <Button className='loginBtn mt-3 ms-4' type='submit'>Save</Button>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* get the display of the items 5 per page */}
                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${ctobTransactions.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FailedReconciliation