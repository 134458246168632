import React, { useState, useEffect } from 'react'
import Header from './Header'
import SideBar from './SideBar'
import './Messages.scss'
import { Card, Col, Row, Form, Table, Button, Image, Modal, Dropdown } from 'react-bootstrap'
import Total from '../../assets/icons/check.png'
import Sent from '../../assets/icons/message.png'
import Outbox from '../../assets/icons/outbox.png'
import Templates from '../../assets/icons/templates.png'

import MessageTemplates from './MessageTemplates'
import MessagesOutbox from './MessagesOutbox'

// auth imports
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';


function Messages() {
    const [showSidebar, setShowSidebar] = useState(true);

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // loading status
    const [isLoading, setIsLoading] = useState(true)

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/message.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('communicationImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);

    const [addTemplate, setAddTemplate] = useState(false);

    const handleReminderModalOpen = () => {
        setAddTemplate(true);
    };

    const handleReminderModalClose = () => {
        setAddTemplate(false);
    };

    const [topUpModal, setTopUpModal] = useState(false);

    const handleTopUpModalOpen = () => {
        setTopUpModal(true);
    };

    const handleTopUpModalClose = () => {
        setTopUpModal(false);
    };


    const accessToken = localStorage.getItem('authTokens');
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` },
    };
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const [landlordApartment, setLandlordApartment] = useState([])
    const [totalBalance, setTotalBalance] = useState('')
    const [totalSent, setTotalSent] = useState('')
    const [totalTemplates, setTotalTemplate] = useState('')
    const [totalOutbox, setTotalOutbox] = useState('')
    const [landlordId, setLandlordId] = useState(null)
    // get the house selected by user
    // useEffect(() => {
    //     setIsLoading(true);
    //     // get the landlord details that are linked to the user
    //     serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
    //         .then((res) => {
    //             // get the landlord Id
    //             const landlordIds = res.data.results.map((landlord) => landlord.id);
    //             setLandlordId(landlordIds.toString())
    //             // get the landlord total sms
    //             serverUrl.get(`/communication/get-sms-balance/?landlord_id=${landlordIds.toString()}`, config)
    //                 .then((res) => {
    //                     const total_balance = res.data.total_sms_bought - res.data.total_sms_tenant
    //                     setTotalBalance(total_balance)
    //                     setTotalSent(res.data.total_sms_tenant)
    //                 })
    //                 .catch((error) => {
    //                     console.log(error)
    //                 })
    //             // get the total number of sms templates
    //             serverUrl.get(`/communication/get_landlord_message_templates/?landlord_id=${landlordIds.toString()}`, config)
    //                 .then((res) => {
    //                     setTotalTemplate(res.data.count)
    //                 })
    //                 .catch((error) => {
    //                     console.log(res)
    //                 })

    //             // get the total number of outbox messages
    //             serverUrl.get(`/communication/get-landlord-messages/?landlord_id=${landlordIds.toString()}`, config)
    //                 .then((res) => {
    //                     setTotalOutbox(res.data.count)
    //                 })
    //                 .catch((error) => {
    //                     console.log(error)
    //                 })
    //             // using the landlord id get the apartments linked to the landlord
    //             const landlordApartmentPromises = landlordIds.map((landlordId) => {
    //                 return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
    //                     .then((res) => res.data.results)
    //                     .catch((error) => {
    //                         console.log(error);
    //                         return [];
    //                     });
    //             });

    //             Promise.all(landlordApartmentPromises)
    //                 .then((results) => {
    //                     // i am merging the sub-arrays into a new array
    //                     const apartments = results.flat();
    //                     setLandlordApartment(apartments);
    //                     // getting the houses in the apartment
    //                     const houseCountPromises = apartments.map((apartment) => {
    //                         return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
    //                             .then((res) => ({ apartmentId: apartment.id, houses: res.data.results }))
    //                             .catch((error) => {
    //                                 console.log(error);
    //                                 return { apartmentId: apartment.id, houses: [] };
    //                             });
    //                     });


    //                 })
    //                 .catch((error) => {
    //                     console.log(error);
    //                     setIsLoading(false)

    //                 });
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }, [config, userId])
    useEffect(() => {
        const fetchLandlordData = async () => {
            try {
                setIsLoading(true);

                // Step 1: Get landlord details linked to the user
                const landlordResponse = await serverUrl.get(
                    `/landlord/landlord/?user_id=${userId}`,
                    config
                );
                const landlordIds = landlordResponse.data.results.map((landlord) => landlord.id);
                setLandlordId(landlordIds.toString());

                // Step 2: Get landlord SMS balance
                const smsResponse = await serverUrl.get(
                    `/communication/get-sms-balance/?landlord_id=${landlordIds.toString()}`,
                    config
                );
                setTotalBalance(
                    smsResponse.data.total_sms_bought - smsResponse.data.total_sms_tenant
                );
                setTotalSent(smsResponse.data.total_sms_tenant);

                // Step 3: Get the total number of SMS templates
                const templatesResponse = await serverUrl.get(
                    `/communication/get_landlord_message_templates/?landlord_id=${landlordIds.toString()}`,
                    config
                );
                setTotalTemplate(templatesResponse.data.count);

                // Step 4: Get the total number of outbox messages
                const outboxResponse = await serverUrl.get(
                    `/communication/get-landlord-messages/?landlord_id=${landlordIds.toString()}`,
                    config
                );
                setTotalOutbox(outboxResponse.data.count);

                // Step 5: Get apartments linked to the landlord
                const apartmentPromises = landlordIds.map((id) =>
                    serverUrl
                        .get(`/landlord/landlord-apartment_name/?landlord_id=${id}`, config)
                        .then((res) => res.data.results)
                        .catch(() => [])
                );
                const apartmentResults = await Promise.all(apartmentPromises);
                const apartments = apartmentResults.flat();
                setLandlordApartment(apartments);

                // Step 6: Get houses linked to each apartment (if needed)
                const housePromises = apartments.map((apartment) =>
                    serverUrl
                        .get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                        .then((res) => ({ apartmentId: apartment.id, houses: res.data.results }))
                        .catch(() => ({ apartmentId: apartment.id, houses: [] }))
                );
                const houseResults = await Promise.all(housePromises);
                console.log(houseResults); // Process house results if required
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchLandlordData();
    }, [userId, config]);

    const [selectedApartmentId, setSelectedApartmentId] = useState("");
    const [apartmentHouse, setApartmentHouse] = useState([]);
    const [selectedApartmentHouse, setSelectedApartmentHouse] = useState(null);
    const [selectedHouseId, setSelectedHouseId] = useState(null);
    const [houseSelectedId, setHouseSelectedId] = useState("");
    const [houseSelectedName, setHouseSelectedName] = useState("")
    const [selectedRecipientOption, setSelectedRecipientOption] = useState("Choose Recipients");



    // get the houses for the selected apartment
    function handleApartmentSelect(selectedApartmentId) {
        setSelectedApartmentId(selectedApartmentId)
        // get the houses to be listed when apartment is selected
        serverUrl
            .get(`/landlord/react-house-apartment-tenant/?apartment_id=${selectedApartmentId}`, config)
            .then((res) => {
                // Filter houses with occupancy status "vacant"
                const vacantHouses = res.data.results.filter((house) => house.status === 'occupied');

                // Set the filtered vacant houses in the state
                setApartmentHouse(vacantHouses);
            })
            .catch((error) => {
                // Handle the error, e.g., display an error message to the user
                console.log("Error fetching apartment data:", error);
                // You can also update state to indicate the error, if needed
            });
    }

    function handleHouseSelect(selectedHouseid) {
        setHouseSelectedId(selectedHouseid)

        // Find the selected house object based on its ID
        const selectedHouse = apartmentHouse.find((house) => house.house_id === parseInt(selectedHouseid));

        // Log the ID and house_number
        if (selectedHouse) {
            setHouseSelectedName(selectedHouse.house)
        }
    }

    const [formData, setFormData] = useState({
        subject: "",
        message: "",

    });

    //   handling the change of the data in the form
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    // submit the composed message
    function handleComposeMessage(e) {
        // prevent form from redirecting to another page
        e.preventDefault();

        // post the data to the backend
        const data = {
            message: formData.message,
            subject: formData.subject,


        }
        // check the conditions of the selectionfirst so that you can post the data correctly
        if (selectedRecipientOption === "All Properties") {
            serverUrl.post(`/communication/send-landlord-messages/?landlord_id=${landlordId}&landlord_user_id=${userId}`, data, config)
                .then((res) => {
                    toast.success('Successfully sent the message to all the tenants in all of your apartments')
                    setFormData({
                        subject: "",
                        message: "",
                    })
                    handleReminderModalClose()
                })
                .catch((error) => {
                    console.log(error)
                })

        } else if (selectedRecipientOption === "One Property") {
            serverUrl.post(`/communication/send-landlord-messages/?apartment_id=${selectedApartmentId}&landlord_id=${landlordId}&landlord_user_id=${userId}`, data, config)
                .then((res) => {
                    toast.success('Successfully sent the message to all the tenants in the property')
                    setFormData({
                        subject: "",
                        message: "",
                    })
                    handleReminderModalClose()
                })
                .catch((error) => {
                    console.log(error)
                })

        } else if (selectedRecipientOption === "One Unit") {
            serverUrl.post(`/communication/send-landlord-messages/?house_id=${houseSelectedId}&apartment_id=${selectedApartmentId}&landlord_id=${landlordId}&landlord_user_id=${userId}`, data, config)
                .then((res) => {
                    toast.success('Successfully sent the message to all the tenant in that house')
                    setFormData({
                        subject: "",
                        message: "",
                    })
                    handleReminderModalClose()
                })
                .catch((error) => {
                    console.log(error)
                })

        }

        // serverUrl.post(`/communication/send-landlord-messages/?house_id=1&apartment_id=1&landlord_id=2`,config)
    }


    const [formDataTopUp, setFormDataTopUp] = useState({
        phone: "",
        sms_unit: "",
        amount: "",
    });

    //   handling the change of the data in the form
    const handleChangeTopup = (event) => {
        const { name, value } = event.target;
        // setFormDataTopUp((prevFormDataTopUp) => ({
        //     ...prevFormDataTopUp,
        //     [name]: value,
        // }));
        // Validate the phone number
        if (name === 'phone') {
            // Remove leading zeros and non-digit characters
            const sanitizedValue = value.replace(/^0+|[^0-9]+/g, '');

            // Ensure it's 9 digits
            if (sanitizedValue.length <= 9) {
                setFormDataTopUp({
                    ...formDataTopUp,
                    [name]: sanitizedValue,
                });
            }
        } else {
            setFormDataTopUp({
                ...formDataTopUp,
                [name]: value,
            });
        }
    };
    const [selectedAmountSms, setSelectedAmountSms] = useState(0); // State to store the calculated amount

    useEffect(() => {
        // Calculate the amount based on different conditions
        let calculatedAmount = 0;

        if (formDataTopUp.sms_unit >= 1000001) {
            calculatedAmount = Math.round(formDataTopUp.sms_unit * 0.25);
        } else if (formDataTopUp.sms_unit >= 400000) {
            calculatedAmount = Math.round(formDataTopUp.sms_unit * 0.40);
        } else if (formDataTopUp.sms_unit >= 100000) {
            calculatedAmount = Math.round(formDataTopUp.sms_unit * 0.55);
        } else if (formDataTopUp.sms_unit >= 20000) {
            calculatedAmount = Math.round(formDataTopUp.sms_unit * 0.65);
        } else {
            calculatedAmount = Math.round(formDataTopUp.sms_unit * 0.80);
        }

        setSelectedAmountSms(calculatedAmount);
    }, [formDataTopUp.sms_unit]);


    // submit the top up message
    function handleTopupcredits(e) {
        // prevent form from redirecting to another page
        e.preventDefault();

        // post the data to the backend
        const data = {
            amount: parseInt(selectedAmountSms),
            sms_unit: parseInt(formDataTopUp.sms_unit),
            phone_number: formDataTopUp.phone,
            source: "web",
            landlord_id: parseInt(landlordId),
            apartment_id: parseInt(selectedApartmentId),
            user_id: userId,

        }
        // check the conditions of the selectionfirst so that you can post the data correctly
        if (selectedRecipientOption === "One Property") {
            serverUrl.post(`/communication/topup-credit/`, data, config)
                .then((res) => {
                    toast.success('Top up of credits is successfull')
                    setFormDataTopUp({
                        amount: "",
                        phone: "",
                    })
                    handleTopUpModalClose()
                })
                .catch((error) => {
                    console.log(error)
                })

        }

        // serverUrl.post(`/communication/send-landlord-messages/?house_id=1&apartment_id=1&landlord_id=2`,config)
    }
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {
                showSidebar && (
                    <SideBar />
                )
            }
            {/* the cards displayed after the filtered calendar */}
            <div className='messagesPage' >
                <div className='messageTopImage' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <p className='properties'>COMMUNICATE</p>
                    <Button variant='warning' className='text-white' style={{ marginTop: '-5rem' }} onClick={handleReminderModalOpen}>COMPOSE</Button>
                </div>

                <div className='staticCards'>
                    <Card className='rentDue' style={{ marginTop: "-3rem" }}>
                        <Card.Body>
                            <Image src={Total}></Image>

                            <div className='float-left'>
                                <span>Total SMS Balance</span>
                                <div className='d-flex mt-2'>
                                    <p>{totalBalance}</p>
                                    <Button className='btn-sm btn btn-success opacity-75' style={{ marginLeft: "2rem", height: "5vh" }} onClick={handleTopUpModalOpen}>Top Up</Button>

                                </div>

                            </div>
                        </Card.Body>

                    </Card>

                    <Card className='defaulters' style={{ marginTop: "-3rem" }}>
                        <Card.Body>
                            <Image src={Sent}></Image>

                            <div className='float-left'>
                                <span className="defaultersText">Total SMS Sent</span>
                                <div className='d-flex mt-2'>
                                    <p>{totalSent}</p>
                                </div>

                            </div>
                        </Card.Body>

                    </Card>

                    <Card className='income' style={{ marginTop: "-3rem" }}>
                        <Card.Body>
                            <Image src={Templates}></Image>

                            <div className='float-left'>
                                <span>Total Templates SMS</span>
                                <div className='d-flex mt-2'>
                                    <p>{totalTemplates}</p>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>

                    <Card className='occupacy' style={{ marginTop: "-3rem" }}>
                        <Card.Body>
                            <Image src={Outbox}></Image>


                            <div className='float-left'>
                                <span>Total Outbox SMS</span>
                                <div className='d-flex mt-2'>
                                    <p>{totalOutbox}</p>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>
                </div>

                {/* modal for topup */}
                <Modal show={topUpModal} onHide={handleTopUpModalClose}>
                    <Modal.Header className='bg-primary text-white' closeButton>

                        <Modal.Title>Top up your credits</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>

                        <Form onSubmit={handleTopupcredits}>
                            <select
                                className='input-field mb-4'
                                style={{ marginLeft: "1.2rem", width: "92%" }}
                                value={selectedRecipientOption}
                                onChange={(e) => setSelectedRecipientOption(e.target.value)}
                            >
                                <option>Choose Recipients</option>
                                <option>All Properties</option>
                                <option>One Property</option>
                            </select>

                            {selectedRecipientOption === "All Properties" && (
                                <>

                                </>
                            )}

                            {selectedRecipientOption === "One Property" && (
                                <>
                                    <select className='input-field mb-4'
                                        style={{ marginLeft: "1.2rem", width: "92%" }}
                                        value={selectedApartmentHouse}
                                        onChange={(e) => handleApartmentSelect(e.target.value)}
                                    >
                                        <option value="">Choose Property</option>
                                        {landlordApartment.map((landlord) => (
                                            <option key={landlord.id} value={landlord.id}>
                                                {landlord.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            )}




                            <>
                                <div className="input-icons  mt-2 mb-1">
                                    <i class="bi bi-chat-left-dots icon"></i>
                                    <input className="input-field" placeholder='Enter phone number' name='phone' value={formDataTopUp.phone} onChange={handleChangeTopup}></input>
                                </div>

                                <div className="input-icons">
                                    <i class="bi bi-send-plus icon"></i>
                                    <input className="input-field" placeholder='Enter SMS amount' name='sms_unit' value={formDataTopUp.sms_unit} onChange={handleChangeTopup} ></input>
                                </div>

                                {/* the sms calculation amount use the sms credit calculation */}
                                {formDataTopUp.sms_unit !== '' ?
                                    <p>This will cost you {selectedAmountSms}</p>
                                    :
                                    <></>
                                }

                            </>

                            <Button variant='success' type='submit' className='float-end'>
                                Top Up
                            </Button>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between d-none">
                        <Button variant='danger' onClick={handleTopUpModalClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                {/* modal for compose message*/}
                <Modal show={addTemplate} onHide={handleReminderModalClose}>
                    <Modal.Header className='bg-primary text-white' closeButton>

                        <Modal.Title>Compose Message</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>

                        <Form onSubmit={handleComposeMessage}>
                            <select
                                className='input-field mb-4'
                                style={{ marginLeft: "1.2rem", width: "92%" }}
                                value={selectedRecipientOption}
                                onChange={(e) => setSelectedRecipientOption(e.target.value)}
                            >
                                <option>Choose Recipients</option>
                                <option>All Properties</option>
                                <option>One Property</option>
                                <option>One House</option>
                            </select>

                            {selectedRecipientOption === "All Properties" && (
                                <>

                                </>
                            )}

                            {selectedRecipientOption === "One Property" && (
                                <>
                                    <select className='input-field mb-4'
                                        style={{ marginLeft: "1.2rem", width: "92%" }}
                                        value={selectedApartmentHouse}
                                        onChange={(e) => handleApartmentSelect(e.target.value)}
                                    >
                                        <option value="">Choose Property</option>
                                        {landlordApartment.map((landlord) => (
                                            <option key={landlord.id} value={landlord.id}>
                                                {landlord.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            )}

                            {selectedRecipientOption === "One Unit" && (
                                <>
                                    <select className='input-field mb-4'
                                        style={{ marginLeft: "1.2rem", width: "92%" }}
                                        value={selectedApartmentHouse}
                                        onChange={(e) => handleApartmentSelect(e.target.value)}
                                    >
                                        <option value="">Choose Apartment</option>
                                        {landlordApartment.map((landlord) => (
                                            <option key={landlord.id} value={landlord.id}>
                                                {landlord.name}
                                            </option>
                                        ))}
                                    </select>

                                    {/* if user has selected one house option display this part */}
                                    <select className='input-field mb-2'
                                        value={selectedHouseId}
                                        onChange={(e) => handleHouseSelect(e.target.value)}
                                        style={{ marginLeft: "1.2rem", width: "92%" }}
                                    >

                                        <option value="">Choose House</option>
                                        {apartmentHouse.map((landlord) => (
                                            <option key={landlord.house_id} value={landlord.house_id}>
                                                {landlord.house} - {landlord.tenant}
                                            </option>
                                        ))}
                                    </select>

                                </>
                            )}


                            <>
                                <div className="input-icons  mt-2 mb-1 d-none">
                                    <i class="bi bi-chat-left-dots icon"></i>
                                    <textarea className="input-field" rows={1} placeholder='Enter Title' name='subject' value={formData.subject} onChange={handleChange}></textarea>
                                </div>

                                <div className="input-icons">
                                    <i class="bi bi-send-plus icon"></i>
                                    <textarea className="input-field" placeholder='Enter Message' name='message' value={formData.message} onChange={handleChange} ></textarea>
                                </div>
                            </>

                            <Button variant='success' type='submit' className='float-end'>
                                Send
                            </Button>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between d-none">
                        <Button variant='danger' onClick={handleReminderModalClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>

                <MessageTemplates />
                <MessagesOutbox />
            </div >
        </>
    )
}

export default Messages