import React, { useState, useEffect } from 'react'
import { Image, Card, FormSelect, Button, Row, Col, Form, FormControl, Modal, Dropdown, Table } from 'react-bootstrap';
import Header from './Header'
import './SelectedProperty.scss'
import SideBar from './SideBar'
import Apartment from '../../assets/img/apartment6.jpg'
import House5 from '../../assets/img/house5.jpg'
import { useParams, Link } from "react-router-dom";
import Book from '../../assets/img/book.png'
import Due from '../../assets/img/due-date.png'
import Percentage from '../../assets/img/discount.png'
import Income from '../../assets/img/salary.png'
import loaderImage from '../../assets/img/logo.png'
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import Rental from '../../assets/img/rental.png'
import Safety from '../../assets/img/security.png'
import Property from '../../assets/img/property.png'
import ApartmentLiked from '../../assets/img/apartment.png'
import { saveAs } from 'file-saver';

// format the number so that you can get shorted versions of it
const formatNumber = (number) => {
  if (number >= 1000) {
    const roundedNumber = Math.floor(number / 1000);
    return `${roundedNumber}k`;
  }
  return number;
};


function SelectedProperty() {
  const [showSidebar, setShowSidebar] = useState(true);
  const { id } = useParams();

  // loading status
  const [isLoading, setIsLoading] = useState(true)

  // apartment
  const [selectedApartment, setSelectedApartment] = useState([])
  const [apartmentImage, setApartmentImage] = useState("")

  // houses of the user
  const [house, setHouse] = useState([])
  const houseTenant = {}
  const [houseSetTenant, setHouseSetTenant] = useState({})
  const [rentStatus, setRentStatus] = useState({})


  const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());
  const [deleteHouse, setDeleteHouse] = useState(null);
  // image of the houses to be stored here
  const houseImage = {}
  const housePaymentStatus = {}
  const [houseSetImage, setHouseSetImage] = useState(null)

  // card metrics
  const [landlordIncomeDue, setLandlordIncomeDue] = useState(0);
  const [apartmentIncome, setApartmentIncome] = useState(0);

  const [landlordPreviousOccupancy, setLandlordPreviousOccupancy] = useState(0);
  const [landlordCurrentOccupancy, setLandlordCurrentOccupancy] = useState(0);
  const [landlordPercentagePaid, setLandlordPercentagePaid] = useState(0);
  const [monthIncome, setMonthIncome] = useState(0);
  const [defaulters, setDefaulters] = useState(0);
  const [apartmentExpectedIncome, setApartmentExpectedIncome] = useState(0);
  const [apartmentReceivedIncome, setApartmentReceivedIncome] = useState(0);
  const [amenities, setAmenities] = useState([])
  const [amenitiesApartment, setAmenitiesApartment] = useState([])
  // for calculations purposes
  const [monthIncomeOldSchool, setMonthIncomeOldSchool] = useState(0);
  const [previousmonthIncomeOldSchool, setPreviousMonthIncomeOldSchool] = useState(0);

  const [accountNumbers, setAccountNumber] = useState([])

  // get the current month so that i can filter out the total income
  const months = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(new Date().getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });

  // Get the current month automatically
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];

  // Get the previous month automatically
  const previousMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
  const previousMonth = months[previousMonthIndex];
  const currentYear = new Date().getFullYear();

  // get the months till today
  const currentDateFilter = new Date();

  const monthsTillToday = Array.from({ length: currentMonthIndex + 1 }, (_, i) => {
    const month = new Date(currentDateFilter.getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });

  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  // get the months and years filtered out
  const [monthFilterActive, setMonthFilterActive] = useState("")

  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }
  // decoding the token so that i can get the user id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;

  // add a new amenity in the apartment
  const [showModalAmenityAdd, setShowModalAmenity] = useState(false);
  const handleOpenModalAmenityAdd = () => {
    setShowModalAmenity(true);
  };

  const handleCloseModalAmenityAdd = () => {
    setShowModalAmenity(false);
  };

  // edit the amenities
  const [showModalAmenityEdit, setShowModalAmenityEdit] = useState(false)
  const handleOpenModalAmenityEdit = () => {
    setShowModalAmenityEdit(true);
  };

  const handleCloseModalAmenityEdit = () => {
    setShowModalAmenityEdit(false);
  };

  const handleCloseModalAmenityEditReload = () => {
    window.location.reload()

  };

  // delete the selected amenities

  const [showModalAmenityDelete, setShowModalAmenityDelete] = useState(false)
  const handleOpenModalAmenityDelete = () => {
    setShowModalAmenityDelete(true);
  };

  const handleCloseModalAmenityDelete = () => {
    setShowModalAmenityDelete(false);
  };


  // randomise for each House
  const [backgroundImages, setBackgroundImages] = useState({});

  // the handle for the month selected
  const [selectedMonth, setSelectedMonth] = useState("");

  const handleMonthButtonClick = (month) => {
    setSelectedMonth(month);
    localStorage.setItem("monthProperty", month);
    setShowModal(false);
    window.location.reload();

  };

  useEffect(() => {


    const getRandomImage = () => {
      const usedImages = Object.values(backgroundImages);
      const availableImages = Array.from({ length: 21 }, (_, index) => index + 1);
      const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
      const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
      const imageUrl = `https://kodinyumba.app/media/Stock/House/house${randomNumber}.jpg`;
      return imageUrl;
    };

    const updateBackgroundImages = () => {
      const updatedImages = { ...backgroundImages };

      // Loop through each house and update the image if not set
      for (const unitDetail of house) {
        const houseId = unitDetail.house_id;
        if (!updatedImages[houseId]) {
          updatedImages[houseId] = getRandomImage();
        }
      }
      setBackgroundImages(updatedImages);
      setIsLoading(false);
    };

    updateBackgroundImages();
  }, [house]);



  // randomise the images for the apartment
  const [backgroundImageApartment, setBackgroundImageApartment] = useState('');

  useEffect(() => {
    const getRandomImage = () => {
      let storedImage = localStorage.getItem('backgroundImage');
      if (!storedImage) {
        storedImage = '';
      }

      let randomNumber = Math.floor(Math.random() * 10) + 1;
      const invalidNumbers = [0, -1];
      while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
        randomNumber = Math.floor(Math.random() * 10) + 1;
      }

      const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
      setBackgroundImageApartment(imageUrl);
      localStorage.setItem('backgroundImage', imageUrl);
      setIsLoading(false);
    };

    getRandomImage();
  }, []);


  // from the localstorage get the logged in user

  useEffect(() => {

    setIsLoading(true)
    // the share count
    serverUrl.get(`/landlord/viewers-favourites/?apartment=${id}`, config)
      .then((res) => {
        SetApartmentLiked(res.data.count)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

    // get the month selected
    let selectedMonthModalPrevious = localStorage.getItem('monthProperty');
    setMonthFilterActive(selectedMonthModalPrevious)


    // get the amenities that we have in our database
    serverUrl.get(`/landlord/amenities/`, config)
      .then((res) => {
        setAmenities(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })

    // get the amenities of each apartment
    serverUrl.get(`/landlord/react-amenity-icon/?apartment_id=${id}`, config)
      .then((res) => {
        setAmenitiesApartment(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })

    // get the landlord details that are linked to the user
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);

        // get the total number of defaulters in the properties
        serverUrl.get(`/landlord/get_defaulters_list/?apartment_id=${id}`, config)
          .then((res) => {
            setDefaulters(res.data.defaulters)
          })
          .catch((error) => {
            console.log(error)
          })

        let selectedMonthModal = localStorage.getItem('monthProperty');

        if (!selectedMonthModal) {
          selectedMonthModal = currentMonth;
        }

        // get the occupancy percrentage
        serverUrl.get(`/landlord/get_rent_summary/?apartment_id=${id}&month=${selectedMonthModal}&year=${currentYear}`, config)
          .then((res) => {
            setLandlordPreviousOccupancy(res.data.previous_occupancy_percentage)
            setLandlordCurrentOccupancy(res.data.occupancy_percentage)
            setApartmentExpectedIncome(res.data.total_rent)
            setApartmentReceivedIncome(res.data.total_income)
            const formattedRentDue = res.data.rent_due
            const formattedRentIncome = res.data.total_income
            setApartmentIncome(formattedRentIncome)
            setLandlordIncomeDue(formattedRentDue)
            setLandlordPercentagePaid(res.data.percentage_paid)
            console.log(res.data)
          })
          .catch((error) => {
            console.log(error)
          })

        // get the total income of the properties in the current month
        // set to get the month filtered if not then display the current month

        serverUrl.get(`/landlord/react-rent-expeses-landlord/?landlord_id=${landlordIds}&month=${selectedMonthModal}`, config)
          .then((res) => {
            setMonthIncomeOldSchool(res.data.total_rent_income)
            const income = res.data.total_rent_income;
            const formattedIncome = income;
            setMonthIncome(formattedIncome)
          })
          .catch((error) => {
            console.log(error)
          })

        // using the landlord id get the apartments linked to the landlord
        landlordIds.map((landlordId) => {
          return serverUrl.get(`/landlord/apartment/?id=${id}`, config)
            .then((res) => {
              setSelectedApartment(res.data.results.find((apartment) => apartment.id === parseInt(id)))
              setIsLoading(false)
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false)
            });

        });

        // get the accounts of the apartment
        const accountCount = {}
        // get the total number of accounts in the apartments

        serverUrl.get(`/landlord/landlord-name-in-apartment/?apartment_id=${id}`, config)
          .then((res) => {
            setAccountNumber(res.data.count)


          }, [])

      })
      .catch((error) => {
        console.log(error);
      });


    // get the houses linked to the apartment
    setIsLoading(true)
    serverUrl.get(`/landlord/react-house-apartment-tenant/?apartment_id=${id}`, config)

      .then((res) => {
        setHouse(res.data.results)

        res.data.results.map((house) => {
          // get the rent status payment
          serverUrl
            .get(`/landlord/get-rent-status/?user_id=${house.tenant_user_id}`, config)
            .then((res) => {
              housePaymentStatus[house.tenant_user_id] = res.data.rent_status

            })
            .catch((error) => {
              console.log(error);
            });
          // map through the houses and get the image of each house

          serverUrl.get(`/landlord/house-images/?house_id=${house.house_id}`, config)
            .then((res) => {
              // console.log("imagehouse", res.data);

              // if the image is 0 then skip the part 
              if (res.data.count > 0) {
                houseImage[res.data.results[0].house] = res.data.results[0].image;
              }

              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        });

        setHouseSetImage(houseImage)
        setRentStatus(housePaymentStatus)
          ;
        // get the tenants of the houses
        res.data.results.map((house) => {
          serverUrl.get(`/tenant/house-tenant-details?house_id=${house.house_id}`, config)
            .then((res) => {

              houseTenant[house.id] = res.data.results[0].user
              setIsLoading(false); // Set loading state to false when data is fetched

            })
            .catch((error) => {
              console.log(error);

            });

        })
        setHouseSetTenant(houseTenant)
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false)
      });

    // get the image of the apartment selected
    setIsLoading(true)
    serverUrl.get(`/landlord/apartment-images/?apartment_id=${id}`, config)
      .then((res) => {
        // console.log("apartmentImage", res.data);

        // if not found then skip 
        if (res.data.count > 0) {
          setApartmentImage(res.data.results[0].image);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

  }, []);


  // get to match the amenitiesif they are from the database
  {
    amenitiesApartment.map((amenity) => {
      // Find the corresponding general amenity using the ID
      const matchingAmenity = amenities.find((item) => item.id === amenity.amenity);

    })
  }
  // either rent or service charge
  const [correctTerminology, setCorrectTerminology] = useState('')
  useEffect(() => {
    serverUrl.get(`landlord/react-rent-scharge?property_id=${id}`, config)
      .then((res) => {
        setCorrectTerminology(res.data.results)
      })
  }, [])

  // get the selected apartment from the database

  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }


  // modal settings for the editing of the user's details
  const [showModal, setShowModal] = useState(false);

  // filter Modal
  const [showModalFilter, setShowModalFilter] = useState(false);

  const [showModalHouse, setShowModalHouse] = useState(false)
  const [showModalDeleteHouse, setShowModalDeleteHouse] = useState(false);
  const [showModalTenant, setShowModalTenant] = useState(false);

  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowModalFilter(true);
  };

  // modal for filtering
  const handleOpenModalFilter = () => {
    setShowModalFilter(true);
  };

  const handleCloseModalFilter = () => {
    setShowModalFilter(false);
  };

  // modal for editing apartment
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // modal for adding new house
  const handleOpenModalHouse = () => {
    setShowModalHouse(true);
  };

  const handleCloseModalHouse = () => {
    setShowModalHouse(false);
  };

  // modal for deleting house
  const handleOpenModalDeleteHouse = unitDetail => {
    setDeleteHouse(unitDetail);
    setShowModalDeleteHouse(true);
  };
  const handleCloseModalDeleteHouse = () => {
    setShowModalDeleteHouse(false);
  };

  // add tenant modal 

  const handleOpenModalTenant = () => {
    setShowModalTenant(true);
  };
  const handleCloseModalTenant = () => {
    setShowModalTenant(false);
  };

  // done button reloading the page
  const handleCloseModalReload = () => {
    setShowModal(false);
    window.location.reload()
  };

  // form data to be sent to backend on editing the apartment
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    about: '',
  });

  // Use useEffect to update formData when selectedApartment changes
  useEffect(() => {
    if (selectedApartment) {
      setFormData({
        name: selectedApartment.name || '',
        location: selectedApartment.location || '',
        about: selectedApartment.about || '',
      });
    }
  }, [selectedApartment]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // form data to be sent to backend on eadding the House
  const [formDataHouse, setFormDataHouse] = useState({
    house_number: "",
    total_rooms: "",
    due_date: "",
    bedroom: "",
    deposit: "",
    bathroom: "",
    rent: ""
  });

  // form data for tenant details
  const [formDataTenant, setFormDataTenant] = useState({
    full_name: "",
    phone_number: ""
  });
  // counting the number of digits inserted on the phone number
  const digitCount = formDataTenant.phone_number.replace(/[^0-9]/g, "").length; // Count only digits



  // onchange functionality on adding house
  const handleChangeHouse = (event) => {
    const { name, value } = event.target;
    setFormDataHouse((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  // onchange functionality on adding tenant
  const handleChangeTenant = (event) => {
    const { name, value } = event.target;
    // limit phone number input to 10 digits
    if (name === "phone_number") {
      const digitOnly = value.replace(/[^0-9]/g, "");
      setFormDataTenant((prevFormData) => ({
        ...prevFormData,
        [name]: digitOnly.substring(0, 10),
      }));
    } else {
      setFormDataTenant((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  }

  // add amenity variables
  const [formDataAmenity, setFormDataAmenity] = useState({
    amenity: ""
  });
  // onchange functionality on adding new amenity
  const handleChangeAmenity = (e) => {
    const selectedAmenityId = e.target.value;
    setFormDataAmenity({ ...formDataAmenity, amenity: selectedAmenityId });
  };

  // the variables of data to be sent

  // edit amenity variables
  const [formDataAmenityEdit, setFormDataAmenityEdit] = useState({
    amenity: "",
    amenityId: ""
  });

  // onchange functionality on editing new amenity
  const handleChangeAmenityEdit = (name) => (event) => {
    const { value } = event.target;
    setFormDataAmenityEdit((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // delete amenity variables
  const [formDataAmenityDelete, setFormDataAmenityDelete] = useState({
    amenity: "",

  });
  // onchange functionality on delete new amenity
  const handleChangeAmenityDelete = (name) => (event) => {
    const { value } = event.target;
    setFormDataAmenityDelete((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // submit the added amenity for that house
  function handleAddAmenity(e) {
    // preventing from redirecting to other pages
    e.preventDefault();

    // the data that will be posted to the api is as follows
    const data = {
      apartment: id,
      amenity: formDataAmenity.amenity
    };

    //  now post the data 
    try {
      serverUrl

        //send the amenities of the apartment to the backend
        .post(`/landlord/apartment-amenities/`, data, config)
        .then((res) => {
          toast.success('Successfully Added The Amenity To The Apartment!');
          setFormDataAmenity({
            amenity: ""
          })
          // get the amenities of each apartment
          serverUrl.get(`/landlord/react-amenity-icon/?apartment_id=${id}`, config)
            .then((res) => {
              setAmenitiesApartment(res.data.results)
            })
            .catch((error) => {
              console.log(error)
            })
        })

        // displaying the messages of why the error 
        .catch((error) => {
          toast.error('Unexpected error')
          console.log('Unexpected error: ' + error.message);

        });

    } catch (error) {
      console.log(error);
    }

  }


  // submit the edited amenity for that house
  function handleEditAmenity(e) {
    // preventing from redirecting to other pages
    e.preventDefault();

    // the data that will be posted to the api is as follows
    const data = {
      apartment: id,
      amenity: formDataAmenityEdit.amenityId,
    };

    //  now post the data
    try {
      serverUrl

        //send the amenities of the apartment to the backend
        .patch(`/landlord/apartment-amenities/${formDataAmenityEdit.amenity}/`, data, config)
        .then((res) => {
          toast.success('Successfully Edited The Amenity To The Apartment!');
          setFormDataAmenityEdit({
            amenity: "",
            amenityId: ""
          })
          // get the amenities of each apartment
          serverUrl.get(`/landlord/react-amenity-icon/?apartment_id=${id}`, config)
            .then((res) => {
              setAmenitiesApartment(res.data.results)
            })
            .catch((error) => {
              console.log(error)
            })

        })

        // displaying the messages of why the error 
        .catch((error) => {
          toast.error('Unexpected error')
          console.log('Unexpected error: ' + error.message);

        });

    } catch (error) {
      console.log(error);
    }

  }

  // submit to delete the amenity
  function handleDeleteAmenity(e) {
    // preventing from redirecting to other pages
    e.preventDefault();

    //  now post the data
    try {
      serverUrl

        //send the amenities of the apartment to the backend
        .delete(`/landlord/apartment-amenities/${formDataAmenityDelete.amenity}/`, config)
        .then((res) => {
          toast.success('Successfully Deleted The Amenity Of The Apartment!');
          setFormDataAmenityDelete({
            amenity: ""
          })
          // get the amenities of each apartment
          serverUrl.get(`/landlord/react-amenity-icon/?apartment_id=${id}`, config)
            .then((res) => {
              setAmenitiesApartment(res.data.results)
            })
            .catch((error) => {
              console.log(error)
            })
        })

        // displaying the messages of why the error 
        .catch((error) => {
          toast.error('Unexpected error')
          console.log('Unexpected error: ' + error.message);

        });

    } catch (error) {
      console.log(error);
    }

  }

  // submit of editing apartment details
  function handleSubmit(e) {

    // preventing from redirecting to other pages
    e.preventDefault();

    // get user details so that you can use it from the access tokens
    const accessToken = localStorage.getItem("authTokens");

    // decode the tokens so that you can get the user_id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // header's access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }

    // the data that will be posted to the api is as follows
    const data = {
      name: formData.name,
      location: formData.location,
      about: formData.about
    };

    //  now post the data
    try {
      serverUrl

        //send the Apartment details using the following endpoint
        .put(`/landlord/apartment/${selectedApartment.id}/`, data, config)
        .then((res) => {

          toast.success('Successfully Changed the details of the apartment!');
          handleCloseModalReload()
        })

        // displaying the messages of why the error 
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            toast.error('Error while changing the details of the apartment' + error.message);
          } else {
            toast.error('Unexpected error')
            console.log('Unexpected error: ' + error.message);
          }
        });

    } catch (error) {
      console.log(error);
    }

  }



  // submit of adding a new unit/house
  function handleSubmitHouse(e) {

    // preventing from redirecting to other pages
    e.preventDefault();

    // get user details so that you can use it from the access tokens
    const accessToken = localStorage.getItem("authTokens");

    // decode the tokens so that you can get the user_id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // header's access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }

    // the data that will be posted to the api is as follows
    const dataHouse = {
      apartment: id,
      house_number: formDataHouse.house_number,
      total_rooms: formDataHouse.total_rooms,
      bedroom: formDataHouse.bedroom,
      bathroom: formDataHouse.bathroom,
      due_date: formDataHouse.due_date,
      token_prepaid_account_number: null,
      about: null,
      deposit: formDataHouse.deposit,
      rent: formDataHouse.rent
    };

    //  now post the data
    try {
      serverUrl

        //send the Apartment details using the following endpoint
        .post(`/landlord/house/`, dataHouse, config)
        .then((res) => {
          setFormDataHouse({
            house_number: "",
            total_rooms: "",
            due_date: "",
            bedroom: "",
            deposit: "",
            bathroom: "",
            rent: ""
          });
          // immediately get the new data
          serverUrl.get(`/landlord/react-house-apartment-tenant/?apartment_id=${id}`, config)
            .then((res) => {
              setHouse(res.data.results)
            })
            .catch((error) => {
              console.log(error)
            })
          toast.success('Successfully Added a new House!');
        })

        // displaying the messages of why the error 
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            console.log(error)
          } else {
            toast.error('Unexpected error')
            console.log('Unexpected error: ' + error.message);
          }
        });

    } catch (error) {
      console.log(error);
    }

  }

  // delete unit/house
  const handleDeleteHouse = () => {

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // the passed if from the modal
    const house_id = deleteHouse.house_id;
    const updateData = {
      is_visible: false,
    }
    // get the landlord details of the user
    serverUrl.patch(`/landlord/house/${house_id}/`, updateData, config)
      .then((res) => {
        setDeleteHouse(null);
        // immediately get the new data
        serverUrl.get(`/landlord/react-house-apartment-tenant/?apartment_id=${id}`, config)
          .then((res) => {
            setHouse(res.data.results)
          })
          .catch((error) => {
            console.log(error)
          })
        handleCloseModalDeleteHouse()
        toast.success("House Deleted!")
      }, [])
      .catch((error) => {
        console.log(error);
      });
  }

  // add new Tenant
  const handleAddTenant = () => {
    handleCloseModalHouse()
    handleOpenModalTenant()
  }
  // add the tenant to database
  function handleSubmitTenanat(e) {
    e.preventDefault();
    // get user details so that you can use it from the access tokens
    const accessToken = localStorage.getItem("authTokens");

    // decode the tokens so that you can get the user_id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // header's access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }

    // the data that will be posted to the api is as follows
    const dataTenant = {
      email: `${formDataTenant.phone_number}@mail.com`,
      full_name: formDataTenant.full_name,
      phone_number: formDataTenant.phone_number,
      password: formDataTenant.phone_number,

    };
    try {
      serverUrl

        //send the tenant details using the following endpoint
        .post(`/users/register/`, dataTenant)
        .then((res) => {
          const pendingData = {
            user: res.data.data.id,
            apartment: id
          }
          serverUrl.post(`/tenant/pending-tenant/`, pendingData, config)
            .then((res) => {
              console.log(res)
            })
            .catch((error) => {
              console.log(error.response.data);
            });
        })
        // displaying the messages of why the error 
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            toast.error(error.response.data.data.error);
          } else {
            toast.error('Unexpected error')
            console.log('Unexpected error: ' + error.message);
          }
        });

    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }

  }

  // send reminder for the landlord
  const [remindersModal, setRemindersModal] = useState(false)
  const [reminderValue, setReminderValue] = useState(null);

  // modal for filtering on the month
  const handleReminderModalOpen = () => {
    setRemindersModal(true);
  };

  const handleReminderModalClose = () => {
    setRemindersModal(false);
  };
  const handleReminderModalReload = () => {
    setRemindersModal(false);
    window.location.reload()
  };

  const [selectedOptionReminder, setSelectedOptionReminder] = useState(null);
  const [addTemplate, setAddTemplate] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [customSubject, setCustomSubject] = useState("Custom Message");
  const [customMessage, setCustomMessage] = useState("");
  const isTemplatesEmpty = messageTemplates.length === 0;
  const [editableSubject, setEditableSubject] = useState(true);
  const [editableMessage, setEditableMessage] = useState(true);

  const handleOptionSelect = (option) => {
    setSelectedOptionReminder(option);
    setAddTemplate(false)
  };

  const handleAddTemplate = () => {
    setSelectedOptionReminder('')
    setAddTemplate(true)
  }


  // Create a Set to store unique rent amounts
  const uniqueRentAmounts = new Set();

  // Filter houses array to get unique rent amounts chcek first if they have service charge
  const uniqueHouses = house.filter((rentHouse) => {
    if (rentHouse.house_rent === 0) {
      // If rent is 0, check for service charge
      if (!uniqueRentAmounts.has(rentHouse.service_charge)) {
        uniqueRentAmounts.add(rentHouse.service_charge);
        return true;
      }
    } else {
      // If rent is not 0, use it as the unique identifier
      if (!uniqueRentAmounts.has(rentHouse.house_rent)) {
        uniqueRentAmounts.add(rentHouse.house_rent);
        return true;
      }
    }
    return false;
  });


  // Create a Set to store unique occupancy status
  const uniqueHouseOccupancy = new Set();

  // Filter houses array to get unique rent amounts
  const uniquePaymentStatus = house.filter((occupancyHouse) => {
    if (!uniqueHouseOccupancy.has(occupancyHouse.rent_payment_status)) {
      uniqueHouseOccupancy.add(occupancyHouse.rent_payment_status);
      return true;
    }
    return false;
  });

  // filter the displayed data based on the rent selected
  const [selectedRent, setSelectedRent] = useState(null);

  // filter the displayed data based on the rent selected
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null);

  // filter based on th house occupancy
  const [selectedOccupancy, setOccupancy] = useState(null);


  // on search of the houses get to list the houses in the selected Apartment
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };

  const filteredData = house.filter((item) => {
    // Filter by selected rent
    if ((selectedRent && item.house_rent !== selectedRent && item.house_rent !== 0) || (selectedRent && item.service_charge !== selectedRent && item.service_charge !== 0)) {
      return false;
    }
    // if ((selectedRent !== 0 || item.house_rent === selectedRent) && (selectedRent === 0 || item.service_charge === selectedRent)) {
    //   return true;
    // } else {
    //   return false;
    // }

    // Filter by occupancy status
    if (selectedOccupancy && item.status !== selectedOccupancy) {
      return false;
    }

    // Filter by rent payment status
    if (selectedPaymentStatus && item.rent_payment_status !== selectedPaymentStatus) {
      return false;
    }

    // Filter by search query
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 12;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };


  // Perform the subtraction and determine the result for the income monthly
  let roundedResult = (apartmentReceivedIncome / apartmentExpectedIncome) * 100;


  let result = Math.round(roundedResult);
  // Check if the result is infinity and assign 0 if true
  if (!isFinite(result)) {
    result = 0;
  }


  // Determine the text color based on the result on the rent income ps it includes the expenses and the rent paid from the tenanat invoice
  let textColorClass = '';
  let sign = '';
  if (result > 0) {
    textColorClass = 'text-success';
    sign = '+';
  } else if (result < 0) {
    textColorClass = 'text-danger';
    sign = '-';
  }
  else if (result == 0) {
    textColorClass = 'text-success';
  }



  // send the reminder for the selected apartment
  const handleSendRemindersGeneral = () => {
    try {
      const accessToken = localStorage.getItem("authTokens");
      // get the access token
      const parsedTokens = JSON.parse(accessToken);
      const access = parsedTokens.access;
      // headers access token
      const config = {
        headers: { Authorization: `Bearer ${access}` },
      };
      // decoding the token so that i can get the user id
      const decodedToken = jwt_decode(accessToken);
      const userId = decodedToken.user_id;

      // get the landlord details that are linked to the user
      serverUrl
        .get(`/landlord/landlord/?user_id=${userId}`, config)
        .then((res) => {
          // get the landlord Id
          const landlordIds = res.data.results.map((landlord) => landlord.id);

          const data = {
            message: customMessage,
            subject: customSubject,
          };
          if (addTemplate) {
            const dataTemplate = {
              title: customSubject,
              text: customMessage,
              created_by_message_template: userId,
              updated_by_message_template: userId
            }
            // post the template
            serverUrl.post(`/communication/message-templates/`, dataTemplate, config)
              .then((res) => {
                toast.success("Reminder sent succesfully!")
                setRemindersModal(false);
              })
              .catch((error) => {
                console.log(error)
              })

          }
          if (reminderValue === 'general') {
            serverUrl.post(`/landlord/send_payment_reminder/?landlord_id=${landlordIds}`, data, config)
              .then((res) => {
                toast.success("Reminder sent succesfully to all your tenants!")
                setRemindersModal(false);
              })
              .catch((error) => {
                console.log(error)
              })
          }
          else {
            serverUrl.post(`/landlord/send_payment_reminder/?apartment_id=${reminderValue}`, data, config)
              .then((res) => {
                toast.success("Reminder sent succesfully to all the tenants in the selected property!")
                setRemindersModal(false);
              })
              .catch((error) => {
                console.log(error)
              })
          }

        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) { }
  };

  // Perform the subtraction and determine the result for the occupancy in each month
  let resultOccupancy = (landlordCurrentOccupancy - landlordPreviousOccupancy);

  // Check if the result is infinity and assign 0 if true
  if (!isFinite(resultOccupancy)) {
    resultOccupancy = 0;
  }

  // RESeting the filter
  const handleReset = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem('monthProperty');
    toast.success('Successfully Monthly Filter On Metrics for ', selectedApartment.name);
    window.location.reload()
  };

  // Determine the text color based on the result on the rent income ps it includes the expenses and the rent paid from the tenanat invoice
  let textColorClassOccupancy = '';
  let signOccupancy = '';
  if (resultOccupancy > 0) {
    textColorClassOccupancy = 'text-success';
    signOccupancy = '+';
  } else if (resultOccupancy < 0) {
    textColorClassOccupancy = 'text-danger';
    signOccupancy = '-';
  }
  else if (resultOccupancy == 0) {
    textColorClassOccupancy = 'text-success';
  }
  try {

  } catch (error) {

  }
  // Filtering the houses with occupancy_status set as "occupied" to be used on the tenants count
  const occupiedHouses = house.filter(houses => houses.status === 'occupied');

  // Counting the number of occupied houses
  const numberOfOccupiedHouses = occupiedHouses.length;

  // apartment background image
  const backgroundImage = apartmentImage || backgroundImageApartment;

  // get the amenities added in each apartment
  const addedAmenityIds = amenitiesApartment.map((amenity) => amenity.amenity);
  const availableAmenities = amenities.filter((amenity) => !addedAmenityIds.includes(amenity.name));

  // newly added features

  const [nearbyFacilitiesApartment, setNearbyFacilitiesApartment] = useState([])
  const [rules, setRules] = useState([])
  const [facilitiesIcons, setFacilitiesIcons] = useState([])

  useEffect(() => {

    // get the nearby facilities for that apartment
    serverUrl.get(`/landlord/alliance-facilities-apartment?apartment_id=${id}`, config)
      .then((res) => {
        setNearbyFacilitiesApartment(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })

    serverUrl.get(`/landlord/nearby-facilities-icons/`, config)
      .then((res) => {
        setFacilitiesIcons(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })

    // get the rules for that apartment
    serverUrl.get(`/landlord/rules/?apartment=${id}`, config)
      .then((res) => {
        setRules(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })

  }, [])

  const [formDataFacilityDelete, setFormDataFacilityDelete] = useState({
    icon: "",

  });
  // onchange functionality on delete new amenity
  const handleChangeFacilityDelete = (name) => (event) => {
    const { value } = event.target;
    setFormDataFacilityDelete((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // Function to convert a string to Camel Case and remove underscores
  function toCamelCase(str) {
    return str.replace(/_/g, ' ');
  }

  const [apartmentLiked, SetApartmentLiked] = useState('')


  // edit the nearby facilities
  const [showModalFacilityEdit, setShowModalFacilityEdit] = useState(false)
  const handleOpenModalFacilityEdit = () => {
    setShowModalFacilityEdit(true);
  };

  const handleCloseModalFacilityEdit = () => {
    setShowModalFacilityEdit(false);
  };
  // edit Facility variables
  const [formDataFacilityEdit, setFormDataFacilityEdit] = useState({
    facility: ""
  });

  // onchange functionality on editing new Facility
  const handleChangeFacilityEdit = (name) => (event) => {
    const { value } = event.target;
    setFormDataFacilityEdit((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [formDataFacilityEdit2, setFormDataFacilityEdit2] = useState({
    facilityId: ""
  });
  const handleChangeFacilityEdit2 = (name) => (event) => {
    const { value } = event.target;
    setFormDataFacilityEdit2((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // add a new amenity in the apartment
  const [showModalFacilities, setShowModalFacilities] = useState(false);
  const handleOpenModalFacilitiesAdd = () => {
    setShowModalFacilities(true);
  };

  const handleCloseModalFaciltiesAdd = () => {
    setShowModalFacilities(false);
  };


  // add nearby facilities
  function handleFacilities(e) {
    // preventing from redirecting to other pages
    e.preventDefault();

    // the data that will be posted to the api is as follows
    const data = {
      apartment: parseInt(id),
      icon: parseInt(formDataFacility.icon),
      distance: formDataFacility.distance
    };

    //  now post the data 
    try {
      serverUrl

        //send the amenities of the apartment to the backend
        .post(`/landlord/nearby-facilities/`, data, config)
        .then((res) => {
          toast.success('Successfully added the nearby facilities for this apartment!');
          setFormDataFacility({
            icon: "",
            distance: ""
          })
          // get the nearby facilities
          serverUrl.get(`/landlord/alliance-facilities-apartment?apartment_id=${id}`, config)
            .then((res) => {
              setNearbyFacilitiesApartment(res.data.results)
            })
            .catch((error) => {
              console.log(error)
            })
        })

        // displaying the messages of why the error 
        .catch((error) => {
          toast.error('Unexpected error')
          console.log('Unexpected error: ' + error.message);

        });

    } catch (error) {
      console.log(error);
    }

  }

  // submit the edited amenity for that house
  function handleEditFacility(e) {
    // preventing from redirecting to other pages
    e.preventDefault();

    // the data that will be posted to the api is as follows
    const data = {
      apartment: id,
      icon: formDataFacilityEdit2.facilityId,
    };

    //  now post the data
    try {
      serverUrl

        //send the amenities of the apartment to the backend
        .patch(`/landlord/nearby-facilities/${formDataFacilityEdit.facility}/`, data, config)
        .then((res) => {
          toast.success('Successfully Edited The Nearby Facility To The Apartment!');
          setFormDataFacilityEdit({
            facility: "",
            facilityId: ""
          })
          // get the amenities of each apartment
          // get the nearby facilities
          serverUrl.get(`/landlord/alliance-facilities-apartment?apartment_id=${id}`, config)
            .then((res) => {
              setNearbyFacilitiesApartment(res.data.results)
            })
            .catch((error) => {
              console.log(error)
            })

        })

        // displaying the messages of why the error 
        .catch((error) => {
          toast.error('Unexpected error')
          console.log('Unexpected error: ' + error.message);

        });

    } catch (error) {
      console.log(error);
    }

  }


  // delete a facilities amenity in the apartment
  const [showModalFacilitiesDelete, setShowModalFacilitiesDelete] = useState(false);
  const handleOpenModalFacilitiesDelete = () => {
    setShowModalFacilitiesDelete(true);
  };

  const handleCloseModalFaciltiesDelete = () => {
    setShowModalFacilitiesDelete(false);
  };

  // submit to delete the facility
  function handleDeleteFacility(e) {
    // preventing from redirecting to other pages
    e.preventDefault();

    //  now post the data
    try {
      serverUrl

        //send the amenities of the apartment to the backend
        .delete(`/landlord/nearby-facilities/${formDataFacilityDelete.icon}/`, config)
        .then((res) => {
          toast.success('Successfully Deleted The Nearby Facility Of The Apartment!');
          setFormDataFacilityDelete({
            icon: ""
          })
          // get the amenities of each apartment
          serverUrl.get(`/landlord/alliance-facilities-apartment?apartment_id=${id}`, config)
            .then((res) => {
              setNearbyFacilitiesApartment(res.data.results)
            })
            .catch((error) => {
              console.log(error)
            })
        })

        // displaying the messages of why the error 
        .catch((error) => {
          toast.error('Unexpected error')
          console.log('Unexpected error: ' + error.message);

        });

    } catch (error) {
      console.log(error);
    }

  }
  // add a new rules in the apartment
  const [showModalRules, setShowModalRules] = useState(false);
  const handleOpenModalRulesAdd = () => {
    setShowModalRules(true);
  };

  const handleCloseModalRulesAdd = () => {
    setShowModalRules(false);
  };

  function handleRules(e) {
    // preventing from redirecting to other pages
    e.preventDefault();

    // the data that will be posted to the api is as follows
    const data = {
      apartment: parseInt(id),
      category: formDataRules.category,
      description: formDataRules.description
    };

    //  now post the data 
    try {
      serverUrl.post(`/landlord/rules/`, data, config)
        .then((res) => {
          toast.success('Successfully added the rules for this property!');
          setFormDataRules({
            category: "",
            description: ""
          })
          // get the rules
          serverUrl.get(`/landlord/rules/?apartment=${id}`, config)
            .then((res) => {
              setRules(res.data.results)
            })
            .catch((error) => {
              console.log(error)
            })
        })

        // displaying the messages of why the error 
        .catch((error) => {
          toast.error('Unexpected error')
          console.log('Unexpected error: ' + error.message);

        });

    } catch (error) {
      console.log(error);
    }

  }

  const [showModalListing, setShowModalListing] = useState(false);


  const handleOpenModalListing = () => {
    setShowModalListing(true);
  };

  const handleCloseModalListing = () => {
    setShowModalListing(false);
  };

  const [showModalFeatured, setShowModalFeatured] = useState(false);

  const handleOpenModalFeatured = () => {
    setShowModalFeatured(true);
  };

  const handleCloseModalFeatured = () => {
    setShowModalFeatured(false);
  };


  const Featured = () => {
    // check if the apartment has an image first
    serverUrl.get(`/landlord/apartment-images/?apartment_id=${id}`, config)
      .then((res) => {
        console.log(res.data.count)
        if (res.data.count === 0) {
          toast.error('Kindly add an image for that apartment so that it can be listed')
          handleCloseModalFeatured()

        }
        else {

          serverUrl.get(`/landlord/apartment/${id}/`, config)
            .then((res) => {
              if (res.data.is_featured === false) {

                const data = {
                  is_featured: true
                }

                serverUrl.patch(`/landlord/apartment/${id}/`, data, config)
                  .then((res) => {

                    serverUrl.get(`/landlord/apartment/?id=${id}`, config)
                      .then((res) => {
                        setSelectedApartment(res.data.results.find((apartment) => apartment.id === parseInt(id)))
                        handleCloseModalFeatured()
                        window.location.reload()
                        toast.success('Succesfully added the property to the featured')
                      })
                      .catch((error) => {
                        console.log(error);
                      })

                  })
                  .catch((error) => {
                    console.log(error)
                  })

              }
              else {

                const data = {
                  is_featured: false
                }

                serverUrl.patch(`/landlord/apartment/${id}/`, data, config)
                  .then((res) => {

                    serverUrl.get(`/landlord/apartment/?id=${id}`, config)
                      .then((res) => {
                        setSelectedApartment(res.data.results.find((apartment) => apartment.id === parseInt(id)))
                        handleCloseModalFeatured()
                        toast.success('Succesfully removed the property from featured')
                      })
                      .catch((error) => {
                        console.log(error);
                      })

                  })
                  .catch((error) => {
                    console.log(error)
                  })

              }
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  };


  const Listing = () => {
    // check if the apartment has an image first
    serverUrl.get(`/landlord/apartment-images/?apartment_id=${id}`, config)
      .then((res) => {
        console.log(res.data.count)
        if (res.data.count === 0) {
          toast.error('Kindly add an image for that apartment so that it can be listed')
          handleCloseModalListing()

        }
        else {
          // check if the apartment has units
          serverUrl.get(`/landlord/house/?apartment_id=${id}`, config)
            .then((res) => {
              const hasHouses = res.data.results.length
              if (hasHouses > 0) {
                serverUrl.get(`/landlord/apartment/${id}/`, config)
                  .then((res) => {
                    if (res.data.is_listed === false) {

                      const data = {
                        is_listed: true
                      }

                      serverUrl.patch(`/landlord/apartment/${id}/`, data, config)
                        .then((res) => {

                          serverUrl.get(`/landlord/apartment/?id=${id}`, config)
                            .then((res) => {
                              setSelectedApartment(res.data.results.find((apartment) => apartment.id === parseInt(id)))
                              handleCloseModalListing()
                              toast.success('Succesfully added the property from listing')
                            })
                            .catch((error) => {
                              console.log(error);
                            })

                        })
                        .catch((error) => {
                          console.log(error)
                        })

                    }
                    else {

                      const data = {
                        is_listed: false
                      }

                      serverUrl.patch(`/landlord/apartment/${id}/`, data, config)
                        .then((res) => {

                          serverUrl.get(`/landlord/apartment/?id=${id}`, config)
                            .then((res) => {
                              setSelectedApartment(res.data.results.find((apartment) => apartment.id === parseInt(id)))
                              handleCloseModalListing()
                              toast.success('Succesfully removed the property from listing')
                            })
                            .catch((error) => {
                              console.log(error);
                            })

                        })
                        .catch((error) => {
                          console.log(error)
                        })

                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
              }

              else {
                toast.error('Kindly add an unit for that property so that it can be listed')
                handleCloseModalListing()
              }

            })


        }
      })
      .catch((error) => {
        console.log(error)
      })
  };



  // get the facilities added in each apartment
  const addedFacilityIds = nearbyFacilitiesApartment.map((facility) => facility.icon_id);
  const availableFacilities = facilitiesIcons.filter((facility) => !addedFacilityIds.includes(facility.id));

  const rulesCategory = [
    {
      name: 'Safety',
      value: 'safety'
    },
    {
      name: 'Property Rules',
      value: 'property_rules'
    },
    {
      name: 'Rental Policy',
      value: 'rental_policy'
    }
  ]
  const uniqueCategories = new Set();

  rules.map((rule) => {
    if (!uniqueCategories.has(rule.category)) {
      uniqueCategories.add(rule.category);
    }

  })
  const uniqueCategoriesArray = [...uniqueCategories];

  // add facilities variables
  const [formDataFacility, setFormDataFacility] = useState({
    distance: "",
    icon: ""
  });

  const handleChangeNearbyFacility = (e) => {
    const selectedFacilityIconId = e.target.value;
    setFormDataFacility({ ...formDataFacility, icon: selectedFacilityIconId });
  };


  // add rules 
  const [formDataRules, setFormDataRules] = useState({
    category: "",
    description: ""
  });

  const handleChangeRulesCategory = (e) => {
    const selectedRuleCategory = e.target.value;
    setFormDataRules({ ...formDataRules, category: selectedRuleCategory });
  };

  const handleChangeRules = (event) => {
    const { name, value } = event.target;
    setFormDataRules((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // onchange functionality on adding new amenity
  const handleChangeFacility = (event) => {
    const { name, value } = event.target;
    setFormDataFacility((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  const [showModalLiked, setShowModalLiked] = useState(false);
  const handleOpenModalLiked = () => {
    setShowModalLiked(true);
  };
  const handleCloseModalLiked = () => {
    setShowModalLiked(false);
  };
  const [likes, SetLikes] = useState([])

  // on search of the houses get to list the houses in the selected Apartment
  const [searchQueryLikes, setSearchQueryLikes] = useState("");
  const [selectedRowLikes, setSelectedRowLikes] = useState(null);

  const handleSearchLikes = (event) => {
    setSearchQueryLikes(event.target.value);
    setSelectedRowLikes(null);
  };
  const filteredDataLikes = likes.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQueryLikes.toLowerCase());
  });
  // design the table to display 10 each per table
  const [currentPageLikes, setCurrentPageLikes] = useState(0);
  const itemsPerPageLikes = 10;
  const totalPagesLikes = Math.ceil(filteredDataLikes.length / itemsPerPageLikes);
  const startIndexLikes = currentPageLikes * itemsPerPageLikes;
  const endIndexLikes = currentPageLikes === totalPagesLikes - 1 ? filteredDataLikes.length : startIndexLikes + itemsPerPageLikes;
  const displayedItemsLikes = filteredDataLikes.slice(startIndexLikes, endIndexLikes);


  const exportCSV = () => {
    const csvData = [
      ['Viewer Name', 'Liked Date'],
      ...likes.map((item) => [item.user_name, new Date(item.created_at).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      }),]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'Property Likes.csv');
  };

  const handlePrevPageLikes = () => {
    setCurrentPageLikes(currentPageLikes - 1);
  };

  const handleNextPageLikes = () => {
    setCurrentPageLikes(currentPageLikes + 1);
  };




  // if the data is loading show a message
  if (isLoading) {
    return (
      <div className="loader-container">
        <img src={loaderImage} alt="Loading..." />
      </div>
    );
  }

  return (
    <>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}

      <div className='selected-property'>

        {/* check if the apartment has an image */}

        <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>

          <div className='ImageHouseName'>
            {selectedApartment && (<>
              <span className='properties'>{selectedApartment.location}</span>
              <h2>{selectedApartment.name}</h2>
            </>)}
            <div className='selectedHouseButtonsTop'>
              {/* {selectedApartment.is_featured === true ?
                <Button className='btn btn-sm btn-info text-white ms-4 me-2' onClick={handleOpenModalFeatured}>Remove Featured</Button>

                :
                <Button className='btn btn-sm btn-secondary text-white ms-4 me-2' onClick={handleOpenModalFeatured}>Add Featured</Button>

              }

              {selectedApartment.is_listed === true ?
                <Button className='btn btn-sm btn-warning text-white ms-4 me-2' onClick={handleOpenModalListing}>Remove Listing</Button>

                :
                <Button className='btn btn-sm btn-success text-white ms-4 me-2' onClick={handleOpenModalListing}>Add Listing</Button>

              } */}
              <Button className='btn btn-sm btn-primary' onClick={handleOpenModal}>Edit<i className='bi bi-pen ms-2'></i></Button>
              <Button className='addHouse btn border-0 text-white btn-sm  me-3 text-white text-decoration-none p-2' style={{ display: "flex", justifyContent: "center", backgroundColor: "#9b59b6", width: "fit-content", float: "right" }}>

                <Link to={`/selected-property-image/${id}`} className='text-decoration-none text-white'>Gallery</Link>

              </Button>

            </div>

            {/* modal to edit the apartment details */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Property Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <>
                  <Link to={`/selected-property-image/${id}`} className='addHouse btn btn-info text-white' style={{ margin: "0 auto", display: "flex", justifyContent: "center" }}>Add Property Image</Link>
                  <Form onSubmit={handleSubmit} className='mt-3'>
                    <p className='lableName'>Property Name</p>
                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Property Name"
                        required
                      />
                    </div>
                    <p className='lableName'>Property Location</p>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        placeholder="Location"
                        required
                      />
                    </div>
                    <p className='lableName'>Property About status</p>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="text"
                        name="about"
                        value={formData.about}
                        onChange={handleChange}
                        placeholder="Description"
                        required
                      />
                    </div>

                    <Button variant="primary" type="submit" className="nextBtn mt-3" >
                      Save
                    </Button>
                  </Form>
                </>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={handleCloseModalReload} >
                  Done
                </Button>
              </Modal.Footer>
            </Modal>


            {/* modal for making apartment listed */}
            <Modal show={showModalListing} onHide={handleCloseModalListing}>
              <Modal.Header closeButton>
                <Modal.Title>Listing Property</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <>
                  {selectedApartment.is_listed === true ?

                    <p>Do you want to remove this property from your listing?</p>
                    :
                    <p>Do you want to add this property to your listing?</p>
                  }
                </>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={Listing} >
                  Yes
                </Button>
                <Button variant="danger" onClick={handleCloseModalListing} >
                  No
                </Button>
              </Modal.Footer>
            </Modal>

            {/* modal for making apartment featured */}
            <Modal show={showModalFeatured} onHide={handleCloseModalFeatured}>
              <Modal.Header closeButton>
                <Modal.Title>Featured Property</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <>
                  {selectedApartment.is_featured === true ?

                    <p>Do you want to remove this property from your featured?</p>
                    :
                    <p>Do you want to add this property to your featured?</p>
                  }
                </>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={Featured} >
                  Yes
                </Button>
                <Button variant="danger" onClick={handleCloseModalFeatured} >
                  No
                </Button>
              </Modal.Footer>
            </Modal>







          </div>

        </div>



        <Card className='CardHouseName mb-4'>
          <Form className="searchProperty w-40">
            <FormControl type="text" placeholder="Search" className="mr-sm-2 rounded-4" onChange={handleSearch} />
          </Form>

          <FormSelect size='sm' className='formProperty w-25' value={selectedRent} onChange={(e) => setSelectedRent(parseInt(e.target.value))}>
            <option disabled selected>{correctTerminology} Amount</option>
            <option value="">All Units</option>
            {uniqueHouses.map((rentHouse) => (
              rentHouse.house_rent === 0 ? (
                <option key={rentHouse.house_id} value={rentHouse.service_charge} > {rentHouse.service_charge}</option>
              )
                : (
                  <option key={rentHouse.house_id} value={rentHouse.house_rent} > {rentHouse.house_rent}</option>

                )


            ))}
          </FormSelect>
          <FormSelect size='sm' className='formProperty w-25 ms-3' value={selectedPaymentStatus} onChange={(e) => setSelectedPaymentStatus(e.target.value)}>
            <option disabled selected>Payment Status</option>
            <option value="">All Units</option>
            {uniquePaymentStatus.map((rentHouse) => (
              rentHouse.rent_payment_status === "None" || rentHouse.rent_payment_status === '' ?
                <></>
                :
                <option key={rentHouse.house_id} value={rentHouse.rent_payment_status} > {rentHouse.rent_payment_status}</option>
            ))}
          </FormSelect>
        </Card>

        {/* filtred calendar */}
        <div className='filterCalendar d-flex justify-content-between'>
          {monthFilterActive ?
            <>
              <Button className='text-white filterButton' style={{ marginLeft: "-65rem" }} onClick={handleReset}>Reset Filter</Button>
              <p className='text-muted text-uppercase fw-bold'>Metrics filters for month {monthFilterActive}</p>
            </>
            :
            <></>}

          <div className='input-group'>
            <div className='input-group-prepend'>
              <span className='input-group-text'>
                <i className="bi bi-calendar" />
              </span>
            </div>


            <Form.Select size='sm' className='formCal' onChange={handleSelectChange}>
              {monthFilterActive ?
                <>
                  <option>{monthFilterActive}</option>
                  <option>Monthly</option>
                </>
                :
                <>
                  <option>{currentMonth}</option>
                  <option>Monthly</option>
                </>}

            </Form.Select>
          </div>
        </div>

        {/* filter modal */}
        <Modal show={showModalFilter} onHide={handleCloseModalFilter}>
          <Modal.Header className='bg-primary text-white' closeButton>
            <Modal.Title>Filter By {selectedOption}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedOption === currentMonth ?
              (
                <>
                  <p>{currentMonth}</p>
                </>
              )
              :
              (
                <>
                  <Form>
                    <Row className="flex-wrap justify-content-between">
                      {monthsTillToday.map((month) => (
                        <Col key={month} xs={6} sm={4} md={4} className="mt-3">
                          <Button className='btn btn-primary text-white w-75' style={{ marginInline: '0.5rem' }} onClick={() => handleMonthButtonClick(month)}>
                            {month}</Button>
                        </Col>
                      ))}
                    </Row>

                  </Form>
                </>

              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseModalFilter}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* metrics cards */}
        <div fluid className='metricsCards'>
          <Card className='rentDue'>
            <Card.Body>
              <Image src={Due}></Image>
              <div className='float-left'>
                <span>{correctTerminology} Due</span>
                <div className='d-flex mt-2'>
                  <p >{landlordIncomeDue?.toLocaleString()}</p>
                  <span className='text-success ms-3 mt-1 d-none'>+15%</span>
                </div>

              </div>
            </Card.Body>

          </Card>

          <Card className='defaulters'>
            <Card.Body>
              <Image src={Book}></Image>
              <div className='float-left'>
                <span className="defaultersText">Defaulters</span>
                <p className="defaultersText">{defaulters}</p>
                {defaulters > 0 ?
                  <Button className="sendReminders btn-sm ms-1" onClick={handleReminderModalOpen}>Send Reminders</Button>
                  :
                  <></>
                }
              </div>

              {/* modal for send reminders */}
              <Modal show={remindersModal} onHide={handleReminderModalClose}>
                <Modal.Header className="bg-primary text-white" closeButton>
                  {addTemplate ? (
                    <Modal.Title>Add Message Templates</Modal.Title>
                  ) : (
                    <Modal.Title>Send Reminder Message</Modal.Title>
                  )}
                </Modal.Header>

                <Modal.Body>
                  <div className="d-flex justify-content-between">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="primary"
                        size="sm"
                        disabled={isTemplatesEmpty || addTemplate}
                      >
                        {selectedOptionReminder ? (
                          selectedOptionReminder.title
                        ) : (
                          <>Choose Template</>
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {messageTemplates.map((template) => (
                          <Dropdown.Item key={template.id} onClick={() => handleOptionSelect(template)}>
                            {template.title}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Button
                      className={`btn btn-sm ${addTemplate ? "btn-primary" : "btn-secondary"
                        }`}
                      onClick={handleAddTemplate}
                    >
                      Add Template
                    </Button>
                  </div>

                  {addTemplate ? (
                    <>
                      <div className="input-icons mt-2 mb-2">
                        <i className="bi bi-chat-left-dots icon"></i>
                        <textarea
                          className="input-field"
                          rows={1}
                          value={customSubject}
                          onChange={(e) => setCustomSubject(e.target.value)}
                        ></textarea>
                      </div>

                      <div className="input-icons">
                        <i className="bi bi-send-plus icon"></i>
                        <textarea
                          className="input-field"
                          rows={8}
                          placeholder="Enter your message"
                          value={customMessage}
                          onChange={(e) => setCustomMessage(e.target.value)}

                        ></textarea>
                      </div>
                    </>

                  ) : selectedOptionReminder ? (

                    <div className="mt-2">
                      <div className="input-icons mb-2">
                        <i className="bi bi-chat-left-dots icon"></i>
                        <textarea
                          className="input-field"
                          rows={1}
                          value={editableSubject ? customSubject : selectedOptionReminder.title}
                          onChange={(e) => setCustomSubject(e.target.value)}
                          readOnly={!editableSubject}
                        ></textarea>
                      </div>

                      <div className="input-icons">
                        <i className="bi bi-send-plus icon"></i>
                        <textarea
                          className="input-field"
                          value={editableMessage ? customMessage : selectedOptionReminder.text}
                          onChange={(e) => setCustomMessage(e.target.value)}
                          readOnly={!editableMessage}
                        ></textarea>
                      </div>
                    </div>


                  ) : (
                    <>
                      <p className="selectionReminderParagraph">Select an option kindly</p>
                    </>
                  )}
                </Modal.Body>


                <Modal.Footer className="d-flex justify-content-between">
                  <Button variant="danger" onClick={handleReminderModalClose}>
                    Close
                  </Button>
                  <Button
                    variant="success"
                    onClick={handleSendRemindersGeneral}
                  >
                    Send
                  </Button>
                </Modal.Footer>
              </Modal>


            </Card.Body>

          </Card>


          <Card className='income'>
            <Card.Body>
              <Image src={Income}></Image>

              <div className='float-left'>
                <span>{correctTerminology} Income</span>
                <div className='d-flex mt-2'>
                  <p className=''>{apartmentIncome?.toLocaleString()}</p>
                  <span className={`ms-3 mt-1 ${textColorClass}`}>{sign}{Math.abs(result)}% Paid</span>
                </div>

              </div>
            </Card.Body>

          </Card>

          <Card className='occupacy'>
            <Card.Body>
              <Image src={Percentage}></Image>
              <div className='float-left'>
                <span>Occupancy</span>
                <div className='d-flex mt-2'>
                  <p>{landlordCurrentOccupancy}%</p>
                  <span className={`ms-3 mt-1 ${textColorClassOccupancy}`}>{signOccupancy}{Math.abs(resultOccupancy)}%</span>
                </div>

              </div>
            </Card.Body>

          </Card>


          <Card className='propertyLiked me-3 d-none'>
            <Card.Body>
              <Image src={ApartmentLiked}></Image>
              <div className='float-left'>
                <span>No of times liked</span>
                <div className='d-flex mt-2'>
                  <p className='fw-bold'>{apartmentLiked}</p>
                  <Button className='btn btn-sm btn-info text-white ms-4' style={{ height: "fit-content" }} onClick={handleOpenModalLiked}>View Properties</Button>
                </div>

              </div>
            </Card.Body>

            {/* modal to show all the likes */}
            <Modal show={showModalLiked} onHide={handleCloseModalLiked} size='xl'>
              <Modal.Header closeButton>
                <Modal.Title>People who liked this property</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='tasks ' >
                  <div className='d-flex justify-content-between'>

                    <div>
                      <Button className='btn btn-success btn-sm text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel'></i></Button>
                      {/* <Button className='btn btn-danger btn-sm text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button> */}
                    </div>
                    <Form className="searchBar">
                      <FormControl type="text" placeholder="Search" onChange={handleSearchLikes} />
                    </Form>
                  </div>
                  <Table className='table mt-2'>
                    <thead className='underline' style={{ borderBottom: "1px solid lightgray" }}>
                      <th>Viewer's Name</th>
                      <th>Liked Date</th>
                    </thead>
                    <tbody>

                      {displayedItemsLikes.map((like) => (
                        <tr key={like.id}>
                          <td>{like.user_name}</td>
                          <td>{new Date(like.created_at).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                          })}
                          </td>
                        </tr>
                      ))}


                    </tbody>
                  </Table>





                  <div className="pagination">
                    <button onClick={handlePrevPageLikes} disabled={currentPageLikes === 0}>
                      &lt;
                    </button>
                    <span>{`${startIndexLikes + 1}-${endIndexLikes} of ${likes.length}`}</span>
                    <button onClick={handleNextPageLikes} disabled={currentPageLikes === totalPagesLikes - 1}>
                      &gt;
                    </button>
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>

                <Button variant="secondary" onClick={handleCloseModalLiked} >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

          </Card>
        </div>

        {/* add a new house */}
        <div className='addHouse'>
          <Button className='btn btn-sm btn-primary mt-5' onClick={handleOpenModalHouse}>Add Unit<i className='bi bi-plus ms-2 fw-bold'></i></Button>
          {/* modal to edit the apartment details */}
          <Modal show={showModalHouse} onHide={handleCloseModalHouse}>
            <Modal.Header closeButton>
              <Modal.Title>Add A New Unit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedApartment && (
                <>
                  <Form onSubmit={handleSubmitHouse}>
                    <p className='lableName'>Unit Name</p>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="text"
                        name="house_number"
                        value={formDataHouse.house_number}
                        onChange={handleChangeHouse}
                        placeholder={"Unit Number"}
                        required
                      />
                    </div>
                    <p className='lableName'>Unit {correctTerminology} Amount</p>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="rent"
                        value={formDataHouse.rent}
                        onChange={handleChangeHouse}
                        placeholder={"Rent"}
                        required
                      />
                    </div>
                    <p className='lableName'>Unit Due Date</p>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="date"
                        name="due_date"
                        value={formDataHouse.due_date}
                        onChange={handleChangeHouse}
                        placeholder={"Due Date"}
                        required
                      />
                    </div>
                    <p className='lableName'>No of Bedrooms</p>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="bedroom"
                        value={formDataHouse.bedroom}
                        onChange={handleChangeHouse}
                        placeholder={"Bedroom"}
                        required
                      />
                    </div>
                    <p className='lableName'>No of Bathrooms</p>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="bathroom"
                        value={formDataHouse.bathroom}
                        onChange={handleChangeHouse}
                        placeholder={"Bathroom"}
                        required
                      />
                    </div>
                    <p className='lableName'>Total rooms</p>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="total_rooms"
                        value={formDataHouse.total_rooms}
                        onChange={handleChangeHouse}
                        placeholder={"Total Rooms"}
                        required
                      />
                    </div>
                    <p className='lableName'>Unit Deposit Amount</p>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="deposit"
                        value={formDataHouse.deposit}
                        onChange={handleChangeHouse}
                        placeholder={"Deposit"}
                        required
                      />
                    </div>

                    <Button variant="primary" type="submit" className="nextBtn mt-3" >
                      Save
                    </Button>
                  </Form> </>)}
            </Modal.Body>
            <Modal.Footer>
              <Button className='d-none' variant="primary" onClick={handleAddTenant} >
                ADD TENANT
              </Button>
              <Button variant="success" onClick={handleCloseModalHouse} >
                DONE
              </Button>
            </Modal.Footer>
          </Modal>

        </div>

        <Row>

          <Col md={9} sm={6} className='d-flex' style={{ height: 'fit-content' }}>

            {house.length === 0 ?
              (<div className='noHouse'>You have no units in this property</div>)
              :
              (
                displayedItems.map((unitDetail, index) =>
                  <>
                    <Card className='house'>
                      <Card.Body>
                        <Link to={`/selected-house/${unitDetail.house_id}`} className='text-decoration-none'>
                          <div className="image-container">
                            {/* Display the image of the house */}
                            {houseSetImage[unitDetail.house_id] && houseSetImage[unitDetail.house_id] ? (
                              <Image
                                src={houseSetImage[unitDetail.house_id]}
                                className="houseUnit"
                                alt="image"
                              />

                            ) : (
                              <Image
                                src={backgroundImages[unitDetail.house_id]}
                                className="houseUnit"
                                alt="default image"
                              />

                            )}
                            {unitDetail.house_rent === 0 ? (
                              <div className="badge">Ksh. {unitDetail.service_charge?.toLocaleString()} /month</div>

                            )
                              : (
                                <div className="badge">Ksh. {unitDetail.house_rent?.toLocaleString()} /month</div>

                              )}
                            <p className="text-start houseTitle" style={{ marginBottom: "0" }} >{unitDetail.house}</p>
                          </div>
                          <p className="houseName" style={{ marginBottom: "5px" }}>{unitDetail.tenant?.toLowerCase()}</p>

                        </Link>
                        <p></p>

                        <div className="buttonsProperties">
                          {unitDetail.status === "vacant" ? (
                            <div className='d-flex justify-content-between w-100'>
                              <Button className="btn btn-sm btn-success text-white rounded-5 ">Vacant</Button>
                              <button onClick={() => handleOpenModalDeleteHouse(unitDetail)} className="icon-button btn btn-danger bg-danger btn-sm opacity-75 text-white">
                                <i className="bi bi-trash"></i>
                              </button>
                            </div>
                          ) : (
                            <div className='buttonsProperty mb-3'>
                              <Button className="btn btn-sm btn-warning text-white OccupiedBtn rounded-5">Occupied</Button>


                              {/* {new Date(unitDetail.due_date).toLocaleDateString() < new Date(currentDate).toLocaleDateString() ?
                              (<Button className="btn btn-sm btn-danger OccupiedBtn rounded-5">Due</Button>)
                              :
                              (<Button className="btn btn-sm btn-primary OccupiedBtn rounded-5">{unitDetail.due_date}</Button>)
                            } */}
                              {/* rent_status */}
                              {unitDetail.rent_payment_status === "paid" ?
                                <Button className='border-0 text-white ms-2 OccupiedBtn rounded-5' style={{ backgroundColor: "#2ecc71" }}>{unitDetail.rent_payment_status}</Button>

                                : unitDetail.rent_payment_status === "partial" ?
                                  <Button className='border-0 text-white ms-2 OccupiedBtn rounded-5' style={{ backgroundColor: "#bdc3c7" }} >{unitDetail.rent_payment_status}</Button>
                                  : unitDetail.rent_payment_status === "None" ?
                                    <></>
                                    :
                                    <Button className='border-0 text-white ms-2 OccupiedBtn rounded-5' style={{ backgroundColor: "#e74c3c" }} >{unitDetail.rent_payment_status}</Button>

                              }




                            </div>
                          )}
                        </div>

                        <div className="buttonsProperties">
                          {unitDetail.status === "vacant" ?
                            (
                              <></>
                            )
                            :
                            (<>
                              <Button to="/#" className="text-danger text-decoration-none p-1 bg-transparent border-0" onClick={handleReminderModalOpen}>
                                Send Reminder <i className="bi bi-arrow-right me-2"></i>
                              </Button>

                              <button onClick={() => handleOpenModalDeleteHouse(unitDetail)} className="icon-button btn btn-danger bg-danger btn-sm opacity-75 text-white" >
                                <i className="bi bi-trash"></i>
                              </button>
                            </>)}

                        </div>
                        <Modal show={showModalDeleteHouse} onHide={handleCloseModalDeleteHouse}>
                          <Modal.Header closeButton>
                            <Modal.Title>Delete Unit</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>Do you want to delete the Unit?</Modal.Body>
                          <Modal.Footer>
                            <Button variant="success" onClick={handleCloseModalDeleteHouse}>
                              No
                            </Button>
                            <Button houseDeleteId={unitDetail.id} variant="danger" onClick={handleDeleteHouse}>
                              Yes
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Card.Body>

                    </Card>

                  </>


                ))
            }
            <div className="pagination" style={{ margin: '0 auto', padding: "2rem", width: 'inherit' }}>
              <button onClick={handlePrevPage} disabled={currentPage === 0}>
                &lt;
              </button>
              <span>{`${startIndex + 1}-${endIndex} of ${house.length}`}</span>
              <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                &gt;
              </button>
            </div>
          </Col>

          <Col md={3} sm={8}>
            <Card className='topProperty'>
              <Card.Header className='fw-bold fs-6 text-start' style={{ color: "#16a085" }}>
                METRICS
              </Card.Header>
              <Card.Body>

                <Row>
                  <Col md={6}>
                    <span>Units</span>
                  </Col >

                  <Col md={6}>
                    <span style={{ color: "#16a085", fontWeight: "bold" }}>[{house.length}]</span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <span>Accounts</span>
                  </Col >

                  <Col md={6}>
                    <span style={{ color: "#16a085", fontWeight: "bold" }}>[{accountNumbers}]</span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <span>Amenities</span>
                  </Col >

                  <Col md={6}>
                    <span style={{ color: "#16a085", fontWeight: "bold" }}>[{amenitiesApartment.length}]</span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <span>Tenants</span>
                  </Col >

                  <Col md={6}>

                    <span style={{ color: "#16a085", fontWeight: "bold" }}>[{numberOfOccupiedHouses}]</span>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <span>USSD</span>
                  </Col >

                  <Col md={6}>
                    <span className='fw-bold' style={{ color: "#16a085", fontWeight: "bold" }}>{selectedApartment.ussd}</span>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className='fw-bold mb-2 fs-6 text-start' style={{ color: "#8e44ad" }}>
                    AMENITIES
                  </Col>

                </Row>

                <Row>
                  {amenitiesApartment.length > 0 ?
                    amenitiesApartment.map((amenity) => (
                      <Col md={12} className="amenties text-start d-flex">
                        <Image src={amenity.icon} style={{ width: "15%", marginBottom: "1rem" }} />
                        <p className='ms-3'> {amenity.amenity}</p>
                      </Col >
                    ))
                    :
                    <p style={{ color: "#95a5a6" }}>No amenities found for this property kindly add one</p>
                  }


                </Row>


                <Row>
                  <Col md={12} className='fw-bold mb-2 fs-6'>
                    <div className='amenitiesDiv'>
                      <span><i className='bi bi-plus bg-primary text-white p-2' onClick={handleOpenModalAmenityAdd}></i></span>

                      {amenitiesApartment.length > 0 ? (
                        <>
                          <span><i className='bi bi-pen bg-success text-white p-2' onClick={handleOpenModalAmenityEdit}></i></span>
                          <span><i className='bi bi-trash bg-danger text-white p-2' onClick={handleOpenModalAmenityDelete}></i></span>
                        </>
                      )
                        :
                        (<></>)}
                    </div>
                    {/* add the amenity to the apartment */}
                    <Modal show={showModalAmenityAdd} onHide={handleCloseModalAmenityAdd}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Amenities For This Apartment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleAddAmenity}>
                          <div className="input-icons">

                            <FormSelect className="formProperty" value={formDataAmenity.amenity} onChange={handleChangeAmenity}>
                              <option disabled selected value="">
                                Select Amenity To Add
                              </option>
                              {availableAmenities.map((amenity) => (

                                <option key={amenity.id} value={amenity.id}>
                                  {amenity.name}
                                </option>
                              ))}
                            </FormSelect>

                          </div>


                          <Button className='nextBtn mt-3' type='submit'>Save</Button>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalAmenityAdd}>
                          Done
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {/* edit the selected amenity in the apartment */}
                    <Modal show={showModalAmenityEdit} onHide={handleCloseModalAmenityEdit}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Amenities For This Apartment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>


                        <Form onSubmit={handleEditAmenity}>
                          <div className="input-icons">
                            <FormSelect
                              className="formProperty"
                              value={formDataAmenityEdit.amenity || ''} // Update the value property
                              onChange={handleChangeAmenityEdit('amenity')} // Pass the name as an argument
                            >
                              <option disabled value="">
                                Select Amenity To Edit
                              </option>
                              {amenitiesApartment.length > 0 ? (
                                amenitiesApartment.map((amenity) => (
                                  <option
                                    key={amenity.amenity_id}
                                    value={amenity.amenity_id}
                                  >
                                    {amenity.amenity}
                                  </option>
                                ))
                              ) : (
                                <p>No Amenities Found For This Apartment. Kindly Add One.</p>
                              )}
                            </FormSelect>

                            <FormSelect
                              className="formProperty mt-3"
                              value={formDataAmenityEdit.amenityId || ''} // Update the value property
                              onChange={handleChangeAmenityEdit('amenityId')} // Pass the name as an argument
                            >
                              <option disabled value="">
                                Change Amenity To
                              </option>
                              {availableAmenities.map((amenity) => (
                                <option key={amenity.id} value={amenity.id}>
                                  {amenity.name}
                                </option>
                              ))}
                            </FormSelect>
                          </div>

                          <Button className="nextBtn mt-3" type="submit">
                            Edit Amenity
                          </Button>
                        </Form>


                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalAmenityEdit}>
                          Done
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {/* delete the selected amenity */}

                    <Modal show={showModalAmenityDelete} onHide={handleCloseModalAmenityDelete}>
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Amenities For This Apartment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>


                        <Form onSubmit={handleDeleteAmenity}>
                          <div className="input-icons">
                            <FormSelect
                              className="formProperty"
                              value={formDataAmenityDelete.amenity || ''} // Update the value property
                              onChange={handleChangeAmenityDelete('amenity')} // Pass the name as an argument
                            >
                              <option disabled value="">
                                Select Amenity To Delete
                              </option>
                              {amenitiesApartment.length > 0 ? (
                                amenitiesApartment.map((amenity) => (
                                  <option
                                    key={amenity.amenity_id}
                                    value={amenity.amenity_id}
                                  >
                                    {amenity.amenity}
                                  </option>
                                ))
                              ) : (
                                <p>No Amenities Found For This Apartment. Kindly Add One.</p>
                              )}
                            </FormSelect>

                          </div>

                          <Button className="nextBtn btn-danger border-0 mt-3" type="submit">
                            Delete Amenity
                          </Button>
                        </Form>


                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalAmenityDelete}>
                          Done
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Col>
                </Row>


                {/* nearby facilities */}

                <Row>
                  <Col md={12} className='fw-bold mb-2 fs-6 text-start' style={{ color: "#d35400" }}>
                    NEARBY FACILITIES
                  </Col>

                </Row>

                <Row>
                  {nearbyFacilitiesApartment.length > 0 ?
                    nearbyFacilitiesApartment.map((amenity) => (
                      <Col md={12} className="amenties text-start d-flex">
                        <Image src={amenity.icon_image} style={{ width: "7%", marginBottom: "1rem" }} />
                        <p className='ms-3'> {amenity.icon},{amenity.distance} metres</p>
                      </Col >
                    ))
                    :
                    <p style={{ color: "#95a5a6" }}>No nearby facilities found for this property kindly add one</p>
                  }
                </Row>


                <Row>
                  <Col md={12} className='fw-bold mb-2 fs-6'>
                    <div className='amenitiesDiv'>
                      <span><i className='bi bi-plus bg-primary text-white p-2' onClick={handleOpenModalFacilitiesAdd}></i></span>

                      {nearbyFacilitiesApartment.length > 0 ? (
                        <>
                          <span><i className='bi bi-pen bg-success text-white p-2' onClick={handleOpenModalFacilityEdit}></i></span>

                          <span><i className='bi bi-trash bg-danger text-white p-2' onClick={handleOpenModalFacilitiesDelete}></i></span>
                        </>
                      )
                        :
                        (<></>)}
                    </div>
                    {/* add the amenity to the apartment */}
                    <Modal show={showModalFacilities} onHide={handleCloseModalFaciltiesAdd}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add facilities near this property</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleFacilities}>
                          <div className="input-icons">

                            <FormSelect className="formProperty" value={formDataFacility.icon} onChange={handleChangeNearbyFacility}>
                              <option value="">Select Facility To Add</option>
                              {availableFacilities.map((amenity) => (
                                <option key={amenity.id} value={amenity.id}>
                                  {amenity.description}
                                </option>
                              ))}
                            </FormSelect>


                            <div className="input-icons mt-3 w-100 p-0">
                              <i className="fa fa-hashtag icon"></i>
                              <input
                                className="input-field"
                                type="number"
                                name="distance"
                                value={formDataFacility.distance}
                                onChange={handleChangeFacility}
                                placeholder={"Distance in meters"}
                                required
                              />
                            </div>

                          </div>


                          <Button className='nextBtn mt-3' type='submit'>Save</Button>
                        </Form>
                      </Modal.Body>

                    </Modal>


                    {/* edit the selected amenity in the apartment */}
                    <Modal show={showModalFacilityEdit} onHide={handleCloseModalFacilityEdit}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit Nearby Facilities For This Property</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>


                        <Form onSubmit={handleEditFacility}>
                          <div className="input-icons">
                            <FormSelect
                              className="formProperty"
                              name='facility'

                              value={formDataFacilityEdit.facility || ''} // Update the value property
                              onChange={handleChangeFacilityEdit('facility')} // Pass the name as an argument
                            >
                              <option disabled value="">
                                Select Facility To Edit
                              </option>
                              {nearbyFacilitiesApartment.length > 0 ? (
                                nearbyFacilitiesApartment.map((amenity) => (
                                  <option
                                    key={amenity.id}
                                    value={amenity.id}
                                  >
                                    {amenity.icon}
                                  </option>
                                ))
                              ) : (
                                <p>No Amenities Found For This Apartment. Kindly Add One.</p>
                              )}
                            </FormSelect>

                            <FormSelect
                              className="formProperty mt-3"
                              value={formDataFacilityEdit2.facilityId || ''}
                              name='facilityId'
                              onChange={handleChangeFacilityEdit2('facilityId')}
                            >
                              <option disabled value="">
                                Change Facility To
                              </option>
                              {availableFacilities.map((amenity) => (
                                <option key={amenity.id} value={amenity.id}>
                                  {amenity.description}
                                </option>
                              ))}
                            </FormSelect>
                          </div>

                          <Button className="nextBtn mt-3" type="submit">
                            Edit Facility
                          </Button>
                        </Form>


                      </Modal.Body>

                    </Modal>


                    {/* delete the selected amenity */}
                    <Modal show={showModalFacilitiesDelete} onHide={handleCloseModalFaciltiesDelete}>
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Facilities For This Apartment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>

                        <Form onSubmit={handleDeleteFacility}>
                          <div className="input-icons">
                            <FormSelect
                              className="formProperty"
                              value={formDataFacilityDelete.icon || ''} // Update the value property
                              onChange={handleChangeFacilityDelete('icon')} // Pass the name as an argument
                            >
                              <option disabled value="">
                                Select Facility To Delete
                              </option>
                              {nearbyFacilitiesApartment.length > 0 ? (
                                nearbyFacilitiesApartment.map((facility) => (
                                  <option
                                    key={facility.id}
                                    value={facility.id}
                                  >
                                    {facility.icon}
                                  </option>
                                ))
                              ) : (
                                <p>No Amenities Found For This Apartment. Kindly Add One.</p>
                              )}
                            </FormSelect>

                          </div>

                          <Button className="nextBtn btn-danger border-0 mt-3" type="submit">
                            Delete Amenity
                          </Button>
                        </Form>


                      </Modal.Body>

                    </Modal>
                  </Col>
                </Row>

                {/* property rules */}
                <Row>
                  <Col md={12} className='fw-bold mb-2 fs-6 text-start' style={{ color: "#16a085" }}>
                    PROPERTY RULES
                  </Col>

                </Row>

                <Row>
                  {rules.length > 0 ?
                    uniqueCategoriesArray.map((category) => (
                      <Col md={12} className="amenties text-start d-flex">
                        {category === 'safety' ?
                          <Image src={Safety} style={{ width: "7%", marginBottom: "1rem" }} />
                          : category === 'rental_policy' ?
                            <Image src={Rental} style={{ width: "7%", marginBottom: "1rem" }} />
                            :
                            <Image src={Property} style={{ width: "7%", marginBottom: "1rem" }} />

                        }
                        <p className='ms-3 d-flex text-capitalize w-100'>{toCamelCase(category)}</p>
                        <Link className='' to={`/rules/${category}/${id}`} style={{ width: "100%", position: "relative", textAlign: "right", fontSize: '0.8rem' }}>View Rules</Link>

                      </Col >
                    ))
                    :
                    <p style={{ color: "#95a5a6" }}>No rules found for this property kindly add one</p>
                  }
                </Row>


                <Row>
                  <Col md={12} className='fw-bold mb-2 fs-6'>
                    <div className='amenitiesDiv'>
                      {uniqueCategoriesArray.length === 3 ?
                        <></>
                        :
                        <span><i className='bi bi-plus bg-primary text-white p-2' onClick={handleOpenModalRulesAdd}></i></span>

                      }


                    </div>

                    {/* add the rules to the apartment */}
                    <Modal show={showModalRules} onHide={handleCloseModalRulesAdd}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add Rules for this property</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleRules}>
                          <div className="input-icons">

                            <FormSelect className="formProperty" value={formDataRules.category} onChange={handleChangeRulesCategory}>
                              <option value="">Select Rules Category</option>
                              {rulesCategory.map((rule) => (
                                <option key={rule.value} value={rule.value}>
                                  {rule.name}
                                </option>
                              ))}
                            </FormSelect>


                            <div className="input-icons mt-3 w-100 p-0">
                              <i className="fa fa-hashtag icon"></i>
                              <textarea
                                className="input-field"
                                type="text"
                                name="description"
                                rows={4}
                                value={formDataRules.description}
                                onChange={handleChangeRules}
                                placeholder={"Description of the rules"}
                                required
                              />
                            </div>

                          </div>


                          <Button className='nextBtn mt-3' type='submit'>Save</Button>
                        </Form>
                      </Modal.Body>

                    </Modal>

                  </Col>
                </Row>


              </Card.Body>

            </Card>

          </Col>
        </Row>
      </div >
    </>
  )
}

export default SelectedProperty
