import React, { useEffect, useState } from 'react';
// styles
import './WalletLogin.scss';

// importing components
import HomeCarousel from '../../components/HomeCarousel';
import HomeFooter from '../../components/HomeFooter';
import { Link } from "react-router-dom";
import { Form, Button, Modal } from "react-bootstrap";
import { serverUrl } from "../../serverUrl";
import axios from 'axios';
import jwt_decode from "jwt-decode"

import { useNavigate } from 'react-router'
import { toast } from 'react-toastify';
import TermsConditions from "../newDashboard/TermsConditions";
import Privacy from "../newDashboard/Privacy";

function WalletLogin() {
    // setting up the form with the data that it will contain
    const [formData, setFormData] = useState({
        fullname: "",
        email: "",
        phoneNumber: "",
        gender: "",
        password: "",
        confirmPassword: "",
    });

    // this are the constants that are used in the setting up of the user's tokens
    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    let [user, setUser] = useState('')
    const navigate = useNavigate();

    // the counting of the phone number digits restricted to count only 10 digits
    const digitCount = formData.phoneNumber.replace(/[^0-9]/g, "").length;


    // the onchange function when some changes are done on a field
    const handleChange = (event) => {
        const { name, value } = event.target;

        // limit phone number input to 10 digits
        if (name === "phoneNumber") {
            const digitOnly = value.replace(/[^0-9]/g, "");
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: digitOnly.substring(0, 10),
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };
    const property_id_wallet = localStorage.getItem("wallet_property")


    const yellowHappyFace = '\u{1F600}';
    // the main function to be carried out on submit of the form
    function handleSubmit(e) {
        // prevent form from redirecting to another page
        e.preventDefault();

        try {
            serverUrl
                // hit endpoint and check if user is there and connect user
                .post("/users/login/", {
                    phone_number: formData.phoneNumber,
                    password: formData.password,
                })
                .then((res) => {
                    // when user has been found get the access token and store the token and navigate them to the home page
                    const data = res.data.data
                    setAuthTokens(data.tokens)
                    setUser(jwt_decode(data.tokens.access))
                    localStorage.setItem('authTokens', JSON.stringify(data.tokens))
                    toast.success(`Login succesful`)

                    const config = {
                        headers: { Authorization: `Bearer ${data.tokens.access}` }
                    }
                    serverUrl.get(`/landlord/landlord/?user_id=${res.data.data.id}`, config)
                        .then((res) => {
                            const data = {
                                landlord: res.data.results[0].id,
                                apartment: parseInt(property_id_wallet),
                            }
                            // check the otp if its there then delete
                            serverUrl.get(`/landlord/landlord-wallet-otp/?landlord=${res.data.results[0].id}&apartment=${parseInt(property_id_wallet)}`, config)
                                .then((res) => {
                                    if (res.data.count === 0) {
                                        serverUrl.post(`/landlord/landlord-wallet-otp/`,data,config)
                                        .then((res)=>{
                                            navigate('/wallet-otp')

                                        })
                                        .catch((error)=>{
                                            console.log(error)
                                        })
                                    }
                                    else {
                                        res.data.results.map((otp) => (
                                            serverUrl.delete(`/landlord/landlord-wallet-otp/${otp.id}/`, config)
                                                .then((res) => {
                                                    console.log(res)
                                                })
                                                .catch((error) => {
                                                    console.log(error)
                                                })
                                        ))
                                    }

                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                            // pass the details to the wallet otp so that the user can get the otp

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        toast.error('Wrong Credentials Check Again Credentials!')
                    } else {
                        console.log('Unexpected error: ' + error.message);
                    }
                });
        } catch (error) {
            console.log(error.response)

        }
    }



    function handleLogout() {
        // clear auth tokens and user data on logout
        setAuthTokens(null);
        localStorage.removeItem('authTokens');
        navigate('/login')
    }


    function updateTokens() {
        // check if access token has expired and update tokens
        const decoded = jwt_decode(authTokens.access);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            axios
                .post('/users/token/refresh/', { refresh: authTokens.refresh })
                .then((res) => {
                    handleSubmit(res.data.data.tokens);
                })
                .catch(() => {
                    handleLogout();
                });
        }
    }

    useEffect(() => {
        // check if auth tokens exist and update tokens every 10 minutes
        if (authTokens) {
            const interval = setInterval(() => {
                updateTokens();
            }, 60000);
            return () => clearInterval(interval);
        }
    }, [authTokens]);

    // modal to display the terms and conditions
    const [showModalTerms, setShowModalTerms] = useState(false);

    const handleOpenModalTerms = () => {
        setShowModalTerms(true);
    };

    const handleCloseModalTerms = () => {
        setShowModalTerms(false);
    };

    // modal to display the privacy
    const [showModalPrivacy, setShowModalPrivacy] = useState(false);

    const handleOpenModalPrivacy = () => {
        setShowModalPrivacy(true);
    };

    const handleCloseModalPrivacy = () => {
        setShowModalPrivacy(false);
    };

    // modal for forgot password
    const [showModalForgot, setShowModalForgot] = useState(false)
    const handleForgotPassword = () => {
        setShowModalForgot(true)
    }
    const handleForgotPasswordClose = () => {
        setShowModalForgot(false)
    }

    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 13) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 13) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/LandingPage/${randomNumber}.jpg`;
            setBackgroundImageApartment(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
        };

        getRandomImage();
    }, []);

    const images = [backgroundImageApartment];

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    return (
        <div className='homeCard'>
            <div className="image-container">
                <HomeCarousel images={images} className="imageHome" />
                <div className="wave"></div>
            </div>
            <div className='main-content '>

                <h2 className='text-capitalize fs-5 fw-bold mt-3'>Welcome to kodi wallet kindly login to get your otp</h2>

                <Form onSubmit={handleSubmit}>
                    <div className="input-icons">
                        <i className="fa fa-phone icon"></i>
                        <input
                            className="input-field"
                            type="number"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <p
                        style={{
                            fontSize: "12px",
                            paddingRight: "20px",
                            float: "right",
                        }}
                    >
                        {digitCount}/10
                    </p>

                    <div className="input-icons">
                        <i className="fa fa-lock icon"></i>
                        <input
                            className="input-field"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                        <span style={{ cursor: "pointer" }} onClick={handleTogglePassword}>
                            {showPassword ? <i className="bi bi-eye-fill" style={{ marginLeft: "-2rem", padding: "10px" }} /> : <i className="bi bi-eye-slash-fill" style={{ marginLeft: "-2rem", padding: "10px" }} />}
                        </span>

                    </div>



                    <Button variant="primary" type="submit" className="loginBtn" onClick={handleForgotPassword}>
                        Login
                    </Button>
                </Form>
            </div>

        </div>
    );
}

export default WalletLogin;
