import React, { useState, useEffect } from "react";

import "./Login.css";
import { Link } from "react-router-dom";
import { Form, Button, Modal } from "react-bootstrap";
import { serverUrl } from "../../serverUrl";
import axios from 'axios';
import jwt_decode from "jwt-decode"

import { useNavigate } from 'react-router'
import { toast } from 'react-toastify';
import TermsConditions from "../newDashboard/TermsConditions";
import Privacy from "../newDashboard/Privacy";

function ForgotPasword() {
    const navigate = useNavigate();

    // setting up the form with the data that it will contain
    const [formData, setFormData] = useState({
        email:'',
    });

    // the onchange function when some changes are done on a field
    const handleChange = (event) => {
        const { name, value } = event.target;
        {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const yellowHappyFace = '\u{1F600}';
    // the main function to be carried out on submit of the form
    function handleSubmit(e) {
        // prevent form from redirecting to another page
        e.preventDefault();

        try {
            serverUrl
                // hit endpoint and check if user is there and connect user
                .post("/users/request-reset-email/", {
                    email: formData.email,
                    redirect_url: 'https://landlord.kodinyumba.compassword-reset/',
                })
                .then((res) => {
                    toast.success(`Check your email we have succesfully reset the password`)
                    navigate('/login')
                })
                .catch((error) => {
                    console.log('Unexpected error: ' + error.message);

                });
        } catch (error) {
            console.log(error.response)

        }
    }
 

    return (
        <div className="loginForm h-75">
            <h2>Forgot your pasword? No worries enter your email so that we can reset it</h2>
            <p className="text-muted">Best tool to manage your property</p>
            <Form onSubmit={handleSubmit}>
                <div className="input-icons">
                    <i className="fa fa-envelope icon"></i>
                    <input
                        className="input-field"
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <Button variant="primary" type="submit" className="loginBtn">
                    Submit
                </Button>
            </Form>
        </div>
    );
}

export default ForgotPasword;
