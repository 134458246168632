import React, { useState } from "react";

const data = [
  { item: "Banana", price: 2.5 },
  { item: "Apple", price: 1.8 },
  { item: "Orange", price: 3.2 },
];

const Table = () => {
  const [sortType, setSortType] = useState("item");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSort = (sortField) => {
    setSortType(sortField);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };

  const sortedData = data.sort((a, b) => {
    const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === "asc") {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  const handleRowClick = (item) => {
    setSelectedRow(item);
  };

  return (
    <div>
      <input type="text" placeholder="Search" onChange={handleSearch} />
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort("item")}>Item</th>
            <th onClick={() => handleSort("price")}>Price</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => (
            <tr key={item.item} onClick={() => handleRowClick(item)}>
              <td>{item.item}</td>
              <td>{item.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedRow && (
        <div>
          <h2>Selected Item:</h2>
          <p>{selectedRow.item}</p>
          <p>Price: {selectedRow.price}</p>
        </div>
      )}
    </div>
  );
};

export default Table;
