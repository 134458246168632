import { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from 'react-router'
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from 'react-toastify';



function Statutory() {

  // the data that will be sent to the database
  const [formData, setFormData] = useState({
    kra: "",
    id: "",
  });


  const digitCount = formData.kra.replace(/\s/g, "").length; // Count only digits
  const navigate = useNavigate();

  // changing events
  const handleChange = (event) => {
    const { name, value } = event.target;

    // limit kra input to 11 digits
    if (name === "kra") {
      const digitOnly = value.replace(/\s/g, "");
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: digitOnly.substring(0, 11),
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };



  //submit user details fuction
  function handleSubmit(e) {
    // prevent form from redirecting to another page
    e.preventDefault();

    // get the token of user after they have registered
    const accessToken = localStorage.getItem("authTokens");

    // decode the tokens so that you can get the user_id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // the data that will be posted to the api is as follows
    const data = {
      user: userId,
      kra_pin: formData.kra,
      id_number: formData.id,
    };

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // header's access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }

    //  now post the data

    serverUrl

      //send landlord details using the endpoint
      .post("/landlord/landlord/", data, config)
      .then((res) => {
        localStorage.setItem('landlord', JSON.stringify(res.data.id))
        toast.success('Details added Successfully')
        // get the registered user
        serverUrl.get(`/users/users-details/${userId}/`, config)
          .then((res) => {
            // if the user is in the pending landlord chech them out
            serverUrl.get(`/landlord/pending-landlord/?phone_number=${res.data.phone_number}`)
              .then((res) => {
                if (res.data.count > 0) {
                  const landlordId = localStorage.getItem("landlord");
                  // add the user in the landlord apartment table
                  const landlordData = {
                    landlord: landlordId,
                    landlord_category: res.data.results[0].landlord_category,
                    apartment: res.data.results[0].apartment,
                    landlord_type: res.data.results[0].landlord_type,
                  }
                  serverUrl.post(`/landlord/landlord-apartment/`, landlordData, config)
                    .then((res) => {
                    })
                  // if they are found then delete them from the pending tenant table
                  serverUrl.delete(`/landlord/pending-landlord/${res.data.results[0].id}/`)
                    .then((res) => {

                    })
                    .catch((error) => {
                      console.log(error)
                    })
                    navigate('/home') 
                }
                else {
                  navigate('/add-property') 
                }

              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })
        


      })

      // displaying the messages of why the error 
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error('Operation Failed: ' + error.response.data.error)
        } else {
          console.log('Unexpected error: ' + error.message);
        }
      });


  }

  // if user wants to skip then pass the user id only
  function submitSkip(e) {
    // prevent form from redirecting to another page
    e.preventDefault();

    // get the token of user after they have registered
    const accessToken = localStorage.getItem("authTokens");

    // decode the tokens so that you can get the user_id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // the data that will be posted to the api is as follows
    const data = {
      user: userId,

    };

    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // header's access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }

    //  now post the data

    serverUrl

      //send landlord details using the endpoint
      .post("/landlord/landlord/", data, config)
      .then((res) => {
        localStorage.setItem('landlord', JSON.stringify(res.data.id))
        toast.success('Details added Successfully')
        // get the registered user
        serverUrl.get(`/users/users-details/${userId}/`, config)
          .then((res) => {
            // if the user is in the pending landlord chech them out
            serverUrl.get(`/landlord/pending-landlord/?phone_number=${res.data.phone_number}`)
              .then((res) => {
                if (res.data.count > 0) {
                  const landlordId = localStorage.getItem("landlord");
                  // add the user in the landlord apartment table
                  const landlordData = {
                    landlord: landlordId,
                    landlord_category: res.data.results[0].landlord_category,
                    apartment: res.data.results[0].apartment,
                    landlord_type: res.data.results[0].landlord_type,
                  }
                  serverUrl.post(`/landlord/landlord-apartment/`, landlordData, config)
                    .then((res) => {
                    })
                  // if they are found then delete them from the pending tenant table
                  serverUrl.delete(`/landlord/pending-landlord/${res.data.results[0].id}/`)
                    .then((res) => {

                    })
                    .catch((error) => {
                      console.log(error)
                    })
                    navigate('/home') 
                }
                else {
                  navigate('/add-property') 
                }

              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })
        


      })

      // displaying the messages of why the error 
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error('Operation Failed: ' + error.response.data.error)
        } else {
          console.log('Unexpected error: ' + error.message);
        }
      });


  }

  return (
    <div className="registerForm">

      <h2>Statutory Information</h2>

      <Form onSubmit={handleSubmit}>
        <div className="input-icons">
          <i className="fa fa-user icon"></i>
          <input
            className="input-field"
            type="text"
            placeholder="KRA Pin"
            name="kra"
            value={formData.kra}
            onChange={handleChange}
            
          />
        </div>
        <p
          style={{
            fontSize: "12px",
            paddingRight: "20px",
            float: "right",
          }}
        >
          {digitCount}/11
        </p>
        <div className="input-icons">
          <i className="fa fa-envelope icon"></i>
          <input
            className="input-field"
            type="number"
            placeholder="ID Number"
            name="id"
            value={formData.id}
            onChange={handleChange}
            
          />
        </div>

        <Button variant="primary" type="submit" className="nextBtn">
          Register
        </Button>
      </Form>
      <Link to="/add-property">
        <Button variant="primary" type="button" className="nextBtn" onClick={submitSkip}>
          Skip
        </Button>
      </Link>
    </div>
  );
}

export default Statutory;
