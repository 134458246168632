import React, { useState, useEffect } from 'react'
import { Container, Image, Card, FormSelect, Button, Row, Col, Form, Dropdown, FormControl, Modal, ModalBody } from 'react-bootstrap';
import Header from './Header'
import './SelectedPropertyUtilities.scss'
import SideBar from './SideBar'
import House5 from '../../assets/img/house5.jpg'
import loaderImage from '../../assets/img/logo.png'
import { useParams, Link } from "react-router-dom";

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

function SelectedPropertyUtilities() {
  const [showSidebar, setShowSidebar] = useState(true);
  const { id } = useParams();
  // houses
  const [house, setHouse] = useState([])
  const houseImage = {}
  const houseTenant = {}
  const occupiedHouse = {}
  const [houseSetImage, setHouseSetImage] = useState({})
  const [houseSetTenant, setHouseSetTenant] = useState({})
  const [houseOccupied, setHouseOccupied] = useState({})
  const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());
  const [utilityStatus, setUtilityStatus] = useState({})
  const paymentStatus = {}

  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }
  // get the current month so that i can filter out the total income
  const months = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(new Date().getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });

  // Get the current month automatically
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];

  const currentYear = new Date().getFullYear();

  // get the selected apartment details
  // loading status
  const [isLoading, setIsLoading] = useState(true)

  // apartment
  const [apartmentSelected, setApartmentSelected] = useState([])
  // utilities
  const [utilitiesCount, setUtilitiesCount] = useState(0)
  const [utilities, setUtilities] = useState([])

  const accessToken = localStorage.getItem("authTokens");
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;


  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }
  // decoding the token so that i can get the user id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;

  useEffect(() => {
    // set the loading status
    setIsLoading(true);

    serverUrl
      .get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);

        // Create an array to store all the requests
        const requests = landlordIds.map((landlordId) => {
          return serverUrl
            .get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
            .then((res) => {
              return res.data.results;
            })
            .catch((error) => {
              console.log(error);
              return [];
            });
        });

        // Wait for all requests to complete
        Promise.all(requests)
          .then((results) => {
            // Merge the results into a single array
            const mergedResults = [].concat(...results);

            setApartmentSelected(mergedResults);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
      });

  }, [userId]);

  const selectedApartmentUtilities = apartmentSelected.find((utilitiesApartment) => utilitiesApartment.id === parseInt(id));


  // get the utilities of each apartment
  useEffect(() => {
    // set the loading status
    setIsLoading(true);

    serverUrl
      .get(`/landlord/expense/?apartment_id=${id}`, config)
      .then((res) => {
        setUtilitiesCount(res.data.count)
        setUtilities(res.data.results)
        setIsLoading(false); // Set loading state to false when data is fetched

      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);


  // get the houses in the selected apartment
  useEffect(() => {
    // set the loading status
    setIsLoading(true);

    serverUrl.get(`/landlord/react-house-apartment-tenant/?apartment_id=${id}`, config)
      .then((res) => {
        setHouse(res.data.results)
        // map through the houses 
        const UtilityStatus = {}

        res.data.results.map((house) => {
          // get the houses with the utilities amount paid

          serverUrl.get(`/landlord/tenant-invoice/?reference=expense`, config)
            .then((res) => {
              res.data.results.map((paidUtility) => {
                UtilityStatus[house.id] = paidUtility.status


              })
            })
            .catch((error) => {
              console.log(error)
            })


          // get the images per each house
          serverUrl.get(`/landlord/house-images/?house_id=${house.id}`, config)
            .then((res) => {
              houseImage[res.data.results[0].house] = res.data.results[0].image
              setIsLoading(false); // Set loading state to false when data is fetched

            })
            .catch((error) => {
              console.log(error);

            });

        })
        setHouseSetImage(houseImage)

        // get the tenants of the houses
        res.data.results.map((house) => {
          serverUrl.get(`/tenant/house-tenant-details?house_id=${house.id}`, config)
            .then((res) => {
              houseTenant[house.id] = res.data.results[0].user
              setIsLoading(false); // Set loading state to false when data is fetched

            })
            .catch((error) => {
              console.log(error);

            });

        })

        // get the utility payment status and see
        res.data.results.map((house) => {
          serverUrl.get(`/landlord/tenant-invoice/?house_id=${house.id}&reference=expense`, config)
            .then((res) => {
              console.log(res)
              if (res.data.results[0].status == "paid" && res.data.results[0].month == currentMonth.toLowerCase()) {
                paymentStatus[res.data.results[0].house] = res.data.results[0].amount


              } else {
                console.log(res.data.results[0].status)
              }
            })
            .catch((error) => {
              console.log(error)
            })
        })
        setUtilityStatus(paymentStatus)
        setHouseSetTenant(houseTenant)
        setIsLoading(false);

      })
      .catch((error) => {
        console.log(error);

      });

    serverUrl.get(`/landlord/display-occupied-houses-in-expenses/?apartment_id=${id}`, config)
      .then((res) => {
        // get the house id each
        res.data.results.map((occupied) => {
          occupiedHouse[occupied.id] = occupied.id
        })
        setHouseOccupied(occupiedHouse)
        setIsLoading(false); // Set loading state to false when data is fetched

      })

      .catch((error) => {
        console.log(error);

      });

  }, [id]);


  // modal when landlord clicks the button for payment
  const [showModal, setShowModal] = useState(false);


  // updates on saving the water units
  const [unitsAdded, setUnitsAdded] = useState(0);
  const handleSaveUnits = (units) => {
    setUnitsAdded(units);
    setShowModal(false);
  }
  // the state handling of the tabs of the utilities
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabId, setActiveTabId] = useState(0);

  const [UtilityClicked, setUtilityClicked] = useState([])
  const [UtilityClickedType, setUtilityClickedType] = useState([])
  const [UtilityClickedId, setUtilityClickedId] = useState([])

  // get the details of the selected tab
  const utilityDetailTab = {}
  const utilityDetailTabId = {}
  const utilityDetailTabIdType = {}

  const handleTabClick = (tabIndex, event) => {
    const expenseId = event.target.getAttribute('expenseId');

    utilities.map((utility) => {
      utilityDetailTab[utility.title] = utility.cost_per_unit
      utilityDetailTabIdType[utility.title] = utility.expense_type
      utilityDetailTabId[utility.title] = utility.id
    })
    setActiveTabId(expenseId)
    setActiveTab(tabIndex);
    setUtilityClicked(utilityDetailTab)
    setUtilityClickedId(utilityDetailTabId)
    setUtilityClickedType(utilityDetailTabIdType)
  };

  // Getting the selected modal in the house

  // add utilities in the apartment
  const [showModalAdd, setShowModalAdd] = useState(false);
  // modal for adding apartment
  const handleOpenModalAdd = () => {
    setShowModalAdd(true);
  };

  const handleCloseModalAdd = () => {
    setShowModalAdd(false);
  };
  const handleCloseModalReload = () => {
    setShowModalAdd(false);
    window.location.reload()
  };


  // delete the selected utility
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [deleteUtility, setDeleteUtility] = useState(null);


  const handleOpenModalDelete = utilityId => {
    setDeleteUtility(utilityId);

    setShowModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setShowModalDelete(false);
  };

  const handleDeleteUtility = () => {

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    // the passed id from the modal
    const id = deleteUtility

    // delete the selected utility
    serverUrl.delete(`/landlord/expense/${id}/`, config)
      .then((res) => {
        setDeleteUtility(null);
        window.location.reload()
        toast.success("Utility Deleted!")
        setIsLoading(false);

      }, [])
      .catch((error) => {
        console.log(error);
      });

  }



  // edit the utility 
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [editUtility, setEditUtility] = useState(null);

  const handleOpenModalEdit = utilityId => {
    setEditUtility(utilityId);
    setShowModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  };
  // the data related with the form
  const [formDataEdit, setFormDataEdit] = useState({
    title: "",
    frequency: "",
    cost_per_unit: "",
    expense_type: ""
  });

  //   handling the change of the data in the form
  const handleChangeEdit = (event) => {
    const { name, value } = event.target;

    setFormDataEdit((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

  };

  // submit the data
  function handleSubmitEdit(e) {
    // prevent form from redirecting to another page
    e.preventDefault();

    // data to be sent to the backend
    const dataEdit = {
      title: activeTab,
      frequency: formDataEdit.frequency.toLowerCase(),
      cost_per_unit: formDataEdit.cost_per_unit,
      expense_type: formDataEdit.expense_type.toLowerCase(),
      apartment: parseInt(id),
    }
    // send the data to the backend 
    try {

      serverUrl.put(`/landlord/expense/${editUtility}/`, dataEdit, config)
        .then((res) => {
          console.log(res)
          toast.success("Utility Succesffuly Edited!")
          window.location.reload()

        })
        .catch((error) => {
          toast.error(error)
        })
    }
    catch (error) {
      console.log(error);
    }
  }


  // add the  units in the house
  const [showModalAddWaterUnit, setShowModalAddWaterUnit] = useState(false);
  const [selectedHouseId, setSelectedHouseId] = useState(null);
  const [selectedUtility, setSelectedUtility] = useState(null);

  // button clicked to open the modal
  const handleButtonClick = (event) => {
    // get the utilities of the card when clicked and the house id of the clicked card
    const houseId = event.target.getAttribute('houseid');
    const utility = event.target.getAttribute('utility');

    setSelectedHouseId(houseId);
    setSelectedUtility(utility)
    setShowModalAddWaterUnit(true);
  }
  // the modal set for adding the unit in the house
  const handleOpenModalWater = () => {
    setShowModalAddWaterUnit(true);
  };
  const handleCloseModalWater = () => {
    setShowModalAddWaterUnit(false);
  };
  const handleCloseModalWaterReload = () => {
    setShowModalAddWaterUnit(false);
    window.location.reload()

  };


  // edit the  units in the house
  const [showModalEditUnit, setShowModalEditUnit] = useState(false);

  // button clicked to open the modal
  const handleButtonClickEdit = (event) => {
    // get the utilities of the card when clicked and the house id of the clicked card
    const houseId = event.target.getAttribute('houseid');
    const utility = event.target.getAttribute('utility');

    setSelectedHouseId(houseId);
    setSelectedUtility(utility)
    setShowModalEditUnit(true);
  }
  // the modal set for adding the unit in the house
  const handleOpenModalEditUnit = () => {
    setShowModalEditUnit(true);
  };
  const handleCloseModalEditUnit = () => {
    setShowModalEditUnit(false);
  };
  const handleCloseModalEditUnitReload = () => {
    setShowModalEditUnit(false);
    window.location.reload()

  };

  // the data related with the form
  const [formData, setFormData] = useState({
    title: "",
    frequency: "",
    cost_per_unit: "",
    expense_type: ""
  });

  //   handling the change of the data in the form
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

  };
  // submit the data
  function handleSubmit(e) {
    // prevent form from redirecting to another page
    e.preventDefault();

    // data to be sent to the backend
    const data = {
      title: formData.title,
      frequency: formData.frequency.toLowerCase(),
      cost_per_unit: formData.cost_per_unit,
      expense_type: formData.expense_type.toLowerCase(),
      apartment: parseInt(id),
    }
    // send the data to the backend 
    try {
      serverUrl.post('/landlord/expense/', data, config)
        .then((res) => {
          console.log(res)
          toast.success("Utility Succesffuly Added!")
          setFormData({
            title: "",
            frequency: "",
            cost_per_unit: "",
            expense_type: ""
          })
        })
        .catch((error) => {
          toast.error(error)
        })
    }
    catch (error) {
      console.log(error);
    }
  }


  // the data related with the form
  const [formDataWater, setFormDataWater] = useState({
    units_consumed: "",
    amount: "",
    house: "",
    expense: ""
  });

  //   handling the change of the data in the form
  const handleChangeWater = (event) => {
    const { name, value } = event.target;

    setFormDataWater((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

  };
  // ADD units of water form handleSubmitWater
  function handleSubmitWater(e) {
    // prevent form from redirecting to another page
    e.preventDefault();
    // data to be sent to the backend
    const data = {
      units_consumed: formDataWater.units_consumed,
      amount: parseInt(UtilityClicked[activeTab] * formDataWater.units_consumed),
      house: selectedHouseId,
      expense: selectedUtility,
      month: currentMonth.toLowerCase(),
      year: currentYear
    }
    // send the data to the backend 
    try {
      serverUrl.post('/landlord/variable-expense-units-consumed/', data, config)
        .then((res) => {
          toast.success("Units Added Successfully!")
          setFormDataWater({
            units_consumed: "",
            amount: "",

          })
          window.location.reload()
        })
        .catch((error) => {
          toast.error(error)
        })
    }
    catch (error) {
      console.log(error);
    }
  }

  // edit theunits
  function handleSubmitEditUnits(e) {
    // prevent form from redirecting to another page
    e.preventDefault();
    // data to be sent to the backend
    const data = {
      units_consumed: formDataWater.units_consumed,
      amount: parseInt(UtilityClicked[activeTab] * formDataWater.units_consumed),
      house: selectedHouseId,
      expense: selectedUtility,
      month: currentMonth.toLowerCase(),
      year: currentYear
    }
    //first get the data of the selected unit and get its id
    serverUrl.get(`/landlord/variable-expense-units-consumed/?house_id=${selectedHouseId}&expense_id=${selectedUtility}`, config)
      .then((res) => {
        const UnitToEdit = res.data.results.filter((variable) => variable.month == currentMonth.toLowerCase() && variable.year == currentYear)[0]
        try {
          serverUrl.patch(`/landlord/variable-expense-units-consumed/${UnitToEdit.id}/`, data, config)
            .then((res) => {
              toast.success("Units Edited Successfully!")
              setFormDataWater({
                units_consumed: "",
                amount: "",

              })
              window.location.reload()
            })
            .catch((error) => {
              toast.error(error)
            })
        }
        catch (error) {
          console.log(error);
        }

      })
      .catch((error) => {
        console.log(error)
      })

  }

  // get the expenses amount in houses
  const [unitHouseCost, setUnitHouseCost] = useState([])
  const [unitsHouse, setUnitsHouse] = useState([])
  const [unitHousePayment, setUnitHousePayment] = useState([])


  // get the units number or amount to be paid by the tenant
  useEffect(() => {
    setIsLoading(true);

    try {
      serverUrl.get(`/landlord/variable-expense-units-consumed/?expense_id=${activeTabId}`, config)
        .then((res) => {
          // loop the units and the expenses
          const houseUnitCost = {}
          const houseUnits = {}
          const currentUnit = res.data.results.filter((currentUnits) => currentUnits.month == currentMonth.toLowerCase() && currentUnits.year == currentYear)
          currentUnit.map((unitCost) => {
            houseUnitCost[unitCost.house] = unitCost.amount
            houseUnits[unitCost.house] = unitCost.units_consumed
          })
          // the amount of unit expenses per house
          setUnitHouseCost(houseUnitCost)
          // the amount of unit consumed per house
          setUnitsHouse(houseUnits)
          setIsLoading(false); // Set loading state to false when data is fetched

        })
        .catch((error) => {
          toast.error(error)
        })

    }
    catch (error) {
      console.log(error);
    }


  }, [activeTabId]);

  // randomise for each House
  const [backgroundImageHouse, setBackgroundImageHouse] = useState('');

  // send reminder for the landlord
  const [remindersModal, setRemindersModal] = useState(false)

  // modal for filtering on the month
  const handleReminderModalOpen = () => {
    setRemindersModal(true);
  };

  const handleReminderModalClose = () => {
    setRemindersModal(false);
  };
  const handleReminderModalReload = () => {
    setRemindersModal(false);
    window.location.reload()
  };

  const [selectedOptionReminder, setSelectedOptionReminder] = useState(null);
  const [addTemplate, setAddTemplate] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOptionReminder(option);
    setAddTemplate(false)
  };

  const handleAddTemplate = () => {
    setSelectedOptionReminder('')
    setAddTemplate(true)
  }

  useEffect(() => {
    const getRandomImage = () => {
      const usedImages = Object.values(backgroundImageHouse);
      const availableImages = Array.from({ length: 21 }, (_, index) => index + 1);
      const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
      const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
      const imageUrl = `https://kodinyumba.app/media/Stock/House/house${randomNumber}.jpg`;
      return imageUrl;
    };

    const updateBackgroundImages = () => {
      const updatedImages = { ...backgroundImageHouse };

      // Loop through each house and update the image if not set
      for (const unitDetail of house) {
        const houseId = unitDetail.house_id;
        if (!updatedImages[houseId]) {
          updatedImages[houseId] = getRandomImage();
        }
      }
      setBackgroundImageHouse(updatedImages);
      setIsLoading(false);
    };

    updateBackgroundImages();
  }, [house]);

  // get the randomised images for each of the utilities provided
  const [backgroundImages, setBackgroundImages] = useState([]);

  useEffect(() => {
    const getRandomImages = () => {
      const getImageUrl = (category, number) => `https://kodinyumba.app/media/Stock/hd/Utility/${category}/${category}${number}.jpg`;

      const categories = ['security', 'water', 'garbage', 'internet', 'electricity'];
      const randomImages = categories.map((category) => {
        const maxImageNumber = getCategoryMaxImageNumber(category);
        const randomNumber = Math.floor(Math.random() * maxImageNumber) + 1;
        return getImageUrl(category, randomNumber);
      });

      setBackgroundImages(randomImages);
    };

    const getCategoryMaxImageNumber = (category) => {
      switch (category) {
        // Set the maximum image number for security category
        case 'security':
          return 9;
        // Set the maximum image number for water category
        case 'water':
          return 6;
        // Set the maximum image number for garbage category
        case 'garbage':
          return 8;
        // Set the maximum image number for internet category
        case 'internet':
          return 5;
        // Set the maximum image number for electricity category
        case 'electricity':
          return 10;

      }
    };

    getRandomImages();
  }, []);

  // randomise the images for the apartment
  const [backgroundImageApartment, setBackgroundImageApartment] = useState('');

  useEffect(() => {
    const getRandomImage = () => {
      let storedImage = localStorage.getItem('backgroundImage');
      if (!storedImage) {
        storedImage = '';
      }
      let randomNumber = Math.floor(Math.random() * 10) + 1;
      while (storedImage.endsWith(`${randomNumber}.jpg`)) {
        randomNumber = Math.floor(Math.random() * 10) + 1;
      }
      const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
      setBackgroundImageApartment(imageUrl);
      localStorage.setItem('backgroundImage', imageUrl);
      setIsLoading(false);
    };

    getRandomImage();
  }, []);
  const utilitiesInApartment = [
    { name: 'Water', value: 'Water' },
    { name: 'Electricity', value: 'Electricity' },
    { name: 'Garbage', value: 'Garbage' },
    { name: 'Security', value: 'Security' },
    { name: 'Internet', value: 'Internet' },
  ];

  const utilitiesPresent = [];
  const utilitiesAbsent = [];

  utilitiesInApartment.forEach((apartmentUtility) => {
    // Check if there is a utility with the same title in utilities
    const matchingUtility = utilities.find(
      (utility) => apartmentUtility.name === utility.title
    );

    // Include the utility in the appropriate array based on presence or absence
    if (matchingUtility) {
      utilitiesPresent.push(matchingUtility);
    } else {
      utilitiesAbsent.push(apartmentUtility);
    }
  });



  // if the data is loading show a message
  if (isLoading) {
    return (
      <div className="loader-container">
        <img src={loaderImage} alt="Loading..." />
      </div>
    );
  }


  return (
    <>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}
      {selectedApartmentUtilities ?
        <div className='selected-property'>

          {utilitiesCount === 0 ?
            <>
              {!activeTab &&

                <div className='topSelected' style={{ backgroundImage: `url(${backgroundImageApartment})` }}>

                  <div className='ImageHouseName'>
                    <div classname='buttons_span'>
                      <span className='mt-5'>{selectedApartmentUtilities.location}</span>
                      {/* <Button className='btn btn-sm btn-success ms-3 me-2'>Edit Utility</Button> */}
                      <Button className='btn btn-sm btn-primary' onClick={handleOpenModalAdd}>Add Utility</Button>
                    </div>

                    <h2 className=''>{selectedApartmentUtilities.name}</h2>
                  </div>

                </div>

              }
              {/* modal for adding new utility */}
              <Modal show={showModalAdd} onHide={handleCloseModalAdd}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Utility
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  <>
                    <Form onSubmit={handleSubmit}>

                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <select className='addUtiilitySelect' name='title' onChange={handleChange} value={formData.title}>
                          <option disabled value="">Select Utility</option>
                          <option value="Water">Water</option>
                          <option value="Electricity">Electricity</option>
                          <option value="Garbage">Garbage</option>
                          <option value="Security">Security</option>
                          <option value="Internet">Internet</option>
                        </select>
                      </div>

                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <select className='addUtiilitySelect' name='frequency' onChange={handleChange} value={formData.frequency}>
                          <option disabled value="">Select Utility Payment Frequency</option>
                          <option value="monthly">Monthly</option>
                          <option value="daily">Daily</option>
                          <option value="annually">Annually</option>
                          <option value="weekly">Weekly</option>
                        </select>
                      </div>

                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <input
                          className="input-field"
                          type="number"
                          name="cost_per_unit"
                          placeholder='Enter Cost Per unit'
                          onChange={handleChange}
                          value={formData.cost_per_unit}
                          required
                        />
                      </div>
                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <select className='addUtiilitySelect' name='expense_type' onChange={handleChange} value={formData.expense_type}>
                          <option disabled value="">Select Utility Type</option>
                          <option value="fixed">Fixed</option>
                          <option value="varying">Varying</option>
                        </select>
                      </div>


                      <Button variant="primary" type="submit" className="nextBtn mt-3" >
                        Save
                      </Button>
                    </Form>
                  </>

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="success" onClick={handleCloseModalReload} >
                    Done
                  </Button>
                </Modal.Footer>
              </Modal>


              <p className='notUtility' style={{ color: "#95a5a6" }}>Add utility, you have none at the moment</p>
            </>

            :

            <>
              {/* water utilities top images and div */}
              {!activeTab &&

                <div className='topSelected' style={{ backgroundImage: `url(${backgroundImageApartment})` }}>

                  <div className='ImageHouseName'>
                    <div classname='buttons_span'>
                      <span className='mt-5'>{selectedApartmentUtilities.location}</span>
                      {/* <Button className='btn btn-sm btn-success ms-3 me-2'>Edit Utility</Button> */}
                      <Button className='btn btn-sm btn-primary' onClick={handleOpenModalAdd}>Add Utility</Button>
                    </div>

                    <h2 className=''>{selectedApartmentUtilities.name}</h2>
                  </div>

                </div>

              }

              {activeTab === 'Water' &&

                <div className='topSelectedWater' style={{ backgroundImage: `url(${backgroundImages[1]})` }}>

                  <div className='ImageHouseName'>
                    <div classname='buttons_span'>
                      <Button onClick={() => handleOpenModalDelete(activeTabId)} className='btn btn-sm btn-danger text-white bi bi-trash ms-2' style={{ width: 'auto', float: 'left' }}></Button>
                      <span className='mt-5'>{selectedApartmentUtilities.location}</span>

                      <Button onClick={() => handleOpenModalEdit(activeTabId)} className='btn btn-sm btn-success ms-3 me-2'>Edit Utility</Button>
                      <Button className='btn btn-sm btn-primary' onClick={handleOpenModalAdd}>Add Utility</Button>
                    </div>
                    {/* edit the selected utitlity */}
                    <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit The Utility Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleSubmitEdit}>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <input
                              className="input-field"
                              value={activeTab}
                              name="title"
                              onChange={(e) => setActiveTab(e.target.value)}
                              required
                            />
                          </div>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <select className='addUtiilitySelect' name='frequency' onChange={handleChangeEdit} value={formDataEdit.frequency}>
                              <option disabled value="">Select Utility Payment Frequency</option>
                              <option value="monthly">Monthly</option>
                              <option value="daily">Daily</option>
                              <option value="annually">Annually</option>
                              <option value="weekly">Weekly</option>
                            </select>
                          </div>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <input
                              className="input-field"
                              type="number"
                              name="cost_per_unit"
                              placeholder='Enter Cost Per unit'
                              onChange={handleChangeEdit}
                              value={formDataEdit.cost_per_unit}
                              required
                            />
                          </div>
                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <select className='addUtiilitySelect' name='expense_type' onChange={handleChangeEdit} value={formDataEdit.expense_type}>
                              <option disabled value="">Select Utility Type</option>
                              <option value="fixed">Fixed</option>
                              <option value="varying">Varying</option>
                            </select>
                          </div>


                          <Button variant="primary" type="submit" className="nextBtn mt-3" >
                            Save
                          </Button>
                        </Form>

                      </Modal.Body>
                    </Modal>

                    {/* delete the selected utitlity */}
                    <Modal show={showModalDelete} onHide={handleCloseModalDelete}>
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Utility</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Do you want to delete this Utility? </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalDelete}>
                          No
                        </Button>
                        <Button utilityDeleteId={activeTabId} variant="danger" onClick={handleDeleteUtility}>
                          Yes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <h2 className=''>{selectedApartmentUtilities.name}</h2>
                    <div style={{ marginLeft: "-6rem" }}>Water Utilities</div>
                  </div>

                </div>

              }
              {/* garbage utilities top images and div */}
              {activeTab === "Garbage" &&

                <div className='topSelectedGarbage' style={{ backgroundImage: `url(${backgroundImages[2]})` }}>

                  <div className='ImageHouseName'>
                    <div classname='buttons_span'>
                      <Button onClick={() => handleOpenModalDelete(activeTabId)} className='btn btn-sm btn-danger text-white bi bi-trash ms-2' style={{ width: 'auto', float: 'left' }}></Button>
                      <span className='mt-5'>{selectedApartmentUtilities.location}</span>
                      <Button onClick={() => handleOpenModalEdit(activeTabId)} className='btn btn-sm btn-success ms-3 me-2'>Edit Utility</Button>
                      <Button className='btn btn-sm btn-primary' onClick={handleOpenModalAdd}>Add Utility</Button>
                    </div>
                    {/* edit the selected utitlity */}
                    <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit The Utility Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleSubmitEdit}>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <input
                              className="input-field"
                              value={activeTab}
                              name="title"
                              onChange={(e) => setActiveTab(e.target.value)}
                              required
                            />
                          </div>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <select className='addUtiilitySelect' name='frequency' onChange={handleChangeEdit} value={formDataEdit.frequency}>
                              <option disabled value="">Select Utility Payment Frequency</option>
                              <option value="monthly">Monthly</option>
                              <option value="daily">Daily</option>
                              <option value="annually">Annually</option>
                              <option value="weekly">Weekly</option>
                            </select>
                          </div>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <input
                              className="input-field"
                              type="number"
                              name="cost_per_unit"
                              placeholder='Enter Cost Per unit'
                              onChange={handleChangeEdit}
                              value={formDataEdit.cost_per_unit}
                              required
                            />
                          </div>
                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <select className='addUtiilitySelect' name='expense_type' onChange={handleChangeEdit} value={formDataEdit.expense_type}>
                              <option disabled value="">Select Utility Type</option>
                              <option value="fixed">Fixed</option>
                              <option value="varying">Varying</option>
                            </select>
                          </div>


                          <Button variant="primary" type="submit" className="nextBtn mt-3" >
                            Save
                          </Button>
                        </Form>

                      </Modal.Body>
                    </Modal>

                    {/* delete the selected utitlity */}
                    <Modal show={showModalDelete} onHide={handleCloseModalDelete}>
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Utility</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Do you want to delete this Utility? </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalDelete}>
                          No
                        </Button>
                        <Button utilityDeleteId={activeTabId} variant="danger" onClick={handleDeleteUtility}>
                          Yes
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <h2 className=''>{selectedApartmentUtilities.name}</h2>
                    <div style={{ marginLeft: "-6rem" }}>Garbage Utilities</div>
                  </div>

                </div>

              }

              {/* interent utilities top images and dive */}
              {activeTab === "Internet" &&

                <div className='topSelectedInterent' style={{ backgroundImage: `url(${backgroundImages[3]})` }}>

                  <div className='ImageHouseName'>
                    <div classname='buttons_span'>
                      <Button onClick={() => handleOpenModalDelete(activeTabId)} className='btn btn-sm btn-danger text-white bi bi-trash ms-2' style={{ width: 'auto', float: 'left' }}></Button>
                      <span className='mt-5'>{selectedApartmentUtilities.location}</span>
                      <Button onClick={() => handleOpenModalEdit(activeTabId)} className='btn btn-sm btn-success ms-3 me-2'>Edit Utility</Button>
                      <Button className='btn btn-sm btn-primary' onClick={handleOpenModalAdd}>Add Utility</Button>
                    </div>
                    {/* edit the selected utitlity */}
                    <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit The Utility Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleSubmitEdit}>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <input
                              className="input-field"
                              value={activeTab}
                              name="title"
                              onChange={(e) => setActiveTab(e.target.value)}
                              required
                            />
                          </div>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <select className='addUtiilitySelect' name='frequency' onChange={handleChangeEdit} value={formDataEdit.frequency}>
                              <option disabled value="">Select Utility Payment Frequency</option>
                              <option value="monthly">Monthly</option>
                              <option value="daily">Daily</option>
                              <option value="annually">Annually</option>
                              <option value="weekly">Weekly</option>
                            </select>
                          </div>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <input
                              className="input-field"
                              type="number"
                              name="cost_per_unit"
                              placeholder='Enter Cost Per unit'
                              onChange={handleChangeEdit}
                              value={formDataEdit.cost_per_unit}
                              required
                            />
                          </div>
                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <select className='addUtiilitySelect' name='expense_type' onChange={handleChangeEdit} value={formDataEdit.expense_type}>
                              <option disabled value="">Select Utility Type</option>
                              <option value="fixed">Fixed</option>
                              <option value="varying">Varying</option>
                            </select>
                          </div>


                          <Button variant="primary" type="submit" className="nextBtn mt-3" >
                            Save
                          </Button>
                        </Form>

                      </Modal.Body>
                    </Modal>

                    {/* delete the selected utitlity */}
                    <Modal show={showModalDelete} onHide={handleCloseModalDelete}>
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Utility</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Do you want to delete this Utility? </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalDelete}>
                          No
                        </Button>
                        <Button utilityDeleteId={activeTabId} variant="danger" onClick={handleDeleteUtility}>
                          Yes
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <h2 className=''>{selectedApartmentUtilities.name}</h2>

                    <div style={{ marginLeft: "-6rem" }}>
                      Internent Utilities
                    </div>
                  </div>

                </div>

              }

              {activeTab === "Security" &&

                <div className='topSelectedSecurity' style={{ backgroundImage: `url(${backgroundImages[0]})` }}>

                  <div className='ImageHouseName'>
                    <div classname='buttons_span'>
                      <Button onClick={() => handleOpenModalDelete(activeTabId)} className='btn btn-sm btn-danger text-white bi bi-trash ms-2' style={{ width: 'auto', float: 'left' }}></Button>
                      <span className='mt-5'>{selectedApartmentUtilities.location}</span>
                      <Button onClick={() => handleOpenModalEdit(activeTabId)} className='btn btn-sm btn-success ms-3 me-2'>Edit Utility</Button>
                      <Button className='btn btn-sm btn-primary' onClick={handleOpenModalAdd}>Add Utility</Button>
                    </div>

                    {/* edit the selected utitlity */}
                    <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit The Utility Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleSubmitEdit}>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <input
                              className="input-field"
                              value={activeTab}
                              name="title"
                              onChange={(e) => setActiveTab(e.target.value)}
                              required
                            />
                          </div>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <select className='addUtiilitySelect' name='frequency' onChange={handleChangeEdit} value={formDataEdit.frequency}>
                              <option disabled value="">Select Utility Payment Frequency</option>
                              <option value="monthly">Monthly</option>
                              <option value="daily">Daily</option>
                              <option value="annually">Annually</option>
                              <option value="weekly">Weekly</option>
                            </select>
                          </div>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <input
                              className="input-field"
                              type="number"
                              name="cost_per_unit"
                              placeholder='Enter Cost Per unit'
                              onChange={handleChangeEdit}
                              value={formDataEdit.cost_per_unit}
                              required
                            />
                          </div>
                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <select className='addUtiilitySelect' name='expense_type' onChange={handleChangeEdit} value={formDataEdit.expense_type}>
                              <option disabled value="">Select Utility Type</option>
                              <option value="fixed">Fixed</option>
                              <option value="varying">Varying</option>
                            </select>
                          </div>


                          <Button variant="primary" type="submit" className="nextBtn mt-3" >
                            Save
                          </Button>
                        </Form>

                      </Modal.Body>
                    </Modal>


                    {/* delete the selected utitlity */}
                    <Modal show={showModalDelete} onHide={handleCloseModalDelete}>
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Utility</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Do you want to delete this Utility? </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalDelete}>
                          No
                        </Button>
                        <Button utilityDeleteId={activeTabId} variant="danger" onClick={handleDeleteUtility}>
                          Yes
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <h2 className=''>{selectedApartmentUtilities.name}</h2>

                    <div style={{ marginLeft: "-6rem" }}>Security Utilities</div>
                  </div>

                </div>
              }

              {activeTab === "Electricity" &&

                <div className='topSelectedElectricity' style={{ backgroundImage: `url(${backgroundImages[4]})` }}>

                  <div className='ImageHouseName'>
                    <div classname='buttons_span'>
                      <Button onClick={() => handleOpenModalDelete(activeTabId)} className='btn btn-sm btn-danger text-white bi bi-trash ms-2' style={{ width: 'auto', float: 'left' }}></Button>
                      <span className='mt-5'>{selectedApartmentUtilities.location}</span>
                      <Button onClick={() => handleOpenModalEdit(activeTabId)} className='btn btn-sm btn-success ms-3 me-2'>Edit Utility</Button>
                      <Button className='btn btn-sm btn-primary' onClick={handleOpenModalAdd}>Add Utility</Button>
                    </div>

                    {/* edit the selected utitlity */}
                    <Modal show={showModalEdit} onHide={handleCloseModalEdit}>
                      <Modal.Header closeButton>
                        <Modal.Title>Edit The Utility Details</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form onSubmit={handleSubmitEdit}>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <input
                              className="input-field"
                              value={activeTab}
                              name="title"
                              onChange={(e) => setActiveTab(e.target.value)}
                              required
                            />
                          </div>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <select className='addUtiilitySelect' name='frequency' onChange={handleChangeEdit} value={formDataEdit.frequency}>
                              <option disabled value="">Select Utility Payment Frequency</option>
                              <option value="monthly">Monthly</option>
                              <option value="daily">Daily</option>
                              <option value="annually">Annually</option>
                              <option value="weekly">Weekly</option>
                            </select>
                          </div>

                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <input
                              className="input-field"
                              type="number"
                              name="cost_per_unit"
                              placeholder='Enter Cost Per unit'
                              onChange={handleChangeEdit}
                              value={formDataEdit.cost_per_unit}
                              required
                            />
                          </div>
                          <div className="input-icons">
                            <i className="fa fa-hashtag icon"></i>
                            <select className='addUtiilitySelect' name='expense_type' onChange={handleChangeEdit} value={formDataEdit.expense_type}>
                              <option disabled value="">Select Utility Type</option>
                              <option value="fixed">Fixed</option>
                              <option value="varying">Varying</option>
                            </select>
                          </div>


                          <Button variant="primary" type="submit" className="nextBtn mt-3" >
                            Save
                          </Button>
                        </Form>

                      </Modal.Body>
                    </Modal>


                    {/* delete the selected utitlity */}
                    <Modal show={showModalDelete} onHide={handleCloseModalDelete}>
                      <Modal.Header closeButton>
                        <Modal.Title>Delete Utility</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Do you want to delete this Utility? </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalDelete}>
                          No
                        </Button>
                        <Button utilityDeleteId={activeTabId} variant="danger" onClick={handleDeleteUtility}>
                          Yes
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <h2 className=''>{selectedApartmentUtilities.name}</h2>

                    <div style={{ marginLeft: "-6rem" }}>Electricity Utilities</div>
                  </div>

                </div>

              }
              {/* modal for adding new utility */}
              <Modal show={showModalAdd} onHide={handleCloseModalAdd}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Utility</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  <>
                    <Form onSubmit={handleSubmit}>

                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <select className='addUtiilitySelect' name='title' onChange={handleChange} value={formData.title}>
                          <option disabled value="">Select Utility</option>

                          {utilitiesAbsent.map((utility) => (
                            <option value={utility.value}>{utility.name}</option>
                          ))}
                        </select>
                      </div>

                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <select className='addUtiilitySelect' name='frequency' onChange={handleChange} value={formData.frequency}>
                          <option disabled value="">Select Utility Payment Frequency</option>
                          <option value="monthly">Monthly</option>
                          <option value="daily">Daily</option>
                          <option value="annually">Annually</option>
                          <option value="weekly">Weekly</option>
                        </select>
                      </div>

                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <input
                          className="input-field"
                          type="number"
                          name="cost_per_unit"
                          placeholder='Enter Cost Per unit'
                          onChange={handleChange}
                          value={formData.cost_per_unit}
                          required
                        />
                      </div>
                      <div className="input-icons">
                        <i className="fa fa-hashtag icon"></i>
                        <select className='addUtiilitySelect' name='expense_type' onChange={handleChange} value={formData.expense_type}>
                          <option disabled value="">Select Utility Type</option>
                          <option value="fixed">Fixed</option>
                          <option value="varying">Varying</option>
                        </select>
                      </div>


                      <Button variant="primary" type="submit" className="nextBtn mt-3" >
                        Save
                      </Button>
                    </Form>
                  </>

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="success" onClick={handleCloseModalReload} >
                    Done
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* the top navbars */}

              {
                <p className='utilityHelp' style={{ color: "#e67e22" }} >Tap on the tab below to select utility </p>
              }

              <div className='navtabs'>
                <ul className="nav nav-tabs">
                  {utilities.map((tab, index) => (
                    <>
                      <li key={tab.id} className={`nav-item ${activeTab === tab.title || activeTabId === tab.id ? 'active' : ''}`}>
                        <a style={{ marginRight: 12 + 'px' }} className={`nav-link ${tab.title === "Water" ? 'waterTab' : ''} ${tab.title === "Garbage" ? 'garbageTab' : ''} ${tab.title === "Security" ? 'securityTab' : ''} ${tab.title === "Internet" ? 'internetTab' : ''} ${tab.title === "Electricity" ? 'electricityTab' : ''}  ${activeTab === tab.title || activeTabId === tab.id ? 'active' : ''}`} onClick={(event) => handleTabClick(tab.title, event)} expenseId={tab.id}>
                          {tab.title}
                        </a>
                      </li>


                    </>


                  ))}

                </ul>

              </div>


              {!activeTab &&

                <div className='tenant_utilities'>
                  {house.length === 0 ?
                    (<div className='noHouse'>You have no Houses in this Apartment</div>)
                    :
                    (
                      house.map((unitDetail, index) =>
                        <>
                          {unitDetail.status === "vacant" ?
                            <></>
                            :
                            <Card className='house' key={index.id}>
                              <Card.Body>

                                <div className="image-container">
                                  {/* display the image of the house */}
                                  {houseSetImage[unitDetail.house_id] && houseSetImage[unitDetail.house_id]
                                    ?
                                    <>
                                      <Image src={houseSetImage[unitDetail.house_id]} className="houseUnit" alt='image' />
                                    </>

                                    :
                                    <Image src={backgroundImageHouse[unitDetail.house_id]} className="houseUnit" alt='image' />

                                  }
                                  <div className="badge">Ksh. {unitDetail.house_rent?.toLocaleString()} /month</div>
                                  <p className="houseTitle text-start" style={{ marginBottom: "0" }}>{unitDetail.house}</p>
                                </div>
                                {unitDetail.status === "vacant" ?
                                  (<p className="houseName" style={{ marginBottom: "5px" }}>No Tenant</p>)
                                  :
                                  (<p className="houseName" style={{ marginBottom: "5px", display: "block", textAlign: "left" }} >{unitDetail.tenant}</p>)}


                                <p></p>

                                <div className="buttonsProperties">
                                  {unitDetail.status === "vacant" ? (
                                    <div className='d-flex justify-content-between w-100'>
                                      <Button className="btn btn-sm btn-success text-white rounded-5 ">Vacant</Button>

                                    </div>
                                  ) : (
                                    <div className='buttonsProperty mb-3'>
                                      <Button className="btn btn-sm btn-warning text-white OccupiedBtn rounded-5">Occupied</Button>
                                      {new Date(unitDetail.due_date).toLocaleDateString() < new Date(currentDate).toLocaleDateString() ?
                                        (<Button className="btn btn-sm btn-danger OccupiedBtn rounded-5 d-none">Due</Button>)
                                        :
                                        (<Button className="btn btn-sm btn-primary OccupiedBtn rounded-5 d-none">{unitDetail.due_date}</Button>)
                                      }

                                    </div>
                                  )}
                                </div>

                                <div className="buttonsProperties">
                                  {unitDetail.status === "vacant" ?
                                    (
                                      <></>
                                    )
                                    :
                                    (<>

                                      {/* <Button to="/#" className="text-danger text-decoration-none p-1 bg-transparent border-0" onClick={handleReminderModalOpen}>
                                        Send Reminder <i className="bi bi-arrow-right me-2"></i>
                                      </Button> */}


                                    </>)}

                                </div>

                              </Card.Body>

                            </Card>



                          }
                        </>
                      ))
                  }
                </div>

              }
              {/* modal for send reminders */}
              <Modal show={remindersModal} onHide={handleReminderModalClose}>
                <Modal.Header className='bg-primary text-white' closeButton>
                  {addTemplate ?
                    <Modal.Title>Add Message Templates</Modal.Title>
                    :
                    <Modal.Title>Send Reminder Message</Modal.Title>

                  }
                </Modal.Header>
                <Modal.Body>
                  <div className='d-flex justify-content-between'>
                    <Dropdown>
                      <Dropdown.Toggle variant={addTemplate ? 'secondary' : 'primary'} size='sm'>

                        {selectedOptionReminder ? (
                          selectedOptionReminder === 'utility' ?
                            <>Utility</>
                            :
                            selectedOptionReminder === 'rent' ?
                              <>Rent</>
                              :
                              <>Choose</>

                        )
                          :
                          <>Choose Template</>
                        }
                      </Dropdown.Toggle>


                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleOptionSelect('utility')}>Utility</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleOptionSelect('rent')}>Rent</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <Button className={`btn btn-sm ${addTemplate ? 'btn-primary' : 'btn-secondary'}`} onClick={handleAddTemplate}>Add Template</Button>
                  </div>

                  {addTemplate ? (
                    <>
                      <div className="input-icons mt-2">
                        <i class="bi bi-send-plus icon"></i>
                        <textarea className="input-field" rows={4} placeholder='Enter Message'></textarea>
                      </div>
                    </>
                  ) : (
                    selectedOptionReminder ? (
                      <div className='mt-2'>
                        {selectedOptionReminder === 'utility' && (
                          <>
                            <div className="input-icons  mb-2">
                              <i class="bi bi-chat-left-dots icon"></i>
                              <textarea className="input-field" rows={1}>Utility Reminder</textarea>
                            </div>

                            <div className="input-icons">
                              <i class="bi bi-send-plus icon"></i>
                              <textarea className="input-field">Utility bill payment reminder</textarea>
                            </div>

                          </>
                        )}

                        {selectedOptionReminder === 'rent' && (
                          <>
                            <div className="input-icons  mb-2">
                              <i class="bi bi-chat-left-dots icon"></i>
                              <textarea className="input-field" rows={1}>Rent Reminder</textarea>
                            </div>

                            <div className="input-icons">
                              <i class="bi bi-send-plus icon"></i>
                              <textarea className="input-field">Please pay the rent</textarea>
                            </div>

                          </>
                        )}
                      </div>
                    ) : (
                      <>

                        <div className="input-icons  mt-2 mb-2">
                          <i class="bi bi-chat-left-dots icon"></i>
                          <textarea className="input-field" rows={1} placeholder='Custom Message'></textarea>
                        </div>

                        <div className="input-icons">
                          <i class="bi bi-send-plus icon"></i>
                          <textarea className="input-field" rows={8} placeholder='Enter Message'></textarea>
                        </div>
                      </>
                    )
                  )}

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                  <Button variant='danger' onClick={handleReminderModalClose}>
                    Close
                  </Button>
                  <Button variant='success' onClick={handleReminderModalReload}>
                    Send
                  </Button>
                </Modal.Footer>
              </Modal>

              {activeTab === "Water" &&
                <div className='tenant_utilities'>
                  {house.length === 0 ?
                    (<div className='noHouse'>You have no Houses in this Apartment</div>)
                    :
                    (

                      house.map((unitDetail, index) =>
                        <>
                          {unitDetail.status === "vacant" ?
                            <></>
                            :
                            <>
                              <Card className='house' key={index.id}>
                                <Card.Body>

                                  <div className="image-container">
                                    {/* display the image of the house */}
                                    {houseSetImage[unitDetail.house_id] && houseSetImage[unitDetail.house_id]
                                      ?
                                      <>
                                        <Image src={houseSetImage[unitDetail.house_id]} className="houseUnit" alt='image' />
                                      </>

                                      :
                                      <Image src={backgroundImageHouse[unitDetail.house_id]} className="houseUnit" alt='image' />

                                    }
                                    <div className="badge">Ksh. {unitDetail.house_rent?.toLocaleString()} /month</div>
                                    <p className="houseTitle text-start" style={{ marginBottom: "0" }}>{unitDetail.house}</p>
                                  </div>
                                  <p className="houseName" style={{ marginBottom: "2px", display: "block", textAlign: "left" }} >{unitDetail.tenant}</p>
                                  <p></p>
                                  {/* get the units and adding unit */}
                                  {UtilityClickedType[activeTab] === "varying" ?

                                    <div className="buttonsProperties mb-3">
                                      {/* check if there is units are added if not then add if added then edit */}
                                      {unitsHouse[unitDetail.house_id] >= 1 ?
                                        <Button className='btn btn-primary text-white' style={{ fontSize: "14px", paddingTop: "0px", paddingBottom: "0px" }} onClick={handleButtonClickEdit} houseid={unitDetail.house_id} utility={UtilityClickedId[activeTab]}>Edit Units</Button>
                                        :
                                        <Button className='btn btn-success text-white' style={{ fontSize: "14px", paddingTop: "0px", paddingBottom: "0px" }} onClick={handleButtonClick} houseid={unitDetail.house_id} utility={UtilityClickedId[activeTab]}>Add Units</Button>
                                      }
                                      <p>Per Unit Ksh {UtilityClicked[activeTab]}</p>

                                    </div>
                                    :
                                    <>

                                    </>
                                  }


                                  {/* if the type is varying then display together with the amount else display the fixed amount price */}
                                  {UtilityClickedType[activeTab] === "varying" ?

                                    // check if the amount is there first if its there display else do not
                                    unitHouseCost[unitDetail.house_id] !== null || unitHouseCost[unitDetail.house_id] !== "0" || unitHouseCost[unitDetail.house_id] !== undefined || unitHouseCost[unitDetail.house_id] !== ' ' ?
                                      <div className="buttonsProperties">

                                        <Button className='btn btn-warning text-white'>Ksh{unitHouseCost[unitDetail.house_id]}/ {unitsHouse[unitDetail.house_id]} Units</Button>
                                        {unitDetail.rent_payment_status === 'paid' ? (
                                          <p className='bg-success text-white p-2'>{unitDetail.rent_payment_status}</p>

                                        ) : unitDetail.rent_payment_status === 'partial' ? (
                                          <p className='bg-warning  text-white p-2'>{unitDetail.rent_payment_status}</p>)
                                          :
                                          (
                                            <p p className='bg-danger  text-white p-2'>{unitDetail.rent_payment_status}</p>

                                          )}

                                      </div>
                                      :
                                      <>
                                      </>
                                    :
                                    <>
                                      <div className="buttonsProperties">
                                        <Button className='btn btn-warning text-white'>Ksh {UtilityClicked[activeTab]}</Button>
                                        {unitDetail.rent_payment_status === 'paid' ? (
                                          <p className='bg-success text-white p-2'>{unitDetail.rent_payment_status}</p>

                                        ) : unitDetail.rent_payment_status === 'partial' ? (
                                          <p className='bg-warning  text-white p-2'>{unitDetail.rent_payment_status}</p>)
                                          :
                                          (
                                            <p p className='bg-danger  text-white p-2'>{unitDetail.rent_payment_status}</p>

                                          )}

                                      </div>
                                    </>
                                  }

                                  {/* <div className="buttonsProperties">
                                    <Button to="/#" className="text-danger text-decoration-none p-1 bg-transparent border-0" onClick={handleReminderModalOpen}>
                                      Send Reminder <i className="bi bi-arrow-right me-2"></i>
                                    </Button>
                                  </div> */}

                                </Card.Body>

                              </Card>
                            </>

                          }
                        </>
                      ))

                  }

                </div>
              }

              {/* Electricity utilities */}

              {activeTab === "Electricity" &&
                <div className='tenant_utilities'>
                  {house.length === 0 ?
                    (<div className='noHouse'>You have no Houses in this Apartment</div>)
                    :
                    (
                      house.map((unitDetail, index) =>
                        <>
                          {unitDetail.status === "vacant" ?
                            <></>
                            :
                            <Card className='house' key={index.id}>
                              <Card.Body>

                                <div className="image-container">
                                  {/* display the image of the house */}
                                  {houseSetImage[unitDetail.house_id] && houseSetImage[unitDetail.house_id]
                                    ?
                                    <>
                                      <Image src={houseSetImage[unitDetail.house_id]} className="houseUnit" alt='image' />
                                    </>

                                    :
                                    <Image src={backgroundImageHouse[unitDetail.house_id]} className="houseUnit" alt='image' />

                                  }
                                  <div className="badge">Ksh. {unitDetail.house_rent?.toLocaleString()} /month</div>
                                  <p className="text-muted float-left">{unitDetail.house}</p>
                                </div>
                                <p className="houseName">{unitDetail.tenant}</p>


                                <p></p>
                                {/* get the units and adding unit */}
                                {UtilityClickedType[activeTab] === "varying" ?

                                  <div className="buttonsProperties mb-3">

                                    {/* check if there is units are added if not then add if added then edit */}
                                    {unitsHouse[unitDetail.house_id] >= 1 ?
                                      <Button className='btn btn-primary text-white' style={{ fontSize: "14px", paddingTop: "0px", paddingBottom: "0px" }} onClick={handleButtonClickEdit} houseid={unitDetail.house_id} utility={UtilityClickedId[activeTab]}>Edit Units</Button>
                                      :
                                      <Button className='btn btn-success text-white' style={{ fontSize: "14px", paddingTop: "0px", paddingBottom: "0px" }} onClick={handleButtonClick} houseid={unitDetail.house_id} utility={UtilityClickedId[activeTab]}>Add Units</Button>
                                    }
                                    <p>Per Unit Ksh {UtilityClicked[activeTab]}</p>

                                  </div>
                                  :
                                  <>
                                  </>
                                }

                                {/* if the type is varying then display together with the amount else display the fixed amount price */}
                                {UtilityClickedType[activeTab] === "varying" ?

                                  <div className="buttonsProperties">
                                    <Button className='btn btn-warning text-white'>Ksh{unitHouseCost[unitDetail.house_id]}/ {unitsHouse[unitDetail.house_id]} Units</Button>
                                    {unitDetail.rent_payment_status === 'paid' ? (
                                      <p className='bg-success text-white p-2'>{unitDetail.rent_payment_status}</p>

                                    ) : unitDetail.rent_payment_status === 'partial' ? (
                                      <p className='bg-warning  text-white p-2'>{unitDetail.rent_payment_status}</p>)
                                      :
                                      (
                                        <p p className='bg-danger  text-white p-2'>{unitDetail.rent_payment_status}</p>

                                      )}

                                  </div>
                                  :
                                  <>
                                    <div className="buttonsProperties">
                                      <Button className='btn btn-warning text-white'>Ksh {UtilityClicked[activeTab]}</Button>
                                      {unitDetail.rent_payment_status === 'paid' ? (
                                        <p className='bg-success text-white p-2'>{unitDetail.rent_payment_status}</p>

                                      ) : unitDetail.rent_payment_status === 'partial' ? (
                                        <p className='bg-warning  text-white p-2'>{unitDetail.rent_payment_status}</p>)
                                        :
                                        (
                                          <p p className='bg-danger  text-white p-2'>{unitDetail.rent_payment_status}</p>

                                        )}

                                    </div>
                                  </>
                                }



                                {/* <div className="buttonsProperties">
                                  <Button to="/#" className="text-danger text-decoration-none p-1 bg-transparent border-0" onClick={handleReminderModalOpen}>
                                    Send Reminder <i className="bi bi-arrow-right me-2"></i>
                                  </Button>
                                </div> */}

                              </Card.Body>

                            </Card>
                          }
                        </>
                      ))

                  }

                </div>
              }

              {/* Garbage utilities */}
              {activeTab === "Garbage" &&
                <div className='tenant_utilities'>
                  {house.length === 0 ?
                    (<div className='noHouse'>You have no Houses in this Apartment</div>)
                    :
                    (
                      house.map((unitDetail, index) =>
                        <>
                          {unitDetail.status === "vacant" ?
                            <></>
                            :
                            <Card className='house' key={index.id}>
                              <Card.Body>

                                <div className="image-container">
                                  {/* display the image of the house */}
                                  {houseSetImage[unitDetail.house_id] && houseSetImage[unitDetail.house_id]
                                    ?
                                    <>
                                      <Image src={houseSetImage[unitDetail.house_id]} className="houseUnit" alt='image' />
                                    </>

                                    :
                                    <Image src={backgroundImageHouse[unitDetail.house_id]} className="houseUnit" alt='image' />

                                  }
                                  <div className="badge">Ksh. {unitDetail.house_rent?.toLocaleString()} /month</div>
                                  <p className="text-muted float-left">{unitDetail.house}</p>
                                </div>
                                <p className="houseName">{unitDetail.tenant}</p>


                                <p></p>
                                {/* get the units and adding unit */}
                                {UtilityClickedType[activeTab] === "varying" ?

                                  <div className="buttonsProperties mb-3">

                                    {/* check if there is units are added if not then add if added then edit */}
                                    {unitsHouse[unitDetail.house_id] >= 1 ?
                                      <Button className='btn btn-primary text-white' style={{ fontSize: "14px", paddingTop: "0px", paddingBottom: "0px" }} onClick={handleButtonClickEdit} houseid={unitDetail.house_id} utility={UtilityClickedId[activeTab]}>Edit Units</Button>
                                      :
                                      <Button className='btn btn-success text-white' style={{ fontSize: "14px", paddingTop: "0px", paddingBottom: "0px" }} onClick={handleButtonClick} houseid={unitDetail.house_id} utility={UtilityClickedId[activeTab]}>Add Units</Button>
                                    }
                                    <p>Per Unit Ksh {UtilityClicked[activeTab]}</p>

                                  </div>
                                  :
                                  <>
                                  </>
                                }


                                {/* if the type is varying then display together with the amount else display the fixed amount price */}
                                {UtilityClickedType[activeTab] === "varying" ?

                                  <div className="buttonsProperties">
                                    <Button className='btn btn-warning text-white'>Ksh{unitHouseCost[unitDetail.house_id]}/ {unitsHouse[unitDetail.house_id]} Units</Button>
                                    {unitDetail.rent_payment_status === 'paid' ? (
                                      <p className='bg-success text-white p-2'>{unitDetail.rent_payment_status}</p>

                                    ) : unitDetail.rent_payment_status === 'partial' ? (
                                      <p className='bg-warning  text-white p-2'>{unitDetail.rent_payment_status}</p>)
                                      :
                                      (
                                        <p p className='bg-danger  text-white p-2'>{unitDetail.rent_payment_status}</p>

                                      )}

                                  </div>
                                  :
                                  <>
                                    <div className="buttonsProperties">
                                      <Button className='btn btn-warning text-white'>Ksh {UtilityClicked[activeTab]}</Button>
                                      {unitDetail.rent_payment_status === 'paid' ? (
                                        <p className='bg-success text-white p-2'>{unitDetail.rent_payment_status}</p>

                                      ) : unitDetail.rent_payment_status === 'partial' ? (
                                        <p className='bg-warning  text-white p-2'>{unitDetail.rent_payment_status}</p>)
                                        :
                                        (
                                          <p p className='bg-danger  text-white p-2'>{unitDetail.rent_payment_status}</p>

                                        )}

                                    </div>
                                  </>
                                }

                                {/* <div className="buttonsProperties">
                                  <Button to="/#" className="text-danger text-decoration-none p-1 bg-transparent border-0" onClick={handleReminderModalOpen}>
                                    Send Reminder <i className="bi bi-arrow-right me-2"></i>
                                  </Button>
                                </div> */}

                              </Card.Body>

                            </Card>
                          }
                        </>
                      ))

                  }

                </div>
              }

              {/* security utilities */}
              {activeTab === "Security" &&
                <div className='tenant_utilities'>
                  {house.length === 0 ?
                    (<div className='noHouse'>You have no Houses in this Apartment</div>)
                    :
                    (
                      house.map((unitDetail, index) =>
                        <>
                          {unitDetail.status === "vacant" ?
                            <></>
                            :
                            <Card className='house' key={index.id}>
                              <Card.Body>

                                <div className="image-container">
                                  {/* display the image of the house */}
                                  {houseSetImage[unitDetail.house_id] && houseSetImage[unitDetail.house_id]
                                    ?
                                    <>
                                      <Image src={houseSetImage[unitDetail.house_id]} className="houseUnit" alt='image' />
                                    </>

                                    :
                                    <Image src={backgroundImageHouse[unitDetail.house_id]} className="houseUnit" alt='image' />

                                  }
                                  <div className="badge">Ksh. {unitDetail.house_rent?.toLocaleString()} /month</div>
                                  <p className="text-muted float-left">{unitDetail.house}</p>
                                </div>
                                <p className="houseName">{unitDetail.tenant}</p>


                                <p></p>
                                {/* get the units and adding unit */}
                                {UtilityClickedType[activeTab] === "varying" ?

                                  <div className="buttonsProperties mb-3">

                                    {/* check if there is units are added if not then add if added then edit */}
                                    {unitsHouse[unitDetail.house_id] >= 1 ?
                                      <Button className='btn btn-primary text-white' style={{ fontSize: "14px", paddingTop: "0px", paddingBottom: "0px" }} onClick={handleButtonClickEdit} houseid={unitDetail.house_id} utility={UtilityClickedId[activeTab]}>Edit Units</Button>
                                      :
                                      <Button className='btn btn-success text-white' style={{ fontSize: "14px", paddingTop: "0px", paddingBottom: "0px" }} onClick={handleButtonClick} houseid={unitDetail.house_id} utility={UtilityClickedId[activeTab]}>Add Units</Button>
                                    }
                                    <p>Per Unit Ksh {UtilityClicked[activeTab]}</p>

                                  </div>
                                  :
                                  <>
                                  </>
                                }


                                {/* if the type is varying then display together with the amount else display the fixed amount price */}
                                {UtilityClickedType[activeTab] === "varying" ?

                                  <div className="buttonsProperties">
                                    <Button className='btn btn-warning text-white'>Ksh{unitHouseCost[unitDetail.house_id]}/ {unitsHouse[unitDetail.house_id]} Units</Button>
                                    {unitDetail.rent_payment_status === 'paid' ? (
                                      <p className='bg-success text-white p-2'>{unitDetail.rent_payment_status}</p>

                                    ) : unitDetail.rent_payment_status === 'partial' ? (
                                      <p className='bg-warning  text-white p-2'>{unitDetail.rent_payment_status}</p>)
                                      :
                                      (
                                        <p p className='bg-danger  text-white p-2'>{unitDetail.rent_payment_status}</p>

                                      )}

                                  </div>
                                  :
                                  <>
                                    <div className="buttonsProperties">
                                      <Button className='btn btn-warning text-white'>Ksh {UtilityClicked[activeTab]}</Button>
                                      {unitDetail.rent_payment_status === 'paid' ? (
                                        <p className='bg-success text-white p-2'>{unitDetail.rent_payment_status}</p>

                                      ) : unitDetail.rent_payment_status === 'partial' ? (
                                        <p className='bg-warning  text-white p-2'>{unitDetail.rent_payment_status}</p>)
                                        :
                                        (
                                          <p p className='bg-danger  text-white p-2'>{unitDetail.rent_payment_status}</p>

                                        )}

                                    </div>
                                  </>
                                }

                                {/* <div className="buttonsProperties">
                                  <Button to="/#" className="text-danger text-decoration-none p-1 bg-transparent border-0" onClick={handleReminderModalOpen}>
                                    Send Reminder <i className="bi bi-arrow-right me-2"></i>
                                  </Button>
                                </div> */}

                              </Card.Body>

                            </Card>
                          }
                        </>
                      ))

                  }

                </div>
              }

              {/* Internet utilities */}
              {activeTab === "Internet" &&
                <div className='tenant_utilities'>
                  {house.length === 0 ?
                    (<div className='noHouse'>You have no Houses in this Apartment</div>)
                    :
                    (
                      house.map((unitDetail, index) =>
                        <>
                          {unitDetail.status === "vacant" ?
                            <></>
                            :
                            <Card className='house' key={index.id}>
                              <Card.Body>

                                <div className="image-container">
                                  {/* display the image of the house */}
                                  {houseSetImage[unitDetail.house_id] && houseSetImage[unitDetail.house_id]
                                    ?
                                    <>
                                      <Image src={houseSetImage[unitDetail.house_id]} className="houseUnit" alt='image' />
                                    </>

                                    :
                                    <Image src={backgroundImageHouse[unitDetail.house_id]} className="houseUnit" alt='image' />

                                  }
                                  <div className="badge">Ksh. {unitDetail.house_rent?.toLocaleString()} /month</div>
                                  <p className="text-muted float-left">{unitDetail.house}</p>
                                </div>
                                <p className="houseName">{unitDetail.tenant}</p>


                                <p></p>
                                {/* get the units and adding unit */}
                                {UtilityClickedType[activeTab] === "varying" ?

                                  <div className="buttonsProperties mb-3">

                                    {/* check if there is units are added if not then add if added then edit */}
                                    {unitsHouse[unitDetail.house_id] >= 1 ?
                                      <Button className='btn btn-primary text-white' style={{ fontSize: "14px", paddingTop: "0px", paddingBottom: "0px" }} onClick={handleButtonClickEdit} houseid={unitDetail.house_id} utility={UtilityClickedId[activeTab]}>Edit Units</Button>
                                      :
                                      <Button className='btn btn-success text-white' style={{ fontSize: "14px", paddingTop: "0px", paddingBottom: "0px" }} onClick={handleButtonClick} houseid={unitDetail.house_id} utility={UtilityClickedId[activeTab]}>Add Units</Button>
                                    }
                                    <p>Per Unit Ksh {UtilityClicked[activeTab]}</p>

                                  </div>
                                  :
                                  <>
                                  </>
                                }


                                {/* if the type is varying then display together with the amount else display the fixed amount price */}
                                {UtilityClickedType[activeTab] === "varying" ?

                                  <div className="buttonsProperties">
                                    <Button className='btn btn-warning text-white'>Ksh{unitHouseCost[unitDetail.house_id]}/ {unitsHouse[unitDetail.house_id]} Units</Button>
                                    {unitDetail.rent_payment_status === 'paid' ? (
                                      <p className='bg-success text-white p-2'>{unitDetail.rent_payment_status}</p>

                                    ) : unitDetail.rent_payment_status === 'partial' ? (
                                      <p className='bg-warning  text-white p-2'>{unitDetail.rent_payment_status}</p>)
                                      :
                                      (
                                        <p p className='bg-danger  text-white p-2'>{unitDetail.rent_payment_status}</p>

                                      )}

                                  </div>
                                  :
                                  <>
                                    <div className="buttonsProperties">
                                      <Button className='btn btn-warning text-white'>Ksh {UtilityClicked[activeTab]}</Button>
                                      {unitDetail.rent_payment_status === 'paid' ? (
                                        <p className='bg-success text-white p-2'>{unitDetail.rent_payment_status}</p>

                                      ) : unitDetail.rent_payment_status === 'partial' ? (
                                        <p className='bg-warning  text-white p-2'>{unitDetail.rent_payment_status}</p>)
                                        :
                                        (
                                          <p p className='bg-danger  text-white p-2'>{unitDetail.rent_payment_status}</p>

                                        )}

                                    </div>
                                  </>
                                }

                                {/* <div className="buttonsProperties">
                                  <Button to="/#" className="text-danger text-decoration-none p-1 bg-transparent border-0" onClick={handleReminderModalOpen}>
                                    Send Reminder <i className="bi bi-arrow-right me-2"></i>
                                  </Button>
                                </div> */}

                              </Card.Body>

                            </Card>
                          }
                        </>
                      ))

                  }

                </div>
              }
              {/* The add units Modal*/}
              <Modal show={showModalAddWaterUnit} onHide={handleCloseModalWater} >
                <Modal.Header closeButton>
                  <Modal.Title>Add Units Consumed For This House</Modal.Title>
                </Modal.Header>
                <ModalBody>

                  <Form onSubmit={handleSubmitWater}>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="units_consumed"
                        placeholder='Enter Units Consumed By The Tenant'
                        onChange={handleChangeWater}
                        value={formDataWater.units_consumed}
                        required
                      />
                    </div>
                    <div className="input-icons d-none">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="amount"
                        placeholder='Enter The Total Amount To Be Paid By The Tenant For The Utility'
                        onChange={handleChangeWater}
                        value={formDataWater.amount}
                      />
                    </div>
                    <Button variant="primary" type="submit" className="nextBtn mt-3" >
                      Save
                    </Button>
                  </Form>

                </ModalBody>
              </Modal>

              {/* edit the units selected */}
              <Modal show={showModalEditUnit} onHide={handleCloseModalEditUnit} >
                <Modal.Header closeButton>
                  <Modal.Title>Add Units Consumed For This House</Modal.Title>
                </Modal.Header>
                <ModalBody>

                  <Form onSubmit={handleSubmitEditUnits}>

                    <div className="input-icons">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="units_consumed"
                        placeholder='Enter Units Consumed By The Tenant'
                        onChange={handleChangeWater}
                        value={formDataWater.units_consumed}
                        required
                      />
                    </div>
                    <div className="input-icons d-none">
                      <i className="fa fa-hashtag icon"></i>
                      <input
                        className="input-field"
                        type="number"
                        name="amount"
                        placeholder='Enter The Total Amount To Be Paid By The Tenant For The Utility'
                        onChange={handleChangeWater}
                        value={formDataWater.amount}
                      />
                    </div>
                    <Button variant="primary" type="submit" className="nextBtn mt-3" >
                      Save
                    </Button>
                  </Form>

                </ModalBody>
              </Modal>
            </>
          }

        </div >
        :
        <h2 className='NotFound' style={{ color: "#95a5a6" }}>Apartment Not Found</h2>
      }
    </>
  )
}



export default SelectedPropertyUtilities