import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import Header from './Header'
import './BulkUploads.scss'
import { Table, Button, Form, FormControl, Modal } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from 'react-toastify';

function BulkUploads() {

  // get user details so that you can link with the Apartment from the access tokens
  const accessToken = localStorage.getItem("authTokens");

  // decode the tokens so that you can get the user_id
  const decodedToken = jwt_decode(accessToken);
  const userId = decodedToken.user_id;

  const months = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  // header's access token
  const config = {
    headers: { Authorization: `Bearer ${access}` }
  }


  // sidebar and header functions
  const [showSidebar, setShowSidebar] = useState(true);
  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }
  const exportCSV = () => {
    const csvData = [
      ['Property Name', 'Location'],
      // ...houseSelectedReport.map((item) => [item.tenant_name, item.name, item.status, item.paid, item.date]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'property_template.csv');
  };

  // add csv template for property
  const [showModalProperty, setshowModalProperty] = useState(false);
  const handleOpenProperty = () => {
    setshowModalProperty(true);
  };

  const handlCloseProperty = () => {
    setshowModalProperty(false);
  };

  // get the house templates
  const exportCSVHouse = () => {
    const csvData = [
      ['Unit Number', 'Bedroom', 'Total Rooms', 'Bathroom', 'Rent', 'Deposit', 'Unit Type', 'Invoice Date', 'Token Prepaid Account Number'],
      // ...houseSelectedReport.map((item) => [item.tenant_name, item.name, item.status, item.paid, item.date]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'unit_template.csv');
  };

  // add csv template for house
  const [showModalHouse, setshowModalHouse] = useState(false);
  const handleOpenHouse = () => {
    setshowModalHouse(true);
  };

  const handleCloseHouse = () => {
    setshowModalHouse(false);
  };

  // get the house templates
  const exportCSVTenant = () => {
    const csvData = [
      ['Full Name', 'Email', 'Phone Number', 'Occupation', 'Marital Status', 'House Number'],
      // ...houseSelectedReport.map((item) => [item.tenant_name, item.name, item.status, item.paid, item.date]),
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'tenant_template.csv');
  };

  // get the water upload template
  const exportCSVWater = () => {
    const csvData = [
      ['House', 'Total Units', 'Unit Price', 'Payable Amount', 'Amount Paid'],
    ];
    const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvBlob, 'units_uploads.csv');
  };

  // add csv template for house
  const [showModalTenant, setshowModalTenant] = useState(false);
  const handleOpenTenant = () => {
    setshowModalTenant(true);
  };

  const handleCloseTenant = () => {
    setshowModalTenant(false);
  };

  // add csv template for units 
  const [showModalUnits, setshowModalUnits] = useState(false);
  const handleOpenUnits = () => {
    setshowModalUnits(true);
  };

  const handleCloseUnits = () => {
    setshowModalUnits(false);
  };

  // randomise the images for my properties
  const [backgroundImage, setBackgroundImage] = useState('');
  useEffect(() => {

    const getRandomImage = () => {
      let storedImage = localStorage.getItem('backgroundImage');
      if (!storedImage) {
        storedImage = '';
      }

      let randomNumber = Math.floor(Math.random() * 7) + 1;
      const invalidNumbers = [0, -1];
      while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
        randomNumber = Math.floor(Math.random() * 7) + 1;
      }

      const imageUrl = `https://kodinyumba.app/media/reports/reports${randomNumber}.jpg`;
      setBackgroundImage(imageUrl);
      localStorage.setItem('backgroundImage', imageUrl);
    };

    getRandomImage();
  }, []);

  // get the  list of apartments owned by the user logged in 

  const [landlordApartment, setLandlordApartment] = useState([])
  const [apartmentUtilities, setApartmentUtilities] = useState([])

  const [landlordUserId, setLandlordUserId] = useState('')
  const [selectedApartmentId, setSelectedApartmentId] = useState("");

  useEffect(() => {
    // get the landlord details that are linked to the user
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);
        setLandlordUserId(landlordIds.toString())
        // using the landlord id get the apartments linked to the landlord
        const landlordApartmentPromises = landlordIds.map((landlordId) => {
          return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
            .then((res) => res.data.results)
            .catch((error) => {
              console.log(error);
              return [];
            });
        });

        Promise.all(landlordApartmentPromises)
          .then((results) => {
            // i am merging the sub-arrays into a new array
            const apartments = results.flat();
            setLandlordApartment(apartments);

          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    // get the utilities of the apartment
    serverUrl.get(`/landlord/expense/?apartment_id=${selectedApartmentId}`, config)
      .then((res) => {
        setApartmentUtilities(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })

  }, [selectedApartmentId])

  // the data to be sent in the backend
  const [formData, setFormData] = useState({
    apartment_csv: null
  });


  const [selectedApartmentHouse, setSelectedApartmentHouse] = useState(null);
  const [selectedApartmentUtility, setSelectedApartmentUtility] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  function handleUtilitySelect(selectedUtilityId) {
    setSelectedApartmentUtility(selectedUtilityId)

  }

  function handleApartmentSelect(selectedApartmentId) {
    setSelectedApartmentId(selectedApartmentId)

  }

  function handleMonthSelected(month) {
    setSelectedMonth(month)

  }

  const [selectedFile, setSelectedFile] = useState(null);


  // onchange functionality  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);


    if (file && file.type.includes('csv')) {
      setSelectedFile(file);
      setFormData((prevFormData) => ({
        ...prevFormData,
        apartment_csv: file,
      }));
    } else {
      setSelectedFile(null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        apartment_csv: null,
      }));
    }
  }

  //upload the apartment csv to the backend 
  function handleUploadApartment(e) {

    // preventing from redirecting to other pages
    e.preventDefault();
    // the data that will be posted to the api is as follows
    const data = new FormData();
    data.append('landlord', landlordUserId)
    data.append('file', formData.apartment_csv);


    // the data that will be posted to the api is as follows
    const configUpload = {
      headers: { Authorization: `Bearer ${access}` }
    }


    //  now post the data
    try {
      serverUrl
        .post(`/landlord/upload-property-template/`, data, configUpload)
        .then((res) => {
          toast.success('Succesfully uploaded the csv of the property!')
          handlCloseProperty()
          setSelectedFile(null);
          window.reload()
          // console.log(res.data)
          // Update the properties to see the new data
          // setLandlordApartment((prevApartments) => [...prevApartments, res.data]);

        })
        .catch((error) => {
          toast.error(error.message)
        });
    } catch (error) {
      console.error("Error:", error);
    }


  }



  //upload the tenant csv to the backend 
  function handleUploadTenant(e) {

    // preventing from redirecting to other pages
    e.preventDefault();
    // the data that will be posted to the api is as follows
    const data = new FormData();
    data.append('apartment_id', selectedApartmentId)
    data.append('file', formData.apartment_csv);


    // the data that will be posted to the api is as follows
    const configUpload = {
      headers: { Authorization: `Bearer ${access}` }
    }

    console.log(formData.apartment_csv.name)

    //  now post the data
    try {
      serverUrl
        .post(`/landlord/upload_tenant/?apartment_id=${selectedApartmentId}`, data, configUpload)
        .then((res) => {
          toast.success('Succesfully Uploaded the tenants')
          handleCloseTenant()
          setSelectedFile(null);
        })
        .catch((error) => {
          console.error("Request failed:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }


  }

  // upload the utilities upload 
  function handleUploadUtilities(e) {

    // preventing from redirecting to other pages
    e.preventDefault();
    // the data that will be posted to the api is as follows
    const data = new FormData();
    data.append('apartment_id', selectedApartmentId)
    data.append('expense_id', selectedApartmentUtility)
    data.append('month', selectedMonth)

    data.append('file', formData.apartment_csv);


    // the data that will be posted to the api is as follows
    const configUpload = {
      headers: { Authorization: `Bearer ${access}` }
    }
    //  now post the data
    try {
      serverUrl
        .post(`/landlord/upload-water-units/`, data, configUpload)
        .then((res) => {
          toast.success('Succesfully Uploaded the utilities unit')
          handleCloseUnits()
          setSelectedFile(null);
        })
        .catch((error) => {
          console.error("Request failed:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }


  }

  // upload the house csv to the backend
  function handleUploadHouse(e) {

    // preventing from redirecting to other pages
    e.preventDefault();
    // the data that will be posted to the api is as follows
    const data = new FormData();
    data.append('apartment_id', selectedApartmentId)
    data.append('file', formData.apartment_csv);


    // the data that will be posted to the api is as follows
    const configUpload = {
      headers: { Authorization: `Bearer ${access}` }
    }

    //  now post the data
    try {
      serverUrl
        .post(`/landlord/upload_houses/?apartment_id=${selectedApartmentId}`, data, configUpload)
        .then((res) => {
          toast.success('Succesfully Uploaded the units')
          handleCloseHouse()
          setSelectedFile(null);
        })
        .catch((error) => {
          toast.success('Sorry an error occured while uploading the csv')
          console.error("Request failed:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }


  }

  const [sortType, setSortType] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);


  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };
  const sortedData = landlordApartment.sort((a, b) => {
    const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === 'asc') {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(' ').toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });

  // design the table to display 10 each per table
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
  const displayedItems = filteredData.slice(startIndex, endIndex);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}
      <div className='bulk'>

        {/* top display */}
        <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
          <p className='properties'>BULK UPLOADS</p>

        </div>

        {/* property upload */}
        <div className="tasks mt-4">
          <h2> Property Upload </h2>

          <div className="d-flex justify-content-between">
            <div className='topButtonsBulk'>
              <Button className="btn btn-sm btn-success text-white me-3" onClick={handleOpenProperty} >
                {' '}
                Bulk uploads <i class="bi bi-upload"></i>
              </Button>
              <Button className="btn btn-sm btn-warning text-white" onClick={exportCSV} >
                Download Template <i class="bi bi-cloud-arrow-down"></i>
              </Button>
            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" onChange={handleSearch} />
            </Form>
          </div>
          <Table className="table mt-3">
            <thead className="underline">
              <tr>
                <th >
                  Property Name<i className="bi bi-chevron-expand"></i>
                </th>

                <th>
                  Location<i className="bi bi-chevron-expand"></i>
                </th>
                <th >
                  About<i className="bi bi-chevron-expand"></i>
                </th>

              </tr>
            </thead>
            <tbody>
              {displayedItems.map((landlord) => (
                <tr>
                  <td>{landlord.name}</td>
                  <td>{landlord.location}</td>
                  <td>{landlord.about}</td>
                </tr>

              ))}

            </tbody>

          </Table>

          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 0}>
              &lt;
            </button>
            <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
              &gt;
            </button>
          </div>

          {/* Modal upload the template */}
          <Modal show={showModalProperty} onHide={handlCloseProperty}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Property CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form encType="multipart/form-data" onSubmit={handleUploadApartment}>
                <label htmlFor="fileInput" className='w-100 mb-3'>Upload Property CSV:</label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".csv"
                  className="d-none"
                  onChange={handleFileChange}
                />

                <p>
                  {selectedFile ? selectedFile.name : 'No file selected'}
                </p>

                <Button variant="success" type='submite'>
                  Upload
                </Button>
              </Form>

            </Modal.Body>

          </Modal>
        </div>

        {/* unit upload */}
        <div className="tasks mt-4">
          <h2> Units Upload </h2>

          <div className="d-flex justify-content-between">
            <div className='topButtonsBulk'>
              <Button className="btn btn-success text-white me-3" onClick={handleOpenHouse} >
                {' '}
                Bulk uploads <i class="bi bi-upload"></i>
              </Button>
              <Button className="btn btn-warning text-white" onClick={exportCSVHouse} >
                Download Template <i class="bi bi-cloud-arrow-down"></i>
              </Button>
            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" />
            </Form>
          </div>
          <Table className="table mt-3">
            <thead className="underline">
              <tr>
                <th >
                  Unit Number<i className="bi bi-chevron-expand"></i>
                </th>
                <th >
                  Rent<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  Due Date<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  Unit Type<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  No of bedrooms<i className="bi bi-chevron-expand"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>

              </tr>
            </tbody>

          </Table>

          {/* Modal upload the template  for house*/}
          <Modal show={showModalHouse} onHide={handleCloseHouse}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Unit CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form encType="multipart/form-data" onSubmit={handleUploadHouse}>

                <Form.Select
                  size='sm'
                  className='formProperty mb-3'
                  value={selectedApartmentHouse}
                  onChange={(e) => handleApartmentSelect(e.target.value)}

                >
                  <option value="">Select Apartment</option>
                  {landlordApartment.map((landlord) => (
                    <option key={landlord.id} value={landlord.id}>
                      {landlord.name}
                    </option>
                  ))}
                </Form.Select>


                <label htmlFor="fileInput" className='w-100 mb-3'>Upload Unit CSV:</label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".csv"
                  className="d-none"
                  onChange={handleFileChange}
                />

                <p>
                  {selectedFile ? selectedFile.name : 'No file selected'}
                </p>

                <Button variant="success" type='submite'>
                  Upload
                </Button>
              </Form>

            </Modal.Body>

          </Modal>

        </div>

        {/* tenant upload */}
        <div className="tasks mt-4">
          <h2> Tenants Upload </h2>

          <div className="d-flex justify-content-between">
            <div className='topButtonsBulk'>
              <Button className="btn btn-success text-white me-3" onClick={handleOpenTenant}>
                {' '}
                Bulk uploads <i class="bi bi-upload"></i>
              </Button>
              <Button className="btn btn-warning text-white" onClick={exportCSVTenant}  >
                Download Templates <i class="bi bi-cloud-arrow-down"></i>
              </Button>
            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" />
            </Form>
          </div>
          <Table className="table mt-3">
            <thead className="underline">
              <tr>
                <th >
                  Tenant Name<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  Tenant Phone Number<i className="bi bi-chevron-expand"></i>
                </th>
                <th >
                  Unit Number<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  Tenant Email<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  Due Date<i className="bi bi-chevron-expand"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>

              </tr>
            </tbody>

          </Table>


          {/* Modal upload the template  for tenant*/}
          <Modal show={showModalTenant} onHide={handleCloseTenant}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Tenant CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form encType="multipart/form-data" onSubmit={handleUploadTenant}>

                <Form.Select
                  size='sm'
                  className='formProperty mb-3'
                  value={selectedApartmentHouse}
                  onChange={(e) => handleApartmentSelect(e.target.value)}

                >
                  <option value="">Select Apartment</option>
                  {landlordApartment.map((landlord) => (
                    <option key={landlord.id} value={landlord.id}>
                      {landlord.name}
                    </option>
                  ))}
                </Form.Select>


                <label htmlFor="fileInput" className='w-100 mb-3'>Upload Tenant CSV:</label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".csv"
                  className="d-none"
                  onChange={handleFileChange}
                />

                <p>
                  {selectedFile ? selectedFile.name : 'No file selected'}
                </p>

                <Button variant="success" type='submite'>
                  Upload
                </Button>
              </Form>

            </Modal.Body>
          </Modal>

        </div>

        {/* water upload */}
        <div className="tasks mt-4">
          <h2> Utilities Upload </h2>

          <div className="d-flex justify-content-between">
            <div className='topButtonsBulk'>
              <Button className="btn btn-success text-white me-3" onClick={handleOpenUnits}>
                {' '}
                Bulk uploads <i class="bi bi-upload"></i>
              </Button>
              <Button className="btn btn-warning text-white" onClick={exportCSVWater}  >
                Download Templates <i class="bi bi-cloud-arrow-down"></i>
              </Button>
            </div>
            <Form className="searchBar">
              <FormControl type="text" placeholder="Search" />
            </Form>
          </div>
          <Table className="table mt-3">
            <thead className="underline">
              <tr>
                <th >
                  House<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  Total Units<i className="bi bi-chevron-expand"></i>
                </th>
                <th >
                  Unit Price<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  Payable Amount<i className="bi bi-chevron-expand"></i>
                </th>
                <th>
                  Amount Paid<i className="bi bi-chevron-expand"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>

              </tr>
            </tbody>

          </Table>


          {/* Modal upload the template  for tenant*/}
          <Modal show={showModalUnits} onHide={handleCloseUnits}>
            <Modal.Header closeButton>
              <Modal.Title>Upload Utilities CSV</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form encType="multipart/form-data" onSubmit={handleUploadUtilities}>

                <Form.Select
                  size='sm'
                  className='formProperty mb-3'
                  value={selectedApartmentHouse}
                  onChange={(e) => handleApartmentSelect(e.target.value)}

                >
                  <option value="">Select Property</option>
                  {landlordApartment.map((landlord) => (
                    <option key={landlord.id} value={landlord.id}>
                      {landlord.name}
                    </option>
                  ))}
                </Form.Select>

                <Form.Select
                  size='sm'
                  className='formProperty mb-3'
                  value={selectedApartmentUtility}
                  onChange={(e) => handleUtilitySelect(e.target.value)}

                >
                  <option value="">Select Utility</option>
                  {apartmentUtilities.map((utility) => (
                    <option key={utility.id} value={utility.id}>
                      {utility.title}
                    </option>
                  ))}
                </Form.Select>

                <Form.Select
                  size='sm'
                  className='formProperty mb-3'
                  value={selectedMonth}
                  onChange={(e) => handleMonthSelected(e.target.value)}

                >
                  <option value="" disabled>Select a month</option>
                  {months.map((month, index) => (
                    <option key={index} value={month.toLowerCase()}>
                      {month.charAt(0).toUpperCase() + month.slice(1)}
                    </option>
                  ))}
                </Form.Select>

                <label htmlFor="fileInput" className='w-100 mb-3'>Upload Units CSV:</label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".csv"
                  className="d-none"
                  onChange={handleFileChange}
                />

                <p>
                  {selectedFile ? selectedFile.name : 'No file selected'}
                </p>

                <Button variant="success" type='submite'>
                  Upload
                </Button>
              </Form>

            </Modal.Body>
          </Modal>

        </div>

      </div>
    </>
  )
}

export default BulkUploads