import React, { useState, useEffect } from 'react'
import './SubscriptionDashboard.scss'
import Header from './Header'
import SideBar from './SideBar'
import { Button, Table, Card, Form, Image, Modal, FormSelect, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Internet from '../../assets/img/card.png'
import Water from '../../assets/img/ewallet.png'
import Electricity from '../../assets/img/bitcoin-wallet.png'
import Security from '../../assets/img/subscription.png'
// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from "react-toastify";
import loaderImage from '../../assets/img/logo.png'


function SubscriptionDashboard() {
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }

    // get the apartements of the landlord
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

    // get the current month so that i can filter out the total income
    const months = Array.from({ length: 12 }, (_, i) => {
        const month = new Date(new Date().getFullYear(), i, 1);
        return month.toLocaleString('default', { month: 'long' });
    });

    const currentDate = new Date();
    const currentMonthIndex = new Date().getMonth();
    const currentMonth = months[currentMonthIndex];
    // get the years
    const currentYear = new Date().getFullYear();
    const startYear = currentYear;

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;

    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;

    const [subscriptions, setSubscriptions] = useState([])
    const [subscriptionData, setSubscriptionData] = useState([])
    const [userLogedIn, setUserLogedIn] = useState([]);
    const [landlordFound, setLandlordFound] = useState('');

    // from the localstorage get the logged in user
    useEffect(() => {

        // get the landlord details that are linked to the user
        setIsLoading(true)
        serverUrl
            .get(`/users/users-details/?id=${userId}`, config)
            .then((res) => {
                setUserLogedIn(res.data.results[0]);
            })
            .catch((error) => {
                console.log(error);
            });
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {
                // get the landlord Id
                const landlordIds = res.data.results.map((landlord) => landlord.id);
                setLandlordFound(res.data.results.map((landlord) => landlord.id))

                // get the subscription wallets 
                serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordIds}`, config)
                    .then((res) => {
                        const apartmentPromise = res.data.results.map((apartment) => {
                            return serverUrl.get(`/landlord/wallet/?property=${apartment.id}`, config)
                                .then((res) => ({
                                    apartment_wallet: res.data.results,
                                }))
                                .catch((error) => {
                                    console.log(error);
                                    return { apartment_wallet: res.data.results };

                                })
                        })
                        Promise.all(apartmentPromise)
                            .then((results) => {
                                // console.log(results)
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })

                    .catch((error) => {
                        console.log(error)
                    })

                // get the subscription plans
                serverUrl.get(`/landlord/subscription_apartments_landlord/?landlord_id=${landlordIds}`, config)
                    .then((res) => {
                        setSubscriptionData(res.data)
                        setSubscriptions(res.data.results)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error);
            });

    }, [userId]);


    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const totalPages = Math.ceil(subscriptions.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? subscriptions.length : startIndex + itemsPerPage;
    const displayedItems = subscriptions.slice(startIndex, endIndex);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
    useEffect(() => {
        const getRandomImage = () => {

            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            setIsLoading(true);

            const updatedImages = { ...backgroundImageApartment };

            // Loop through each house and update the image if not set
            for (const unitDetail of subscriptions) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImage();
                }
            }
            setBackgroundImageApartment(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [subscriptions]);

    const [unpaidInvoices, setUnpaidInvoices] = useState([]);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState(false);
    const [subscriptionModalInvoice, setSubscriptionModalInvoice] = useState(false);
    const [selectedSubscriptionAmountInt, setSelectedSubscriptionAmountInt] = useState(null);
    const [selectedSubscriptionPeriod, setSelectedSubscriptionPeriod] = useState(null);

    const handleCloseSubscriptionInvoice = () => {
        setSubscriptionModalInvoice(false);
    };

    const handlePayPlan = (plan) => {

        serverUrl.get(`/landlord/subscriptions-invoice/?wallet=${plan.wallet_id}`, config)
            .then((res) => {
                setSubscriptionModalInvoice(true);
                const unpaidInvoices = res.data.results.filter((invoice) => invoice.status !== 'paid');
                const totalUnpaidAmount = unpaidInvoices.reduce((total, invoice) => total + parseFloat(invoice.amount), 0);

                setInvoicesBalance(totalUnpaidAmount)
                setUnpaidInvoices(unpaidInvoices);
            })
            .catch((error) => {
                console.log(error)
            })
    };

    const [invoiceData, setInvoiceData] = useState({});
    const [apartmentWallets, setApartmentWallets] = useState([]);
    const [subscriptionPaymentModal, setSubscriptionPaymentModal] = useState(false);
    const [walletInvoiceIdTransaction, setWalletInvoiceIdTransaction] = useState("");
    const [walletIdTransaction, setWalletIdTransaction] = useState("");
    const [subscriptionPay, setSubscriptionPay] = useState(false);
    const [phoneNumberPayment, setPhoneNumberPayment] = useState("");
    const handlePhoneNumberChange = (e) => {
        setPhoneNumberPayment(e.target.value);
    };

    const handleSubscriptionPaymentOpen = (invoiceId) => {
        // get the details of the selected invoice
        serverUrl
            .get(`/landlord/subscriptions-invoice/${invoiceId}/`, config)
            .then((res) => {
                setInvoiceData(res.data);
                // get the wallets for the apartment
                serverUrl
                    .get(`/landlord/wallet/?property=${res.data.apartment}`, config)
                    .then((res) => {
                        setApartmentWallets(
                            res.data.results.filter(
                                (wallet) => wallet.account_type !== "subscription"
                            )
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
        setSubscriptionPaymentModal(true);
        setWalletInvoiceIdTransaction(invoiceId);
        setSubscriptionModalInvoice(false);
        setWalletIdTransaction("0");
    };

    const handleCloseSubscription = () => {
        setSubscriptionModal(false);
    };
    const handleCloseSubscriptionPay = () => {
        setSubscriptionPay(false);
    };
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedWalletName, setSelectedWalletName] = useState([]);
    const [selectedMpesaOption, setSelectedMpesaOption] = useState(null);

    const handleMpesaOption = (method) => {
        setSelectedMpesaOption(method);
    };

    const handlePaymentMethod = (method) => {
        setSelectedPaymentMethod(method);
        setSelectedWalletName([]);
        handleMpesaOption(null);
    };

    const handleSubscriptionPaymentClose = () => {
        setSubscriptionPaymentModal(false);
        setSelectedPaymentMethod(null);
        setSelectedMpesaOption(null);
    };
    const [invoicesBalance, setInvoicesBalance] = useState('');

    const [amountPhone, setAmountPhone] = useState("");
    const mpesSubscriptionBalance = parseInt(invoicesBalance) - parseInt(amountPhone)
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [selectedWalletBalance, setSelectedWalletBalance] = useState(null);
    const [selectedCurrentWalletBalance, setSelectedCurrentWalletBalance] = useState(null);
    const handleAmountPayment = (e) => {
        setAmountPhone(e.target.value);
    };

    function handleWalletSelect(selectedWalletId) {
        setSelectedWallet(selectedWalletId);
        serverUrl
            .get(
                `/landlord/wallet-account-balance?wallet=${selectedWalletId}`,
                config
            )
            .then((res) => {
                setSelectedWalletBalance(res.data.results[0].wallet_balance);
                const balancePayment =
                    res.data.results[0].wallet_balance - parseInt(invoiceData.amount);
                setSelectedCurrentWalletBalance(balancePayment);

                const balance = parseInt(invoiceData.amount) - balancePayment;
            })
            .catch((error) => {
                console.log(error);
            });

        // get the details of the selected wallet
        serverUrl
            .get(`/landlord/wallet/${selectedWalletId}/`, config)
            .then((res) => {
                setSelectedWalletName(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const [enteredAmount, setEnteredAmount] = useState("");
    const handleAmountChange = (amount) => {
        setEnteredAmount(amount);
    };


    function handleAddSubscription(e) {
        e.preventDefault();
        // get the subscription wallet
        serverUrl
            .get(`landlord/wallet/?property=${subscriptionId}`, config)
            .then((res) => {
                const wallet = res.data.results.filter(
                    (wallet) => wallet.account_type === "subscription"
                );

                const data = {
                    property: subscriptionId,
                    status: "inactive",
                    amount: parseInt(selectedSubscriptionAmountInt),
                    duration: selectedSubscriptionPeriod,
                    wallet: wallet[0].id,
                };
                // post the subscription data
                serverUrl
                    .post(`/landlord/subscriptions/`, data, config)
                    .then((res) => {
                        const invoiceData = {
                            apartment: subscriptionId,
                            wallet: wallet[0].id,
                            subscription: res.data.id,
                            amount: parseInt(selectedSubscriptionAmountInt),
                            month: currentMonth.toLocaleLowerCase(),
                            year: currentYear,
                            status: "unpaid",
                            user: userId,
                        };
                        serverUrl
                            .post(`/landlord/subscriptions-invoice/`, invoiceData, config)
                            .then((res) => {
                                setSubscriptionModal(false);
                                toast.success("Succesfully created a subscription plan");
                                window.location.reload()
                                // setSubscriptionPay(true);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const [selectedApartmentIdInvoice, setSelectedApartmentIdInvoice] = useState("");


    const handlePaySubscription = () => {
        if (selectedPaymentMethod === "wallet") {
            const data = {
                subscription_wallet: parseInt(selectedWallet),
                wallet: invoiceData.wallet,
                property: selectedApartmentIdInvoice,
                wallet_balance: parseInt(selectedWalletBalance) - parseInt(enteredAmount),
                wallet_name: selectedWalletName.name,
                invoice: parseInt(invoiceData.id),
                amount_paid: parseInt(enteredAmount),
            };
            const formData = new FormData();

            // Append each key-value pair to the FormData object
            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    formData.append(key, String(data[key]));
                }
            }
            // post the data for the payment
            serverUrl
                .post(`/landlord/pay-subscription-from-wallet/`, formData, config)
                .then((res) => {
                    // show paid and close the modal
                    toast.success("Succesfuly made payment for the subscription");
                    setEnteredAmount(null);
                    setSelectedWallet(null);
                    setSelectedWalletName(null);
                    setSelectedPaymentMethod(null);
                    setSubscriptionPaymentModal(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        else {
            let modifiedPhoneNumber = "";

            if (selectedMpesaOption === "my_number") {
                modifiedPhoneNumber = userLogedIn.phone_number;

                if (userLogedIn.phone_number.length === 10 && userLogedIn.phone_number.startsWith("0")) {
                    modifiedPhoneNumber = "254" + userLogedIn.phone_number.slice(1);
                }
            } else if (selectedMpesaOption === "other_number") {
                modifiedPhoneNumber = phoneNumberPayment;

                if (phoneNumberPayment.length === 10 && phoneNumberPayment.startsWith("0")) {
                    modifiedPhoneNumber = "254" + phoneNumberPayment.slice(1);
                }
            }

            const data = {
                amount: parseInt(amountPhone),
                phone_number: modifiedPhoneNumber,
                current_balance: parseInt(invoiceData.amount) - amountPhone,
                wallet: invoiceData.wallet,
                subscription_plan_id: invoiceData.subscription,
                invoice_id: parseInt(invoiceData.id),
                property_id: invoiceData.apartment,
                landlord: landlordFound[0]

            };
            // post the data 
            serverUrl.post(`/landlord/deposit-subscription-payment`, data, config)
                .then((res) => {
                    toast.success('Kindly wait to input mpesa pin')
                })
                .catch((error) => {
                    console.log(error)
                })


        }
    };

    // statement data
    const [toastId, setToastId] = useState(null);

    const handleDownloadStatement = (plan) => {
        // Show toast when download starts
        const toastId = toast.info('PDF is being downloaded...', { autoClose: false });
        setToastId(toastId); // Store toast id in state

        serverUrl.get(`/landlord/generate_statement_subscription/?wallet_id=${plan.wallet_id}&download=true`, config)
            .then((res) => {
                // Close toast when download is successful
                toast.update(toastId, { render: 'Succesfully downloaded your wallet statement', autoClose: 3000 });
            })
            .catch((error) => {
                console.log(error);
                // Close toast if download fails
                toast.dismiss(toastId);
            });
    };

    const handleViewStatement = (plan) => {
        // Show toast when view starts
        const toastId = toast.info('PDF is being viewed...', { autoClose: false });

        // Construct the URL to fetch the PDF content from the backend server
        const pdfUrl = `http://127.0.0.1:8000/api/landlord/generate_statement_subscription/?wallet_id=${plan.wallet_id}&download=true`;

        // Open the PDF URL in a new tab
        window.open(pdfUrl, '_blank');

        // Close toast after opening the PDF
        toast.success('PDF viewing is successful');
        toast.dismiss(toastId);
    };


    const handleRenew = (plan) => {
        console.log("renew", plan)
    };
    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={loaderImage} alt="Loading..." />
            </div>
        );
    }
    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='subscription_properties'>
                <div className='topSelectedProperty'>
                    <p className='properties text-uppercase'>subscriptions For Your Properties</p>

                </div>



                <div className='staticCards'>
                    <Card className='rentDueElectricity'>
                        <Card.Body className='d-flex'>

                            <Image src={Electricity} alt='water'></Image>

                            <div className='float-left'>
                                <span>Most Used Plan</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost' style={{ textTransform: 'capitalize' }}>
                                        {subscriptionData.most_used_plan && subscriptionData.most_used_plan.duration}
                                    </p>
                                </div>

                            </div>
                        </Card.Body>

                    </Card>

                    <Card className='rentDue'>
                        <Card.Body className='d-flex'>

                            <Image src={Water} alt='water'></Image>

                            <div className='float-left'>
                                <span>Most Active Wallet</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost'>{subscriptionData.most_active_wallet && (subscriptionData.most_active_wallet.wallet)} Subscription</p>
                                </div>

                            </div>
                        </Card.Body>

                    </Card>

                    <Card className='defaulters'>
                        <Card.Body className='d-flex'>

                            <Image src={Security} alt='security'></Image>

                            <div className='float-left'>
                                <span className="">Total Balance</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost'>Ksh. {subscriptionData.total_balance_wallet && (subscriptionData.total_balance_wallet.toLocaleString())}</p>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>

                    <Card className='occupacy'>
                        <Card.Body className='d-flex'>

                            <Image src={Internet} alt='internet'></Image>

                            <div className='float-left'>
                                <span>Inactive Plans</span>
                                <div className='d-flex mt-2'>
                                    <p className='cost' >{subscriptionData.inactive_subscriptions_count}</p>
                                </div>

                            </div>
                        </Card.Body>

                    </Card>
                </div>

                <div className='tasks' >
                    <h5 className='float-start'>My List of Subscriptions</h5>

                    <Table className='table'>
                        <thead className='underline'>
                            <th>Image</th>
                            <th>Property</th>
                            <th>Subscription Plan</th>
                            <th>Subscription Status</th>
                            <th>Started</th>
                            <th>Final Day</th>
                            <th>Remaining Days</th>
                            {/* <th>Actions</th> */}

                        </thead>
                        <tbody>
                            {displayedItems.map((listApartment, index) =>
                                <tr>
                                    <td>
                                        {selectedApartmentImage[listApartment.id] && selectedApartmentImage[listApartment.id] ?
                                            <Image src={selectedApartmentImage[listApartment.id]} className="imageTableDashboard" alt={index}></Image> :
                                            <Image src={backgroundImageApartment[listApartment.id]} className="imageTableDashboard"></Image>
                                        }

                                    </td>

                                    <td>{listApartment.property}</td>
                                    <td>
                                        <Link to={`/wallet-data/${listApartment.wallet_id}`}>
                                            {listApartment.plan}
                                        </Link>

                                    </td>
                                    {listApartment.status === 'inactive' ?

                                        <td><span className={`badge bg-danger p-2`}>{listApartment.status} </span></td>

                                        : listApartment.status === 'expired' ?


                                            <td><span className={`badge bg-secondary p-2`}>{listApartment.status} </span></td>
                                            :

                                            <td><span className={`badge bg-success p-2`}>{listApartment.status} </span></td>

                                    }

                                    {listApartment.started_at === null ?
                                        <td>Plan not Active yet</td>
                                        :
                                        <td>{new Date(listApartment.started_at).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}
                                        </td>
                                    }
                                    {listApartment.ended_at === null ?
                                        <td>Plan not Active yet</td>
                                        :
                                        <td>{new Date(listApartment.ended_at).toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}
                                        </td>
                                    }
                                    {listApartment.ended_at === null ? (
                                        <td>Plan not Active yet</td>
                                    ) : (
                                        <td className={
                                            Math.ceil((new Date(listApartment.ended_at) - new Date()) / (1000 * 60 * 60 * 24)) < 10
                                                ? "text-danger"
                                                : Math.ceil((new Date(listApartment.ended_at) - new Date()) / (1000 * 60 * 60 * 24)) > 20
                                                    ? "text-success"
                                                    : "text-warning"
                                        }>
                                            {Math.ceil((new Date(listApartment.ended_at) - new Date()) / (1000 * 60 * 60 * 24))} days
                                        </td>
                                    )}
                                    {/* the if statement for the buttons to be used */}
                                    {/* <td>
                                        {listApartment.ended_at === null ? (
                                            <Button className="btn btn-sm btn-success" onClick={() => handlePayPlan(listApartment)}>
                                                Pay Subscription
                                            </Button>
                                        ) : (
                                            <div className='d-flex'>
                                                <button
                                                    className={
                                                        Math.ceil((new Date(listApartment.ended_at) - new Date()) / (1000 * 60 * 60 * 24)) < 10
                                                            ? "btn btn-sm btn-danger"
                                                            : Math.ceil((new Date(listApartment.ended_at) - new Date()) / (1000 * 60 * 60 * 24)) > 14
                                                                ? "btn btn-sm btn-primary"
                                                                : "btn btn-sm btn-warning text-white"
                                                    }
                                                    onClick={
                                                        Math.ceil((new Date(listApartment.ended_at) - new Date()) / (1000 * 60 * 60 * 24)) < 10
                                                            ? () => handlePayPlan(listApartment)
                                                            : Math.ceil((new Date(listApartment.ended_at) - new Date()) / (1000 * 60 * 60 * 24)) > 14
                                                                ? () => handleViewStatement(listApartment)
                                                                : () => handleRenew(listApartment)
                                                    }
                                                >
                                                    {Math.ceil((new Date(listApartment.ended_at) - new Date()) / (1000 * 60 * 60 * 24)) < 10
                                                        ? "Pay"
                                                        : Math.ceil((new Date(listApartment.ended_at) - new Date()) / (1000 * 60 * 60 * 24)) > 14
                                                            ? (
                                                                <>
                                                                    <i className="bi bi-eye-fill me-1"></i> Statement
                                                                </>
                                                            )
                                                            : "Renew"
                                                    }
                                                </button>
                                                {Math.ceil((new Date(listApartment.ended_at) - new Date()) / (1000 * 60 * 60 * 24)) > 14 && (
                                                    <button className="btn btn-sm btn-info ms-2 text-white" onClick={() => handleDownloadStatement(listApartment)}>
                                                        <i className="bi bi-download me-1"></i> Statement
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </td> */}



                                </tr>
                            )}


                        </tbody>
                    </Table>

                    <div className="pagination">
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${subscriptions.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                </div>

                {/* modal to display the invoices */}
                <Modal
                    show={subscriptionModalInvoice}
                    onHide={handleCloseSubscriptionInvoice}
                    size="xl"
                    style={{ overflowY: "auto", height: "50vh" }}

                >
                    <Modal.Header closeButton>
                        <Modal.Title
                            style={{ fontSize: "1.5rem", color: "rgb(31 248 129)" }}
                        >
                            Subscription Invoices
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="fw-bold text-info text-uppercase">
                            Kindly pay so that you can have access to your property data
                        </p>
                        <Table
                            className="table "
                            style={{ borderBottom: "1px solid white" }}
                        >
                            <thead className="underline">
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Wallet</th>
                                <th>Month</th>
                                <th>Year</th>
                                <th>Actions</th>
                            </thead>
                            <tbody>
                                {unpaidInvoices.map((invoice) => (
                                    <tr>
                                        <td>Ksh {invoice.amount.toLocaleString()} </td>
                                        {invoice.status === "unpaid" ?
                                            <td className='text-danger'>{invoice.status} </td>
                                            :
                                            <td className='text-warning'>{invoice.status} </td>

                                        }
                                        <td>Subscription </td>
                                        <td>{invoice.month} </td>
                                        <td>{invoice.year} </td>
                                        <td>
                                            <Button
                                                className="btn btn-sm border-0"
                                                style={{ background: "rgb(240 204 29)" }}
                                                onClick={() =>
                                                    handleSubscriptionPaymentOpen(invoice.id)
                                                }
                                            >
                                                Make payment
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-secondary text-white fw-bold opacity-75"
                            onClick={handleAddSubscription}
                        >
                            Done
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* modal payment process */}
                <Modal
                    show={subscriptionPaymentModal}
                    onHide={handleSubscriptionPaymentClose}
                >
                    <Modal.Header closeButton>
                        <Modal.Title
                            style={{ fontSize: "1.5rem", color: "#0dcaf0" }}
                        >
                            Choose payment method
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='fw-bold text-uppercase'>Your subscription balance is Ksh. {invoicesBalance.toLocaleString()}</p>
                        {apartmentWallets.length !== 0 ? (
                            <div className="d-flex justify-content-between mb-3">
                                <Button
                                    className="border-0"
                                    style={{ background: "#343a4080" }}
                                    onClick={() => handlePaymentMethod("wallet")}
                                >
                                    Wallet
                                </Button>
                                <Button
                                    className="border-0"
                                    style={{ background: "rgb(255 215 26)" }}
                                    onClick={() => handlePaymentMethod("mpesa")}
                                >
                                    {" "}
                                    Mpesa
                                </Button>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-between">
                                <Button
                                    className="btn btn-sm  text-white border-0"
                                    style={{ background: "#9b59b6" }}
                                    onClick={() => handleMpesaOption("my_number")}
                                >
                                    Pay with my number
                                </Button>
                                <Button
                                    className="btn btn-sm text-white border-0"
                                    style={{ background: "#34495e" }}
                                    onClick={() => handleMpesaOption("other_number")}
                                >
                                    Pay with another number
                                </Button>
                            </div>
                        )}

                        {selectedPaymentMethod !== null ? (
                            selectedPaymentMethod === "wallet" ? (
                                <>
                                    <FormSelect
                                        className="mt-2"
                                        value={selectedWallet}
                                        onChange={(e) => handleWalletSelect(e.target.value)}
                                    >
                                        <option selected disabled value="">
                                            Select wallet to pay from
                                        </option>
                                        {apartmentWallets.map((wallet) => (
                                            <option key={wallet.id} value={wallet.id}>
                                                {wallet.name}
                                            </option>
                                        ))}
                                    </FormSelect>
                                    {selectedWalletName.length === 0 ? (
                                        <></>
                                    ) : (
                                        <>
                                            {selectedWalletBalance === 0 ? (
                                                <p className="mt-2">
                                                    Sorry, {selectedWalletName.name} does not have
                                                    any money in it to make payment
                                                </p>
                                            ) : (
                                                <p className="mt-2">
                                                    {selectedWalletName.name} has a balance of
                                                    Ksh. {selectedWalletBalance}
                                                </p>
                                            )}
                                        </>
                                    )}
                                    {selectedWalletBalance === 0 ? (
                                        <></>
                                    ) : (
                                        <>
                                            <input
                                                type="number"
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 form-control mt-2"
                                                placeholder="Enter amount to pay"
                                                value={enteredAmount}
                                                onChange={(e) =>
                                                    handleAmountChange(e.target.value)
                                                }
                                            />

                                            {enteredAmount ? (
                                                <>
                                                    {selectedWalletBalance < enteredAmount ? (
                                                        <p className="mt-2">
                                                            Sorry Insuficient Balanace
                                                        </p>
                                                    ) : (
                                                        <p className="mt-2">
                                                            Balance in {selectedWalletName.name} will
                                                            be {selectedWalletBalance - enteredAmount}{" "}
                                                        </p>
                                                    )}
                                                </>
                                            ) : (
                                                <> </>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="d-flex justify-content-between">
                                    <Button
                                        className="btn btn-sm text-white border-0"
                                        style={{ background: "#9b59b6" }}
                                        onClick={() => handleMpesaOption("my_number")}
                                    >
                                        Pay with my number
                                    </Button>
                                    <Button
                                        className="btn btn-sm  text-white border-0"
                                        style={{ background: "#34495e" }}
                                        onClick={() => handleMpesaOption("other_number")}
                                    >
                                        Pay with another number
                                    </Button>
                                </div>
                            )
                        ) : (
                            <></>
                        )}
                        <Form>
                            {selectedMpesaOption !== null ? (
                                <>
                                    {selectedMpesaOption === "my_number" ? (
                                        <div className="d-block">
                                            <input
                                                value={userLogedIn.phone_number}
                                                type="number"
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 form-control mt-2"
                                                placeholder="Enter amount to pay"
                                            />
                                            <input
                                                type="number"
                                                value={amountPhone}
                                                name="amount_phone"
                                                onChange={handleAmountPayment}
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 form-control mt-2"
                                                placeholder="Enter amount to pay"
                                            />
                                        </div>
                                    ) : (
                                        <div className="d-block">
                                            <input
                                                type="number"
                                                value={phoneNumberPayment}
                                                name="phone_number"
                                                onChange={handlePhoneNumberChange}
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 form-control mt-2"
                                                placeholder="Enter phone number"
                                            />
                                            <input
                                                type="number"
                                                value={amountPhone}
                                                name="amount_phone"
                                                onChange={handleAmountPayment}
                                                style={{ borderRadius: "5px" }}
                                                className="p-2 w-100 form-control mt-2"
                                                placeholder="Enter amount to pay"
                                            />
                                        </div>
                                    )}
                                    {amountPhone && (
                                        <p className="mt-2 text-warning">The subscription balance will be Ksh. {mpesSubscriptionBalance.toLocaleString()}</p>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-success text-white fw-bold opacity-75"
                            onClick={handlePaySubscription}
                        >
                            Pay
                        </Button>
                    </Modal.Footer>
                </Modal>



            </div>
        </>
    )
}

export default SubscriptionDashboard