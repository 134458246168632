import React, { useState } from 'react'
import './HeaderConfigure.css'
import { Form, FormControl, ListGroup, Container, Card, Image, Button } from 'react-bootstrap';
import apartment6 from '../../assets/img/apartment6.jpg'


function HeaderConfigure() {
    // declaring the items that are supposed to be searched
  const itemList = ['A10','A15','A20','B10','K15','C25'];
  const [searchTerm, setSearchTerm] = useState('');

//   handling the event of when the items are searched for
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

//   the results to be displayed when the items are searched for
  const searchResults = itemList.filter((item) => {
    return item.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <>
    {/* top of the body */}
      <div className='headerMain'>
        <h3>Properties</h3>
        <Form className="searchBar">
          <FormControl type="text" placeholder="Search" className="mr-sm-2" value={searchTerm} onChange={handleSearchChange} />
        </Form>
        </div>

        {/* the items displayed on search */}
        {searchTerm && (
          <ListGroup className='itemSearch'>
            {searchResults.map((result, index) => {
              return <ListGroup.Item key={index} className='items'>{result}</ListGroup.Item>;
            })}
          </ListGroup>
        )}

        {/* the loner card */}
     <Container className='ApartmentCard'>
          <Card>
            <Card className='houseImage'>
            <Image src={apartment6} className='imageCardLoner' />
            </Card>
            <h3>name of house</h3>
          
            <p><i className='bi bi-geo-alt-fill text-warning'></i>name of location</p>
            <i className='bi bi-trash-fill text-danger'></i>
          </Card>
        </Container>

        {/* Secon title with search */}
        <div className='headerMain'>
        <h3>Houses</h3>
        <Form className="searchBar">
          <FormControl type="text" placeholder="Search" className="mr-sm-2" value={searchTerm} onChange={handleSearchChange} />
        </Form>
        </div>

        {/* houses together with their details */}
        <Container className='houseDeatilsCard' >
          <Card className='housesCards'>
              <Image src={apartment6}  className='imageCard'></Image>

              <div className='houseDetails'>
              <h3>House No</h3>
              <p><i className='bi bi-person housePerson'></i>Tenant Name</p>
              <p ><span className='text-muted'>ksh Amount</span>/per month</p>
              <div className='houseButtons'>
           <Button className='btn btn-danger btn-sm'>vacant or not</Button>
           <i className='bi bi-trash-fill '></i>
           </div>
              </div>
          </Card>

          <Card className='housesCards'>
           
           <Image src={apartment6}  className='imageCard'></Image>

           <div className='houseDetails'>
           <h3>House No</h3>
           <p><i className='bi bi-person housePerson'></i>Tenant Name</p>
           <p ><span className='text-muted'>ksh Amount</span>/per month</p>
           <div className='houseButtons'>
           <Button className='btn btn-danger btn-sm'>vacant or not</Button>
           <i className='bi bi-trash-fill '></i>
           </div>
           </div>
          </Card>

          <Card className='housesCards'>
           
           <Image src={apartment6}  className='imageCard'></Image>

           <div className='houseDetails'>
           <h3>House No</h3>
           <p><i className='bi bi-person housePerson'></i>Tenant Name</p>

           <p ><span className='text-muted'>ksh Amount</span>/per month</p>
           <div className='houseButtons'>
           <Button className='btn btn-danger btn-sm'>vacant or not</Button>
           <i className='bi bi-trash-fill '></i>
           </div>
          
           </div>
          </Card>
      
        </Container>
    </>
  )
}

export default HeaderConfigure
