import React, { useState, useEffect } from 'react'
import Header from '../Header'
import SideBar from '../SideBar'
import './IssueReport.scss'
import { useParams } from 'react-router-dom';
import { Button, Table, Form, FormControl, Modal } from 'react-bootstrap';



// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../../serverUrl";
import { toast } from 'react-toastify';


function ApartmentIssueReport() {
    // sidebar and header functions
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }

    // getting the id and finding the apparment details
    const { id } = useParams();

    // house selected
    const [houseSelected, setHouseSelected] = useState([])
    const [houseSelectedIssues, setHouseSelectedIssues] = useState([])
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    // get the list of the houses

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);

    // get the house selected by user
    useEffect(() => {
        setIsLoading(true)
        serverUrl.get(`/landlord/apartment/${id}/`, config)
            .then((res) => {
                setHouseSelected(res.data)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })
        // get the tenant of the house
        serverUrl.get(`/tenant/apartment-tenant-issues/?apartment_id=${id}`, config)
            .then((res) => {
                console.log('apart', res)
                setHouseSelectedIssues(res.data.results.filter((issue) => issue.status === true))
                serverUrl.get(`/tenant/tenant/${res.data.results[0].tenant}`, config)
                    .then((res) => {
                        // console.log(res.data)
                        serverUrl.get(`/users/users-details/${res.data.user}/`, config)
                            .then((res) => {
                                setIsLoading(false)
                            })
                            .catch((error) => {
                                console.log(error);
                                setIsLoading(false)
                            })

                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false)
                    })

            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })

        // get the issues report for the apartment
        serverUrl.get(`/tenant/house-tenant-issues/?house_id=${id}`, config)
            .then((res) => {
                // setHouseSelectedIssues(res.data.results.filter((issue) => issue.status === true))
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);
    // declare the selected house
    const selectedUnit = houseSelected
    const idHouse = parseInt(id)

    // set is active to false when clicked
    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };


    function handleDelete(issueId) {
        // update the status of the issue to false
        const data = {
            "is_active": false

        }
        serverUrl.patch(`/tenant/tenant-issue/${issueId}/`, data, config)
            .then((res) => {
                const updatedActiveIssues = houseSelectedIssues.filter(issue => issue.id !== issueId);
                setHouseSelectedIssues(updatedActiveIssues);
                toast.success('Successfully Deleted the Report');
                handleModalClose();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    // searching function and sorting functionalities
    const [sortType, setSortType] = useState("name");
    const [sortOrder, setSortOrder] = useState("asc");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSort = (sortField) => {
        setSortType(sortField);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const sortedData = houseSelectedIssues.sort((a, b) => {
        const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === "asc") {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredData = sortedData.filter((item) => {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });

    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}

            {selectedUnit.id === idHouse ?
                <div className='overflowReport'>


                    {/* list of the tenants of the apartment and payment details */}
                    <div className='tasks ' >
                        <h2>Tenants Feedback- {selectedUnit.name} </h2>


                        <div className='d-flex justify-content-between'>

                            <div>
                                <Button className='btn btn-success text-white me-3'> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
                                <Button className='btn btn-danger text-white'>PDF <i className='bi bi-file-pdf-fill'></i></Button>
                            </div>
                            <Form className="searchBar">
                                <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                            </Form>
                        </div>
                        <Table className='table mt-2'>
                            <thead className='underline'>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Issue Type</th>
                                <th>Date</th>


                            </thead>
                            <tbody>

                                {displayedItems.map((selectedIssue) => (
                                    <tr key={selectedIssue.id}>
                                        <td>{selectedIssue.tenant}</td>
                                        <td>{selectedIssue.description}</td>
                                        <td className='text-capitialise'>{selectedIssue.category}</td>
                                        <td>{new Date(selectedIssue.created_at).toLocaleDateString('en-US')}</td>
                                        <td><i className='bi bi-trash-fill text-danger' onClick={handleModalOpen}></i></td>

                                        <Modal show={showModal} onHide={handleModalClose}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Issue Report Delete For {selectedUnit.house_number}</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                Are you sure you want to delete this Issuue Report?
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="danger" onClick={() => handleDelete(selectedIssue.id)} >
                                                    Yes
                                                </Button>
                                                <Button variant="success" onClick={handleModalClose} >
                                                    No
                                                </Button>
                                            </Modal.Footer>

                                        </Modal>
                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                        <div className="pagination">
                            <button onClick={handlePrevPage} disabled={currentPage === 0}>
                                &lt;
                            </button>
                            <span>{`${startIndex + 1}-${endIndex} of ${houseSelectedIssues.length}`}</span>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                                &gt;
                            </button>

                        </div>
                    </div>


                </div>
                :
                <></>
            }
        </>
    )
}

export default ApartmentIssueReport