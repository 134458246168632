import React, { useState } from "react";
// import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image,pdf } from "@react-pdf/renderer";
import logo from "../../assets/img/apartment9.jpg";

const MyTable = () => {

  const [tableData, setTableData] = useState([
    { name: "John", age: 25, gender: "Male" },
    { name: "Jane", age: 30, gender: "Female" },
  ]);

  // const styles = StyleSheet.create({
  //   page: {
  //     flexDirection: "column",
  //     backgroundColor: "#fff",
  //     padding: 40,
  //   },
  //   logo: {
  //     position: "absolute",
  //     top: 0,
  //     right: 40,
  //     width: 50,
  //     height: 50,
  //   },
  //   section: {
  //     margin: 10,
  //   },
  //   header: {
  //     backgroundColor: "#F5F5F5",
  //     borderBottomWidth: 1,
  //     borderBottomColor: "#000",
  //     padding: 10,
  //   },
  //   cell: {
  //     padding: 10,
  //     borderBottomWidth: 1,
  //     borderBottomColor: "#F5F5F5",
  //     fontSize: 12,
  //   },
  //   table: {
  //     marginBottom: 20,
  //     borderCollapse: "collapse",
  //     width: "100%",
  //   },
  //   th: {
  //     textAlign: "left",
  //     fontWeight: "bold",
  //   },
  //   tr: {
  //     backgroundColor: "#fff",
  //   },
  //   odd: {
  //     backgroundColor: "#F5F5F5",
  //   },
  // });

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Age</th>
            <th>Gender</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>{row.name}</td>
              <td>{row.age}</td>
              <td>{row.gender}</td>
            </tr>
          ))}
        </tbody>
      </table>
   
      <br />
 
    </div>
  );
};

export default MyTable;
