import React, { useState, useEffect } from 'react'
import { Button, Table, Form, FormControl, Modal, FormSelect } from 'react-bootstrap';
import { saveAs } from 'file-saver';

// auth imports
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../serverUrl';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

function WalletCheckers() {

    const { id } = useParams();


    // get the user who is logged in and the date related to them
    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;
    const [addTemplate, setAddTemplate] = useState(false);

    const handleReminderModalOpen = () => {
        setAddTemplate(true);
    };

    const handleReminderModalClose = () => {
        setAddTemplate(false);
    };

    const [templates, setAr] = useState([])
    const [landlord, setLanlordId] = useState('')

    // const authorizer = [
    //     {
    //         name: "Faith Mwende",
    //         phone: "075124562",
    //         type: "checker",
    //     },
    //     {
    //         name: "Alex Mwendwa",
    //         phone: "075124562",
    //         type: "Maker",
    //     },
    // ]
    const activeProperty = localStorage.getItem('active_wallet_property')
    const [authorizer, setAuthorizer] = useState([])
    const [landlordCount, setLandlordCount] = useState('')
    const [landlordApartment, setLandlordApartment] = useState('')

    useEffect(() => {
        serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
            .then((res) => {

                // get the total number of sms templates
                serverUrl.get(`/landlord/property_landlord_details?property_id=${activeProperty}`, config)
                    .then((res) => {
                        setAuthorizer(res.data.authorizers)
                    })
                    .catch((error) => {
                        console.log(res)
                    })
            })
            .catch((error) => {
                console.log(error);
            });

        serverUrl.get(`/landlord/property_landlord_details?property_id=${activeProperty}`, config)
            .then((res) => {
                setLandlordCount(res.data.count)
                setLandlordApartment(res.data.results)
            })
    }, []);

    const [formData, setFormData] = useState({
        landlord: "",
        authorizer_type: "",

    });

    //   handling the change of the data in the form
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    // submit the data to add the template
    function handleAddTemplate(e) {
        // prevent form from redirecting to another page
        e.preventDefault();

        // data to be sent to the backend
        const data = {
            property: activeProperty,
            landlord: formData.landlord,
            authorizer_type: formData.authorizer_type,

        }
        console.log(data)
        // send the data to the backend 
        try {
            serverUrl.post('/landlord/wallet-authorizers/', data, config)
                .then((res) => {
                    toast.success("Authorizer added succesfully!")
                    handleReminderModalClose()

                    serverUrl.get(`/landlord/property_landlord_details?property_id=${activeProperty}`, config)
                        .then((res) => {
                            setAuthorizer(res.data.authorizers)
                        })
                        .catch((error) => {
                            console.log(res)
                        })

                    setFormData({
                        landlord: "",
                        authorizer_type: "",
                    })
                })
                .catch((error) => {
                    toast.error(error.response.data.error)
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    // delete the template
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedTemplateId, setSelectedTemplateId] = useState(null)

    const handleDeleteModalOpen = (templateId) => {
        setDeleteModal(true);
        setSelectedTemplateId(templateId)
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    function handleDeleteTemplate(e) {
        // prevent form from redirecting to another page
        e.preventDefault();
        // delete the message template that is selected
        serverUrl.delete(`/communication/message-templates/${selectedTemplateId}/`, config)
            .then((res) => {
                // Update the state to remove the deleted template
                setAuthorizer((prevTemplates) =>
                    prevTemplates.filter((template) => template.id !== selectedTemplateId)
                );
                handleDeleteModalClose()
                toast.success("Successfully deleted the template!")

            })
            .catch((error) => {
                console.log(error)
            })
    }

    // update the template
    const [updateModal, setUpdateModal] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)

    const handleUpdateModalOpen = (templateId) => {
        setUpdateModal(true);
        setSelectedTemplate(templateId)
    };

    const handleUpdateModalClose = () => {
        setUpdateModal(false);
    };

    const [formDataEdit, setFormDataEdit] = useState({
        subject: "",
        content: "",

    });

    //   handling the change of the data in the form
    const handleChangeEdit = (event) => {
        const { name, value } = event.target;
        setFormDataEdit((prevFormDataEdit) => ({
            ...prevFormDataEdit,
            [name]: value,
        }));
    };

    function handleUpdateTemplate(e) {
        // prevent form from redirecting to another page
        e.preventDefault();
        // update the message template that is selected
        // data to be sent to the backend
        const data = {
            title: formDataEdit.subject,
            text: formDataEdit.content,
            created_by_message_template: userId,
            updated_by_message_template: userId
        }

        serverUrl.patch(`/communication/message-templates/${selectedTemplate}/`, data, config)
            .then((res) => {
                // Update the local template state manually
                const updatedTemplate = res.data;
                const updatedTemplates = templates.map(template =>
                    template.id === updatedTemplate.id ? updatedTemplate : template
                );
                setAuthorizer(updatedTemplates);

                handleUpdateModalClose()
                toast.success("Successfully updated the template!")

            })
            .catch((error) => {
                console.log(error)
            })
    }
    const exportCSV = () => {
        const csvData = [
            ['Name', 'Phone Number', 'Authorizer Type'],
            ...authorizer.map((item) => [item.name, item.phone, item.type]),
        ];
        const csvBlob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvBlob, 'Authorizers.csv');
    };

    const [sortType, setSortType] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSort = (sortField) => {
        setSortType(sortField);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };



    // const sortedData = templates.sort((a, b) => {
    const sortedData = authorizer.sort((a, b) => {

        const sortValueA = typeof a[sortType] === 'string' ? a[sortType].toLowerCase() : a[sortType];
        const sortValueB = typeof b[sortType] === 'string' ? b[sortType].toLowerCase() : b[sortType];
        if (sortOrder === 'asc') {
            return sortValueA > sortValueB ? 1 : -1;
        } else {
            return sortValueA < sortValueB ? 1 : -1;
        }
    });

    const filteredData = sortedData.filter((item) => {
        const searchableFields = Object.values(item).join(' ').toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });

    const handleRowClick = (item) => {
        setSelectedRow(item);
    };

    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);


    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    return (
        <>
            {/* list of the tenants of the apartment and payment details */}
            <div className='tasks mt-5'>
                <h2 style={{ color: "#e67e22" }}>Wallet Checkers </h2>

                <div className='d-flex justify-content-between '>
                    <p></p>
                    {landlordCount && landlordCount === 0 ?
                        <></>
                        :
                        <Button className='btn btn-sm float-end' onClick={handleReminderModalOpen}>Add Authorizer</Button>
                    }
                </div>

                <div className='d-flex justify-content-between mt-2'>

                    <div>
                        <Button className='btn btn-success text-white me-3' onClick={exportCSV}> EXCEL <i className='bi bi-file-earmark-excel '></i></Button>
                    </div>
                    <Form className="searchBar">
                        <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                    </Form>
                </div>
                <Table className='table mt-2'>
                    <thead className='underline'>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Authorizer Type</th>
                        {/* <th>Actions</th> */}



                    </thead>
                    <tbody>
                        {displayedItems.map((template) => (
                            <tr >
                                <td>{template.full_name}</td>
                                <td>{template.phone_number}</td>
                                <td className='text-capitalize'>{template.authorizer_type}</td>

                                {/* <td>
                                    <i
                                        className='bi bi-trash-fill text-danger'
                                        onClick={() => handleDeleteModalOpen(template.id)}
                                    ></i>
                                </td> */}
                                {/* modal to delete the template */}
                                <Modal show={deleteModal} onHide={handleDeleteModalClose}>
                                    <Modal.Header className='bg-danger text-white' closeButton>

                                        <Modal.Title>Delete Authorizer?</Modal.Title>

                                    </Modal.Header>
                                    <Modal.Body>

                                        <Form onSubmit={handleDeleteTemplate}>
                                            <p> Are you sure you want to delete this Authorizer?</p>

                                            <Modal.Footer className="d-flex justify-content-between">
                                                <Button variant='danger' type='submit'>
                                                    Yes
                                                </Button>

                                                <Button variant='success' onClick={handleDeleteModalClose}>
                                                    No
                                                </Button>

                                            </Modal.Footer>
                                        </Form>


                                    </Modal.Body>

                                </Modal>
                            </tr>
                        ))}




                    </tbody>
                    {/* modal for adding authorizers */}
                    <Modal show={addTemplate} onHide={handleReminderModalClose}>
                        <Modal.Header className='bg-primary text-white' closeButton>

                            <Modal.Title>Add Authorizer</Modal.Title>

                        </Modal.Header>
                        <Modal.Body>

                            <Form onSubmit={handleAddTemplate}>

                                <div className='mb-3'>
                                    <label className='withLabel w-100'>Authorizer</label>

                                    <FormSelect name='landlord' onChange={handleChange} value={formData.landlord} required>
                                        <option selected disabled value=''>Select authorizer to add</option>
                                        {landlordApartment &&
                                            landlordApartment.map((landlord) => (
                                                <>
                                                    <option value={landlord.landlord_id}>{landlord.full_name}</option>
                                                </>
                                            ))}
                                    </FormSelect>
                                </div>
                                <div className='mb-3'>
                                    <label className='withLabel w-100'>Authorizer Type</label>

                                    <FormSelect required name='authorizer_type' onChange={handleChange} value={formData.authorizer_type}>
                                        <option selected disabled value=''>Select authorizer to add</option>
                                        <option value='maker'>Maker</option>
                                        <option value='checker'>Checker</option>
                                    </FormSelect>
                                </div>

                                
                            </Form>


                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-between">
                        <Button variant='primary' type='submit' onClick={handleAddTemplate}>
                                    Add
                                </Button>
                            <Button variant='danger' onClick={handleReminderModalClose}>
                                Close
                            </Button>

                        </Modal.Footer>
                    </Modal>
                </Table>
                <div className="pagination">
                    <button onClick={handlePrevPage} disabled={currentPage === 0}>
                        &lt;
                    </button>
                    <span>{`${startIndex + 1}-${endIndex} of ${authorizer.length}`}</span>
                    <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                        &gt;
                    </button>
                </div>
            </div>
        </>
    )
}

export default WalletCheckers