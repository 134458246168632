import React, { useState,useEffect } from 'react'
import Header from '../Header';
import SideBar from '../SideBar';
import './TenantReports.scss'
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Card, Table, Image, Button } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

import House from '../../../assets/img/rent2.png'
import Due from '../../../assets/img/budget.png'
import HistoryReport from './HistoryReport';
import IssueReport from './IssueReport';

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../../serverUrl";
import ReceiptReport from './ReceiptReport';
import InvoiceReport from './InvoiceReport';
import PaymentImage from './PaymentImage';

function TenantReports() {
    // sidebar and header functions
    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    const { id } = useParams();
    // house selected
    const [houseSelected, setHouseSelected] = useState([])
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    // get the list of the houses

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);

// get the house selected by user
    useEffect(() => {
        setIsLoading(true)
        serverUrl.get(`/landlord/house/${id}/`, config)
            .then((res) => {
                setHouseSelected(res.data)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })
        // get the tenant of the house
        serverUrl.get(`/tenant/occupancy/?house_id=${id}`, config)
            .then((res) => {

                serverUrl.get(`/tenant/tenant/${res.data.results[0].tenant}`, config)
                    .then((res) => {
                        // console.log(res.data)
                        serverUrl.get(`/users/users-details/${res.data.user}/`, config)
                            .then((res) => {
                                setIsLoading(false)
                            })
                            .catch((error) => {
                                console.log(error);
                                setIsLoading(false)
                            })

                    })
                    .catch((error) => {
                        console.log(error);
                        setIsLoading(false)
                    })

            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })


    }, []);

// declare the selected house
  const selectedUnit = houseSelected
    const idHouse = parseInt(id)
    // get the default tenants

    const Defaulters = [
        {
            name: 'K12',
            income: '25,000',
            Received: '20,000',
            months: '11',
            tenant: '2',
            mobile: '0723456789',
            tenant_name: 'Jane Muthoni'
        },
        {
            name: 'A02',
            income: '45,000',
            Received: '10,000',
            months: '9',
            tenant: '8',
            mobile: '0712345678',
            tenant_name: 'Peter Mwangi'
        },
        {
            name: 'C20',
            income: '50,000',
            Received: '41,000',
            months: '4',
            tenant: '2',
            mobile: '0798765432',
            tenant_name: 'Grace Wanjiku'
        },
        {
            name: 'K23',
            income: '35,000',
            Received: '28,000',
            months: '8',
            tenant: '3',
            mobile: '0712345678',
            tenant_name: 'Mary Kamau'
        },
        {
            name: 'B11',
            income: '30,000',
            Received: '25,000',
            months: '6',
            tenant: '2',
            mobile: '0723456789',
            tenant_name: 'Samuel Maina'
        },
        {
            name: 'D08',
            income: '40,000',
            Received: '32,000',
            months: '2',
            tenant: '4',
            mobile: '0798765432',
            tenant_name: 'Lucy Njeri'
        },
        {
            name: 'F15',
            income: '20,000',
            Received: '15,000',
            months: '10',
            tenant: '2',
            mobile: '0723456789',
            tenant_name: 'Simon Gitau'
        },
        {
            name: 'G22',
            income: '28,000',
            Received: '22,000',
            months: '9',
            tenant: '2',
            mobile: '0712345678',
            tenant_name: 'Faith Wairimu'
        },
        {
            name: 'H17',
            income: '38,000',
            Received: '30,000',
            months: '5',
            tenant: '3',
            mobile: '0798765432',
            tenant_name: 'Brian Mwangi'
        },
        {
            name: 'J10',
            income: '42,000',
            Received: '35,000',
            months: '6',
            tenant: '2',
            mobile: '0723456789',
            tenant_name: 'Caroline Wambui'
        },
        {
            name: 'K09',
            income: '27,000',
            Received: '24,000',
            months: '1',
            tenant: '2',
            mobile: '0712345678',
            tenant_name: 'Michael Waweru'
        },
        {
            name: 'M05',
            income: '32,000',
            Received: '28,000',
            months: '2',
            tenant: '3',
            mobile: '0798765432',
            tenant_name: 'Grace Akinyi'
        },
        {
            name: 'N12',
            income: '36,000',
            Received: '30,000',
            months: '1',
            tenant: '2',
            mobile: '0723456789',
            tenant_name: 'Trufena Wafula'

        }
    ]

    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;

    const totalPages = Math.ceil(Defaulters.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? Defaulters.length : startIndex + itemsPerPage;
    const displayedItems = Defaulters.slice(startIndex, endIndex);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    return (
        <>
            <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
            {showSidebar && (
                <SideBar />
            )}
            <div className='report_Tenant'>
                 {
                selectedUnit.id === idHouse ?
                <>
                 <ReceiptReport/>
                <InvoiceReport/>
                  <HistoryReport/>
                <IssueReport/>
                <PaymentImage/>
               
                </>
                :
                <></>}
              
           </div>
        </>
    )
}

export default TenantReports