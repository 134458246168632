import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import './BottomNavbar.css'

function BottomNavbar() {
  return (
    <div className='navbarBottom'> 
      <Navbar>
        <Container>
          <Nav.Item>
            <NavLink to="/home" className="NavLink" activeClassName="active">
              <i className="bi bi-house navlinkicon"></i>
              <span className='navlinkSpan'>Home</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/configure" className="NavLink" activeClassName="active">
              <i className="bi bi-gear navlinkicon"></i>
              <span className='navlinkSpan'>Configure</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/utilities" className="NavLink" activeClassName="active">
              <i className="bi bi-house-gear navlinkicon"></i>
              <span className='navlinkSpan'>Utilities</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/reports" className="NavLink" activeClassName="active">
              <i className="bi bi-tools navlinkicon"></i>
              <span className='navlinkSpan'>Reports</span>
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink to="/profile" className="NavLink" activeClassName="active">
              <i className="bi bi-person navlinkicon"></i>
              <span className='navlinkSpan'>Profile</span>
            </NavLink>
          </Nav.Item>
        </Container>
      </Navbar>
    </div>
  )
}

export default BottomNavbar
