import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import House6 from '../../assets/img/house6.jpg';
import House7 from '../../assets/img/house7.jpg';
import House8 from '../../assets/img/house9.jpg';

// auth imports
import jwt_decode from 'jwt-decode';
import { serverUrl } from '../../serverUrl';

function SelectedHouseCarousel({ houseId }) {
  // loading status
  const [isLoading, setIsLoading] = useState(true);
  // the selected house Images
  const [unitSelected, setUnitSelected] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Update this line to show only one slide
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    prevArrow: <button type="button" className="slick-prev"></button>,
    nextArrow: <button type="button" className="slick-next"></button>,
  };
  // Get the house object with the matching id
  const imageDemo = [House6, House7, House8];

  // Setting up the image
  const [currentImage, setCurrentImage] = useState(0);
  const [imageLength, setImageLength] = useState(0);
  const [imagesUnit, setImagesUnit] = useState([]);

  // headers configurations
  const accessToken = localStorage.getItem('authTokens');
  // get the access token
  const parsedTokens = JSON.parse(accessToken);
  const access = parsedTokens.access;

  // headers access token
  const config = {
    headers: { Authorization: `Bearer ${access}` },
  };

  // get the images within the house
  useEffect(() => {
    setIsLoading(true);
    serverUrl
      .get(`landlord/house-images/?house_id=${houseId}`, config)
      .then((res) => {
        setImageLength(res.data.results.length);
        setImagesUnit(res.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
  // get the length of the images and how they will be displayed

  useEffect(() => {
    setIsLoading(true);

    const timer = setInterval(() => {
      setCurrentImage((currentImage + 1) % imageLength);
    }, 10000); // changing after 10 seconds
    setIsLoading(false);

    return () => {
      clearInterval(timer);
      setIsLoading(false);
    };
  }, [currentImage, imageLength]);
  // end of image setup

  if (isLoading) {
    return <div>Loading Image</div>;
  }

  return (
    <>
      {imageLength === 0 ? (
        <Slider {...settings} className="imageDemo">
          {imageDemo.map((imageUrl) => (
            <div key={imageUrl} className="selectedHouseCarousel">
              <img src={imageUrl} alt="carousel slide" />
            </div>
          ))}
        </Slider>
      ) : (
        <Slider {...settings} className="imageHouse">
          {imagesUnit.map((imageUrl) => (
            <div key={imageUrl} className="selectedHouseCarousel">
              <img src={imageUrl.image} alt="carousel slide" />
            </div>
          ))}
        </Slider>
      )}
    </>
  );
}

export default SelectedHouseCarousel;
